import { Injectable, Inject } from '@angular/core';
import { Store } from '@ngrx/store';

import * as actions from '@shared/state/actions';
import * as State from '@shared/state/interface';
import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { CryptoService } from './crypto.shared.service';
import { LocationsService } from './locations.shared.service';
import { AppSettingsService } from './app-settings.shared.service';
import { VenueImagesService } from './venue-images.shared.service';

@Injectable({
    providedIn: 'root',
})
export class InitService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public store: Store<State.IStateShared>,
        public cryptoService: CryptoService,
        public locationsService: LocationsService,
        public appSettingsService: AppSettingsService,
        public venueImagesService: VenueImagesService,
    ) { }

    public async unsetSplashLoader(): Promise<boolean> {
        return true;
        /* 
            <div class="splash">
        <div class="splash-loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>

    <style type="text/css">
        .splash {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 888;
            background-color: var(--color-brand-accent, orange);
        }

        .splash.-out {
            animation: splash-loader-out 0.5s 1 forwards;
        }

        .splash-loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 80px;
            height: 80px;
        }

        .splash-loader div {
            position: absolute;
            top: 33px;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background-color: var(--color-hero-ui, white);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
        }

        .splash-loader div:nth-child(1) {
            left: 8px;
            animation: splash-loader1 0.6s infinite;
        }

        .splash-loader div:nth-child(2) {
            left: 8px;
            animation: splash-loader2 0.6s infinite;
        }

        .splash-loader div:nth-child(3) {
            left: 32px;
            animation: splash-loader2 0.6s infinite;
        }

        .splash-loader div:nth-child(4) {
            left: 56px;
            animation: splash-loader3 0.6s infinite;
        }

        @keyframes splash-loader-out {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
            }
        }

        @keyframes splash-loader1 {
            0% {
                transform: scale(0);
            }

            100% {
                transform: scale(1);
            }
        }

        @keyframes splash-loader3 {
            0% {
                transform: scale(1);
            }

            100% {
                transform: scale(0);
            }
        }

        @keyframes splash-loader2 {
            0% {
                transform: translate(0, 0);
            }

            100% {
                transform: translate(24px, 0);
            }
        }
    </style>
        */
        // return new Promise(resolve => {
        //     const getElem = () => document.querySelector('.splash') || null;

        //     if (!getElem()) return resolve(false);
        //     setTimeout(() => {
        //         getElem().classList.add('-out');
        //         setTimeout(() => {
        //             try {
        //                 const splashElem = getElem();
        //                 if (splashElem) {
        //                     document.body.removeChild(splashElem);
        //                 }
        //             } catch (ex) {

        //             }
        //         }, 500);
        //     }, 1000);
        // });
    }

    public async init(cleanSessionRedirect: boolean = false): Promise<boolean> {
        return new Promise(resolve => {

            /*
                Set base title for whole app getting data from config - base title is set in config file
                This is slow solution. Base title should be set directly in index.html file.
            */
            if (this.config.title) {
                Utils.Meta.setTags({ title: this.config.title });
            }

            /*
                Some payment methods require redirecting and restoring state
            */
            if (Utils.Redirect.isRedirecting() && !cleanSessionRedirect) {
                return resolve(false);
            }
            Utils.Redirect.unsetRedirect();
            Utils.Storage.remove(OLO.Enums.SESSION_STORAGE.STATE, 'sessionStorage');

            /* Check for session data */
            this.store.dispatch(actions.MemberSessionRequest());

            /*
                Set app mode and device type
            */
            this.appSettingsService.init();

            /*
                Get venue image if neede
            */
            if (this.config.venue && this.config.venue.id) {
                this.venueImagesService.requestVenueImage();
            }



            /* Restore current pickup time */
            try {
                const pickupTime: string | undefined = Utils.Storage.getItem(OLO.Enums.USER_STORAGE.CURRENT_PICKUP_TIME as unknown as string);

                if (pickupTime) {
                    let json: OLO.Ordering.IPickupTime = JSON.parse((pickupTime as unknown) as string);
                    json.PlaceOrderTimeout = new Date(json.PlaceOrderTimeout);
                    json.Date = new Date(json.Date);

                    this.store.dispatch(actions.CurrentLocationPickupTimeRestore(json));
                }

            } catch (ex) {
                console.error('Unable to restore current pickup time');
            }

            /* Load saved cart contents */
            try {
                const cartData: string | undefined = Utils.Storage.getItem(OLO.Enums.CART_STORAGE.DATA as unknown as string);

                if (cartData) {
                    let json: State.ICart = JSON.parse(this.cryptoService.decrypt(cartData as string));
                    if (!json) throw new Error('Bad cart data');
                    this.store.dispatch(actions.CartLoad({ cart: json }));
                }

            } catch (ex) {
                Utils.Storage.remove(OLO.Enums.CART_STORAGE.DATA as unknown as string);
                console.error('Unable to restore cart\'s contents');
            }

            /* Set previously saved locationNo */
            const currentLocationNo: string = Utils.Storage.getItem(OLO.Enums.USER_STORAGE.CURRENT_LOCATION as unknown as string);
            if (currentLocationNo && !Number.isNaN(+currentLocationNo)) {
                this.store.dispatch(actions.CurrentLocationSet({ locationNo: +currentLocationNo }));
            }

            /* Request locations */
            this.locationsService.requestLocations();

            /* Confirm application initializations */
            setTimeout(() => {
                this.appSettingsService.setInitAppInitFlag();
                resolve(true);
            }, 0);
        });

    }
}
