import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as State from '@shared/state/interface';
import * as Utils from '@shared/core/utils';
import * as Tokens from '@shared/core/tokens';

import { LocationsService } from './locations.shared.service';
import { RouteService } from './route.shared.service';
import { OnlineMenuService } from './online-menu.shared.service';
import { ModalsService } from './modals.shared.service';

import { Observable, of } from 'rxjs';
import { tap, take, filter, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class LocationDetailsService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public store: Store<State.IStateShared>,
        public actions$: Actions,
        public locationsService: LocationsService,
        public routeService: RouteService,
        public onlineMenuService: OnlineMenuService,
        public modalsService: ModalsService,
    ) { }

    public accessDeniedModal(): boolean {
        const id: number = new Date().getTime();

        this.modalsService.show({
            id,
            type: 'alert',
            params: {
                showButton: true,
                buttonLabel: 'find another store',
                title: 'Out of Hours',
                body: `The store is currently not open
                            for online ordering service.
                            Please check back during standard business hours.`
            }
        });

        this.actions$
            .pipe(
                ofType(
                    actions.ModalClose,
                ),
                take(1),
            ).subscribe((action) => {
                this.routeService.navigateToLocationsSearchView();
            });

        return false;
    }

    public canEnterCurrentLocation$(): Observable<{
        locationNo: number;
        locationDetails: APIv1.LocationBusinessModel;
    }> {
        this.store.dispatch(actions.CurrentLocationValidationRequest());

        const accessDenied = () => {
            this.store.dispatch(actions.CurrentLocationValidationErrorRequest());
            return of(null);
        };

        return this.store
            .pipe(
                select(selectors.currentLocationNoByRoute),
                filter(locationNo => locationNo !== undefined),
                switchMap(locationNo => {
                    if (!locationNo) return accessDenied();

                    /* Check if user can activate this location #2/2 */
                    return this.store
                        .pipe(
                            select(selectors.isLocationLoading(locationNo)),
                            filter(isLoading => isLoading === false),
                            withLatestFrom(
                                this.store.pipe(
                                    select(selectors.canOrderFromLocation(locationNo, this.config))
                                ),
                                this.store
                                    .pipe(
                                        select(selectors.getLocationDetails(locationNo)),
                                    ),
                            ),
                            switchMap(([isLoading, canEnterLocation, locationDetails]) => {
                                if (!canEnterLocation) return accessDenied();
                                this.store.dispatch(actions.CurrentLocationSet({ locationNo }));
                                this.store.dispatch(actions.CurrentLocationValidationSuccessRequest());

                                return of({
                                    locationNo,
                                    locationDetails
                                });
                            }),
                        );
                }),
                take(1)
            );
    }
}
