import { Injectable, Inject } from '@angular/core';
import { Store, select, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';
import * as Services from '@shared/core/services';

import * as StateModels from '../interface';

import { Observable, of, forkJoin } from 'rxjs';
import { catchError, map, switchMap, take, filter, withLatestFrom, delay, mergeMap } from 'rxjs/operators';


@Injectable()
export class PaymentsEffects {
    @Effect() public paymentInitWithPrevalidatedCard$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.PaymentInit
            ),
            withLatestFrom(
                this._store.pipe(select(selectors.getCardState)),
                this._store.pipe(select(selectors.getMemberState)),
                this._store.pipe(select(selectors.paymentHasErrors)),
                this._store.pipe(select(selectors.getPaymentStepsStatus)),
                this._store.pipe(select(selectors.getPaymentState))
            ),
            switchMap(([action, cardState, memberState, paymentHasErrors, step, paymentState]) => {
                if (paymentHasErrors) {
                    if (paymentState.OrderId && paymentState.data.TransactionId) {
                        if (this._config.demoMode === true) {
                            return [
                                actions.PaymentClearErrors(),
                                actions.__DEMO__PaymentStepPaymentStatusCheck({ TransactionId: paymentState.data.TransactionId, OrderId: paymentState.OrderId }),
                            ];
                        }

                        return [
                            actions.PaymentClearErrors(),
                            actions.PaymentStepPaymentStatusCheck({ TransactionId: paymentState.data.TransactionId, OrderId: paymentState.OrderId }),
                        ];
                    }

                    return this._error(`#33 Payment process has errors`);
                }

                if (step !== 'init') {
                    return this._error(`#37 Payment step error. Should be "init", is: ${step}`);
                }

                if (this._config.paymentProvider === undefined || this._config.paymentProvider === null) {
                    return this._error('#41 Payment provider not defined. Defined it config.js file');
                }

                const isGuest: boolean = memberState.isGuestModeEnabled && memberState.guestData !== null;
                if (isGuest) {
                    return [
                        actions.MemberValidateEmailDataRequest({ email: memberState.guestData.Email }),
                        actions.MemberValidatePhoneRequest({ phone: memberState.guestData.MobileNumber }),
                        actions.PaymentStepValidateGuestData(null),
                    ];
                }

                if (this._config.demoMode === true) {
                    return of(actions.__DEMO__PaymentStepCreateOrder());
                }

                /* For members */
                return of(actions.PaymentStepCreateOrder());
            })
        );

    @Effect() public paymentInitAramarkFlow$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.PaymentInitAramark
            ),
            withLatestFrom(
                this._store.pipe(select(selectors.paymentHasErrors)),
                this._store.pipe(select(selectors.getPaymentStepsStatus)),
            ),
            switchMap(([action, paymentHasErrors, step]) => {
                if (paymentHasErrors) {
                    return this._error(`#73 Payment process has errors`);
                }

                if (step !== 'init') {
                    return this._error(`#77 Payment step error. Should be "init", is: ${step}`);
                }

                if (this._config.paymentProvider === undefined || this._config.paymentProvider === null) {
                    return this._error('#81 Payment provider not defined. Defined it config.js file');
                }

                if (action.member) {
                    /* For guests */
                    return [
                        actions.MemberVerifyEmailRestoreFlags(),
                        actions.MemberVerifyPhoneRestoreFlags(),
                        actions.MemberGuestDataReset(),
                        actions.MemberGuestDataSet({ guestData: action.member as APICommon.IOnlineOrderPartialMember }),
                        actions.CreditCardTokenDataReset(),

                        actions.MemberValidateEmailDataRequest({ email: action.member.Email }),
                        actions.MemberValidatePhoneRequest({ phone: action.member.MobileNumber }),

                        actions.PaymentStepValidateGuestData(action.creditCard),
                    ];
                }

                if (this._config.demoMode === true) {
                    return of(actions.__DEMO__PaymentStepCreateOrder());
                }

                /* For members */
                return of(actions.PaymentStepCreateOrder());
            })
        );

    @Effect() public stepValidateGuestData$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.PaymentStepValidateGuestData),
            switchMap(action => {
                return this._store
                    .pipe(
                        select(selectors.getMemberState),
                        filter(member => member.validateEmail.isValidating !== true && member.validatePhone.isValidating !== true),
                        take(1),
                        switchMap(state => {
                            if (state.validateEmail.hasFailed || state.validatePhone.hasFailed) {
                                return this._error('#120 Invalid guest\'s email address or phone number');
                            }

                            return forkJoin(
                                this._membersService.getMembers({ mobilePhoneNumber: state.guestData.MobileNumber }),
                                this._membersService.getMembers({ memberEmail: state.guestData.Email }),
                            ).pipe(
                                withLatestFrom(
                                    this._store.pipe(select(selectors.paymentHasErrors)),
                                    this._store.pipe(select(selectors.getPaymentStepsStatus)),
                                ),
                                switchMap(([[byPhone, byEmail], paymentHasErrors, step]) => {
                                    if (paymentHasErrors) {
                                        return this._error(`#133 Payment process has errors`);
                                    }

                                    if (step !== 'validate_guest') {
                                        return this._error(`#137 Payment step error. Should be "validate_guest", is: ${step}`);
                                    }

                                    const nextStep = () => {
                                        if (this._config.demoMode === true) {
                                            return of(actions.__DEMO__PaymentStepCreateOrder());
                                        }

                                        /* If card is provided but not saved, run these steps */
                                        if (action.creditCard) {
                                            switch (this._config.paymentProvider) {
                                                case OLO.Enums.PAYMENT_PROVIDER.CONVERGE:
                                                    return [
                                                        actions.GetCreditCardToken({
                                                            cardNumber: action.creditCard.cardNo,
                                                            expiryDate: action.creditCard.expDate,
                                                            saveCard: action.creditCard.save
                                                        }
                                                        ),
                                                        actions.PaymentStepValidateGuestCardToken()
                                                    ];

                                                case OLO.Enums.PAYMENT_PROVIDER.PAYMENT_EXPRESS:
                                                    return of(actions.PaymentStepCreateOrder());
                                                // return [
                                                //     actions.GetCreditCardToken({
                                                //         cardNumber: action.creditCard.cardNo,
                                                //         expiryDate: action.creditCard.expDate,
                                                //         saveCard: action.creditCard.save
                                                //     }),
                                                // ];

                                                case OLO.Enums.PAYMENT_PROVIDER.CARD_CONNECT:
                                                    console.warn('TODO - HANDLE CARD CONNECT');
                                                    return [];

                                                default:
                                                    console.error('Payment provider not provided in config.js');
                                                    return [];
                                            }
                                        }

                                        return of(actions.PaymentStepCreateOrder());
                                    };

                                    const memberByPhone: APIv1.MemberModel = byPhone.Items[0];
                                    const memberByEmail: APIv1.MemberModel = byEmail.Items[0];

                                    const isUpdatedRequired: boolean = memberByPhone && memberByEmail && memberByPhone.MemberId !== memberByEmail.MemberId
                                        || memberByPhone && !memberByEmail && memberByPhone.EmailAddress !== state.guestData.Email;
                                    if (isUpdatedRequired) {
                                        /* We update member with phone number found */
                                        const updatedModel: APIv1.MemberModel = {
                                            ...memberByPhone,
                                            EmailAddress: state.guestData.Email,
                                        };

                                        return this._membersService.updateUser(updatedModel)
                                            .pipe(
                                                switchMap(success => {
                                                    if (!success) throw new Error('Error on user update request');

                                                    return nextStep();
                                                })
                                            );
                                    }

                                    return nextStep();

                                })
                            );
                        })
                    );
            }),
            catchError(ex => {
                return this._error('#212 Payment failed', ex);
            })

        );

    @Effect() public stepValidateGuestCardTokens$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.PaymentStepValidateGuestCardToken
            ),
            switchMap(action => {
                return this._store
                    .pipe(
                        select(selectors.getCardState),
                        filter(cardsState => cardsState.token.isGettingToken === false && (cardsState.token.hasSucceeded === true && cardsState.activeCardToken !== null || cardsState.token.hasFailed === true)),
                        take(1),
                        withLatestFrom(
                            this._store.pipe(select(selectors.paymentHasErrors)),
                            this._store.pipe(select(selectors.getPaymentStepsStatus)),
                        ),
                        switchMap(([cardsState, paymentHasErrors, step]) => {
                            if (paymentHasErrors) {
                                return this._error(`#234 Payment process has errors`);
                            }

                            if (step !== 'validate_card_token') {
                                return this._error(`#238 Payment step error. Should be "validate_card_token", is: ${step}`);
                            }

                            if (cardsState.token.hasFailed || cardsState.activeCardToken === null) {
                                return this._error('#242 Unable to get card token');
                            }

                            return of(actions.PaymentStepCreateOrder());
                        })
                    );
            })
        );

    @Effect() public stepCreateOrder$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.PaymentStepCreateOrder,
            ),
            withLatestFrom(
                this._store.pipe(select(selectors.canPostOnlineOrder)),
                this._store.pipe(select(selectors.isPaymentProcessValid)),
                this._store.pipe(select(selectors.paymentHasErrors)),
                this._store.pipe(select(selectors.getPaymentStepsStatus)),
            ),
            switchMap(([action, canPostOnlineOrder, isPaymentProcessValid, paymentHasErrors, step]) => {
                if (!canPostOnlineOrder || !isPaymentProcessValid || paymentHasErrors) {
                    return this._error('#264 Unable to create order - insufficient data or data corrupted');
                }

                if (step !== 'create_order') {
                    return this._error(`#268 Payment step error. Should be "create_order", is: ${step}`);
                }

                return [
                    actions.OnlineOrderClearPostOrderRequestFlags(),
                    actions.OnlineOrderCreateRequest(),
                    actions.PaymentStepPay(),
                ];
            })
        );

    @Effect() public stepPay$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.PaymentStepPay
            ),
            switchMap(action => {

                return this._store
                    .pipe(
                        select(selectors.getOnlineOrderState),
                        filter(orderState => orderState.createRequest.isCreating === false),
                        take(1),
                        withLatestFrom(
                            this._store.pipe(select(selectors.getActiveCardId)),
                            this._store.pipe(select(selectors.getActiveCardToken)),
                            this._store.pipe(select(selectors.getOnlineOrder)),
                            this._store.pipe(select(selectors.getActiveCardDetails)),
                            this._store.pipe(select(selectors.isAccountChargeSelected))
                        ),
                        switchMap(([orderState, activeCardId, activeCardToken, onlineOrder, cardDetails, isAccountChargeSelected]) => {
                            if (orderState.createRequest.hasFailed) {
                                return this._error('#299 Unable to create order');
                            }

                            return this._store
                                .pipe(
                                    select(selectors.isCartLocationsPickupsCalculating),
                                    filter(isCalculating => isCalculating === false),
                                    take(1),
                                    withLatestFrom(
                                        this._store.pipe(select(selectors.paymentHasErrors)),
                                        this._store.pipe(select(selectors.getPaymentStepsStatus)),
                                    ),
                                    switchMap(([calculated, paymentHasErrors, step]) => {
                                        if (paymentHasErrors) {
                                            return this._error('#312 Unable to create order due to payment process errors');
                                        }

                                        if (step !== 'paying') {
                                            return this._error(`#317 Payment step error. Should be "paying", is: ${step}`);
                                        }
                                        if (isAccountChargeSelected) {
                                            /* Handle account charge */
                                            return this._paymentsService.payWithAccountCharge(onlineOrder.Id, {
                                                Amount: onlineOrder.TotalGrossValue,
                                                SetOrderAsValidatedOnSuccess: true,
                                            })
                                                .pipe(
                                                    switchMap(payload => {
                                                        switch (payload.Status) {
                                                            case OLO.Enums.PAYMENT_STATUS.SUCCESS:
                                                                return of(actions.PaymentStepComplete({ OrderId: onlineOrder.Id, payload }));

                                                            case OLO.Enums.PAYMENT_STATUS.FAILED:
                                                                return [
                                                                    actions.PaymentReset(),
                                                                    ...this._error('#373 Payment declined by Payment Provider. Status: ' + payload.Status, payload.Status)
                                                                ];
                                                        }
                                                    }),
                                                );
                                        }

                                        const OrderId: number = onlineOrder.Id;
                                        const PaymentMethod: APIv2.ExecutePaymentModel = {
                                            Amount: onlineOrder.TotalLeftToPay,
                                            PaymentAccountId: activeCardId,
                                            Token: activeCardToken,
                                            SetOrderAsValidatedOnSuccess: true,
                                            PaymentProvider: this._config.paymentProvider, // OLO.Enums.PAYMENT_PROVIDER.CONVERGE
                                        };

                                        if (this._config.paymentProvider === OLO.Enums.PAYMENT_PROVIDER.CARD_CONNECT) {
                                            // const d = cardDetails.ExpirationDate.split('-');
                                            // const lastDay = new Date(+`20${d[2]}`, +d[0], 0).getDate();
                                            PaymentMethod.ExpirationDate = Utils.CreditCards.dateToISOString(cardDetails.ExpirationDate); // `20${d[2]}-${d[0]}-${lastDay < 10 ? `0${lastDay}` : `${lastDay}`}T23:59:59.000Z`;
                                        }

                                        return this._paymentsService.pay(OrderId, PaymentMethod)
                                            .pipe(
                                                map(({ TransactionId }) => {
                                                    return actions.PaymentStepPaymentStatusCheck({ TransactionId, OrderId });
                                                }),
                                            );
                                    })
                                );
                        })
                    );
            }),
            catchError(ex => {
                return this._error('#350 Payment failed', ex);
            })
        );

    @Effect() public stepPaymentStatusCheck$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.PaymentStepPaymentStatusCheck
            ),
            withLatestFrom(
                this._store.pipe(select(selectors.getPaymentStepsStatus)),
            ),
            mergeMap(([action, step]) => {
                if (step !== 'payment_status_check') {
                    return this._error(`#361 Payment step error. Should be "payment_status_check", is: ${step}`);
                }

                return this._paymentsService.getPaymentStatus(action.TransactionId)
                    .pipe(
                        delay(1000),
                        switchMap(payload => {
                            switch (payload.Status) {
                                case OLO.Enums.PAYMENT_STATUS.SUCCESS:
                                    return of(actions.PaymentStepComplete({ OrderId: action.OrderId, payload }));

                                case OLO.Enums.PAYMENT_STATUS.FAILED:
                                    return [
                                        actions.PaymentReset(),
                                        ...this._error('#373 Payment declined by Payment Provider. Status: ' + payload.Status, payload.Status)
                                    ];

                                default:
                                    return of(actions.PaymentStepPaymentStatusCheck({ TransactionId: action.TransactionId, OrderId: action.OrderId }));
                            }
                        }),
                    );
            }),
            catchError(ex => {
                return this._error('#382 Payment status check failed', ex);
            })
        );

    @Effect() public cleanUpOnSuccessfulPayment$ = this._actions$
        .pipe(
            ofType(actions.PaymentStepComplete),
            switchMap(action => {
                const bundleActions: Action[] = [
                    actions.CreditCardTokenDataReset(),
                    actions.OnlineOrderStateReset(),
                    actions.CartReset(),
                    actions.LocationsFiltersReset(),
                    actions.CurrentLocationReset(),
                ];

                if (this._config.onlineOrders?.sendAutoConfirmationEmail === true) {
                    bundleActions.push(
                        actions.OnlineOrderSendConfrimationEmailRequest({ orderId: action.OrderId })
                    );
                }

                return bundleActions;
            }),
        );

    private _error(error: string = '', ex: any = null): Array<Action> {
        console.error('Payment error:', error, ex);
        return [
            actions.OnlineOrderClearPostOrderRequestFlags(),
            // actions.CreditCardTokenDataReset(),
            actions.PaymentStepFailed(error)
        ];
    }

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _actions$: Actions,
        private _paymentsService: Services.PaymentsService,
        private _membersService: Services.MembersService,
        private _store: Store<StateModels.IStateShared>,
    ) { }
}
