import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Utils from '@shared/core/utils';
import * as State from '@shared/state/interface';
import * as Services from '@shared/core/services';

import * as StateModels from '../interface';

import { Observable, of, never, timer, defer } from 'rxjs';
import { switchMap, auditTime, withLatestFrom, take, audit, map, tap, combineLatest } from 'rxjs/operators';

@Injectable()
export class CartEffects {
    /*
        Set up basic props for cart
        - pickupTime
        - onlineMenu
        - locationNo

        Validate these comparing to current selection.
        Check if user has changed pickup time, locationNo, or there is different onlineMenu (overlaps with pickupTime).

        If anything has changed comparing to cart setup, clear cart and show info.
    */
    @Effect() public onTransferItemToCartValidateAndSetBasicCartProperties$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CartSetup,
                actions.CartSetupWithMultipleItems,
                actions.CartPickupTimeUpdate,
            ),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getCart),
                    ),
                this._store
                    .pipe(
                        select(selectors.getCartLocationFriendlyName),
                    ),
                this._store
                    .pipe(
                        select(selectors.getCurrentLocationNo),
                    ),
                this._store
                    .pipe(
                        select(selectors.getCurrentPickupTime),
                    ),
                this._store
                    .pipe(
                        select(selectors.getOnlineMenuData),
                    ),
                this._store
                    .pipe(
                        select(selectors.getMenuFlowDetailsByWizzard)
                    ),
                this._store
                    .pipe(
                        select(selectors.getWizzardMenuFlow)
                    ),
                this._store
                    .pipe(
                        select(selectors.isCheckoutPage)
                    )
            ),
            switchMap(([action, cart, cartLocationFriendlyName, currentLocationNo, currentPickupTime, onlineMenu, menuFlowDetails, wizzardMenuFlow, isCheckoutPage]) => {
                if (action.type === actions.CartPickupTimeUpdate.type) {
                    currentPickupTime = action.pickupTime;
                }

                if (isCheckoutPage) {
                    onlineMenu = cart.onlineMenu;
                }

                if (
                    action.type === actions.CartSetup.type
                    && action.item.MenuFlowId
                ) {
                    /* Validate menuFlow wizzard errors first */
                    const errors: State.IWizzardError[] = this._wizzardService.validateWizzardItems(menuFlowDetails, wizzardMenuFlow);
                    if (errors.length > 0) {
                        return of(actions.WizzardValidate({ errors }));
                    }
                }

                const isEditing: boolean = action.type === actions.CartSetup.type && !!action.item['_Id'] === true;

                const summary = {
                    cartIsEmpty: cart.itemsSimple.length === 0 && cart.itemsMenuFlow.length === 0,
                    isCartLocationNoSet: !!cart.locationNo,
                    isCartPickupTimeSet: !!cart.pickupTime,
                    isOnlineMenuTimeSet: !!cart.onlineMenu && !!cart.onlineMenu.StartTime && !!cart.onlineMenu.EndTime,
                    /* checks for warning */
                    isAddingToCartFromDifferentLocation: isEditing ? false : cart.locationNo !== currentLocationNo,
                    isCurrentPickupTimeDifferentThanCartPickupTime: isEditing ? false : currentPickupTime === null || cart.pickupTime === null || currentPickupTime.Id !== cart.pickupTime.Id, /* unsafe - show warning modal */
                    isCartOnlineMenuTimeDifferentThanCurrent: isEditing ?
                        false : cart.onlineMenu !== null ?
                            cart.onlineMenu.StartTime !== onlineMenu.StartTime
                            || cart.onlineMenu.EndTime !== onlineMenu.EndTime : true, /* unsafe - show warning modal - onlineMenu might be null if user is editing item without onlineMenu context */
                    /* summary for 3 above */
                    showEmptyWarningModal: false,
                    forceCartReset: false,
                };

                /* Fix ordering when page reloads when in asap */
                const isOrderingInASAPMode: boolean = currentPickupTime !== null && currentPickupTime.IsAsap === true && (cart.pickupTime === null || cart.pickupTime !== null && cart.pickupTime.IsAsap === true);
                if (isOrderingInASAPMode) {
                    /* Check if difference is less then pickupTimeOrderTimeout */
                    summary.isCurrentPickupTimeDifferentThanCartPickupTime = false;
                }
                const dispatchActions = (...extraActions: Action[]) => {
                    const arr: Action[] = [];
                    /* #1 Cart locationNo */
                    if (!summary.isCartLocationNoSet || summary.isAddingToCartFromDifferentLocation) {
                        arr.push(actions.CartSetLocationNo(currentLocationNo));
                    }
                    /* #2 Cart PickupTime */
                    if (!summary.isCartPickupTimeSet || summary.isCurrentPickupTimeDifferentThanCartPickupTime) {
                        arr.push(actions.CartSetPickupTime({ ...currentPickupTime }));
                    }
                    /* #3 OnlineMenu time */
                    if (!summary.isOnlineMenuTimeSet || summary.isCartOnlineMenuTimeDifferentThanCurrent) {
                        arr.push(actions.CartSetOnlineMenu({ onlineMenu }));
                    }

                    if (action.type === actions.CartSetup.type) {
                        arr.push(
                            actions.CartTransferItemRequest({
                                modalId: action.modalId,
                                locationNo: currentLocationNo,
                                item: action.item,
                            }),

                        );
                    }

                    if (action.type === actions.CartSetupWithMultipleItems.type) {
                        const menuFlows: State.ICartMenuFlow[] = action.menuFlows;
                        const simpleItems: State.ICartSimpleItem[] = action.simpleItems;

                        if (menuFlows && menuFlows.length) {
                            menuFlows.forEach(menuFlow => {
                                arr.push(
                                    actions.CartMenuFlowAddWithMerge({
                                        locationNo: currentLocationNo,
                                        item: {
                                            ...Utils.Items.convertToSanitizedMenuFlowItem(menuFlow)
                                        },
                                    }
                                    )
                                );
                            });
                        }

                        if (simpleItems && simpleItems.length) {
                            simpleItems.forEach(simpleItem => {
                                arr.push(
                                    actions.CartSimpleItemAdd({
                                        locationNo: currentLocationNo,
                                        item: {
                                            ...Utils.Items.convertToSanitizedSimpleItem(simpleItem)
                                        }
                                    }
                                    )
                                );
                            });
                        }
                    }

                    if (summary.forceCartReset) {
                        arr.unshift(actions.CartRemoveAllItems());
                    }
                    return extraActions ? arr.concat(extraActions) : arr;
                };
                if (!summary.cartIsEmpty) {
                    summary.forceCartReset = summary.isAddingToCartFromDifferentLocation || summary.isCurrentPickupTimeDifferentThanCartPickupTime || summary.isCartOnlineMenuTimeDifferentThanCurrent;
                }

                summary.showEmptyWarningModal = summary.forceCartReset === true && summary.cartIsEmpty === false;

                if (!summary.showEmptyWarningModal) {

                    if (action.type === actions.CartSetupWithMultipleItems.type) {
                        this._modalsService.close(action.modalId);
                    }
                    return dispatchActions();

                } else {
                    /* Handle modal */
                    const modalParams: State.IModal = {
                        type: 'active-order-prompt',
                        animate: null,
                    };
                    if (action.modalId) {
                        this._modalsService.swap(action.modalId, modalParams);
                    } else {
                        this._modalsService.show(modalParams);
                    }

                    return this._actions$
                        .pipe(
                            ofType(
                                actions.CartActiveOrderContinue,
                                actions.CartActiveOrderStartNew,
                            ),
                            take(1),
                            switchMap(activeOrderDecision => {
                                const closeModal = () => this._modalsService.close(action.modalId);
                                closeModal();
                                if (activeOrderDecision.type === actions.CartActiveOrderContinue.type) {
                                    // closeModal();

                                    // /* Give some time to close modal before navigating to avoid modal's 'injury'. This is pure precautious */
                                    // setTimeout(() => this._routeService.navigateToCartsLocation(), 501);

                                    return [
                                        actions.CurrentLocationPickupTimeSet({ ...cart.pickupTime })
                                    ];
                                }

                                if (activeOrderDecision.type === actions.CartActiveOrderStartNew.type) {
                                    return dispatchActions(
                                        actions.CartSetPickupTime({ ...currentPickupTime }) /* AOLO-384 - fix */
                                    );
                                }
                            })
                        );
                }

            }),
        );

    @Effect() public addItemToCartAndCheckForAnyUpsells$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CartTransferItemRequest,
            ),
            switchMap(action => {
                return this._store.pipe(
                    select(selectors.getModalById(action.modalId)),
                    take(1),
                    withLatestFrom(
                        this._store.select(selectors.getMenuFlowDetailsByWizzard),
                        this._store.select(selectors.getWizzardMenuFlow),
                    ),
                    switchMap(([modal, menuFlow, fromWizzardMenuFlow]) => {

                        const isMenuFlow: boolean = !!action.item.MenuFlowId;
                        if (!isMenuFlow) {
                            /* for SIMPLE ITEM */
                            const isEditing: boolean = Number.isInteger(action.item._Id);

                            this._modalsService.close(action.modalId);

                            if (!isEditing) {
                                const productWithId = {
                                    ...action.item
                                } as State.ICartSimpleItem;
                                productWithId._Id = new Date().getTime();
                                return [
                                    actions.CartSimpleItemAdd({ locationNo: action.locationNo, item: Utils.Items.convertToSanitizedSimpleItem(productWithId) }),
                                    actions.WizzardUnmountAll(),
                                ];
                            }
                            return [
                                actions.CartSimpleItemUpdate({ item: Utils.Items.convertToSanitizedSimpleItem(action.item as State.ICartSimpleItem) }),
                                actions.WizzardUnmountAll(),
                            ];
                        }

                        /* for MENU FLOW */
                        /* If any errors, just show errors */
                        const bundleActions: Action[] = [];
                        const wizzardMenuFlow: State.IWizzardMenuFlow = fromWizzardMenuFlow as State.IWizzardMenuFlow;
                        const menuFlowDetails: APIv1.MenuFlowDetailsModel = menuFlow as APIv1.MenuFlowDetailsModel;
                        const isNew: boolean = wizzardMenuFlow._Id === null;

                        const errors: State.IWizzardError[] = this._wizzardService.validateWizzardItems(menuFlow, wizzardMenuFlow);
                        if (errors.length > 0) {
                            return of(actions.WizzardValidate({ errors }));
                        }

                        if (isNew) {
                            /* Add to cart */
                            wizzardMenuFlow._Id = new Date().getTime();

                            bundleActions.push(
                                actions.CartMenuFlowAddWithMerge({
                                    locationNo: wizzardMenuFlow.LocationNo, item: { /* AOLO-282 */
                                        _Id: new Date().getTime(),
                                        ...wizzardMenuFlow
                                    }
                                })
                            );

                        } else {
                            /* Update in cart */
                            bundleActions.push(
                                actions.CartMenuFlowUpdate({ item: wizzardMenuFlow })
                            );
                        }

                        bundleActions.push(
                            actions.WizzardUnmountAll(),
                        );

                        /* Show Upsell  */
                        const showUpsellModal: boolean = isNew && Number.isInteger((menuFlowDetails as APIv1.MenuFlowDetailsModel).UpsellMenuFlowId);
                        if (showUpsellModal) {
                            bundleActions.push(
                                actions.WizzardMenuFlowUpsellSetupRequest((menuFlowDetails as APIv1.MenuFlowDetailsModel).UpsellMenuFlowId, wizzardMenuFlow.LocationNo, action.modalId)
                            );
                        }

                        /* And hide modal if there is no upsell to show */
                        if (action.modalId && !showUpsellModal) {
                            this._modalsService.close(action.modalId);
                        }
                        return bundleActions;
                    })
                );
            }),

        );

    @Effect() public findDuplicatedMenuFlowAndMerge$: Observable<Action> = this._actions$ /* AOLO-282 */
        .pipe(
            ofType(
                actions.CartMenuFlowAddWithMerge
            ),
            withLatestFrom(
                this._store.select(selectors.getCartMenuFlows),
            ),
            switchMap(([action, cartMenuFlows]) => {

                const newItem: State.ICartMenuFlowExtended = action.item;
                const foundSimilarInCart: State.ICartMenuFlow = cartMenuFlows.find(obj => obj.MenuFlowId === newItem.MenuFlowId
                    && obj.PosDescription === newItem.PosDescription
                    && obj.PosDisplay === newItem.PosDisplay
                    && obj.SpecialInstructions === newItem.SpecialInstructions
                    && obj.UnitPrice === newItem.UnitPrice);
                return of(foundSimilarInCart)
                    .pipe(
                        take(1),
                        switchMap(() => {
                            if (!foundSimilarInCart) return of(false);
                            let isSimilar: boolean = true;

                            if (foundSimilarInCart.Pages.length !== newItem.Pages.length) { return of(isSimilar = false); }
                            foundSimilarInCart.Pages.forEach(Page => {
                                /* Check if all pages have same PageIdentifier and products QTY prop */
                                const foundPage = newItem.Pages.find(obj => obj.PageIdentifier === Page.PageIdentifier);
                                if (!foundPage || foundPage.Products.length !== Page.Products.length) {
                                    return isSimilar = false;
                                }

                                /* Check products */
                                Page.Products.forEach(Product => {
                                    const foundProduct = foundPage.Products.find(obj => obj.ProductId === Product.ProductId
                                        && obj.Quantity === Product.Quantity
                                        && obj.OriginalPrice === Product.OriginalPrice
                                        && obj.OverridedPrice === Product.OverridedPrice
                                        && obj.PageProductIdentifier === Product.PageProductIdentifier
                                        && obj.Plu === Product.Plu
                                        && obj.ProductName === Product.ProductName
                                        && obj.ProductDescription === Product.ProductDescription);

                                    if (!foundProduct) { return isSimilar = false; }

                                    /* Check IngredientsAdded */
                                    const foundIngredients = foundProduct.IngredientsChanges ? foundProduct.IngredientsChanges : null;
                                    const productIngredients = Product.IngredientsChanges ? Product.IngredientsChanges : null;
                                    if (foundIngredients === null && productIngredients === null) {
                                        return;
                                    }
                                    if (typeof foundIngredients !== typeof productIngredients) {
                                        return isSimilar = false;
                                    }

                                    if (foundProduct.IngredientsChanges.IngredientsAdded.length !== Product.IngredientsChanges.IngredientsAdded.length) {
                                        return isSimilar = false;
                                    }
                                    Product.IngredientsChanges.IngredientsAdded.forEach(ingredient => {
                                        const foundIngredient = foundProduct.IngredientsChanges.IngredientsAdded.find(obj => obj.ExtraPrice === ingredient.ExtraPrice
                                            && obj.IngredientPLU === ingredient.IngredientPLU
                                            && obj.ModifierID === ingredient.ModifierID
                                            && obj.IngredientPLU === ingredient.IngredientPLU);
                                        if (!foundIngredient) { return isSimilar = false; }
                                    });

                                    /* Check IngredientsModified */
                                    if (foundProduct.IngredientsChanges.IngredientsModified.length !== Product.IngredientsChanges.IngredientsModified.length) {
                                        return isSimilar = false;
                                    }
                                    Product.IngredientsChanges.IngredientsModified.forEach(ingredient => {
                                        const foundIngredient = foundProduct.IngredientsChanges.IngredientsModified.find(obj => obj.ExtraPrice === ingredient.ExtraPrice
                                            && obj.IngredientPLU === ingredient.IngredientPLU
                                            && obj.ModifierID === ingredient.ModifierID
                                            && obj.IngredientPLU === ingredient.IngredientPLU);
                                        if (!foundIngredient) { return isSimilar = false; }
                                    });

                                    /* Check IngredientsRemoved */
                                    if (foundProduct.IngredientsChanges.IngredientsRemoved.length !== Product.IngredientsChanges.IngredientsRemoved.length) {
                                        return isSimilar = false;
                                    }
                                    Product.IngredientsChanges.IngredientsRemoved.forEach(ingredient => {
                                        const foundIngredient = foundProduct.IngredientsChanges.IngredientsRemoved.find(obj =>
                                            obj.IngredientPLU === ingredient.IngredientPLU
                                            && obj.ID === ingredient.ID
                                            && obj.IngredientPLU === ingredient.IngredientPLU);
                                        if (!foundIngredient) { return isSimilar = false; }
                                    });

                                    /* Check IngredientsRemoved */
                                    if (foundProduct.IngredientsChanges.IngredientsSwapped.length !== Product.IngredientsChanges.IngredientsSwapped.length) {
                                        return isSimilar = false;
                                    }
                                    Product.IngredientsChanges.IngredientsSwapped.forEach(ingredient => {
                                        const foundIngredient = foundProduct.IngredientsChanges.IngredientsSwapped.find(obj =>
                                            obj.ExtraPrice === ingredient.ExtraPrice
                                            && obj.ID === ingredient.ID
                                            && obj.ModifierID === ingredient.ModifierID
                                            && obj.ModifierName === ingredient.ModifierName
                                            && obj.NewIngredientPLU === ingredient.NewIngredientPLU
                                            && obj.OldIngredientPLU === ingredient.OldIngredientPLU
                                            && obj.OrderItemId === obj.OrderItemId);
                                        if (!foundIngredient) { return isSimilar = false; }
                                    });
                                });

                            });
                            return of(isSimilar);



                        }),
                        // withLatestFrom(
                        //     this._store.pipe(
                        //         select(selectors.getAllModals)
                        //     )
                        // ),
                        switchMap(isSimilar => { /* <boolean, actions.CartMenuFlowIncrement | actions.CartMenuFlowAdd> */
                            /* Handle result here / dispatch proper action */
                            if (!isSimilar) {
                                /* AOLO-517 */
                                const markedHidden = {
                                    ...newItem,
                                    Pages: newItem.Pages.map(page => {
                                        if (page.HideFromKiosk === true) {
                                            return {
                                                ...page,
                                                Products: page.Products.map(product => {
                                                    return {
                                                        ...product,
                                                        _HideFromKiosk: true,
                                                    };
                                                })
                                            };
                                        }
                                        return page;
                                    })
                                };

                                return of(actions.CartMenuFlowAdd({ locationNo: action.locationNo, item: markedHidden }));
                            }

                            return this._store
                                .pipe(
                                    select(selectors.isDeviceMobile),
                                    take(1),
                                    switchMap(isMobile => {
                                        if (!isMobile) {
                                            setTimeout(() => {
                                                this._cartService.showPopup();
                                            }, 100);
                                        }
                                        return of(actions.CartMenuFlowIncrement(foundSimilarInCart._Id, newItem.Quantity));
                                    })
                                );

                        })
                    );
            }),
        );

    @Effect() public closeActiveOrderPromptWhenUsingReorder$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CartSimpleItemAdd,
                actions.CartMenuFlowAdd,
            ),
            audit(() => timer(100)),
            withLatestFrom(
                this._store.pipe(
                    select(selectors.getAllModals)
                ),
                this._store.pipe(
                    select(selectors.isDeviceMobile)
                ),
                (action, modals, isMobile) => {
                    /*
                        Reorder case with Active order prompt
                        Reordering items ommit upsell checks, where modals are controlled, so they won't get closed.
                        I know this is a bit robust, but when doing things other way around - starting project from the middle, this is
                        easier than refactoring complicated flow.
                        This should be handled better, but for now, let's just pretend it works
                    */
                    let modal: State.IModal;
                    if (action.item._IsReorder) {
                        /* We've got item from reorder here - should close modal now */
                        modal = modals.find(obj => obj.type === 'active-order-prompt');
                    }

                    return [action, modal, isMobile];
                }
            ),
            switchMap(([action, modal, isMobile]) => {
                if (modal) {
                    this._modalsService.close((modal as State.IModal).id);
                }

                /* TOLO-106 */
                if (!isMobile) {
                    this._cartService.showPopup();
                }
                return never();
            })
        );

    @Effect() public setupWizzardOnCartItemEdit$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.CartEditItem),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getCart)
                    ),
                (action, cart: State.ICart) => [action.item, cart.locationNo],
            ),
            switchMap(([item, locationNo]) => of(actions.WizzardSetupItem(locationNo as number, (item as State.ICartMenuFlow | State.ICartSimpleItem)))),
        );

    /* https://media2.giphy.com/media/ErdfMetILIMko/giphy.gif?cid=3640f6095c111836466d35436759d9a8 */
    @Effect({ dispatch: false }) saveCartContentsInStorage$: Observable<never> = this._actions$
        .pipe(
            ofType(
                actions.CartReset,
                actions.CartRemoveAllItems,
                actions.CartMenuFlowAdd,
                actions.CartMenuFlowDecrement,
                actions.CartMenuFlowIncrement,
                actions.CartMenuFlowRemove,
                actions.CartMenuFlowUpdate,
                actions.CartSimpleItemAdd,
                actions.CartSimpleItemDecrement,
                actions.CartSimpleItemIncrement,
                actions.CartSimpleItemRemove,
                actions.CartSimpleItemUpdate,
                // actions.CartSetPendingClear
            ),
            auditTime(500),
            withLatestFrom(
                this._store.select(selectors.getCart),
            ),
            switchMap(([action, cart]) => {
                if (action.type === actions.CartReset.type) {
                    Utils.Storage.remove(OLO.Enums.CART_STORAGE.DATA);
                } else {
                    Utils.Storage.set(OLO.Enums.CART_STORAGE.DATA, this._cryptoService.encrypt(JSON.stringify(cart)));
                }

                return never();
            })
        );

    /* Remove cart data from storage */
    @Effect({ dispatch: false }) removeCartDataFromStorage$: Observable<never> = this._actions$
        .pipe(
            ofType(
                actions.CartReset,
            ),
            switchMap(() => {
                Utils.Storage.remove(OLO.Enums.CART_STORAGE.DATA as unknown as string);

                return never();
            })
        );

    @Effect() public recalculateCartContents$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                // actions.CART_RESET,
                actions.CartRemoveAllItems,
                actions.CartMenuFlowAdd,
                actions.CartMenuFlowDecrement,
                actions.CartMenuFlowIncrement,
                actions.CartMenuFlowRemove,
                actions.CartMenuFlowUpdate,
                actions.CartSimpleItemAdd,
                actions.CartSimpleItemDecrement,
                actions.CartSimpleItemIncrement,
                actions.CartSimpleItemRemove,
                actions.CartSimpleItemUpdate
            ),
            withLatestFrom(
                this._store.pipe(
                    select(selectors.isCheckoutPage)
                )
            ),
            switchMap(([data, isCheckout]) => {
                // if (currentRoute && currentRoute.urlAfterRedirects !== undefined && currentRoute.urlAfterRedirects.includes('/checkout/summary')) {
                //     return of(actions.OnlineOrderRecalculateRequest());
                // }

                if (isCheckout) return of(actions.OnlineOrderRecalculateRequest());

                return never();

            }),
        );

    @Effect() public resetCartOnLastItemRemoved$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CartMenuFlowRemove,
                actions.CartSimpleItemRemove,
            ),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getCart)
                    )
            ),
            switchMap(([action, cart]) => {
                const productsTotal: number = cart.itemsSimple ? cart.itemsSimple.length : 0;
                const menuFlowsTotal: number = cart.itemsMenuFlow ? cart.itemsMenuFlow.length : 0;

                if (productsTotal || menuFlowsTotal) return never();
                return of(actions.CartReset());
            })
        );

    constructor(
        private _actions$: Actions,
        private _store: Store<StateModels.IStateShared>,
        private _cryptoService: Services.CryptoService,
        private _modalsService: Services.ModalsService,
        private _wizzardService: Services.WizzardService,
        private _cartService: Services.CartService,
        private _routeService: Services.RouteService,
    ) { }
}
