import { Injectable } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';
import * as Services from '@shared/core/services';
import * as State from '@shared/state/interface';
import * as StateModels from '../interface';

import { Observable, of, never } from 'rxjs';
import { map, catchError, switchMap, mergeMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class OnlineMenuProductsImagesEffects {
    private _imgParams: APICommon.IImagesGetParams = { width: 150, height: 150 };

    @Effect() public onCartItemEditRequestImageForItem$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CartEditItem
            ),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getCart)
                    )
            ),
            switchMap(([action, cart]) => {
                const itemId: number = action.item.MenuFlowId ? action.item.MenuFlowId : (action.item as State.ICartSimpleItemExtended).ProductId;
                const isMenuFlow: boolean = action.item.MenuFlowId ? true : false;

                if (!cart.onlineMenu || !cart.onlineMenu.Pages) return never();
                return cart.onlineMenu.Pages.map(page => actions.OnlineMenuProductsImagesRequest({ params: this._imgParams, pageId: page.Id }));
                // let pageId: number = null;
                // cart.onlineMenu.Pages.forEach(page => {
                //     if (pageId) return;
                //     page.Products.forEach(product => {
                //         if (pageId) return;

                //         if (isMenuFlow && itemId === product.MenuFlowId) {
                //             return pageId = page.Id;
                //         }

                //         if (!isMenuFlow && itemId === product.ProductId) {
                //             return pageId = page.Id;
                //         }
                //     });
                // });

                // if (!pageId) return never();
                // console.log('pageId', pageId, 'cartItem', action.item);
                // return of(
                //     actions.OnlineMenuPageImagesRequest({ params: this._imgParams, pageId })
                // );
            })
        );

    @Effect() public onOnlineMenuSuccessRequest$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.OnlineMenuPagesSuccessRequest),
            switchMap(({ payload }) =>
                payload.Pages.map(Page =>
                    actions.OnlineMenuProductsImagesRequest({ params: this._imgParams, pageId: Page.Id })
                )
            )
        );

    @Effect() public onPageImagesRequest$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.OnlineMenuProductsImagesRequest),
            mergeMap(({ params, pageId }) =>
                this._imagesService.getImagesForOnlineMenuPageProducts(params, pageId)
                    .pipe(
                        map(payload => actions.OnlineMenuProductsImagesSuccessRequest({ pageId, payload })),
                        catchError(ex => of(actions.OnlineMenuProductsImagesErrorRequest({ pageId, ex }))),
                    )
            )
        );


    constructor(
        private _actions$: Actions,
        private _imagesService: Services.ImagesService,
        private _store: Store<StateModels.IStateShared>,
    ) { }

}
