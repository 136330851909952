import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './loyalty-product-programs.actions';
import { ILoyaltyProductProgramItem } from './loyalty-product-programs.interface';

const initialState: ILoyaltyProductProgramItem[] = [];

export const loyaltyProductProgramsReducerFn = createReducer<ILoyaltyProductProgramItem[]>(
    initialState,
    on(
        actions.LoyaltyProductProgramsReset,
        () => ([])
    ),
    on(
        actions.LoyaltyProductProgramsRequest,
        (state, action) => {
            let found: boolean = false;
            const recalculatedState: ILoyaltyProductProgramItem[] = state.reduce((acc, item) => {
                if (item.ProgramName === action.requestParams.programName) {
                    found = true;
                    return [
                        ...acc,
                        {
                            ...item,
                            isDownloading: true,
                            hasSucceeded: false,
                            hasFailed: false,
                        }
                    ];
                }
                return [
                    ...acc,
                    item,
                ];
            }, []);

            return found ? recalculatedState : [
                ...state,
                {
                    ProgramName: action.requestParams.programName,
                    isDownloading: true,
                    hasSucceeded: false,
                    hasFailed: false,
                    downloadedDate: null,
                    data: null,
                }
            ];
        }
    ),
    on(
        actions.LoyaltyProductProgramsSuccessRequest,
        (state, action) => state.map(obj => {
            if (obj.ProgramName === action.requestParams.programName) {
                return {
                    ...obj,
                    isDownloading: false,
                    hasSucceeded: true,
                    hasFailed: false,
                    downloadedDate: new Date().getTime(),
                    data: action.payload || null,
                };
            }

            return obj;
        })
    ),
    on(
        actions.LoyaltyProductProgramsErrorRequest,
        (state, action) => state.map(obj => {
            if (obj.ProgramName === action.requestParams.programName) {
                return {
                    ...obj,
                    isDownloading: false,
                    hasSucceeded: false,
                    hasFailed: true,
                };
            }

            return obj;
        })
    ),
);

export function loyaltyProductProgramsReducer(state: ILoyaltyProductProgramItem[] | undefined, action: Action) {
    return loyaltyProductProgramsReducerFn(state, action);
}
