import { Injectable, Inject } from '@angular/core';
import { Store, select, MemoizedSelector, createSelector } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as State from '@shared/state';
import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';
import * as Services from '@shared/core/services';

import { Observable } from 'rxjs';
import { map, auditTime, filter, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FiltersController {
    private _pickupModes: OLO.Components.DropDown.IDropDownOption[] = [
        {
            Id: 1,
            Name: 'ASAP'
        },
        {
            Id: 2,
            Name: 'Later today',
        }
    ];

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        private _store: Store<State.IStateShared>,
        private _modalsService: Services.ModalsService,
    ) { }

    public get pickupModes(): OLO.Components.DropDown.IDropDownOption[] {
        return this._pickupModes.map(mode => {
            if (mode.Id === 2 && this.config.pickups.futureOrders) {
                return {
                    ...mode,
                    Name: 'Schedule'
                };
            }
            return {
                ...mode,
            };
        });
    }

    // public getAvailablePickupTimesForAllLocations(
    //     orderTimeoutBufferMins: number = this.config.pickups.orderTimeoutBufferMins,
    //     startBufferMins: number = this.config.pickups.startBufferMins,
    //     nextTick: number = this.config.pickups.nextTick,
    // ): MemoizedSelector<State.IStateShared, OLO.Ordering.IPickupTime[]> {
    //     return createSelector(
    //         selectors.getEdgeOrderingTimeInfoForLocations(),
    //         (edgeCases) => {
    //             const DayOfWeek: number = new Date().getDay();

    //             if (edgeCases.MinimumPickupTime === null) {
    //                 return null;
    //             }
    //             return Utils.Dates.generatePickupTimesList({
    //                 location: null,
    //                 asapPickupMins: edgeCases.MinimumPickupTime,
    //                 openingHours: {
    //                     DayOfWeek: (DayOfWeek as APICommon.DayOfWeek),
    //                     OpeningTime: edgeCases.OpeningTime,
    //                     ClosingTime: edgeCases.ClosingTime,
    //                     // LocationId: 0, /* API bug */
    //                 },
    //                 orderTimeoutBufferMins,
    //                 startBufferMins,
    //                 nextTick,
    //             });
    //         }
    //     );
    // }

    // public generatePickupTimesListForAllLocations$(
    //     orderTimeoutBufferMins: number = this.config.pickups.orderTimeoutBufferMins,
    //     startBufferMins: number = this.config.pickups.startBufferMins,
    //     nextTick: number = this.config.pickups.nextTick,
    // ): Observable<OLO.Ordering.IPickupTime[]> {
    //     return this._store.pipe(
    //         select(selectors.generatedPickupTimesForAllLocations(orderTimeoutBufferMins, startBufferMins, nextTick)),
    //         filter(c => c !== null && c.length > 0),
    //         distinct(c => c[0].Id && c[c.length - 1].Id),
    //     );
    // }


    public getLocationFilters$(): Observable<State.ILocationsFilters> {
        return this._store.pipe(select(selectors.getLocationFilters));
    }

    public getLocationFilterCurrentMode$(): Observable<OLO.Components.DropDown.IDropDownOption> {
        return this._store.pipe(select(selectors.getLocationFiltersPickupMode));
    }

    public getLocationFilterSelectedPickupTime$(): Observable<OLO.Ordering.IPickupTime> {
        return this._store
            .pipe(
                select(selectors.getLocationFilterSelectedPickupTime)
            );
    }

    public getLocationsSearchInput$(): Observable<string> {
        return this._store.pipe(
            select(selectors.getLocationFiltersSearchInput),
        );
    }

    public asapPickupTimeForLocation$(locationNo: number): Observable<number> {
        return this._store.pipe(
            select(selectors.getMinimumPickupTimeForLocation(locationNo)),
            map(obj => {
                if (!obj || obj.isDownloading) return null;
                return obj.MinimumPickupTime;
            }),
            auditTime(0),
        );
    }

    // public locationFilterPickupTimeOptions$(): Observable<OLO.Ordering.IPickupTime[]> {
    //     return this._store.pipe(
    //         select(selectors.generatedPickupTimesForAllLocations(
    //             this.config.pickups.orderTimeoutBufferMins,
    //             this.config.pickups.startBufferMins,
    //             this.config.pickups.nextTick
    //         )),
    //         filter(c => c !== null && c.length > 0),
    //         distinct(c => c[0].Id && c[c.length - 1].Id),
    //     );
    // }

    public pickupTimesListForLocation$(period: OLO.Ordering.IPeriod, locationNo: number) {
        return this._store
            .pipe(
                select(selectors.generatedPickupTimesForLocationForPeriod(period, locationNo))
            );
    }

    public pickupTimesListForAllLocations$(includeDayName: boolean = true, limit: number = null, isSchedule: boolean = false): Observable<OLO.Ordering.IPickupTime[]> {
        return this._store
            .pipe(
                select(selectors.generatedFiltersPickupTimesForAllLocations(
                    this.config,
                    includeDayName,
                    limit,
                    isSchedule,
                ))
            );
        // return this.locationFilterPickupTimeOptions$()
        //     .pipe(
        //         map(obj => {
        //             let list = [
        //                 {
        //                     Date: null,
        //                     DateLocalISO: null,
        //                     Hour: null,
        //                     Id: 1,
        //                     Index: null,
        //                     IsAsap: true,
        //                     MinutesFromNow: null,
        //                     Name: `${isSchedule ? 'Today - ' : ''}ASAP`,
        //                     DisplayName: `${isSchedule ? 'Today - ' : ''}ASAP`,
        //                     PlaceOrderTimeout: null,
        //                     IsToday: true,
        //                     DayNo: 0,
        //                 },
        //                 ...obj
        //                     .filter(pickup => {
        //                         return pickup.IsAsap === false;
        //                     })
        //                     .map(pickup => {
        //                         let Name: string = includeDayName ? `${Utils.Dates.getDayNameFromDate(pickup.Date)} ${pickup.Name}` : pickup.Name;

        //                         if (isSchedule) {
        //                             const diff = Utils.Dates.datesDiffInDays(new Date(), pickup.Date);

        //                             switch (true) {
        //                                 case diff === 0:
        //                                     Name = `Today - ${pickup.Name}`;
        //                                     break;
        //                                 case diff === 1:
        //                                     Name = `Tomorrow - ${pickup.Name}`;
        //                                     break;
        //                             }

        //                             return {
        //                                 ...pickup,
        //                                 Name
        //                             };
        //                         }
        //                         return {
        //                             ...pickup,
        //                             Name,
        //                         };
        //                     })];

        //             if (limit && list.length > limit) {
        //                 list.length = limit;
        //             }

        //             if (isSchedule) {
        //                 list.push({
        //                     Date: null,
        //                     DateLocalISO: null,
        //                     Hour: null,
        //                     Id: -1,
        //                     Index: null,
        //                     IsAsap: false,
        //                     MinutesFromNow: null,
        //                     Name: `Schedule for later`,
        //                     DisplayName: `Schedule for later`,
        //                     PlaceOrderTimeout: null,
        //                     IsToday: null,
        //                     DayNo: null,
        //                 });
        //             }

        //             return list;
        //         })
        //     );
    }


    public pickupTimesListForAllLocationsForPeriod$(period: OLO.Ordering.IPeriod/* , includeDayName: boolean = true, limit: number = null, isSchedule: boolean = false */): Observable<OLO.Ordering.IPickupTime[]> {
        return this._store
            .pipe(
                select(selectors.generatedPickupTimesForAllLocationsForPeriod(
                    period,
                    0,
                    60,
                    60
                ))
            );
    }

    public getPickupTimeByScheduleValues$(periodId: number, pickupTimeId: number): Observable<OLO.Ordering.IPickupTime> {
        return this._store
            .pipe(
                select(selectors.getAllLocationsPeriods()),
                map(periods => periods?.find(obj => obj.Id === periodId)),
                switchMap(period => {
                    if (period.IsToday) {
                        return this.pickupTimesListForAllLocations$();
                    }
                    return this.pickupTimesListForAllLocationsForPeriod$(period);
                }),
                map(pickups => pickups?.find(obj => obj.Id === pickupTimeId))
            );
    }

    private _dispatchLocationsFiltersActions(data: OLO.Ordering.IPickupTime): void {
        /* Just update state */
        this._store.dispatch(actions.LocationsFiltersSetPickupTime(data));
        this._store.dispatch(actions.CurrentLocationFiltersPickupTimeSync(data));
    }

    public onLocationsFilterModeChange(modeId: number): void {
        this._store.dispatch(actions.LocationsFiltersSetPickupMode(this.pickupModes[modeId] as OLO.Ordering.IFilterForPickupMode));
    }

    public onLocationsFilterChange($event: OLO.Ordering.IPickupTime): void {
        this._dispatchLocationsFiltersActions($event as OLO.Ordering.IPickupTime);
    }

    public clearPickupFilters(): void {
        this._store.dispatch(actions.LocationsFiltersRestorePickupTime());
        this._store.dispatch(actions.CurrentLocationFiltersPickupTimeSync(null));
    }

    public selectPickupTimeOption(option: OLO.Ordering.IPickupTime, processName: boolean = false): void {
        if (option.Id === 1) {
            this.clearPickupFilters();
            return;
        }

        const processedOption = {
            ...option,
            Name: processName ? option.Name.includes(' ') ? option.Name.split(' ')[1] : option.Name : option.Name,
        };

        this.onLocationsFilterChange(processedOption);
    }

    public setLocationsFiltersSearchTerm(term: string = null): void {
        this._store.dispatch(actions.LocationsFiltersSetSearch(term));
    }

    public setOnlineMenuFiltersSearchTerm(term: string = null): void {
        this._store.dispatch(actions.OnlineMenuFiltersSetSearch(term));
    }

    public getOnlineMenuFiltersSearchTerm$(): Observable<string> {
        return this._store
            .pipe(
                select(selectors.getOnlineMenuFiltersSearchInput)
            );
    }

    public openScheduleModal(modalId: number, locationNo: number = null, update: boolean = false): void {
        this._modalsService.show({
            id: modalId || new Date().getTime(),
            type: 'schedule',
            locationNo,
            params: {
                update,
            }
        });
    }
}
