import { createAction, props } from '@ngrx/store';

/* For reset */
export const OnlineOrderStateReset = createAction('[Online order] RESET STATE');

// export const OnlineOrderSetPendingStateReset = createAction('[Online order] SET PENDING RESET ON STATE');

export const OnlineOrderClearPostOrderRequestFlags = createAction('[Online order] Clear flags for create order');

/* For post */
export const OnlineOrderCreateRequest = createAction('[Online order] Request create');

export const OnlineOrderCreateSuccessRequest = createAction(
    '[Online order] Success request create',
    props<{ payload: APIv1.OnlineOrderDetailedBusinessModel; }>(),
);

export const OnlineOrderCreateErrorRequest = createAction(
    '[Online order] Error request create',
    props<{ order: APIv1.OnlineOrderDetailedBusinessModel; ex?: any; }>(),
);

/* For update */
export const OnlineOrderUpdateRequest = createAction(
    '[Online order] Request update',
    props<{ order: APIv1.OnlineOrderDetailedBusinessModel; }>(),
);

export const OnlineOrderUpdateSuccessRequest = createAction(
    '[Online order] Success request update',
    props<{ payload: APIv1.OnlineOrderDetailedBusinessModel; }>(),
);

export const OnlineOrderUpdateErrorRequest = createAction(
    '[Online order] Error request update',
    props<{ order: APIv1.OnlineOrderDetailedBusinessModel, ex?: any; }>(),
);

/* For get */
export const OnlineOrderRequest = createAction(
    '[Online order] Request get',
    props<{ orderId: number; }>(),
);

export const OnlineOrderSuccessRequest = createAction(
    '[Online order] Success request get',
    props<{ payload: APIv1.OnlineOrderDetailedBusinessModel; }>(),
);

export const OnlineOrderErrorRequest = createAction(
    '[Online order] Error request get',
    props<{ orderId: number; ex?: any; }>(),
);

/* for recalculate */
export const OnlineOrderRecalculateRequest = createAction('[Online order] Request recalculate');

export const OnlineOrderRecalculateSuccessRequest = createAction(
    '[Online order] Success request recalculate',
    props<{ payload: APIv1.OnlineOrderDetailedBusinessModel; }>(),
);

export const OnlineOrderRecalculateErrorRequest = createAction(
    '[Online order] Error request recalculate',
    props<{ order: APIv1.OnlineOrderDetailedBusinessModel; ex?: any; }>(),
);

/* for sending email confirmation */
export const OnlineOrderSendConfrimationEmailRequest = createAction(
    '[Online order] send email confirmation for online order',
    props<{ orderId: number; }>(),
);

export const OnlineOrderSendConfrimationEmailSuccessRequest = createAction(
    '[Online order] send email confirmation for online order - success',
    props<{ result: boolean; }>(),
);

export const OnlineOrderSendConfrimationEmailErrorRequest = createAction(
    '[Online order] send email confirmation for online order - error',
    props<{ ex?: any; }>(),
);

/* Order type select */
export const OnlineOrderTypeSelect = createAction(
    '[Online order] Select order type',
    props<{ orderType: APICommon.OrderTypeExtended; }>(),
);

export const OnlineOrderTypeUpdateValues = createAction(
    '[Online order] Update order type values',
    props<{ values: APICommon.OrderTypeDetailDefinitionExtended[]; }>()
);

export const OnlineOrderResetOrderTypeValues = createAction(
    '[Online order] Reset order type values'
);

/* Send email receipt */
export const OnlineOrderSendEmailReceiptRequest = createAction(
    '[Online order] Request send email receipt',
    props<{ orderId: number; }>()
);

export const OnlineOrderSendEmailReceiptSuccessRequest = createAction(
    '[Online order] Success Request send email receipt',
    props<{ orderId: number; }>()
);

export const OnlineOrderSendEmailReceiptErrorRequest = createAction(
    '[Online order] Error Request send email receipt',
    props<{ orderId: number; ex?: any; }>()
);

export const OnlineOrderSendEmailReceiptReset = createAction(
    '[Online order] Reset send email receipt'
);
