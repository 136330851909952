import { Injectable, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import * as StateModels from '../interface';

import { Observable, throwError, of, never, iif } from 'rxjs';
import { catchError, switchMap, map, tap, withLatestFrom, filter, take, delay, auditTime } from 'rxjs/operators';

@Injectable()
export class MembersEffects {
    @Effect() public verifyLinkRewardsAccount$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.MemberLinkRewardsAccountVerifyRequest
            ),
            switchMap(({ params }) => {
                return this._membersService.getMembers(params)
                    .pipe(
                        map(({ Items }) => {
                            if (!Items.length) throw new Error('Member not found by params: ' + params);
                            return actions.MemberLinkRewardsAccountVerifySuccessRequest({ params, payload: Items[0] });
                        }),
                        catchError(ex => {
                            return of(actions.MemberLinkRewardsAccountVerifyErrorRequest({ params }));
                        })
                    );
            })
        );

    @Effect() public onVerifyLinkRequestMemberDataToUpdateStep$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberLinkRewardsAccountVerifySuccessRequest),
            switchMap(({ payload }) => of(actions.MemberDataRequest({ memberId: payload.MemberId })))
        );

    @Effect() public requestUserDataSuccess$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberDataSuccessRequest),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getMemberState)
                    ),
                this._store
                    .pipe(
                        select(selectors.isMemberAuthorizedJWT)
                    )
            ),
            switchMap(([action, memberState, isAuthorized]) => {
                const member = action.payload;

                const isLinkingRewardsAccount = memberState.authorizationStep === OLO.Enums.AUTH_STEP.ACCOUNT_LINKING_REWARDS_SIGN_UP &&
                    memberState.verifyLinkRewardsAccount.data !== null;

                if (isLinkingRewardsAccount) {
                    return [
                        actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.ACCOUNT_LINKING_REWARDS_PASSWORD })
                    ];
                }


                const requireUpdate: boolean = member && member.IsOnlineRegistered === false;
                if (!isAuthorized && requireUpdate) {
                    return [
                        actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.REGISTER })
                    ];
                }

                const memberRequiresRevalidation: boolean = (member.IsEmailValidated === false || member.IsMobileValidated === false);
                const isVeryfiying: boolean = memberState.authorizationStep === OLO.Enums.AUTH_STEP.ON_LOGIN_DATA_VALIDATION;

                const shouldCheckValidation: boolean = isVeryfiying === true
                    || ((memberState.authorizationStep === OLO.Enums.AUTH_STEP.VERIFY_EMAIL || memberState.authorizationStep === OLO.Enums.AUTH_STEP.VERIFY_PHONE)
                        && memberRequiresRevalidation);

                if (shouldCheckValidation) {
                    if (!member.IsMobileValidated) {
                        return [
                            actions.MemberSendPhoneVerificationCodeDataRequest({ phoneNo: memberState.data.MobilePhone }),
                            actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.VERIFY_PHONE })
                        ];
                    }

                    if (!member.IsEmailValidated) {
                        /* AOLO-465 */
                        if (this._config.unverifiedEmailQuickLogin) {

                            if (member.IsMobileValidated && memberState.authorizationStep === OLO.Enums.AUTH_STEP.VERIFY_EMAIL) {
                                return of(
                                    actions.MemberQuickLoginRequest({
                                        login: member.MobilePhone,
                                        password: this._getPasswordForQuickLogin(),
                                        authorizationType: memberState.authorizationType,
                                    })
                                );
                            }

                        }

                        return [
                            actions.MemberSendEmailVeryficationRequest({ email: member.EmailAddress }),
                            actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.VERIFY_EMAIL }),
                        ];
                    }

                    return [
                        actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.LOGIN_SUCCESS }),
                        actions.MemberJwtDataRequest({ email: member.EmailAddress, password: memberState.accountPassword }),
                    ];
                }

                /*
                    This part is new - for TOLO - not tested in ARAMARK
                    Finish registration or update process?
                */
                if (isAuthorized && memberState.update.updateHasSucceeded) {
                    return [
                        actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.UPDATE_SUCCESS })
                    ];
                }

                if (!isAuthorized && memberState.data && memberState.data.IsOnlineRegistered && memberState.data.IsEmailValidated && memberState.data.IsMobileValidated && memberState.authorizationStep > OLO.Enums.AUTH_STEP.REGISTER) {
                    return [
                        actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.REGISTER_SUCCESS })
                    ];
                }

                return never();

            }),
        );

    @Effect() public simulateEmailVerifyRequests$ = this._actions$
        .pipe(
            ofType(
                actions.MemberVerifyEmailRequest,
            ),
            switchMap(() => this._store
                .pipe(
                    select(selectors.getMemberState),
                    filter(state => state.isDownloading === false),
                    take(1),
                    switchMap(state => {
                        /* This stupid action is required to control proper button behavior. We don't have valid endpoint that will check email validation flag */
                        if (state.hasSucceeded && state.data && state.data.IsEmailValidated) {
                            return of(actions.MemberVerifyEmailSuccessRequest());
                        }

                        return this._actions$
                            .pipe(
                                ofType(
                                    actions.MemberSendEmailVeryficationSuccessRequest,
                                    actions.MemberSendEmailVeryficationDataResponseError,
                                ),
                                take(1),
                                switchMap(() => of(actions.MemberVerifyEmailErrorRequest()))
                            );
                    }),
                )
            ),
        );

    @Effect() public checkGuestMemberToCompleteAccountSetup$ = this._actions$
        .pipe(
            ofType(
                actions.MemberCompleteAccountSetupGuestCheck,
            ),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.isMemberAuthorizedJWT)
                    )
            ),
            switchMap(([{ orderId }, isAuthorized]) => {
                if (isAuthorized) return never();

                return this._store
                    .pipe(
                        select(
                            selectors.getHistoryOrder(orderId),
                        ),
                        filter(order => order !== undefined && order !== null && order.data !== null),
                        take(1),
                        switchMap(order => {
                            return of(actions.MemberDataRequest({ memberId: order.data.MemberId }));
                        })
                    );
            })
        );

    @Effect() public revalidateAndSetGuestData$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.MemberGuestDataRevalidateAndSet
            ),
            switchMap(action => {
                return [
                    actions.MemberGuestDataReset(),
                    actions.MemberGuestDataSet({ guestData: action.guestData as APICommon.IOnlineOrderPartialMember }),
                    actions.MemberVerifyEmailRestoreFlags(),
                    actions.MemberVerifyPhoneRestoreFlags(),
                    actions.MemberValidateEmailDataRequest({ email: action.guestData.Email }),
                    actions.MemberValidatePhoneRequest({ phone: action.guestData.MobileNumber }),
                ];
            })
        );

    @Effect({ dispatch: false }) public memberSignOut$: Observable<any> = this._actions$
        .pipe(
            ofType(
                actions.MemberSignOut,
            ),
            switchMap(action => {
                this._authService.signOut(action.redirect, action.resetCart || false);

                return [
                    actions.MemberAccountBalanceReset()
                ];
            }),
        );

    @Effect() public initUpdateMemberProfile$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.MemberProfileUpdateInit
            ),
            switchMap(({ userModel, modalId }) => {
                return this._store
                    .pipe(
                        select(selectors.getMemberState),
                        take(1),
                        switchMap(memberState => {
                            if (!memberState.data) {
                                console.error('Members data is not available', memberState.data);
                                return never();
                            }

                            /* Update without verification */
                            if (!userModel.hasOwnProperty('EmailAddress')
                                && !userModel.hasOwnProperty('MobilePhone')
                                && memberState.data.IsEmailValidated
                                && memberState.data.IsMobileValidated
                            ) {
                                return [
                                    actions.MemberProfileUpdateRequest({
                                        ...memberState.data,
                                        ...userModel,
                                    }, modalId)
                                ];
                            }

                            const IsEmailValidated: boolean = memberState.data.IsEmailValidated === true && memberState.data.EmailAddress === userModel.EmailAddress;

                            if (memberState.data.MobilePhone === userModel.MobilePhone) {
                                if (!IsEmailValidated && modalId) {
                                    this._modalsService.show({
                                        type: 'auth',
                                        id: modalId,
                                    });
                                }

                                return [
                                    actions.MemberProfileUpdateRequest({
                                        ...memberState.data,
                                        ...userModel,
                                        IsEmailValidated,
                                    }),
                                    ...(IsEmailValidated ? [] : [
                                        actions.MemberSendEmailVeryficationRequest({ email: userModel.EmailAddress }),
                                        actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.VERIFY_EMAIL }),
                                    ]),
                                ];
                            }

                            if (modalId) {
                                this._modalsService.show({
                                    type: 'auth',
                                    id: modalId,
                                });
                            }

                            return [
                                actions.MemberSendPhoneVerificationCodeDataRequest({ phoneNo: userModel.MobilePhone }),
                                actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.BEFORE_UPDATE_VERIFY_PHONE })
                            ];
                        })
                    );
            })
        );

    @Effect() public updateMemberProfileData$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.MemberProfileUpdateRequest
            ),
            switchMap(({ userModel, modalId }) => {
                return this._membersService.validateUserNewProfileData(userModel)
                    .pipe(
                        switchMap(response => {
                            if (!response) return of(actions.MemberProfileUpdateErrorRequest({ userModel, ex: new Error('User with provied details already exists') }));

                            return this._membersService.updateUser(userModel)
                                .pipe(
                                    map(() => actions.MemberProfileUpdateSuccessRequest({ userModel, payload: userModel, modalId })),
                                    catchError(ex => of(actions.MemberProfileUpdateErrorRequest({ userModel, ex })))
                                );

                        })
                    );
            })

        );

    @Effect() public revalidateEmailAndCellAfterProfileUpdate$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.MemberProfileUpdateSuccessRequest,
            ),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getAllModals)
                    )
            ),
            switchMap(([{ userModel, payload, modalId }, modals]) => {
                if (!modalId) return never();

                const isCellValidated = payload.IsMobileValidated === true;
                const isEmailValidated = payload.IsEmailValidated === true;

                if (!modals.find(obj => obj.id === modalId)) {
                    this._modalsService.show({
                        type: 'auth',
                        id: modalId,
                    });
                }

                if (!isCellValidated) {
                    return [
                        actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.VERIFY_PHONE }),
                        actions.MemberSendPhoneVerificationCodeDataRequest({ phoneNo: payload.MobilePhone }),
                    ];
                }

                if (!isEmailValidated) {
                    return [
                        actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.VERIFY_EMAIL }),
                        actions.MemberSendEmailVeryficationRequest({ email: payload.EmailAddress }),
                    ];
                }

                return [
                    actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.UPDATE_SUCCESS }),
                ];
            })
        );

    @Effect() public signOutIfUserWontValidateDataAfterUpdate$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.ModalClose,
                actions.ModalCloseAll,
            ),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getMemberState)
                    )
            ),
            switchMap(([action, memberState]) => {
                const member = memberState.data;
                if (!member) return never();

                const isValid: boolean = member.IsEmailValidated && member.IsMobileValidated;
                if (isValid) return never();


                if (action.type === actions.ModalClose.type && action.id === -100 || action.type === actions.ModalCloseAll.type) {
                    return of(actions.MemberSignOut({ redirect: true }));
                }

                return never();
            })
        );

    @Effect() public validateConfirmEmailToken$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.MemberConfirmEmailTokenRequest
            ),
            switchMap(({ token }) =>
                this._membersService.validateMemberConfirmEmailToken(token)
                    .pipe(
                        map(payload => actions.MemberConfirmEmailTokenSuccessRequest({ token, payload })),
                        catchError(ex => of(actions.MemberConfirmEmailTokenErrorRequest({ token, ex })))
                    )
            )
        );

    @Effect() public confirmEmailAddress$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.MemberConfirmEmailRequest
            ),
            switchMap(({ token }) =>
                this._membersService.confirmEmailAddress(token)
                    .pipe(
                        map(payload => actions.MemberConfirmEmailSuccessRequest({ token, payload })),
                        catchError(ex => of(actions.MemberConfirmEmailErrorRequest({ token, ex }))),
                    )
            )
        );

    @Effect() public validateResetPasswordToken$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.MemberValidatePasswordResetTokenRequest
            ),
            switchMap(({ token }) =>
                this._membersService.validateMemberPasswordResetToken(token)
                    .pipe(
                        map(payload => actions.MemberValidatePasswordResetTokenSuccessRequest({ token, payload })),
                        catchError(ex => of(actions.MemberValidatePasswordResetTokenErrorRequest({ token, ex }))),
                    )
            ),
        );

    @Effect() public resetForgottenPassword$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.MemberForgottenPasswordResetRequest
            ),
            switchMap(({ model }) =>
                this._membersService.resetForgottenPassword({ Token: model.Token, NewPassword: model.NewPassword })
                    .pipe(
                        map(payload => actions.MemberForgottenPasswordResetSuccessRequest({ model, payload })),
                        catchError(ex => of(actions.MemberForgottenPasswordResetErrorRequest({ model, ex }))),
                    )
            )
        );

    @Effect() public requestUserData$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberDataRequest),
            switchMap(action => {
                return this._membersService.getUserData(action.memberId)
                    .pipe(
                        map(response => actions.MemberDataSuccessRequest({ memberId: action.memberId, payload: response })),
                        catchError((ex) => {
                            return throwError(actions.MemberDataErrorRequest(ex));
                        })
                    );
            }),
        );

    @Effect() public quickLoginRequest$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberQuickLoginRequest),
            switchMap(action => {
                return this._authService.signIn({
                    Login: action.login,
                    Password: action.password,
                    LoginType: action.authorizationType
                })
                    .pipe(
                        withLatestFrom(this._store.select(selectors.getMemberState)),
                        switchMap(([sessionKey, memberState]) => {
                            return of(
                                actions.MemberQuickLoginSuccessRequest({
                                    login: action.login,
                                    password: action.password,
                                    authorizationType: action.authorizationType,
                                    sessionKey: sessionKey
                                })
                            );
                        }),
                        catchError(ex => {
                            return of(actions.MemberQuickLoginErrorRequest({
                                login: action.login,
                                password: action.password,
                                authorizationType: action.authorizationType,
                                ex,
                            }));
                        })
                    );
            })
        );

    @Effect() public quickLoginOnSuccessSetup$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberQuickLoginSuccessRequest),
            withLatestFrom(this._store.select(selectors.getMemberState)),
            switchMap(([{ sessionKey, authorizationType, password }, memberState]) => {
                return [
                    actions.CreateMemberSession({ sessionKey, accountId: String(memberState.data.MemberId), authorizationType: authorizationType }),
                    actions.MemberAuthorizationSetFlag({ flag: true }),
                    actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.LOGIN_SUCCESS }),
                    actions.MemberJwtDataRequest({ email: memberState.data.EmailAddress, password: password }),
                ];
            })
        );

    @Effect() public linkRewardsAccountInit$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberLinkRewardsAccountRequest),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getMemberState)
                    )
            ),
            switchMap(([{ password }, state]) => {
                return this._membersService.changeMemberPassword(state.data.MemberId, password)
                    .pipe(
                        switchMap(response => {
                            if (!response) return throwError('Unable to change member password');
                            const extractedMobile: string[] = state.accountLogin?.split(':').map(v => v);
                            const model: APIv1.MemberModel = {
                                ...state.data,
                                MemberGroupId: this._config.defaultMemberGroupId,
                            };
                            if (this._config.countries && extractedMobile?.length === 2) {
                                const foundCountry = this._config.countries.find(obj => obj.Id === +extractedMobile[0]);
                                if (foundCountry) {
                                    model.MobilePhone = foundCountry.PhonePrefix + extractedMobile[1];
                                }
                            }

                            return this._membersService.updateUser(model)
                                .pipe(
                                    map(hasUpdated => {
                                        if (!hasUpdated) throw new Error('Unable to update member');
                                        return actions.MemberLinkRewardsAccountSuccessRequest({ password, payload: model });
                                    })
                                );
                        }),
                        catchError(ex => {
                            console.error('Link Reward Account error', ex);
                            return of(actions.MemberLinkRewardsAccountErrorRequest({ password, ex }));
                        })
                    );
            })
        );

    @Effect() public onSuccessLinkingAccountVerifyPhone$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberLinkRewardsAccountSuccessRequest),
            switchMap(({ payload }) => {
                return [
                    actions.MemberSendPhoneVerificationCodeDataRequest({ phoneNo: payload.MobilePhone }),
                    actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.VERIFY_PHONE })
                ];
            })
        );

    @Effect() public requestUpdateUserData$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberUpdateRequest),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getMemberState)
                    )
            ),
            switchMap(([{ userModel }, state]) => {
                return this._membersService.updateUser(userModel)
                    .pipe(
                        map(response => {
                            return actions.MemberUpdateSuccessRequest({ userModel, payload: userModel });
                        }),
                        catchError((ex) => {
                            return of(actions.MemberUpdateErrorRequest({ userModel, ex }));
                        })
                    );
            }),
        );

    @Effect() public onSuccessfulMemberUpdateShowOk$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.MemberUpdateSuccessRequest
            ),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getMemberState)
                    )
            ),
            switchMap(([action, state]) => {
                if (state.authorizationStep === OLO.Enums.AUTH_STEP.BEFORE_UPDATE_VERIFY_PHONE) {
                    if (action.payload.IsEmailValidated) {
                        return [
                            actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.UPDATE_SUCCESS }),
                        ];
                    } else {
                        return [
                            actions.MemberProfileUpdateInit({ userModel: action.payload, modalId: null })
                        ];
                    }
                }

                return never();
            }),
        );

    @Effect() public requestPasswordChange$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberPasswordChangeRequest),
            switchMap(({ NewPassword, OldPassword, MemberId }) => {
                return this._membersService.updatePasswordRequest({ OldPassword, NewPassword }, MemberId)
                    .pipe(
                        map(response => response ? actions.MemberPasswordChangeSuccessRequest({ OldPassword, NewPassword, MemberId }) : actions.MemberPasswordChangeErrorRequest({ OldPassword, NewPassword, MemberId })),
                        catchError((ex) => {
                            return of(actions.MemberPasswordChangeErrorRequest({ OldPassword, NewPassword, MemberId, ex: ex.error && ex.error.Message && typeof ex.error.Message === 'string' ? ex.error : null }));
                        })
                    );
            })
        );

    @Effect() public initAuthorization$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberAuthorizationInit),
            switchMap(({ authorizationType, accountLogin }) => {

                switch (authorizationType) {
                    case OLO.Enums.LOGIN_TYPE.MEMBER_CARD_NUMBER_BASED_LOGIN:
                    case OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN:
                        return of(actions.MemberValidateLoginRequest({ login: accountLogin, loginType: authorizationType }));
                }

            }),
        );

    @Effect() public requestLoginValidation$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberValidateLoginRequest),
            switchMap(({ login, loginType }) => {
                return this._membersService.validateLogin(login, loginType)
                    .pipe(
                        map(payload => {
                            return actions.MemberValidateLoginSuccessRequest({ login, loginType, payload });
                        }),
                        catchError(ex => {
                            return of(actions.MemberValidateLoginErrorRequest({ login, loginType, ex }));
                        })
                    );
            }),
        );

    @Effect() public loginValidationSuccess$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberValidateLoginSuccessRequest),
            switchMap(action => {
                if (action.payload !== null && action.payload.MemberId && action.payload.IsOnlineRegistered) {

                    return of(actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.PASSWORD }));
                } else {
                    if (action.payload === null && this._config.signUpRewardsAccountLinking?.enabled === true) {
                        return of(
                            actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.ACCOUNT_LINKING_REWARDS_INFO })
                        );
                    }

                    const returnedActions: Action[] = [actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.REGISTER })];

                    if (action.payload && action.payload.MemberId) {
                        returnedActions.push(actions.MemberDataRequest({ memberId: action.payload.MemberId }));
                    }

                    return returnedActions;
                }
            }),
        );

    @Effect() public requestMemberLogin$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberSignInDataRequest),
            withLatestFrom(this._store.select(selectors.getMemberState)),
            switchMap(([action, memberState]) => {
                const password = action.password;
                const login = action.login || memberState.accountLogin;
                const credentials: OLO.Members.IMemberLogin = { Login: login, Password: password, LoginType: memberState.authorizationType };

                this._storePasswordForQuickLogin(action.password);

                return this._authService.signIn(credentials)
                    .pipe(
                        map(response => response !== null ? actions.MemberSignInDataResponseSuccess({ password, sessionKey: response }) : actions.MemberSignInDataResponseError({ password })),
                        catchError(ex => {
                            return of(actions.MemberSignInDataResponseError({ password, ex }));
                        })
                    );
            }),
        );

    @Effect() public requestJWTLogin$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberJwtDataRequest),
            switchMap(({ email, password }) => {
                return this._jwtService.reqestJWToken({ Email: email, Password: password })
                    .pipe(
                        map(response => response ? actions.MemberJwtDataResponseSuccess({ email, password }) : actions.MemberJwtDataResponseError({ email, password })),
                        catchError(ex => {
                            return of(actions.MemberJwtDataResponseError({ email, password }));
                        })
                    );
            }),
        );


    @Effect() public requestMemberLoginSuccess$: Observable<any> = this._actions$
        .pipe(
            ofType(actions.MemberSignInDataResponseSuccess),
            withLatestFrom(this._store.select(selectors.getMemberState)),
            switchMap(([action, memberState]) => {
                const sessionKey = action.sessionKey;

                const bundleActions: any[] = [
                    actions.MemberAuthorizationSetFlag({ flag: true }),
                    actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.ON_LOGIN_DATA_VALIDATION }),
                    actions.MemberDataRequest({ memberId: memberState.accountId }),
                ];

                if (memberState.data && memberState.data.IsMobileValidated && memberState.data.IsEmailValidated && memberState.accountId && sessionKey) {
                    bundleActions.push(actions.CreateMemberSession({ sessionKey, accountId: memberState.accountId as string, authorizationType: memberState.authorizationType }));
                }

                return bundleActions;
            }),
        );

    @Effect() public validatePhone$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberValidatePhoneRequest),
            switchMap(({ phone }) => {
                return this._store
                    .pipe(
                        select(selectors.getMemberState),
                        filter(memberState => memberState.isDownloading === false),
                        take(1),
                        switchMap(memberState => {
                            // return this._membersService.validatePhoneNumber(phone, memberState.data !== null ? memberState.data.MemberId : null)
                            return this._membersService.validateMemberByProperty(phone, OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN, memberState.data !== null ? memberState.data.MemberId : null)
                                .pipe(
                                    map(response => {
                                        return response ? actions.MemberValidatePhoneSuccessRequest({ phone }) : actions.MemberValidatePhoneDataResponseError({ phone });
                                    }),
                                    catchError(ex => {
                                        console.error('Phone check validation error:', ex);
                                        return of(actions.MemberValidatePhoneDataResponseError({ phone, ex }));
                                    })
                                );
                        })
                    );
            }),
        );

    @Effect() public validateEmail$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberValidateEmailDataRequest),
            switchMap(({ email }) => {
                return this._store
                    .pipe(
                        select(selectors.getMemberState),
                        take(1),
                        switchMap(memberState => {
                            // return this._membersService.validateEmailAddress(email, memberState.data ? memberState.data.MemberId : null)
                            return this._membersService.validateMemberByProperty(email, OLO.Enums.LOGIN_TYPE.EMAIL_BASED_LOGIN, memberState.data ? memberState.data.MemberId : null)
                                .pipe(
                                    map(response => response ? actions.MemberValidateEmailDataResponseSuccess({ email }) : actions.MemberValidateEmailDataResponseError({ email })),
                                    catchError(ex => {
                                        console.error('Email check validation error:', ex);
                                        return of(actions.MemberValidateEmailDataResponseError({ email, ex }));
                                    })
                                );
                        })
                    );


            }),
        );



    @Effect() public requestForgotPassword$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberForgotPasswordDataRequest),
            switchMap(({ email }) => {
                return this._membersService.resendForgotPasswordConfirmation(email)
                    .pipe(
                        switchMap(response => {
                            /*
                                We don't care here if response is false due to security reasons.
                                We navigate to success step.
                            */
                            return [
                                actions.MemberForgotPasswordDataResponseSuccess({ email }),
                                actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.FORGOT_PASSWORD_SENT }),
                            ];
                        }),
                        catchError(ex => {
                            console.error('EXEPTION', ex, typeof ex);
                            return of(actions.MemberForgotPasswordDataResponseError({ email, ex }));
                        })
                    );
            }),
        );

    @Effect() public setMemberNewPassword$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberPasswordSetRequest),
            switchMap(({ memberId, password }) => {
                return this._membersService.changeMemberPassword(memberId, password)
                    .pipe(
                        map(response => {
                            if (response) return actions.MemberPasswordSetSuccessRequest({ memberId, password });

                            return actions.MemberPasswordSetErrorRequest({ memberId, password });
                        }),
                        catchError(ex => {
                            console.error('Unable to set user password', ex);
                            return of(actions.MemberPasswordSetErrorRequest({ memberId, password }));
                        })
                    );
            })
        );

    @Effect() public initializePartialMemberSignUpProcess$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberPartialSignUpRequest),
            switchMap(({ memberId, memberData }) => {
                return this._membersService.getUserData(memberId)
                    .pipe(
                        tap(() => {
                            this._store.dispatch(actions.MemberPasswordSetRequest({ memberId, password: memberData.Password }));
                        }),
                        switchMap(memberDetails => {
                            return this._store
                                .pipe(
                                    select(selectors.getMemberState),
                                    delay(100),
                                    filter(state => state.setPassword.isSetting === false),
                                    take(1),
                                    switchMap(state => {
                                        if (state.setPassword.hasFailed) {
                                            throw new Error('Password set has failed');
                                        }

                                        const newMemberModel: APIv1.MemberModel = {
                                            ...memberDetails,
                                            RecievePromo: memberData.RecievePromo,
                                        };
                                        return this._membersService.updateUser(newMemberModel).pipe(
                                            switchMap(updateResponse => {
                                                if (updateResponse) {
                                                    this._membersService.resendTemporaryVerificationCode();

                                                    return [
                                                        actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.VERIFY_PHONE }),
                                                        actions.MemberPartialSignUpSuccessRequest({ memberId, memberData, payload: newMemberModel }),
                                                    ];
                                                }

                                                throw new Error('Unable to update user');
                                            })
                                        );
                                    })
                                );
                        }),
                        catchError(ex => {
                            console.error('Unable do register partial member', ex);
                            return of(actions.MemberPartialSignUpErrorRequest({ memberId, memberData }));
                        })
                    );
            }),
        );

    @Effect() public initializeSignUpProcess$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberSignUpProcessInit),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getMemberState)
                    )
            ),
            tap(([{ memberData }, initialState]) => {
                if (initialState.uniqueCode.hasSucceeded === false && !initialState.uniqueCode.data) {
                    this._store.dispatch(actions.MemberValidatePhoneRequest({ phone: memberData.MobilePhone }));
                    this._store.dispatch(actions.MemberValidateEmailDataRequest({ email: memberData.EmailAddress }));
                }
            }),
            switchMap(([{ memberData }, initialState]) => {
                return this._store
                    .pipe(
                        select(selectors.getMemberState),
                        filter(state => state.uniqueCode.hasSucceeded === true && state.uniqueCode.data !== null ||
                            state.validatePhone.isValidating === false && (state.validatePhone.hasSucceeded === true || state.validatePhone.hasFailed === true) &&
                            state.validateEmail.isValidating === false && (state.validateEmail.hasSucceeded === true || state.validateEmail.hasFailed === true)
                        ),
                        take(1),
                        switchMap(state => {
                            if (state.validatePhone.hasFailed || state.validateEmail.hasFailed) return never();

                            this._storePasswordForQuickLogin(memberData.Password);

                            if (state.uniqueCode.hasSucceeded === true && state.uniqueCode.data !== null) {
                                return [
                                    actions.MemberSignUpRequest({
                                        memberData: {
                                            ...memberData,
                                            IsMobileValidated: true,
                                        },
                                        existingMember: state.uniqueCode.data
                                    })
                                ];
                            }

                            return [
                                actions.MemberSendPhoneVerificationCodeDataRequest({ phoneNo: memberData.MobilePhone }),
                                actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.VERIFY_PHONE })
                            ];
                        }),
                    );
            })
        );

    @Effect() public requestRegisterMember$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberSignUpRequest),
            switchMap(({ memberData, existingMember }) => {
                /* There might be user without phone number due to new linking account cases - make sure it's not there */
                return iif(() => {
                    return !!existingMember === true;
                },
                    of(existingMember),
                    this._membersService.getMembers({
                        memberEmail: memberData?.EmailAddress || existingMember?.EmailAddress || null,
                    }).pipe(
                        map(({ Items }) => Items[0] || null)
                    )
                ).pipe(
                    switchMap(existingMemberChecked => {
                        return this._authService.signUp(memberData, existingMemberChecked)
                            .pipe(
                                map(payload => actions.MemberSignUpSuccessRequest({ memberData: payload })),
                                catchError(ex => {
                                    return of(actions.MemberSignUpErrorRequest({ memberData, existingMember: existingMemberChecked, ex }));
                                })
                            );
                    })
                );
            }),
        );

    @Effect() public requestRegisterMemberSuccess$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberSignUpSuccessRequest),
            withLatestFrom(
                this._store.
                    pipe(
                        select(selectors.getMemberState)
                    ),
                this._store
                    .pipe(
                        select(selectors.isMemberAuthorizedJWT)
                    )
            ),
            switchMap(([action, memberState, isAuthorized]) => {
                const bundleActions: Action[] = [];

                if (!isAuthorized) {

                    if (memberState.addData || memberState.data.IsEmailValidated === false) {
                        bundleActions.push(
                            actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.VERIFY_EMAIL }),
                            actions.MemberSendEmailVeryficationRequest({ email: memberState.addData ? memberState.addData.EmailAddress : memberState.data.EmailAddress }),
                        );
                    } else {
                        bundleActions.push(
                            actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.LOGIN }),
                        );
                    }
                } else {
                    bundleActions.push(
                        actions.MemberDataRequest({ memberId: memberState.accountId })
                    );
                }

                return bundleActions;
            }),
        );

    @Effect() public requestSendPhoneVeryficationCode$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberSendPhoneVerificationCodeDataRequest),
            switchMap(({ phoneNo }) => {
                return this._authService.verifyPhoneNumber(phoneNo)
                    .pipe(
                        map(response => response ? actions.MemberSendPhoneVerificationCodeDataResponseSuccess({ phoneNo }) : actions.MemberSendPhoneVerificationCodeDataResponseError({ phoneNo })),
                        catchError(ex => {
                            console.error('VerifyPhoneNoError', ex);
                            return of(actions.MemberSendPhoneVerificationCodeDataResponseError({ phoneNo, ex }));
                        })
                    );
            }),
        );

    @Effect() public requestVerifyPhoneCode$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberVerifyPhoneDataRequest),
            switchMap(({ phoneNo, token }) => {

                return this._authService.verifyPhoneNumberToken(phoneNo, token)
                    .pipe(
                        map(response => response ? actions.MemberVerifyPhoneDataSuccessRequest({ phoneNo, token }) : actions.MemberVerifyPhoneDataErrorRequest({ phoneNo, token })),
                        catchError(ex => {
                            console.error('VerifyPhoneNumberToken', ex);
                            return of(actions.MemberVerifyPhoneDataErrorRequest({ phoneNo, token, ex }));
                        })
                    );
            }),
        );

    @Effect() public requestPhoneVeryficationSuccess$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberVerifyPhoneDataSuccessRequest),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getMemberState)
                    ),
            ),
            switchMap(([action, memberState]) => {
                if (memberState.authorizationStep === OLO.Enums.AUTH_STEP.BEFORE_UPDATE_VERIFY_PHONE && memberState.update.data !== null && memberState.data !== null) {
                    const IsEmailValidated: boolean = memberState.data.EmailAddress === memberState.update.data.EmailAddress;
                    return [
                        actions.MemberUpdateRequest({
                            userModel: {
                                ...memberState.data,
                                ...memberState.update.data,
                                IsMobileValidated: true,
                                IsEmailValidated,
                            }
                        }),
                    ];
                }

                return [
                    actions.MemberSignUpRequest({ memberData: memberState.addData, existingMember: memberState.data }),
                ];
            }),
        );

    @Effect() public requestVerifyEmail$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberSendEmailVeryficationRequest),
            switchMap(({ email }) => {
                return this._store
                    .pipe(
                        select(selectors.getMemberState),
                        auditTime(100),
                        filter(state => state.update.isUpdating !== true),
                        take(1),
                        switchMap(state => {
                            console.log(state.update);
                            const model: APIv1.MemberEmailConfirmationRequestModel = {
                                MemberEmail: email,
                            };
                            return this._membersService.resendEmailConfirmation(model)
                                .pipe(
                                    map(response => response ? actions.MemberSendEmailVeryficationSuccessRequest({ email }) : actions.MemberSendEmailVeryficationDataResponseError({ email })),
                                    catchError(ex => {
                                        console.error('ResendEmailConfirmation', ex);
                                        return of(actions.MemberSendEmailVeryficationDataResponseError({ email, ex }));
                                    })
                                );
                        })
                    );
            }),
        );

    @Effect({ dispatch: false }) public createSession$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.CreateMemberSession),
            switchMap(({ sessionKey, accountId, authorizationType }) => {
                this._clearPasswordForQuickLogin();
                this._sessionService.createSession(sessionKey, accountId, authorizationType);

                return never();
            }),
        );


    @Effect() public checkUserSessionOnAppInit$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberSessionRequest),
            switchMap(action => {
                return this._sessionService.getSession()
                    .pipe(
                        switchMap(response => {
                            if (!response) {
                                return of(actions.MemberSessionErrorRequest({ sessionKey: null, accountId: null, authorizationType: null }));
                            }

                            return this._authService.validateSession()
                                .pipe(
                                    switchMap(isValid => {
                                        if (!isValid) {
                                            return of(actions.MemberSessionErrorRequest({ sessionKey: null, accountId: null, authorizationType: null }));
                                        }

                                        return of(actions.MemberSessionSuccessRequest({ sessionKey: response.SessionKey, accountId: response.AccountId as string, authorizationType: response.AuthorizationType }));
                                    })
                                );
                        })
                    );
            }),
        );

    @Effect() public setSessionFlags: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberSessionSuccessRequest),
            switchMap(({ accountId }) => {
                return [
                    actions.MemberAuthorizationSetFlag({ flag: true }),
                    actions.MemberDataRequest({ memberId: accountId }),
                    actions.MemberJwtDataResponseSuccess({ email: null, password: null }),
                ];
            }),
        );

    @Effect() public removeSessionDataOnError$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.MemberSessionErrorRequest
            ),
            withLatestFrom(
                this._sessionService.getSession()
            ),
            switchMap(([action, session]) => {
                /* Sign out only if there was session detected */
                if (session) {
                    this._sessionService.removeSession();
                    return of(actions.MemberSignOut({ redirect: false }));
                }
                return never();
            })
        );

    @Effect() public onLoyaltyProductSuccessRequestGetInfoAboutMemberProducts$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.LoyaltyProductProgramsSuccessRequest
            ),
            switchMap(action => {
                return this._store
                    .pipe(
                        select(selectors.getMemberState),
                        filter(state => state.data !== null),
                        take(1),
                        switchMap(state => {
                            const actionsBundle: Action[] = [];

                            if (!state.freeProducts.data && !state.freeProducts.isDownloading) {
                                actionsBundle.push(
                                    actions.MemberFreeProductsRequest({ memberId: state.data.MemberId })
                                );
                            }

                            if (!state.loyaltyProducts.data && !state.loyaltyProducts.isDownloading) {
                                actionsBundle.push(
                                    actions.MemberLoyaltyProductsRequest({ memberId: state.data.MemberId })
                                );
                            }

                            return actionsBundle;
                        })
                    );
            }),
        );

    @Effect() public requestFeeProducts$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.MemberFreeProductsRequest
            ),
            switchMap(({ memberId }) => this._membersService.apiGetFreeProductsForMemberRequest(memberId)
                .pipe(
                    map(payload => actions.MemberFreeProductsSuccessRequest({ memberId, payload })),
                    catchError(ex => {
                        console.error('MemberFreeProducts request error for member', memberId, ex);
                        return of(actions.MemberFreeProductsErrorRequest({ memberId, ex }));
                    }),
                )
            )
        );

    @Effect() public requestLoyaltyProducts$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.MemberLoyaltyProductsRequest
            ),
            switchMap(({ memberId }) => this._membersService.apiGetLoyaltyProductsForMemberRequest(memberId)
                .pipe(
                    map(payload => actions.MemberLoyaltyProductsSuccessRequest({ memberId, payload })),
                    catchError(ex => {
                        console.error('MemberLoyaltyProducts request error for member', memberId, ex);
                        return of(actions.MemberLoyaltyProductsErrorRequest({ memberId, ex }));
                    }),
                )
            )
        );

    @Effect() public requestMemberCodeCheck$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.MemberUniqueCodeCheckRequest
            ),
            switchMap(({ memberCode }) => {
                return this._membersService.checkMemberUniqueCode(memberCode)
                    .pipe(
                        map(payload => actions.MemberUniqueCodeCheckSuccessRequest({ memberCode, payload })),
                        catchError(ex => {
                            console.error('Check member code error', ex);
                            // actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.FAILED });
                            return [
                                actions.MemberUniqueCodeCheckErrorRequest({ memberCode, ex }),
                                actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.UNIQUE_CODE_ERROR }),
                            ];
                        })
                    );

            })
        );

    @Effect() public setStepAfterUniqueCodeRequest$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.MemberUniqueCodeCheckSuccessRequest
            ),
            switchMap(({ payload }) => {
                if (payload && payload.IsOnlineRegistered === true) {
                    return [
                        actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.UNIQUE_CODE_ERROR }),
                        // actions.MemberDataRequest({ memberId: payload.MemberId })
                    ];
                }
                return never();
            })
        );

    @Effect() public showBirthdayPopupOnSignIn$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.MemberJwtDataResponseSuccess,
            ),
            delay(3000),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getMemberState)
                    ),
                this._store
                    .pipe(
                        select(selectors.isMemberAuthorizedJWT)
                    )
            ),
            switchMap(([action, state, isAuthorized]) => {
                if (!isAuthorized
                    || this._config.birthdayRewards !== true
                    || this._config.appMode === IAppMode.ORDERING_ONLY
                    || Utils.Storage.getItem(OLO.Enums.USER_STORAGE.BIRTHDAY_REWARDS) === 'skip'
                    || !state.data
                    || state.data.Birthday
                ) return never();

                return this._store
                    .pipe(
                        select(selectors.isMemberLoading),
                        filter(isLoading => isLoading === false),
                        take(1),
                        switchMap(() => {
                            this._modalsService.show({
                                type: 'auth',
                            });

                            return [
                                actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.BIRTHDAY_REWARDS })
                            ];
                        })
                    );
            }),
        );

    @Effect() public triggerRequestAccountBalanceForMemberAfterSuccessTransaction$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.PaymentStepComplete
            ),
            filter(() => this._config.accountCharge?.enabled === true),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.isMemberAuthorizedJWT),
                    ),
                this._store
                    .pipe(
                        select(selectors.isAccountChargeSelected)
                    )
            ),
            switchMap(([action, isMemberAuthorized, hasPaidWithAccountCharge]) => {
                if (isMemberAuthorized && hasPaidWithAccountCharge) return of(actions.MemberAccountBalanceRequest({}));
                return never();
            })
        );

    @Effect() public triggerRequestAccountBalanceForMemberWhenAuthorized$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.MemberDataSuccessRequest,
                actions.MemberJwtDataResponseSuccess,
            ),
            filter(() => this._config.accountCharge?.enabled === true),
            auditTime(100),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.isMemberAuthorizedJWT),
                    ),
                this._store
                    .pipe(
                        select(selectors.getMemberState),
                    )
            ),
            switchMap(([action, isAuthorized, state]) => {
                if (!state.data?.HasAccount || state.accountBalance.hasSucceeded === true || !isAuthorized) return never();

                return of(actions.MemberAccountBalanceRequest({ memberId: state.data.MemberId }));
            })
        );

    @Effect() public requestAccountBalance$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MemberAccountBalanceRequest),
            switchMap(() => this._membersService.apiGetMemberAccountBalance()
                .pipe(
                    map(payload => actions.MemberAccountBalanceSuccessRequest({ payload })),
                    catchError(ex => {
                        console.error('Request account balance error', ex);
                        return of(actions.MemberAccountBalanceErrorRequest({ ex }));
                    })
                )
            )
        );

    private _storePasswordForQuickLogin(password: string): void {
        if (!this._config.unverifiedEmailQuickLogin) return;

        Utils.Storage.set(OLO.Enums.USER_STORAGE.PASSWORD, this._cryptoService.encrypt(password), 'sessionStorage');
    }

    private _getPasswordForQuickLogin(): string {
        if (!this._config.unverifiedEmailQuickLogin) return null;
        const storage: string = Utils.Storage.getItem(OLO.Enums.USER_STORAGE.PASSWORD, 'sessionStorage');

        if (!storage) return null;

        const decrypted: string = this._cryptoService.decrypt(storage);

        return decrypted || null;
    }

    private _clearPasswordForQuickLogin(): void {
        Utils.Storage.remove(OLO.Enums.USER_STORAGE.PASSWORD, 'sessionStorage');
    }
    /* https://stackoverflow.com/questions/43226681/how-to-subscribe-to-action-success-callback-using-ngrx-and-effects */
    /* https://medium.com/@amcdnl/handling-errors-in-ngrx-effects-2a116640d6bb */
    /* https://github.com/ngrx/example-app/issues/132 */
    /* https://stackoverflow.com/questions/41685519/ngrx-effects-error-handling */
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _actions$: Actions,
        private _store: Store<StateModels.IStateShared>,
        private _membersService: Services.MembersService,
        private _authService: Services.AuthService,
        private _sessionService: Services.SessionService,
        private _jwtService: Services.JWTService,
        private _cryptoService: Services.CryptoService,
        private _modalsService: Services.ModalsService,
    ) { }

}
