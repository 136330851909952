/* This class needs to be extended by app specific interceptor class in order to use app specific notification system */
// tslint:disable:prefer-const
/* https://medium.com/@aleixsuau/error-handling-angular-859d529fa53a */
import { ErrorHandler, NgZone, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import * as Enums from '@shared/core/enums';
@Injectable()
export class ErrorsHandlerSharedService implements ErrorHandler {

    constructor(
        /*
            https://stackoverflow.com/questions/34827334/triggering-change-detection-manually-in-angular
            Force to use this in child classes that extends this - to use changedetection in service
        */
        public zone: NgZone,
    ) { }

    public handleError(error: OLO.Common.PromiseError | Error | Response | HttpErrorResponse | PositionError) {
        /*
            AOLO-536
            Strange case of Benjamin Button...
            There was an error (unknown), page got reloaded... Why? No one knows.
            Why another payment went through automaticaly after reload?
            1. Lie or scenario not described properly
            2. Some uncovered case with navigation? - Maybe Bart's amazing 'page controller' based on 'keywords'. Check posibility
            3. There was some kind of error created by angular/ngrx that caused it to refresh and continue doing what it was doing?
            4. Uncovered case bad error handling?
            5. Scenario not described properly, there was an error, but at the second time she tried, at first time she navigated to order confirmation page
        */
        // console.warn('Error interceptor: Clearing local storage due to uncovered error');
        // window.localStorage.removeItem('__build'); /* This will force to clear sotrage data when page refreshes - for safety reasons */
        // window.localStorage.clear();
        // window.sessionStorage.clear();
        // document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });

        this.logError(error);
        const constructorName: string = error.constructor.name;
        const obj: string = Object.prototype.toString.call(error);

        switch (true) {

            case error instanceof HttpErrorResponse || constructorName === 'Response':
                this._httpErrorsHandler(error as Response | HttpErrorResponse);
                break;

            case constructorName === 'PositionError':
                this._geolocationErrorsHandler(error as PositionError);
                break;
            case obj === '[object Error]' && constructorName === 'Error' && error.hasOwnProperty('rejection'):
                this._httpErrorsHandler((error as OLO.Common.PromiseError).rejection);
                break;
            default:
                this.unknownError(error);
        }
    }

    private _geolocationErrorsHandler(error: PositionError) {
        let code: number = (error as PositionError).code || 0;
        let statusText: string = (error as PositionError).message || 'Unknown status';
        let errorCode: Enums.ERROR_CODES;
        let title: string = 'Geolocation Error';

        /* Assign error codes */
        if (Enums.ERROR_CODES['GEOLOCATION_' + code]) {
            title = errorCode = Enums.ERROR_CODES['GEOLOCATION_' + code];
        }

        this.showError(title, `${statusText}`, `We were unable to get your coodrinates. Some features on this website might not be available for you.`, code, errorCode);
    }

    private _httpErrorsHandler(error: Response | HttpErrorResponse): void {
        let code: number = (error as HttpErrorResponse).status || 0;
        let statusText: string = (error as HttpErrorResponse).statusText || 'Unknown status';
        let errorCode: Enums.ERROR_CODES;
        let message: string = '';
        let title: string = 'Http Error';

        if ((error as Response).json && typeof (error as Response).json === 'function') {
            try {
                const json: any = (error as Response).json();
                if (json.Message) {
                    message = json.Message;
                }
            } catch (ex) {
                if (!message) {
                    message = 'Unable to get error.json().Message. Please check the console for more detailed error info';
                }
            }
        }

        if (!navigator.onLine || error.url !== undefined && error.url === null || code === 0) {
            message = 'Check Internet Connection';
        }

        /* Assign error codes */
        if (Enums.ERROR_CODES['HTTP_' + code]) {
            title = errorCode = Enums.ERROR_CODES['HTTP_' + code];
        }

        if (!message) {
            if (error.hasOwnProperty('message') && error['message']) {
                message = error['message'];
            }
        }

        this.showError(title, `${statusText}`, `${message}`, code, errorCode);
    }

    public unknownError(...args: any[]) {
        this.showError('Unknown Error', '???', 'Please check the console for more detailed error info', null);
    }

    public logError(error: any): void {
        console.error(error.constructor.name, error);
    }

    public showError(errorType: string, title: string, description: string, statusCode: number, errorCode?: Enums.ERROR_CODES): any {
        // throw new Error('You need to overwrite this method');
        this.zone.run(() => {
            // this._alertMessagesService.show({
            //     type: 'error',
            //     title: `Error ${errorType}`,
            //     description: `${Number.isInteger(statusCode) ? 'Status code: ' + statusCode + '. ' : ''}${description ? description + '.' : ''}`
            // });
        });
    }
}
