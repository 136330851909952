import { createAction, props } from '@ngrx/store';

export const MemberStateReset = createAction('[Member] STATE RESET');

export const MemberUnsetAddData = createAction('[Member] Unset Add data for sign up process');

export const MemberUnsetAccountCredentials = createAction('[Member] Unset account credentials');

export const MemberUnsetUpdateAndValidation = createAction('[Member] Just clear out all flags that were set to update or verify account - make things clear to avoid collisions');

export const MemberSignOut = createAction(
    '[Member] Sign out',
    props<{ redirect: boolean | string; resetCart?: boolean; }>()
);

/* Quick login with email verification ommited */

export const MemberQuickLoginRequest = createAction(
    '[Member] Request - Quick login',
    props<{ login: string; password: string; authorizationType: OLO.Enums.LOGIN_TYPE; }>(),
);

export const MemberQuickLoginSuccessRequest = createAction(
    '[Member] Success request - Quick login',
    props<{ login: string; password: string; authorizationType: OLO.Enums.LOGIN_TYPE; sessionKey: string; }>(),
);


export const MemberQuickLoginErrorRequest = createAction(
    '[Member] Error request - Quick login',
    props<{ login: string; password: string; authorizationType: OLO.Enums.LOGIN_TYPE; ex?: any; }>(),
);

/* Get user data */
export const MemberDataRequest = createAction(
    '[Member] Request - get user data by ID',
    props<{ memberId: string | number; }>(),
);

export const MemberDataSuccessRequest = createAction(
    '[Member] Success request - get user data by ID',
    props<{ memberId: string | number; payload: APIv1.MemberModel; }>(),
);

export const MemberDataErrorRequest = createAction(
    '[Member] Error request - get user data by ID',
    props<{ memberId: string | number; payload: APIv1.MemberModel, ex?: any; }>(),
);

/* Authorization actions */
export const MemberValidateLoginRequest = createAction(
    '[Member] Request - validate login',
    props<{ login: string; loginType: OLO.Enums.LOGIN_TYPE; }>(),
);

export const MemberValidateLoginSuccessRequest = createAction(
    '[Member] Success request - validate login',
    props<{ login: string; loginType: OLO.Enums.LOGIN_TYPE; payload: APIv1.MemberModel; }>(),
);

export const MemberValidateLoginErrorRequest = createAction(
    '[Member] Error request - validate login',
    props<{ login: string; loginType: OLO.Enums.LOGIN_TYPE; ex?: any; }>(),
);

export const MemberSignInDataRequest = createAction(
    '[Member] Request - sign in/login',
    props<{ login?: string; password: string; }>(),
);

export const MemberSignInDataResponseSuccess = createAction(
    '[Member] Request - sign in/login success',
    props<{ password: string; sessionKey: string; }>(),
);

export const MemberSignInDataResponseError = createAction(
    '[Member] Request - sign in/login error',
    props<{ password: string; ex?: any; }>(),
);

export const MemberAuthorizationInit = createAction(
    '[Member] Init authorization flow',
    props<{ authorizationType: OLO.Enums.LOGIN_TYPE; accountLogin: string; }>(),
);

export const MemberAuthorizationSetFlag = createAction(
    '[Member] Set authorization flag',
    props<{ flag: boolean; }>(),
);

export const MemberAuthorizationSetStep = createAction(
    '[Member] Set authorization step',
    (p: { step: OLO.Enums.AUTH_STEP; }) => {
        return p;
    }
);

/* Guest Mode */
export const MemberGuestModeSet = createAction(
    '[Member] Guest mode set',
    props<{ flag: boolean; }>(),
);

export const MemberGuestDataRevalidateAndSet = createAction(
    '[Member] Guest data set revalidate and set',
    props<{ guestData: APICommon.IOnlineOrderPartialMember; }>(),
);

export const MemberGuestDataSet = createAction(
    '[Member] Guest data set',
    props<{ guestData: APICommon.IOnlineOrderPartialMember; }>(),
);

export const MemberGuestDataReset = createAction('[Member] Guest data reset');

/* JWT */
export const MemberJwtDataRequest = createAction(
    '[Member] Request - sign in/login jwt',
    props<{ email: string; password: string; }>(),
);

export const MemberJwtDataResponseSuccess = createAction(
    '[Member] Success request - sign in/login jwt',
    props<{ email: string; password: string; }>(),
);

export const MemberJwtDataResponseError = createAction(
    '[Member] Error request - sign in/login jwt',
    props<{ email: string; password: string; ex?: any; }>(),
);

/* PHONE and EMAIL Validation */
export const MemberValidatePhoneRequest = createAction(
    '[Member] Request - validate phone number',
    props<{ phone: string; }>(),
);

export const MemberValidatePhoneSuccessRequest = createAction(
    '[Member] Success request - validate phone number',
    props<{ phone: string; }>(),
);

export const MemberValidatePhoneDataResponseError = createAction(
    '[Member] Error request - validate phone number',
    props<{ phone: string; ex?: any; }>(),
);

export const MemberValidateEmailDataRequest = createAction(
    '[Member] Request - validate email',
    props<{ email: string; }>(),
);

export const MemberValidateEmailDataResponseSuccess = createAction(
    '[Member] Success request - validate email',
    props<{ email: string; }>(),
);

export const MemberValidateEmailDataResponseError = createAction(
    '[Member] Error request - validate email',
    props<{ email: string; ex?: any; }>(),
);

/* Session */
export const CreateMemberSession = createAction(
    '[Member] Creating session data',
    props<{ sessionKey: string; accountId: string; authorizationType: OLO.Enums.LOGIN_TYPE; }>(),
);

export const MemberSessionRequest = createAction('[Member] Check session data');

export const MemberSessionSuccessRequest = createAction(
    '[Member] Success request session data',
    props<{ sessionKey: string; accountId: string; authorizationType: OLO.Enums.LOGIN_TYPE; }>(),
);

export const MemberSessionErrorRequest = createAction(
    '[Member] Error request session data',
    props<{ sessionKey: string; accountId: string; authorizationType: OLO.Enums.LOGIN_TYPE; ex?: any; }>(),
);

// /* Partial member complete register */
// export const MemberPartialMemberSignUpProcessInit = createAction(
//     '[Member] Initialize sign up of partial member member',
//     props<{ memberId: number; memberData: APIv1.MemberModel; }>(),
// );

/* Register member */
export const MemberSignUpProcessInit = createAction(
    '[Member] Initialize sign up of new member',
    props<{ memberData: APIv1.MemberModel; }>(),
);

export const MemberSignUpRequest = createAction(
    '[Member] Request - sign up/register new member',
    props<{ memberData: APIv1.MemberModel; existingMember: APIv1.MemberModel; }>(),
);

export const MemberSignUpSuccessRequest = createAction(
    '[Member] Success request - sign up/register new member',
    props<{ memberData: APIv1.MemberModel; }>(),
);

export const MemberSignUpErrorRequest = createAction(
    '[Member] Error request - sign up/register new member',
    props<{ memberData: APIv1.MemberModel; existingMember: APIv1.MemberModel; ex?: any; }>(),
);

/* Updating member */
export const MemberProfileUpdateInit = createAction(
    '[Member] Update profile init',
    props<{ userModel: APIv1.MemberModel; modalId: number; }>(),
);

export const MemberProfileUpdateRequest = createAction(
    '[Member] Request update signed in user\'s profile data',
    (userModel: APIv1.MemberModel, modalId: number = null) => ({ userModel, modalId }),
);

export const MemberProfileUpdateSuccessRequest = createAction(
    '[Member] Success request update signed in user\'s profile data',
    props<{ userModel: APIv1.MemberModel; payload: APIv1.MemberModel; modalId?: number; }>(),
);

export const MemberProfileUpdateErrorRequest = createAction(
    '[Member] Error request update signed in user\'s profile data',
    props<{ userModel: APIv1.MemberModel; ex?: any; modalId?: number; }>(),
);

/* THESE ACTIONS ARE SICK - USED IN DIFFERENT UPDATE TYPES - NOT FOR JUST UPDATING USER DATA IN ACCOUNT PROFILE VIEW..... 'so generic' */
export const MemberUpdateRequest = createAction(
    '[Member] Request - update user data',
    props<{ userModel: APIv1.MemberModel; }>(),
);

export const MemberUpdateSuccessRequest = createAction(
    '[Member] Request success - update user data',
    props<{ userModel: APIv1.MemberModel, payload: APIv1.MemberModel; }>(),
);

export const MemberUpdateErrorRequest = createAction(
    '[Member] Request error - update user data',
    props<{ userModel: APIv1.MemberModel; ex?: any; }>(),
);

export const MemberRestoreUpdateFlags = createAction('[Member] Restore flags - update user data');

/* Change password */
export const MemberPasswordChangeRequest = createAction(
    '[Member] Request - update user password',
    props<{ OldPassword?: string; NewPassword?: string; MemberId: number; }>(),
);

export const MemberPasswordChangeSuccessRequest = createAction(
    '[Member] Request success - update user password',
    props<{ OldPassword: string; NewPassword: string; MemberId: number; }>(),
);

export const MemberPasswordChangeErrorRequest = createAction(
    '[Member] Request error - update user password',
    props<{ OldPassword: string; NewPassword: string; MemberId: number; ex?: any; }>(),
);

export const MemberPasswordChangeRestoreFlags = createAction('[Member] Restore flags - update user password');

/* Forgot Password */
export const MemberForgotPasswordDataRequest = createAction(
    '[Member] Request - forgot password',
    props<{ email: string; }>(),
);

export const MemberForgotPasswordDataResponseSuccess = createAction(
    '[Member] Request - forgot password response success',
    props<{ email: string; }>(),
);

export const MemberForgotPasswordDataResponseError = createAction(
    '[Member] Request - forgot password response error',
    props<{ email: string; ex?: any; }>(),
);

export const MemberForgotPasswordRestoreFlags = createAction('[Member] Restore flags - forgot password');

/* Verify Phone Number */
export const MemberSendPhoneVerificationCodeDataRequest = createAction(
    '[Member] Request - send verification code for phone',
    props<{ phoneNo: string; }>(),
);

export const MemberSendPhoneVerificationCodeDataResponseSuccess = createAction(
    '[Member] Success request - send verification code for phone',
    props<{ phoneNo: string; }>(),
);

export const MemberSendPhoneVerificationCodeDataResponseError = createAction(
    '[Member] Error request - send verification code for phone',
    props<{ phoneNo: string; ex?: any; }>(),
);

export const MemberVerifyPhoneDataRequest = createAction(
    '[Member] Request - verify phone',
    props<{ phoneNo: string; token: string; }>(),
);

export const MemberVerifyPhoneDataSuccessRequest = createAction(
    '[Member] Success request - verify phone',
    props<{ phoneNo: string; token: string; }>(),
);

export const MemberVerifyPhoneDataErrorRequest = createAction(
    '[Member] Error request - verify phone',
    props<{ phoneNo: string; token: string; ex?: any; }>(),
);

export const MemberVerifyPhoneRestoreFlags = createAction('[Member] Restore flags - verify phone');

/* Verify Email */
export const MemberSendEmailVeryficationRequest = createAction(
    '[Member] Request - send verification emial for email',
    props<{ email: string; }>(),
);

export const MemberSendEmailVeryficationSuccessRequest = createAction(
    '[Member] Success request - send verification emial for email',
    props<{ email: string; }>(),
);

export const MemberSendEmailVeryficationDataResponseError = createAction(
    '[Member] Error request - send verification emial for email',
    props<{ email: string; ex?: any; }>(),
);

export const MemberVerifyEmailRestoreFlags = createAction('[Member] Restore flags - verify email');

/* Validate password reset token */
export const MemberValidatePasswordResetTokenRequest = createAction(
    '[Member] GUID validate request',
    props<{ token: string; }>(),
);

export const MemberValidatePasswordResetTokenSuccessRequest = createAction(
    '[Member] GUID validate success request',
    props<{ token: string; payload: boolean; }>(),
);

export const MemberValidatePasswordResetTokenErrorRequest = createAction(
    '[Member] GUID validate error request',
    props<{ token: string; ex?: any; }>(),
);

export const MemberValidatePasswordResetTokenReset = createAction('[Member] GUID validate reset');

/* Reset forgotten password */
export const MemberForgottenPasswordResetRequest = createAction(
    '[Member] Request forgotten password reset',
    props<{ model: APIv1.MemberForgottenPasswordResetModel; }>(),
);

export const MemberForgottenPasswordResetSuccessRequest = createAction(
    '[Member] Success request forgotten password reset',
    props<{ model: APIv1.MemberForgottenPasswordResetModel; payload: boolean; }>(),
);

export const MemberForgottenPasswordResetErrorRequest = createAction(
    '[Member] Error request forgotten password reset',
    props<{ model: APIv1.MemberForgottenPasswordResetModel; ex?: any; }>(),
);

/* Confirm email */
export const MemberConfirmEmailTokenRequest = createAction(
    '[Member] Request confirm token for email address',
    props<{ token: string; }>(),
);

export const MemberConfirmEmailTokenSuccessRequest = createAction(
    '[Member] Success request confirm token for email address',
    props<{ token: string; payload: boolean; }>(),
);

export const MemberConfirmEmailTokenErrorRequest = createAction(
    '[Member] Error request confirm token for email address',
    props<{ token: string; ex?: any; }>(),
);

export const MemberConfirmEmailRequest = createAction(
    '[Member] Request confirm email address',
    props<{ token: string; }>(),
);

export const MemberConfirmEmailSuccessRequest = createAction(
    '[Member] Success request confirm email address',
    props<{ token: string; payload: boolean; }>(),
);

export const MemberConfirmEmailErrorRequest = createAction(
    '[Member] Error request confirm email address',
    props<{ token: string; ex?: any; }>(),
);

export const MemberConfirmEmailReset = createAction('[Member] Confirm email state reset');


export const MemberCompleteAccountSetupGuestCheck = createAction(
    '[Member] Get data to check if guest user can complete account setup',
    props<{ orderId: number; }>(),
);

/* Simulating email verification */
export const MemberVerifyEmailRequest = createAction(
    '[Member] Request verify email - for simulating requests',
);

export const MemberVerifyEmailSuccessRequest = createAction(
    '[Member] Success request verify email - for simulating requests',
);

export const MemberVerifyEmailErrorRequest = createAction(
    '[Member] Error request verify email - for simulating requests',
);

export const MemberVerifyEmaiResetFlags = createAction(
    '[Member] Reset verify email - for simulating requests',
);

export const MemberPartialSignUpRequest = createAction(
    '[Member] Request partial member sign up',
    props<{ memberId: number; memberData: APIv1.MemberModel; }>(),
);

export const MemberPartialSignUpSuccessRequest = createAction(
    '[Member] Success request partial member sign up',
    props<{ memberId: number; memberData: APIv1.MemberModel; payload: APIv1.MemberModel; }>(),
);

export const MemberPartialSignUpErrorRequest = createAction(
    '[Member] Error request partial member sign up',
    props<{ memberId: number; memberData: APIv1.MemberModel; ex?: any; }>(),
);

export const MemberPartialSignUpResetState = createAction(
    '[Member] Partial member sign up state reset'
);

export const MemberPasswordSetRequest = createAction(
    '[Member] Request password set for user',
    props<{ memberId: number; password: string; }>(),
);

export const MemberPasswordSetSuccessRequest = createAction(
    '[Member] Success request password set for user',
    props<{ memberId: number; password: string; }>(),
);

export const MemberPasswordSetErrorRequest = createAction(
    '[Member] Error request password set for user',
    props<{ memberId: number; password: string; ex?: any; }>(),
);

export const MemberPasswordSetResetState = createAction(
    '[Member] Password set state reset',
);

/* Free products */
export const MemberFreeProductsRequest = createAction(
    '[Member] Request free products',
    props<{ memberId: number; }>(),
);

export const MemberFreeProductsSuccessRequest = createAction(
    '[Member] Success request free products',
    props<{ memberId: number, payload: APIv1.MemberFreeProductModel[]; }>(),
);

export const MemberFreeProductsErrorRequest = createAction(
    '[Member] Error request free products',
    props<{ memberId: number; ex?: any; }>(),
);

/* Loyalty products */
export const MemberLoyaltyProductsRequest = createAction(
    '[Member] Request loyalty products',
    props<{ memberId: number; }>(),
);

export const MemberLoyaltyProductsSuccessRequest = createAction(
    '[Member] Success Request loyalty products',
    props<{ memberId: number; payload: APIv1.GetLoyaltyProductProgramTrackingBusinessModel[]; }>(),
);

export const MemberLoyaltyProductsErrorRequest = createAction(
    '[Member] Error request loyalty products',
    props<{ memberId: number, ex?: any; }>(),
);

/* Unique registration code */
export const MemberUniqueCodeCheckRequest = createAction(
    '[Member] Request unique code check',
    props<{ memberCode: string; }>(),
);

export const MemberUniqueCodeCheckSuccessRequest = createAction(
    '[Member] Success request unique code check',
    props<{ memberCode: string, payload: APIv1.MemberModel; }>(),
);

export const MemberUniqueCodeCheckErrorRequest = createAction(
    '[Member] Error request unique code check',
    props<{ memberCode: string, ex?: any; }>(),
);

export const MemberUniqueCodeCheckReset = createAction(
    '[Member] Reset unique code check'
);


/* Account balance */
export const MemberAccountBalanceRequest = createAction(
    '[Member] Request account balance',
    props<{}>(),
);

export const MemberAccountBalanceSuccessRequest = createAction(
    '[Member] Success request account balance',
    props<{ payload: APIv2.GetMemberAccountBalanceResponse; }>(),
);

export const MemberAccountBalanceErrorRequest = createAction(
    '[Member] Error request account balance',
    props<{ ex: any; }>(),
);

export const MemberAccountBalanceReset = createAction(
    '[Member] Account balance reset'
);

/* Link Rewards account */
export const MemberLinkRewardsAccountVerifyRequest = createAction(
    '[Member] Request link rewards account verify',
    props<{ params: APICommon.IMembersGetPaginatedList; }>(),
);

export const MemberLinkRewardsAccountVerifySuccessRequest = createAction(
    '[Member] Success request link rewards account verify',
    props<{ params: APICommon.IMembersGetPaginatedList; payload: APIv1.MemberModel; }>(),
);

export const MemberLinkRewardsAccountVerifyErrorRequest = createAction(
    '[Member] Error request link rewards account verify',
    props<{ params: APICommon.IMembersGetPaginatedList; ex?: any; }>(),
);


export const MemberLinkRewardsAccountVerifyReset = createAction(
    '[Member] Reset link rewards account verify',
);

/* Link account */
export const MemberLinkRewardsAccountRequest = createAction(
    '[Member] Request Link Rewards Member Account Init',
    props<{ password: string; }>(),
);

export const MemberLinkRewardsAccountSuccessRequest = createAction(
    '[Member] Success Request Link Rewards Member Account Init',
    props<{ password: string; payload: APIv1.MemberModel; }>(),
);

export const MemberLinkRewardsAccountErrorRequest = createAction(
    '[Member] Error Request Link Rewards Member Account Init',
    props<{ password: string; ex?: any; }>(),
);
