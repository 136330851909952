import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, ILoyaltyProductProgramItem } from '../interface';

const state = createFeatureSelector<IStateShared, ILoyaltyProductProgramItem[]>('loyaltyProductPrograms');

export const getLoyaltyProductPrograms = createSelector(
    state,
    loyaltyProductPrograms => loyaltyProductPrograms,
);

export const getLoyaltyProductProgram = (programName: string) => createSelector(
    state,
    loyaltyProductPrograms => loyaltyProductPrograms.find(obj => obj.ProgramName === programName)
);
/* APIv1.GetLoyaltyProductProgramBusinessModel */
export const getAllValidLoyaltyPrograms = createSelector(
    state,
    loyaltyProductPrograms => {
        const now = new Date();
        return loyaltyProductPrograms.reduce((acc, programStateModel) => {
            if (programStateModel.isDownloading
                || !programStateModel.data
                || programStateModel.data.length === 0
                || programStateModel.data[0] === undefined
                || new Date(programStateModel.data[0].EndDate) <= now
                || new Date(programStateModel.data[0].StartDate) > now
            ) return acc;

            return [
                ...acc,
                programStateModel.data[0]
            ];
        }, [] as APIv1.GetLoyaltyProductProgramBusinessModel[]);
    }
);

export const isDownloadingAnyProgram = createSelector(
    state,
    loyaltyProductPrograms => {
        return loyaltyProductPrograms.some(obj => obj.isDownloading === true);
    }
);

export const hasRequestedLoyaltyProductPrograms = createSelector(
    state,
    loyaltyProductPrograms => loyaltyProductPrograms.length > 0,
);
