import { createAction, props } from '@ngrx/store';

export const LocationsFiltersReset = createAction('[Locations filters] Reset');

export const LocationsFiltersSetPickupMode = createAction(
    '[Locations filters] Set pickup time mode ASAP/LATER TODAY',
    (mode: OLO.Ordering.IFilterForPickupMode = { Id: 1, Name: 'ASAP' }) => ({ mode }),
);

export const LocationsFiltersSetPickupTime = createAction(
    '[Locations filters] Set pickup time',
    (pickupTime: OLO.Ordering.IPickupTime) => ({ pickupTime }),
);

export const LocationsFiltersRestorePickupTime = createAction('[Locations filters] Restore to ASAP pickup time');

export const LocationsFiltersSyncPickupTime = createAction(
    '[Locations filters] Request adjust pickup time to when CURRENT TIME is changing without triggering EFFECTS!',
    (pickupTime: OLO.Ordering.IPickupTime = null) => ({ pickupTime }),
);

export const LocationsFiltersSetSearch = createAction(
    '[Locations filter] Set search string',
    (search: string = null) => ({ search }),
);
