import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    AlertMessagesStateModule,
    AppSettingsStateModule,
    AvailablePickupsStateModule,
    CartStateModule,
    CartPopupStateModule,
    CreditCardsStateModule,
    CurrentLocationStateModule,
    DietaryTagsImagesStateModule,
    FreeProductsImagesStateModule,
    GeolocationStateModule,
    HistoryOrdersStateModule,
    IngredientsStateModule,
    LatestTransactionsStateModule,
    LoaderStateModule,
    LocationsStateModule,
    LocationsFiltersStateModule,
    LocationsImagesStateModule,
    LoyaltyIntroductionPagesStateModule,
    LoyaltyIntroductionPagesImagesStateModule,
    LoyaltyMessagesStateModule,
    LoyaltyProductProgramsStateModule,
    MemberCardBarcodesImagesStateModule,
    MembersStateModule,
    MenuFlowDefaultActivationsStateModule,
    MenuFlowImagesStateModule,
    MenuFlowsStateModule,
    ModalsStateModule,
    OnlineMenuStateModule,
    OnlineMenuFiltersStateModule,
    OnlineMenuPagesImagesStateModule,
    OnlineMenuProductsImagesStateModule,
    OnlineOrderStateModule,
    // OrderingTimeInfoStateModule,
    OrderTypesStateModule,
    OrderTypesImagesStateModule,
    PaymentStateModule,
    PickupTimeValidateStateModule,
    ProductImagesStateModule,
    ReorderStateModule,
    RouterStateModule,
    AppStateStateModule,
    TopBarStateModule,
    VenuesImagesStateModule,
    WizzardStateModule,
} from './modules';

/*
    If you need to customize shared effects, create own module in your current project with new effects,
    and include it as a feature
*/
const stateModules: any[] = [
    AlertMessagesStateModule,
    AppSettingsStateModule,
    AvailablePickupsStateModule,
    CartStateModule,
    CartPopupStateModule,
    CreditCardsStateModule,
    CurrentLocationStateModule,
    DietaryTagsImagesStateModule,
    FreeProductsImagesStateModule,
    GeolocationStateModule,
    HistoryOrdersStateModule,
    IngredientsStateModule,
    LatestTransactionsStateModule,
    LoaderStateModule,
    LocationsStateModule,
    LocationsFiltersStateModule,
    LocationsImagesStateModule,
    LoyaltyIntroductionPagesStateModule,
    LoyaltyIntroductionPagesImagesStateModule,
    LoyaltyMessagesStateModule,
    LoyaltyProductProgramsStateModule,
    MemberCardBarcodesImagesStateModule,
    MembersStateModule,
    MenuFlowDefaultActivationsStateModule,
    MenuFlowImagesStateModule,
    MenuFlowsStateModule,
    ModalsStateModule,
    OnlineMenuStateModule,
    OnlineMenuFiltersStateModule,
    OnlineMenuPagesImagesStateModule,
    OnlineMenuProductsImagesStateModule,
    OnlineOrderStateModule,
    // OrderingTimeInfoStateModule,
    OrderTypesStateModule,
    OrderTypesImagesStateModule,
    PaymentStateModule,
    PickupTimeValidateStateModule,
    ProductImagesStateModule,
    ReorderStateModule,
    RouterStateModule,
    AppStateStateModule,
    TopBarStateModule,
    VenuesImagesStateModule,
    WizzardStateModule,
];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        ...stateModules,
    ],
    exports: [
        ...stateModules
    ],
})
export class StateSharedModule { }
