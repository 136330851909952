import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { buildEnvironment } from './environments/environment.build';
import { CONFIG } from './app/config';

console.log(buildEnvironment);
if (CONFIG.googleAnalytics?.length > 0 && window.gtag) {
    CONFIG.googleAnalytics.forEach(obj => {
        window.gtag('config', obj.id);
    });
}
if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
