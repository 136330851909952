import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';
import * as State from '@shared/state/interface';

import * as actions from '@shared/state/actions';
import * as selectors from '@shared/state/selectors';

import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PaymentsService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
        public store: Store<State.IStateShared>,
    ) { }

    public payWithAccountCharge(orderId: number, requestModel: APIv2.ExecuteOnlineOrderAccountChargeModel): Observable<APIv2.OnlineOrdersPerformAccountCharge.Responses.$201> {
        return this.httpClient.post<APIv2.OnlineOrdersPerformAccountCharge.Responses.$201>(`${Utils.HTTP.switchApi(this.config.api.base)}/OnlineOrders/${orderId}/accountCharge`, requestModel);
    }

    public pay(orderId: number, requestModel: APIv2.ExecutePaymentModel): Observable<APIv2.ExecutePaymentResponse> {
        return this.httpClient.post<APIv2.ExecutePaymentResponse>(`${Utils.HTTP.switchApi(this.config.api.base)}/OnlineOrders/${orderId}/pay`, requestModel);
    }

    public getPaymentStatus(transactionId: string, clientAppKey: string = this.config.api.key): Observable<APIv2.GetTransactionResponse> {
        return this.httpClient.get<APIv2.GetTransactionResponse>(`${Utils.HTTP.switchApi(this.config.api.base)}/Payments/${transactionId}`);
    }

    public resetPaymentFlow(): void {
        this.store.dispatch(actions.PaymentReset());
    }

    public cleanUp(): void {
        /* When exiting checkout page, make sure online order, payment and credit card data is reset */
        this.store.dispatch(actions.OnlineOrderStateReset());
        this.store.dispatch(actions.CreditCardTokenDataReset());
        this.store.dispatch(actions.PaymentReset());
    }

}
