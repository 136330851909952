/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { APIv2Configuration as __Configuration } from '../apiv-2configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PaginatedListPaymentAccountsListResponse } from '../models/paginated-list-payment-accounts-list-response';
import { CreatePaymentAccountResponse } from '../models/create-payment-account-response';
import { CreatePaymentAccountRequest } from '../models/create-payment-account-request';
import { SetPaymentAccountAsDefaultResponse } from '../models/set-payment-account-as-default-response';
import { DeactivatePaymentAccountResponse } from '../models/deactivate-payment-account-response';
import { GetMemberAccountBalanceResponse } from '../models/get-member-account-balance-response';
@Injectable({
  providedIn: 'root',
})
class MembersService extends __BaseService {
  static readonly membersGetMemberCardsPath = '/api/v2/members/creditCards';
  static readonly membersCreateMemberCardPath = '/api/v2/members/creditCards';
  static readonly membersSetPaymentAccountAsDefaultPath = '/api/v2/members/creditCards/{cardId}';
  static readonly membersRemoveMemberCardPath = '/api/v2/members/creditCards/{cardId}';
  static readonly membersGetMemberAccountBalancePath = '/api/v2/members/accountBalance';
  static readonly membersClearMemberPersonalDataPath = '/api/v2/members/clear';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieves member payment card list
   * @return OK
   */
  membersGetMemberCardsResponse(): __Observable<__StrictHttpResponse<PaginatedListPaymentAccountsListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/members/creditCards`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaginatedListPaymentAccountsListResponse>;
      })
    );
  }
  /**
   * Retrieves member payment card list
   * @return OK
   */
  membersGetMemberCards(): __Observable<PaginatedListPaymentAccountsListResponse> {
    return this.membersGetMemberCardsResponse().pipe(
      __map(_r => _r.body as PaginatedListPaymentAccountsListResponse)
    );
  }

  /**
   * Stores member payment card
   * @param request undefined
   * @return OK
   */
  membersCreateMemberCardResponse(request: CreatePaymentAccountRequest): __Observable<__StrictHttpResponse<CreatePaymentAccountResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/members/creditCards`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreatePaymentAccountResponse>;
      })
    );
  }
  /**
   * Stores member payment card
   * @param request undefined
   * @return OK
   */
  membersCreateMemberCard(request: CreatePaymentAccountRequest): __Observable<CreatePaymentAccountResponse> {
    return this.membersCreateMemberCardResponse(request).pipe(
      __map(_r => _r.body as CreatePaymentAccountResponse)
    );
  }

  /**
   * Sets payment account as default. Note that only one payment account can be set as a deafult one.
   * @param cardId undefined
   * @return OK
   */
  membersSetPaymentAccountAsDefaultResponse(cardId: number): __Observable<__StrictHttpResponse<SetPaymentAccountAsDefaultResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v2/members/creditCards/${cardId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SetPaymentAccountAsDefaultResponse>;
      })
    );
  }
  /**
   * Sets payment account as default. Note that only one payment account can be set as a deafult one.
   * @param cardId undefined
   * @return OK
   */
  membersSetPaymentAccountAsDefault(cardId: number): __Observable<SetPaymentAccountAsDefaultResponse> {
    return this.membersSetPaymentAccountAsDefaultResponse(cardId).pipe(
      __map(_r => _r.body as SetPaymentAccountAsDefaultResponse)
    );
  }

  /**
   * Deletes member payment card
   * @param cardId Member payment card identifier
   * @return OK
   */
  membersRemoveMemberCardResponse(cardId: number): __Observable<__StrictHttpResponse<DeactivatePaymentAccountResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v2/members/creditCards/${cardId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DeactivatePaymentAccountResponse>;
      })
    );
  }
  /**
   * Deletes member payment card
   * @param cardId Member payment card identifier
   * @return OK
   */
  membersRemoveMemberCard(cardId: number): __Observable<DeactivatePaymentAccountResponse> {
    return this.membersRemoveMemberCardResponse(cardId).pipe(
      __map(_r => _r.body as DeactivatePaymentAccountResponse)
    );
  }

  /**
   * Retrieves member account balance information
   * @return OK
   */
  membersGetMemberAccountBalanceResponse(): __Observable<__StrictHttpResponse<GetMemberAccountBalanceResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/members/accountBalance`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetMemberAccountBalanceResponse>;
      })
    );
  }
  /**
   * Retrieves member account balance information
   * @return OK
   */
  membersGetMemberAccountBalance(): __Observable<GetMemberAccountBalanceResponse> {
    return this.membersGetMemberAccountBalanceResponse().pipe(
      __map(_r => _r.body as GetMemberAccountBalanceResponse)
    );
  }

  /**
   * Clears member personal data
   * @return OK
   */
  membersClearMemberPersonalDataResponse(): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v2/members/clear`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * Clears member personal data
   * @return OK
   */
  membersClearMemberPersonalData(): __Observable<boolean> {
    return this.membersClearMemberPersonalDataResponse().pipe(
      __map(_r => _r.body as boolean)
    );
  }
}

module MembersService {
}

export { MembersService }
