import { createAction, props } from '@ngrx/store';

export const MenuFlowImagesRequest = createAction(
    '[Menu flow images] Request',
    props<{ params: APICommon.IImagesGetParams; menuFlowIds: number[] }>(),
);

export const MenuFlowImagesSuccessRequest = createAction(
    '[Menu flow images] Success request',
    props<{ payload: APIv1.ImageUrlModel[]; menuFlowIds: number[] }>(),
);

export const MenuFlowImagesErrorRequest = createAction(
    '[Menu flow images] Error request',
    props<{ menuFlowIds: number[]; ex?: any }>(),
);
