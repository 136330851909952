import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';

import * as State from '@shared/state';
import * as Tokens from '@shared/core/tokens';
import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';

import { Observable } from 'rxjs';
import { map, distinct, distinctUntilChanged, auditTime, filter, combineLatest, withLatestFrom, switchMap, take, tap, pairwise } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class IngredientsController {
    constructor(
        private _store: Store<State.IStateShared>,
    ) { }

    public getIngredientsForWizzardProduct(product: APIv1.MenuFlowProduct): Observable<State.IIngredient> {
        return this._store
            .pipe(
                select(selectors.getWizzardMenuFlow),
                filter(wizzardMenuFlow => wizzardMenuFlow !== null),
                switchMap(wizzardMenuFlow =>
                    this._store
                        .pipe(
                            select(selectors.getIngredientsForProduct(product.ProductId, wizzardMenuFlow.LocationNo)))
                )
            );
    }

    public isIngredientForWizzardProductMandatory(product: APIv1.MenuFlowProduct): Observable<boolean> {
        return this.getIngredientsForWizzardProduct(product)
            .pipe(
                map(ingredient => {
                    // Model has changed, there is no IsOptional flag any more in api model
                    /* return ingredient.data.Ingredients[0].IsOptional === false */
                    return true;
                }),
            );
    }
}
