import { createAction, props } from '@ngrx/store';

export const ProductImagesRequest = createAction(
    '[Product images] Request',
    props<{ params: APICommon.IImagesGetParams; productIds: number[] }>(),
);

export const ProductImagesSuccessRequest = createAction(
    '[Product images] Success request',
    props<{ payload: APIv1.ImageUrlModel[]; productIds: number[] }>(),
);

export const ProductImagesErrorRequest = createAction(
    '[Product images] Error request',
    props<{ productIds: number[]; ex?: any }>(),
);
