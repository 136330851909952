import { Injectable, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Utils from '@shared/core/utils';
import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';

import * as StateModels from '../interface';

import { Observable, of } from 'rxjs';
import { take, map, catchError, withLatestFrom, switchMap, filter } from 'rxjs/operators';

@Injectable()
export class OnlineOrdersEffects {
    @Effect() onOnlineOrderCreateRequest$: Observable<any> = this._actions$
        .pipe(
            ofType(actions.OnlineOrderCreateRequest),
            withLatestFrom(
                this._store.select(selectors.getGuestData),
                this._store.select(selectors.getCurrentMember),
            ),
            switchMap(([action, guestData, memberData]) => {

                return this._store
                    .pipe(
                        select(selectors.getOnlineOrderRecalcData),
                        filter(calculatedOrder => calculatedOrder.isRecalculating === false),
                        take(1),
                        withLatestFrom(
                            this._store
                                .pipe(
                                    select(selectors.getOnlineOrderState)
                                ),
                            this._store
                                .pipe(
                                    select(selectors.getLoyaltyAppId)
                                )
                        ),
                        switchMap(([calculatedOrder, orderState, LoyaltyAppId]) => {
                            if (calculatedOrder.hasFailed || !calculatedOrder.data) throw new Error('Invalid recalculated order data');

                            let email: string = null;
                            if (this._config.onlineOrders?.sendAutoReceiptEmail === true) {
                                if (guestData?.Email) {
                                    email = guestData.Email;
                                }

                                if (memberData?.EmailAddress) {
                                    email = memberData.EmailAddress;
                                }
                            }
                            const model: APIv1.OnlineOrderDetailedBusinessModel = {
                                ...calculatedOrder.data,
                                Id: null,
                                MemberId: memberData ? memberData.MemberId : null,
                                PartialMember: guestData,
                                LoyaltyAppId,
                                ...Utils.OnlineOrders.extendWithOrderTypeSurcharges(calculatedOrder.data, orderState.orderType, false)
                            };

                            return this._onlineOrdersService.createNewOnlineOrder(model, email)
                                .pipe(
                                    map(payload => {
                                        return actions.OnlineOrderCreateSuccessRequest({ payload });
                                    }),
                                );
                        }),
                        catchError(ex => of(actions.OnlineOrderCreateErrorRequest({ order: null, ex })))
                    );
            })

        );

    @Effect() onOnlineOrderSendConfirmationEmail$: Observable<any> = this._actions$
        .pipe(
            ofType(actions.OnlineOrderSendConfrimationEmailRequest),
            switchMap(action => {
                return this._onlineOrdersService.sendEmailWithOrderConfirmation(action.orderId)
                    .pipe(
                        map(result => result ? actions.OnlineOrderSendConfrimationEmailSuccessRequest({ result }) : actions.OnlineOrderSendConfrimationEmailErrorRequest({})),
                        catchError(ex => of(actions.OnlineOrderSendConfrimationEmailErrorRequest({ ex })))
                    );
            }),
        );



    @Effect() onOnlineOrderRecalculateRequest$: Observable<any> = this._actions$
        .pipe(
            ofType(actions.OnlineOrderRecalculateRequest),
            withLatestFrom(
                this._store.select(selectors.getCart),
                this._store.select(selectors.getCartTotalValue),
                this._store.select(selectors.getCurrentMember),
                this._store
                    .pipe(
                        select(selectors.getOnlineOrderState)
                    ),
                this._store
                    .pipe(
                        select(selectors.getLoyaltyAppId)
                    )
            ),
            switchMap(([action, cart, cartTotalValue, member, orderState, LoyaltyAppId]) => {
                const _tempOrderModel = Utils.OnlineOrders.convertCart(this._config.saleName, cart, cart.pickupTime, {
                    MemberId: member ? member.MemberId : null,
                    OrderTypeId: this._config.onlineOrders.OrderTypeId,
                    OnlineOrderType: this._config.onlineOrders.OnlineOrderType,
                    RemoveModifiers: this._config.onlineOrders && this._config.onlineOrders.allowModifiers === false ? true : false,
                });

                const orderModel = {
                    ..._tempOrderModel,
                    ...Utils.OnlineOrders.extendWithOrderTypeSurcharges(_tempOrderModel, orderState.orderType, true),
                    LoyaltyAppId,
                };

                return this._onlineOrdersService.recalculateOnlineOrder(orderModel)
                    .pipe(
                        map(payload => actions.OnlineOrderRecalculateSuccessRequest({ payload })),
                        catchError(ex => of(actions.OnlineOrderRecalculateErrorRequest({ order: orderModel, ex })))
                    );
            }),
        );

    @Effect() onlineOrderRequest$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.OnlineOrderRequest),
            switchMap(({ orderId }) => {
                return this._onlineOrdersService.getOnlineOrder(orderId)
                    .pipe(
                        map(payload => {
                            return actions.OnlineOrderSuccessRequest({ payload });
                        }),
                        catchError(ex => of(actions.OnlineOrderErrorRequest({ orderId, ex })))
                    );
            })
        );

    @Effect() sendOrderReceipt$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.OnlineOrderSendEmailReceiptRequest),
            switchMap(({ orderId }) => this._onlineOrdersService.sendOnlineOrderReceipt(orderId)
                .pipe(
                    map(response => response ? actions.OnlineOrderSendEmailReceiptSuccessRequest({ orderId }) : actions.OnlineOrderSendEmailReceiptErrorRequest({ orderId })),
                    catchError(ex => {
                        console.log(`Send order receipt error`, ex);
                        return of(actions.OnlineOrderSendEmailReceiptErrorRequest({ orderId, ex }));
                    }),
                )
            )
        );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _actions$: Actions,
        private _onlineOrdersService: Services.OnlineOrdersService,
        private _store: Store<StateModels.IStateShared>
    ) { }
}
