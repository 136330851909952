import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';

import * as State from '@shared/state';
import * as Tokens from '@shared/core/tokens';
import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';

import { Observable } from 'rxjs';
import { map, distinct, distinctUntilChanged, auditTime, filter, combineLatest, withLatestFrom, switchMap, take, tap, pairwise } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GeolocationController {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _store: Store<State.IStateShared>,
    ) { }

    public get userCoordsState$(): Observable<State.IGeolocation> {
        return this._store
            .pipe(
                select(selectors.getGeolocationState),
            );
    }

    public getDistanceToLocation$(coords: OLO.Maps.IMapSimpleCoords, toFixed: number = 2): Observable<number> {
        return this.userCoordsState$
            .pipe(
                map(state => {
                    if (state.data && coords.latitude && coords.longitude) {
                        let distance: number = Services.GeolocationService.getDistanceFromTo(
                            {
                                longitude: state.data.longitude,
                                latitude: state.data.latitude,
                            }, {
                            ...coords
                        });

                        if (this._config.localization.units === 'imperial') {
                            distance = Utils.Numbers.kilometersToMiles(distance);
                        }

                        if (toFixed !== null && toFixed !== undefined && toFixed >= 0) {
                            distance = +distance.toFixed(toFixed);
                        }

                        return distance;
                    }
                    return null;
                })
            );
    }
}
