import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, IOnlineOrder } from '../interface';
import * as Utils from '@shared/core/utils';

const state = createFeatureSelector<IStateShared, IOnlineOrder>('onlineOrder');

export const getOnlineOrder = createSelector(
    state,
    onlineOrder => onlineOrder.data
);

export const getOnlineOrderState = createSelector(
    state,
    onlineOrder => onlineOrder
);

export const getOnlineOrderCreateRequestState = createSelector(
    state,
    onlineOrder => onlineOrder.createRequest
);

export const getOnlineOrderTotalTax = createSelector(
    state,
    onlineOrder => {
        if (onlineOrder.recalculateRequest.data !== null && onlineOrder.recalculateRequest.data.TotalTaxes !== null && onlineOrder.recalculateRequest.data.TotalTaxes.length !== 0) {
            return onlineOrder.data.TotalTaxes
                .map(tax => tax.Value)
                .reduce((sum, current) => sum + current);
        }

        return 0;
    }
);

export const getOnlineOrderRecalcData = createSelector(
    state,
    onlineOrder => onlineOrder.recalculateRequest
);

export const isOnlineOrderLoading = createSelector(
    state,
    onlineOrder => (
        onlineOrder.updateRequest.isUpdating ||
        onlineOrder.downloadRequest.isDownloading ||
        onlineOrder.createRequest.isCreating
    )
);

// export const isOnlineOrderPendingResetSet = createSelector(
//     state,
//     onlineOrder => onlineOrder.pendingStateReset
// );

export const isOnlineOrderRecalculating = createSelector(
    state,
    onlineOrder => onlineOrder.recalculateRequest.isRecalculating
);

export const postOnlineOrderFailed = createSelector(
    state,
    onlineOrder => onlineOrder.createRequest.hasFailed
);

export const hasRecalculateFailed = createSelector(
    state,
    onlineOrder => onlineOrder.recalculateRequest.hasFailed
);

export const hasRecalculateFailedErrorMapped = createSelector(
    hasRecalculateFailed,
    hasFailed => !hasFailed ? null : Utils.Messages.mapErrors({ apiCommunication: 'We are having trouble processing payment. Please try again' })[0],
);

export const getSelectedOrderType = createSelector(
    state,
    onlineOrder => onlineOrder.orderType
);

export const isOrderTypeValid = createSelector(
    state,
    onlineOrder => {
        const _ = onlineOrder.orderType;
        if (!_) return false;
        if (_.Details.length === 0) return true;

        const hasRequired = _.Details.some(obj => obj.IsRequired === true);
        if (!hasRequired) return _.Details.reduce((flag, field) => {
            if (flag === false) return false;
            return field.hasOwnProperty('_Value');
        }, true);

        return _.Details.reduce((flag, field) => {
            if (flag === false) return false;
            if (field.IsRequired && !!field._Value === false) {
                return false;
            }

            return true;
        }, true);
    }
);

export const isOrderTypeValidForPosting = createSelector(
    state,
    onlineOrder => {
        const _ = onlineOrder.orderType;
        if (!_ || _.Details.length === 0) return true;

        const hasRequired = _.Details.some(obj => obj.IsRequired === true);

        if (!hasRequired) return true;

        return _.Details.reduce((flag, field) => {
            if (flag === false) return false;
            return !!field._Value;
        }, true);
    }
);

export const isSendingEmailReceipt = createSelector(
    state,
    onlineOrder => onlineOrder.sendEmailReceipt.isSending
);

export const hasMetMinimumOrderValueCriteria = createSelector(
    state,
    onlineOrder => {
        if (onlineOrder.data && onlineOrder.orderType) {
            return onlineOrder.data.TotalLeftToPay >= (onlineOrder.orderType.MinimumOrderValue || 0);
        }

        return null;
    }
);

export const minimumOrderTypeValue = createSelector(
    state,
    onlineOrder => onlineOrder.orderType?.MinimumOrderValue || null
);

export const isPaymentDisabledForOnlineOrderValidation = createSelector(
    getOnlineOrderState,
    isOrderTypeValidForPosting,
    hasMetMinimumOrderValueCriteria,
    (orderState, isValidOrderType, orderValueValid) => {
        return orderState.updateRequest.isUpdating ||
            orderState.downloadRequest.isDownloading ||
            orderState.createRequest.isCreating ||
            orderState.recalculateRequest.isRecalculating ||
            !isValidOrderType ||
            orderValueValid === false;
    }
);



export const onlineOrderErrorsMapped = createSelector(
    state,
    hasRecalculateFailedErrorMapped,
    hasMetMinimumOrderValueCriteria,
    (onlineOrder, errors, hasMetValueCriteria) => {
        if (errors) return errors;
        if (hasMetValueCriteria === false) return Utils.Messages.mapErrors({ minimumOrderValue: `You have not reached the minimum spend of $${onlineOrder.orderType.MinimumOrderValue.toFixed(2)}` })[0];
        return null;
    }
);
