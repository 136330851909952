import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import * as Tokens from '@shared/core/tokens';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class TransactionsService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
    ) { }

    public getLatestTransactionsForMember(memberId: number, limit: number = 10): Observable<APIv1.LoyaltyAppTransactionModel[]> {
        return this.httpClient.get<APIv1.TransactionsGetMemberNewestTransactions.Responses.$200>(`${this.config.api.base}/transactions/latest/${memberId}?pagingArgs.pageSize=${limit}&pagingArgs.pageNo=1`)
            .pipe(
                map(response => response.Items)
            );
    }
}
