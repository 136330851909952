import { Injectable, Inject } from '@angular/core';
import { Store } from '@ngrx/store';

import * as actions from '@shared/state/actions';

import * as State from '@shared/state/interface';
import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { LoyaltyAppService } from './loyalty-app.shared.service';

import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AppSettingsService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public store: Store<State.IStateShared>,
        public loyaltyAppService: LoyaltyAppService,
    ) { }

    public init(): void {
        this.loyaltyAppService.requestLoyaltyAppInfo();

        /* Regular checker */
        Utils.HTTP.isOnline$()
            .subscribe(online => this.store.dispatch(actions.AppSettingsSetOnlineStatus({ online })));

        this.setAppMode(this.config.venue && this.config.venue.id && this.config.venue.name ? OLO.Enums.APP_MODE.VENUE : OLO.Enums.APP_MODE.LOCATION);
        this.setDeviceType(Utils.Devices.isMobile() ? OLO.Enums.DEVICE_TYPE.MOBILE : OLO.Enums.DEVICE_TYPE.DESKTOP);
    }

    public setAppMode(mode: OLO.Enums.APP_MODE): void {
        this.store.dispatch(actions.SetApplicationMode({ mode }));
    }

    public setDeviceType(deviceType: OLO.Enums.DEVICE_TYPE): void {
        this.store.dispatch(actions.SetDeviceType({ deviceType }));
    }

    public setInitAppInitFlag(): void {
        this.store.dispatch(actions.SetApplicationInitFlag());
    }

    public onlineStatus$(): Observable<boolean> {
        return Utils.HTTP.isOnline$();
    }
}
