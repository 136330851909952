import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './payment.actions';
import { IPayment } from './payment.interface';

const initialState: IPayment = {
    OrderId: null,
    PaymentMethod: null,

    Errors: [],
    PaymentStepStatus: null,

    isPaying: false,
    hasSucceeded: false,
    hasFailed: false,
    data: null,
};

export const paymentsReducerFn = createReducer(
    initialState,
    on(
        actions.PaymentClearErrors,
        state => ({
            ...state,
            Errors: [],
        }),
    ),
    on(
        actions.PaymentInitAramark,
        actions.PaymentInit,
        (state, action) => ({
            OrderId: null,
            PaymentMethod: null,
            Errors: [],
            PaymentStepStatus: 'init',

            isPaying: true,
            hasSucceeded: false,
            hasFailed: false,
            data: null,
        })
    ),
    on(
        actions.PaymentStepValidateGuestData,
        (state, action) => ({
            ...state,
            PaymentStepStatus: 'validate_guest'
        })
    ),
    on(
        actions.PaymentStepValidateGuestCardToken,
        actions.PaymentStepValidateMemberCardToken,
        (state, action) => ({
            ...state,
            PaymentStepStatus: 'validate_card_token',
        })
    ),
    on(
        actions.PaymentStepCreateOrder,
        (state, action) => ({
            ...state,
            PaymentStepStatus: 'create_order',
        })
    ),
    on(
        actions.PaymentStepPay,
        (state, action) => ({
            ...state,
            PaymentStepStatus: 'paying',
        })
    ),
    on(
        actions.PaymentStepPaymentStatusCheck,
        (state, action) => ({
            ...state,
            OrderId: action.OrderId,
            PaymentStepStatus: 'payment_status_check',
            data: {
                TransactionId: action.TransactionId,
            }
        })
    ),
    on(
        actions.PaymentStepComplete,
        (state, action) => ({
            ...state,
            OrderId: action.OrderId,
            PaymentStepStatus: 'complete',
            data: {
                ...action.payload,
            },
            isPaying: false,
            hasSucceeded: true,
            hasFailed: false,
        })
    ),
    on(
        actions.PaymentStepFailed,
        (state, action) => ({
            ...state,

            PaymentStepStatus: 'failed',
            Errors: [
                ...state.Errors,
                {
                    Id: action.Id,
                    Message: action.Message,
                }
            ],
            isPaying: false,
            hasSucceeded: false,
            hasFailed: true,
        })
    ),
    on(
        actions.PaymentReset,
        (state, action) => JSON.parse(JSON.stringify(initialState))
    ),
);

export function paymentsReducer(state: IPayment | undefined, action: Action) {
    return paymentsReducerFn(state, action);
}
