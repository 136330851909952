import { Injectable, Inject } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { Actions, ofType, Effect } from '@ngrx/effects';

import * as actions from '../actions';

import * as StateModels from '../interface';

import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';

import { Observable, of } from 'rxjs';
import { switchMap, map, mergeMap, catchError } from 'rxjs/operators';

@Injectable()
export class OrderTypesEffects {
    @Effect() public requestOrderTypeForLocation$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CurrentLocationSet,
                actions.CartSetup,
            ),
            switchMap(action => {
                return of(actions.OrderTypesRequest({ locationNo: action.locationNo }));
            })
        );

    @Effect() public requestOrderTypes$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.OrderTypesRequest
            ),
            mergeMap(({ locationNo }) => this._loylatyAppService.apiGetOrderTypes(locationNo)
                .pipe(
                    map(payload => actions.OrderTypesSuccessRequest({ locationNo, payload })),
                    catchError(ex => {
                        console.error('OrderTypes request error', ex);
                        return of(actions.OrderTypesErrorRequest({ locationNo, ex }));
                    })
                )
            )
        );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _actions$: Actions,
        private _loylatyAppService: Services.LoyaltyAppService,
    ) { }
}
