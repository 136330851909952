import { createReducer, on, Action } from '@ngrx/store';
import { ICreditCards } from './credit-cards.interface';
import * as actions from './credit-cards.actions';

const initialState: ICreditCards = {
    activeCardId: null,
    activeCardToken: null,
    activeCardRedirectUrl: null,
    sessionToken: null,
    showAddCardForm: false, /* WARNING! This is just to show cc form on UI! Shouldn't be here!!! Boldman's idea. I've renamed it to showAddCardForm from isAddingNewCard */
    data: [],
    download: {
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: false,
        downloadedDate: null,
    },
    add: {
        isAdding: false,
        hasSucceeded: false,
        hasFailed: false,
    },
    remove: {
        id: null,
        isRemoving: false,
        hasSucceeded: false,
        hasFailed: false,
        removedDate: null,
    },
    token: {
        isGettingToken: false,
        hasSucceeded: false,
        hasFailed: false,
    },
    validation: { /* Required card validation after payment express rediredc return */
        isValidating: false,
        hasSucceeded: false,
        hasFailed: false,
    },
};

export const creditCardReducerFn = createReducer<ICreditCards>(
    initialState,
    on(
        actions.CreditCardsClearAllUnsavedCards,
        state => ({
            ...state,
            data: state.data.filter(obj => !(obj._SaveAwait === true && obj.Id === null) && !(obj.Id === null && obj.Token === null)),
        })
    ),
    on(
        actions.CreditCardsStateReset,
        (state, action) => ({
            ...state,
            ...JSON.parse(JSON.stringify(initialState))
        })
    ),
    on(
        actions.CreditCardTokenDataReset,
        (state, action) => {
            return {
                ...JSON.parse(JSON.stringify(initialState)),
                showAddCardForm: true,
            };
        }
    ),
    on(
        actions.CreditCardsResetError,
        (state, action) => ({
            ...state,
            download: {
                ...state.download,
                hasFailed: false,
            },
            token: {
                ...state.token,
                hasFailed: false,
            },
            remove: {
                ...state.remove,
                hasFailed: false,
            },
            add: {
                ...state.add,
                hasFailed: false,
            },
            validation: {
                ...state.validation,
                hasFailed: false,
            }
        })
    ),
    on(
        actions.CreditCardShowForm,
        (state, action) => {
            return {
                ...state,
                showAddCardForm: action.isAdding,
            };
        }
    ),
    on(
        actions.SelectActiveCreditCardId,
        (state, action) => ({
            ...state,
            activeCardId: action.cardId,
            activeCardToken: null,
        })
    ),
    on(
        actions.SelectActiveCreditCardToken,
        (state, action) => ({
            ...state,
            activeCardToken: action.token,
            activeCardId: null,
        })
    ),
    on(
        actions.AddCardToState,
        (state, action) => ({
            ...state,
            showAddCardForm: false,
            data:
                [
                    ...state.data,
                    action.card
                ]
        })
    ),
    on(
        actions.GetCreditCardToken,
        (state, action) => ({
            ...state,
            token: {
                ...state.token,
                isGettingToken: true,
                hasSucceeded: false,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.GetCreditCardTokenWithRedirect,
        (state, action) => ({
            ...state,
            activeCardRedirectUrl: null,
            activeCardToken: null,
            sessionToken: null,

            token: {
                ...state.token,
                isGettingToken: true,
                hasSucceeded: false,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.CreditCardsSuccessRequestToken,
        (state, action) => ({
            ...state,
            token: {
                ...state.token,
                isGettingToken: false,
                hasSucceeded: true,
                hasFailed: false,
            },
        })
    ),
    on(

        actions.CreditCardsSuccessRequestTokenWithRedirect,
        (state, action) => ({
            ...state,
            activeCardRedirectUrl: action.redirectUrl,
            sessionToken: action.token,
            token: {
                ...state.token,
                isGettingToken: false,
                hasSucceeded: true,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.__DEMO__CreditCardsSuccessRequestToken,
        (state, action) => {
            return {
                ...state,
                showAddCardForm: false,
                add: {
                    ...state.add,
                    isAdding: false,
                    hasSucceeded: true,
                    hasFailed: false,
                },
                token: {
                    ...state.token,
                    isGettingToken: false,
                    hasSucceeded: true,
                    hasFailed: false,
                },
            };
        }
    ),
    on(
        actions.CreditCardsErrorRequestToken,
        (state, action) => ({
            ...state,
            token: {
                ...state.token,
                isGettingToken: false,
                hasSucceeded: false,
                hasFailed: true,
            },
        })
    ),
    on(
        actions.CreditCardsErrorRequestTokenWithRedirect,
        (state, action) => ({
            ...state,
            activeCardRedirectUrl: null,
            activeCardToken: null,
            sessionToken: null,
            token: {
                ...state.token,
                isGettingToken: false,
                hasSucceeded: false,
                hasFailed: true,
            },
        })
    ),
    on(
        actions.CreditCardsAddRequest,
        actions.CreditCardsAddAfterRedirectRequest,
        (state, action) => ({
            ...state,
            add: {
                ...state.add,
                isAdding: true,
                hasFailed: false,
                hasSucceeded: false,
            },

        })
    ),
    on(
        actions.CreditCardsAddSuccessRequest,
        (state, action) => ({
            ...state,
            add: {
                ...state.add,
                isAdding: false,
                hasFailed: false,
                hasSucceeded: true,
            }

        })
    ),
    on(
        actions.CreditCardsAddAfterRedirectSuccessRequest,
        (state, action) => {
            const isNewDefault = action.newCard.IsDefault;
            return {
                ...state,
                add: {
                    ...state.add,
                    isAdding: false,
                    hasFailed: false,
                    hasSucceeded: true,
                },
                data: state.data.map(card => {
                    if (card.Token === action.card.Token) {
                        const c = {
                            ...card,
                            ...action.newCard,
                        };

                        delete c._SaveAwait;
                        return c;
                    } else {
                        if (isNewDefault) {
                            return {
                                ...card,
                                IsDefault: false,
                            };
                        }

                        return card;

                    }
                })
            };
        }
    ),
    on(
        actions.CreditCardsAddErrorRequest,
        actions.CreditCardsAddAfterRedirectErrorRequest,
        (state, action) => ({
            ...state,
            add: {
                ...state.add,
                isAdding: false,
                hasFailed: true,
                hasSucceeded: false,
            }
        })
    ),
    on(
        actions.CreditCardsRemoveRequest,
        (state, action) => ({
            ...state,
            remove: {
                ...state.remove,
                id: action.cardId,
                removedDate: null,
                isRemoving: true,
                hasSucceeded: false,
                hasFailed: false,
            }

        })
    ),
    on(
        actions.CreditCardsRemoveSuccessRequest,
        (state, action) => {
            return {
                ...state,
                remove: {
                    ...state.remove,
                    id: null,
                    removedDate: new Date().getTime(),
                    isRemoving: false,
                    hasSucceeded: true,
                    hasFailed: false,
                },
                data: state.data.filter(cart => cart.Id !== action.cardId),
                showAddCardForm: state.data.filter(cart => cart.Id !== action.cardId).length === 0 && state.activeCardId !== -1
            };
        }
    ),
    on(
        actions.CreditCardsRemoveErrorRequest,
        (state, action) => ({
            ...state,
            remove: {
                ...state.remove,
                isRemoving: false,
                hasSucceeded: false,
                hasFailed: true,
            },
        })
    ),
    on(
        actions.CreditCardsRequest,
        (state, action) => ({
            ...state,
            download: {
                ...state.download,
                isDownloading: true,
                hasSucceeded: false,
                hasFailed: false
            }
        })
    ),
    on(
        actions.CreditCardsSuccessRequest,
        (state, action) => {
            return {
                ...state,
                download: {
                    ...state.download,
                    isDownloading: false,
                    hasSucceeded: true,
                    hasFailed: false,
                    downloadedDate: new Date().getTime(),
                },
                data: action.payload.reduce((acc, card) => {
                    const found = acc.find(obj => obj.Id === card.Id);
                    if (!found) {
                        return [
                            ...acc,
                            card,
                        ];
                    }

                    return acc.map(obj => {
                        if (obj.Id === card.Id) {
                            return card;
                        }
                        return obj;
                    });
                }, [
                    ...state.data
                ]),
            };
        }
    ),
    on(
        actions.CreditCardsErrorRequest,
        (state, action) => ({
            ...state,
            download: {
                ...state.download,
                isDownloading: false,
                hasSucceeded: false,
                hasFailed: true,
            },
        })
    ),
    on(
        actions.CreditCardsValidateReset,
        state => ({
            ...state,
            validation: {
                ...state.validation,
                isValidating: false,
                hasSucceeded: false,
                hasFailed: false,
            }
        })
    ),
    on(
        actions.CreditCardsValidateRequest,
        state => ({
            ...state,
            activeCardRedirectUrl: null,
            validation: {
                ...state.validation,
                isValidating: true,
                hasSucceeded: false,
                hasFailed: false,
            }
        })
    ),
    on(
        actions.CreditCardsValidateSuccessRequest,
        (state, action) => ({
            ...state,
            activeCardToken: action.card.CardId,
            activeCardRedirectUrl: null,
            sessionToken: null,
            showAddCardForm: false,
            validation: {
                ...state.validation,
                isValidating: false,
                hasSucceeded: true,
                hasFailed: false,
            },
            data: state.data.map(obj => {
                if (obj.Id === null && obj.Token === null) {
                    return {
                        ...obj,
                        Token: action.card.CardId
                    };
                }
                return obj;
            })
        })
    ),
    on(
        actions.CreditCardsValidateErrorRequest,
        state => ({
            ...state,
            activeCardToken: null,
            activeCardRedirectUrl: null,
            sessionToken: null,
            validation: {
                ...state.validation,
                isValidating: false,
                hasSucceeded: false,
                hasFailed: true,
            }
        })
    ),
    on(
        actions.CreditCardsRemoveUnsavedCard,
        (state, action) => {
            return {
                ...state,
                activeCardToken: null,
                showAddCardForm: state.activeCardId !== -1,
                data: state.data.filter(card => card.Id === null && card.Token === action.token),
            };
        }
    ),
    on(
        actions.CreditCardsSetErrorValidationStatusToValidatingCards,
        state => ({
            ...state,
            data: state.data.map(card => {
                if (card._ValidationStatus === 'validating') {
                    return {
                        ...card,
                        _ValidationStatus: 'error',
                    };
                }
                return card;
            })
        })
    ),
);

export function creditCardReducer(state: ICreditCards | undefined, action: Action) {
    return creditCardReducerFn(state, action);
}
