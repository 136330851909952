import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, IRouter } from '../interface';

const state = createFeatureSelector<IStateShared, IRouter>('router');

export const getCurrentRoute = createSelector(
    state,
    router => router.current
);

export const getPreviousRoute = createSelector(
    state,
    router => router.previous
);

export const getRoutesHistory = createSelector(
    state,
    router => router.history
);

export const getCurrentRouteQueryParams = createSelector(
    state,
    router => {
        if (!router.current) return {};
        return router.current.queryParams;
    }
);

export const isCurrentRouteHome = createSelector(
    getCurrentRoute,
    current => current && (current.url === '/' || current.url === '')
);

export const isCurrentRouteLoyalty = createSelector(
    getCurrentRoute,
    current => current && (current.url === '/loyalty')
);

export const isCheckoutPage = createSelector(
    getCurrentRoute,
    current => current && (current.url.startsWith('/checkout'))
);

export const isEmailConfirmationPage = createSelector(
    getCurrentRoute,
    current => current && (current.url.startsWith('/email-confirmation'))
);

export const isResetPasswordPage = createSelector(
    getCurrentRoute,
    current => current && (current.url.startsWith('/reset-password'))
);

export const isError404Page = createSelector(
    getCurrentRoute,
    current => current && (current.url.startsWith('/404'))
);
