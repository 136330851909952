import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as State from '@shared/state/interface';
import * as Utils from '@shared/core/utils';
import * as Tokens from '@shared/core/tokens';

import { ModalsService } from './modals.shared.service';
import { LocationsService } from './locations.shared.service';

import { Observable, of } from 'rxjs';
import { map, filter, withLatestFrom, combineLatest, switchMap, distinct, auditTime, distinctUntilChanged, tap, take, skipWhile } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PickupsService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public store: Store<State.IStateShared>,
        public locationsService: LocationsService,
        public modalsService: ModalsService,
        public actions$: Actions,
        public router: Router,
    ) { }

    public availablePickupsListForLocation$(locationNo: number, limit: number = null, futureOrders: boolean = false) {
        return this.store
            .pipe(
                select(selectors.getAvailablePickupTimesWithFutureForLocation(this.config, locationNo, futureOrders)),
                map(arr => {
                    const list = [...arr];
                    if (!list) return null;

                    if (limit && list.length > limit) {
                        list.length = limit;
                    }

                    if (futureOrders && arr.length > limit) {
                        list.push(Utils.Dates.createScheduleModel());
                    }

                    if (!futureOrders) {
                        return list.map(obj => {
                            if (obj.IsAsap) {
                                return {
                                    ...obj,
                                    Name: 'ASAP'
                                };
                            }
                            return obj;
                        });
                    }
                    return list;

                }),
                auditTime(0),
            );
    }

    // public availablePickupsListForCurrentLocation$(limit: number = null, futureOrders: boolean = false): Observable<OLO.Ordering.IPickupTime[]> {
    //     return this.store.pipe(
    //         select(selectors.getAvailablePickupTimesForCurrentLocation(futureOrders)),
    //         map(arr => {
    //             console.warn(arr);
    //             const list = [...arr];
    //             if (!list) return null;

    //             if (limit && list.length > limit) {
    //                 list.length = limit;
    //             }

    //             if (futureOrders && arr.length > limit) {
    //                 list.push(Utils.Dates.createScheduleModel());
    //             }

    //             if (!futureOrders) {
    //                 return list.map(obj => {
    //                     if (obj.IsAsap) {
    //                         return {
    //                             ...obj,
    //                             Name: 'ASAP'
    //                         };
    //                     }
    //                     return obj;
    //                 });
    //             }
    //             return list;

    //         }),
    //         auditTime(0),
    //     );
    // }

    // public availablePickupsListForCurrentLocation$(labels: OLO.Components.ILabel = {}, limit: number = null, isSchedule: boolean = false): Observable<OLO.Ordering.IPickupTime[]> {
    //     const defaults: OLO.Components.ILabel = { prefix: 'ASAP ( ', suffix: 'mins )', defaultLabel: 'ASAP' };
    //     const newLabels: OLO.Components.ILabel = {
    //         ...defaults,
    //         ...labels,
    //     };

    //     return this.store.pipe(
    //         select(selectors.getAvailablePickupTimesForCurrentLocation),
    //         filter(obj => obj !== null),
    //         combineLatest(
    //             this.store.pipe(
    //                 select(selectors.getCurrentLocationNo),
    //                 filter(locationNo => !Number.isNaN(locationNo)),
    //             )
    //         ),
    //         switchMap(([list, locationNo]) => {
    //             return this.store.pipe(
    //                 select(selectors.getOrderInfoCompleteForLocationByDate(locationNo)),
    //                 map((orderInfo) => {
    //                     if (!orderInfo || !orderInfo.IsOpen) return list;
    //                     return list.map(obj => {
    //                         if (obj.IsAsap) {
    //                             return {
    //                                 ...obj,
    //                                 Name: obj.MinutesFromNow ? `${newLabels.prefix}${obj.MinutesFromNow}${newLabels.suffix}` : `${newLabels.defaultLabel}`
    //                             };
    //                         }

    //                          return obj;
    //                     });
    //                 })
    //             );
    //         }),
    //         auditTime(0),
    //     );
    // }

    public selectedCurrentPickupTime$(): Observable<OLO.Ordering.IPickupTime> {
        return this.store.pipe(
            select(selectors.getCurrentPickupTime),
        );
    }

    public selectedCurrentPickupTimeLabel$(isSchedule: boolean, type: OLO.Types.PICKUP_LABEL_TYPES = 'location'): Observable<string> {
        return this.store.pipe(
            select(selectors.getCurrentPickupTime),
            map(pickupTime => {
                if (!isSchedule && pickupTime.IsAsap) {
                    return 'ASAP';
                }

                return Utils.Dates.createPickupLabelName(
                    this.config.pickups.futureOrders,
                    type,
                    pickupTime.Date,
                    pickupTime.IsAsap,
                    null,
                    pickupTime.IsAsap ? pickupTime.MinutesFromNow : null);
            })
        );
    }

    public selectPickupTimeForCurrentLocation(pickupTime: OLO.Ordering.IPickupTime): void {
        this.store.dispatch(actions.CurrentLocationPickupTimeSet(pickupTime));
        this.store.dispatch(actions.LocationsFiltersSyncPickupTime(pickupTime.IsAsap ? null : pickupTime)); /* Keep change pickup time synced with current pickup time */

        // if (pickupTime.IsAsap) {
        //     this.store.dispatch(actions.LocationsFiltersSetPickupMode({ Id: 1, Name: 'ASAP' }));
        // } else {
        //     this.store.dispatch(actions.LocationsFiltersSetPickupMode({ Id: 2, Name: this.config.pickups.futureOrders ? 'Schedule' : 'Later today' }));
        // }
    }

    /*
        This will validate cart
    */
    public validateSelectedPickupTimeObjForOnlineMenu(
        date: Date = new Date(),
        pickupTimeObj: OLO.Ordering.IPickupTime,
        onlineMenuTimes: { StartTime?: string; EndTime?: string; },
        openHours: APIv1.OpeningHoursModel = null,
        locationUpdatedPickupTimeObj: APIv1.MinimumPickupTimeModel = null,
    ): boolean {
        if (!pickupTimeObj || onlineMenuTimes === null || onlineMenuTimes.StartTime === null || onlineMenuTimes.EndTime === null) return false;
        const d: Date = date;

        let isToday: boolean = pickupTimeObj.Date.getDate() === d.getDate() && pickupTimeObj.Date.getMonth() === d.getMonth() && pickupTimeObj.Date.getFullYear() === d.getFullYear();
        let hasExceededPlaceOrderTimeoutForPickupTime: boolean = pickupTimeObj.IsAsap ? false : d > pickupTimeObj.PlaceOrderTimeout;
        let isPickupTimeInOnlineMenuTimeRange: boolean = Utils.Dates.isHourInTimeRange(pickupTimeObj.Hour, onlineMenuTimes.StartTime, onlineMenuTimes.EndTime);
        const isPickupTimeInLocationOpenRange: boolean = !openHours ? true : Utils.Dates.isHourInTimeRange(pickupTimeObj.Hour, openHours.OpeningTime, openHours.ClosingTime);

        /* FUTURE ORDERS ONLY! */
        if (this.config.pickups.futureOrders && !pickupTimeObj.IsToday) {
            if (!isPickupTimeInOnlineMenuTimeRange || !isPickupTimeInLocationOpenRange) return false;
            return true;
        }


        /*
            AOLO-278
            Validate if preperation time changed during checkout and kitchen won't be able to prepeare meal on time,
            should this be moved to validateSelectedPickupTimeObj?
        */
        if (locationUpdatedPickupTimeObj) {
            const locationClosingHour: number = Utils.Dates.createHoursIntFromDate(openHours.ClosingTime);
            const newPickupTime: Date = new Date(new Date().getTime() + locationUpdatedPickupTimeObj.MinimumPickupTime * 60 * 1000);
            const newPickupTimeHour: number = Utils.Dates.createHoursIntFromDate(newPickupTime);

            if (pickupTimeObj.IsAsap) {
                if (newPickupTimeHour >= locationClosingHour) {
                    return false;
                }
            } else {
                /* AOLO-404 */
                const targetPickupTimeDiff = pickupTimeObj.Id - locationUpdatedPickupTimeObj.MinimumPickupTime * 60 * 1000;
                const prepTimeExceedsPickupTime = targetPickupTimeDiff < d.getTime();
                if (prepTimeExceedsPickupTime) return false;
            }
        }
        /* AOLO-278 - END */

        /* AOLO-277 - when user spend too much time on checkout and menu changes */
        const now: Date = new Date();
        const nowHours: string = Utils.Dates.hoursFromDate(now, false) + ':59';
        const isNowInOnlineMenuRange: boolean = Utils.Dates.isHourInTimeRange(nowHours, onlineMenuTimes.StartTime, onlineMenuTimes.EndTime);

        const isOpenNow: boolean = Utils.Dates.isHourInHoursRange(Utils.Dates.getLocalISOFormatDate(now), openHours.OpeningTime, openHours.ClosingTime, 'from');
        const willBeOpenToday: boolean = Utils.Dates.isHourInHoursRange(Utils.Dates.getLocalISOFormatDate(now), Utils.Dates.getLocalISOFormatDate(now), openHours.ClosingTime, 'from');
        const allowClosedLocationOrders = this.config.onlineOrders.allowClosedLocationOrders === true;

        /* Base mandatory checks no need to go deeper if any of these four are false */
        if (!isToday) return false;
        if (!isPickupTimeInOnlineMenuTimeRange) return false;
        if (allowClosedLocationOrders) {
            if (isOpenNow && pickupTimeObj.IsAsap === true && !isNowInOnlineMenuRange) return false;
            if (!isOpenNow && willBeOpenToday && pickupTimeObj.IsAsap === true && !isPickupTimeInOnlineMenuTimeRange) return false;
        } else {
            console.warn('Ordering from closed location is not allowed');
            if (pickupTimeObj.IsAsap === true && !isNowInOnlineMenuRange) return false;
        }

        let isUpdatedPickupTimeInLocationOpenHoursRange: boolean = true;
        let onlineMenuHasChanged: boolean = false;

        if (locationUpdatedPickupTimeObj && openHours) {
            /*
                We don't want to take orders for user that will show up after location is closed.
                Check if pickupTime + target pickup time won't exceed location's opening time.
            */
            const newPickupTime: number = new Date().getTime() + locationUpdatedPickupTimeObj.MinimumPickupTime * 60 * 1000;
            const newPickupTimeDate: Date = new Date(newPickupTime);
            const newPickupTimeHour: string = Utils.Dates.hoursFromDate(newPickupTimeDate, false);

            isUpdatedPickupTimeInLocationOpenHoursRange = Utils.Dates.isHourInTimeRange(newPickupTimeHour, openHours.OpeningTime, openHours.ClosingTime);

            /* online menu has changed */
            const nowHours: string = Utils.Dates.hoursFromDate(new Date(), false);
            onlineMenuHasChanged = Utils.Dates.isHourInTimeRange(nowHours, onlineMenuTimes.StartTime, onlineMenuTimes.EndTime);
        }

        /*
            Validate case when placeOrderTimeout has been exceeded but all params are ok
            Used for PAYMENT CASE, final step, where order can still be placed if location is open, newer pickup time is location open time range and online menu hasn't changed (TODO)
        */
        if (hasExceededPlaceOrderTimeoutForPickupTime && openHours && locationUpdatedPickupTimeObj) {
            return isPickupTimeInLocationOpenRange && isUpdatedPickupTimeInLocationOpenHoursRange && !onlineMenuHasChanged;
        }

        /* Regular check without updated data - used when navigating between pages */
        return !hasExceededPlaceOrderTimeoutForPickupTime && isPickupTimeInLocationOpenRange;
    }

    public validateCartWithPopup(): Observable<boolean | null> {
        return this.store.select(selectors.getCart)
            .pipe(
                take(1),
                tap(cart => {
                    // this.store.dispatch(actions.OrderingTimeInfoRequest([cart.locationNo], Utils.Dates.getLocalISOFormatDate(new Date(), true)));

                    /*
                        Warning! AOLO-272 ..and many others
                        These inconspicuous lines of code are responsible for uber deep level of inception,
                        where proper pickup time is inserted into order.
                        This mechanism detects if user's cart pickup time obj is ASAP and then overrides values
                        with FRESH data for order.
                        Then Recalculation action is dispatch, that creates order stub with new pickup data,
                        ...and finally, in lower parts of code, this new pickup object is deeply validated
                        ..and finally finally, if all goes well, in theory, new order will be created from
                        recalculated data.
                        This is really heavy stuff
                    */
                    if (cart.pickupTime.IsAsap) {
                        const pickupTimeObj = Utils.Dates.overrdrivePickupTimeObjToAsap(cart.pickupTime);
                        this.store.dispatch(actions.CartSetPickupTime(pickupTimeObj));
                        this.store.dispatch(actions.OnlineOrderRecalculateRequest());
                    }

                }),
                switchMap(cart => {
                    return this.store
                        .pipe(
                            select(selectors.getOnlineOrderState),
                            auditTime(10),
                            filter(onlineOrderState => onlineOrderState.recalculateRequest.isRecalculating === false),
                            take(1),
                            switchMap(onlineOrderState => {
                                if (onlineOrderState.recalculateRequest.hasFailed || onlineOrderState.recalculateRequest.data === null) {
                                    return of(false);
                                }

                                return this.store
                                    .pipe(
                                        select(selectors.getOrderingTimeInfoByLocationNo(cart.locationNo)),
                                        filter(orderingTimeInfo => {
                                            return orderingTimeInfo !== null && orderingTimeInfo !== undefined;
                                        }),
                                        take(1),
                                        withLatestFrom(
                                            this.store.select(selectors.getOrderingTimeInfoForCartPickupLocationNo(cart.locationNo)),
                                            this.store.select(selectors.isLocationOpenNowByOrderingTimeInfoObj(cart.locationNo)),
                                            this.store.select(selectors.getMinimumPickupTimeForLocationAndDate(cart.locationNo, cart.pickupTime.Date)),
                                        ),
                                        map(([orderingTimeInfoObj, timeInfoObj, isOpenNow, preperationTimeObj]) => {
                                            if (!orderingTimeInfoObj) {
                                                return null; /* Invalid case */
                                            }

                                            /*
                                                Data not found or preperation object doesn't exist,
                                                also check open status if app is configured not to take orders from closed locations.
                                            */
                                            const closedLocationOrder = this.config.onlineOrders.allowClosedLocationOrders === true || this.config.pickups.futureOrders ? true : isOpenNow;

                                            if (!orderingTimeInfoObj || orderingTimeInfoObj.length === 0 || !timeInfoObj || !preperationTimeObj || !closedLocationOrder) return false;


                                            /* Validate hours here */
                                            return this.validateSelectedPickupTimeObjForOnlineMenu(
                                                new Date(),
                                                cart.pickupTime,
                                                cart.onlineMenu,
                                                timeInfoObj,
                                                preperationTimeObj
                                            );
                                        }),
                                        tap(isCartValid => {
                                            if (isCartValid === false) { /* AOLO-275 - SHOW prompt */
                                                console.warn('RESET here');
                                                this.store.dispatch(actions.CartReset());

                                                /* AOLO-308 - Reset stuff, make sure data is reloaded */
                                                this.store.dispatch(actions.LocationsFiltersReset());
                                                this.store.dispatch(actions.CurrentLocationReset());

                                                const id: number = new Date().getTime();
                                                this.modalsService.show({
                                                    id,
                                                    type: 'alert',
                                                    params: {
                                                        preTitle: `WARNING`,
                                                        title: `Cart is empty`,
                                                        body: `We've detected you spent
                                                    too much time placing an order.
                                                    Either our menu has changed or we
                                                    won't be able to prepare your
                                                    meal for selected pickup time.`
                                                    }
                                                });

                                                this.actions$
                                                    .pipe(
                                                        ofType(
                                                            actions.ModalClose
                                                        ),
                                                        map(action => action),
                                                        skipWhile(action => action.id !== id),
                                                        take(1)
                                                    ).subscribe(action => {
                                                        this.router.navigate(['/'])
                                                            .then(() => {
                                                                /* AOLO-308 - Reset stuff, make sure data is reloaded */
                                                                this.locationsService.requestLocations();
                                                            });
                                                    });

                                                return;
                                            }

                                            if (isCartValid === null) { /* Some error... TODO what TODO? */
                                                this.modalsService.show({
                                                    type: 'alert',
                                                    params: {
                                                        preTitle: `WARNING`,
                                                        title: `Error`,
                                                        body: `There was an error
                                                    when validating your order.
                                                    Please try again.`
                                                    }
                                                });
                                            }
                                        }),
                                        take(1)
                                    );
                            })
                        );
                }),


            );
    }

}
