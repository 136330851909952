/* https://px5.docs.apiary.io/#introduction/authentication */
import { Injectable, Inject } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PaymentExpressPaymentProviderService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
    ) { }

    public getCardDetails(params: PPPaymentExpress.ICardIdParams): Observable<APIv2.PaymentsGetPaymentExpressSessionResult.Responses.$200> {
        const customParams: any = { ...params };
        delete customParams.sessionToken;
        return this.httpClient.get(`${Utils.HTTP.switchApi(this.config.api.base)}/Payments/paymentExpress/session/${params.sessionToken}${Utils.HTTP.object2string(customParams)}`);
    }

    public requestCardToken(cardData: OLO.CreditCards.ICreditCardDetails, locationNo: number = null, defaultSettings: APIv1.PaymentExpressSettingsResponse = null): Observable<APIv2.PaymentExpressSettingsResponse> {
        if (!locationNo) {
            if (!defaultSettings) return throwError('No default settings provided for PaymentExpress payment provider');
            return Observable.create(observer => {
                observer.next({
                    ...defaultSettings
                });
                observer.complete();
            });
        }

        return this.getSettingsForLocation(locationNo);
    }

    public getSettingsForLocation(locationNo: number, clientAppKey: string = this.config.api.key): Observable<APIv2.PaymentExpressSettingsResponse> {
        return this.httpClient.get(`${Utils.HTTP.switchApi(this.config.api.base)}/Payments/paymentExpress/settings/${locationNo}`)
            .pipe(
                catchError(ex => {
                    console.error('LocationNo not provided', ex);
                    return throwError(ex);
                })
            );
    }
}
