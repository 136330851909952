import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, IMenuFlows } from '../interface';

const state = createFeatureSelector<IStateShared, IMenuFlows[]>('menuFlows');


export const getMenuFlows = createSelector(
    state,
    menuFlows => menuFlows
);

export const getMenuFlow = (menuFlowId: number, locationNo: number) => createSelector(
    state,
    menuFlows => menuFlows.find(menuFlow => menuFlow.MenuFlowId === menuFlowId && menuFlow.LocationNo === locationNo)
);

export const getMenuFlowPage = (pageIdentifier: number, menuFlowId: number, locationNo: number) => createSelector(
    getMenuFlow(menuFlowId, locationNo),
    menuFlow => {
        if (!menuFlow) return null;

        return menuFlow.data.Pages.find(page => page.PageIdentifier === pageIdentifier);
    }
);

export const isMenuFlowPageSingleAllowedOnly = (pageIdentifier: number, menuFlowId: number, locationNo: number) => createSelector(
    getMenuFlowPage(pageIdentifier, menuFlowId, locationNo),
    page => {
        if (!page) return null;
        return page.PageMaxQuantity === 1;
    }
);

export const getMenuFlowDetails = (menuFlowId: number, locationNo: number) => createSelector(
    getMenuFlow(menuFlowId, locationNo),
    menuFlow => {
        if (!menuFlow || !menuFlow.data) return null;

        return menuFlow.data;
    }
);

export const getMenuFlowPages = (menuFlowId: number, locationNo: number) => createSelector(
    getMenuFlowDetails(menuFlowId, locationNo),
    menuFlowDetails => {
        if (!menuFlowDetails) return null;
        return menuFlowDetails.Pages.filter(page => page.HideFromKiosk !== true);
    }
);
