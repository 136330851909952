import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store, select } from '@ngrx/store';

import * as actions from '@shared/state/actions';

import * as Tokens from '@shared/core/tokens';
import * as State from '@shared/state/interface';
import * as Utils from '@shared/core/utils';

import { Observable, throwError } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class LocationsService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
        public store: Store<State.IStateShared>,
        public router: Router,
    ) { }

    public getLocations(customParams: APICommon.ITerminalsLocationsGetParams = {}): Observable<APIv1.TerminalsGetLocations.Responses.$200> {
        const defaultParams: APICommon.ITerminalsLocationsGetParams = {
            isActive: true,
            isAvailableInLoyaltyApps: true,
            locationOLOIsActive: true,
        };

        const params: HttpParams = new HttpParams({
            fromObject: ({ ...defaultParams, ...customParams } as any)
        });

        return this.httpClient.get<APIv1.TerminalsGetLocations.Responses.$200>(`${this.config.api.base}/terminals/locations`, { params })
            .pipe(
                delay(1000),
                catchError((ex) => throwError(ex))
            );
    }

    public getLocationsWithOnlineOrdering(customParams: APICommon.ITerminalsOnlineOrderingLocationsGetParams = {}): Observable<APIv1.TerminalsGetOnlineOrderingLocations.Responses.$200> {
        const defaultParams: APICommon.ITerminalsOnlineOrderingLocationsGetParams = {
            duration: APICommon.ORDERING_TIME_INFO_DURATION.DAY
        };

        const params: HttpParams = new HttpParams({
            fromObject: ({ ...defaultParams, ...customParams } as any)
        });

        return this.httpClient.get<APIv1.TerminalsGetOnlineOrderingLocations.Responses.$200>(`${this.config.api.base}/terminals/onlineOrderingLocations`, { params })
            .pipe(
                map(data => {
                    return data.map(location => {
                        return {
                            ...location,
                            OrderingTimeInfo: location.OrderingTimeInfo.map(timeInfo => {
                                return {
                                    ...timeInfo,
                                    PickupTimes: timeInfo.PickupTimes.map(pickup => {
                                        /* Fix data - when pickup has later 'To' or earlier 'From' values then get values from timeInfo object  */
                                        return {
                                            ...pickup,
                                            From: Utils.Dates.createHoursIntFromDate(pickup.From) < Utils.Dates.createHoursIntFromDate(timeInfo.OpeningTime) ? timeInfo.OpeningTime : pickup.From,
                                            To: Utils.Dates.createHoursIntFromDate(pickup.To) > Utils.Dates.createHoursIntFromDate(timeInfo.ClosingTime) ? timeInfo.ClosingTime : pickup.To,
                                        };
                                    })
                                };
                            })
                        };
                    });

                    // if (this.config.pickups?.futureOrders !== true) return data;
                    // const now: Date = new Date();

                    // return data.map(location => {
                    //     const min = location.FutureOrderingMinDaysAhead;
                    //     const max = location.FutureOrderingMinDaysAhead;

                    //     const isFutureOrderingAllowed = min !== null && min !== 0
                    //         && max !== null && max !== 0;

                    //     return {
                    //         ...location,
                    //         OrderingTimeInfo: location.OrderingTimeInfo ? location.OrderingTimeInfo.reduce((acc, timeInfo) => {
                    //             const isToday: boolean = Utils.Dates.datesDiffInDays(now, timeInfo.Date) === 0;
                    //             if (isToday) {
                    //                 return [
                    //                     ...acc,
                    //                     timeInfo,
                    //                 ];
                    //             }

                    //             const isInFutureOrdersTimeRange = isFutureOrderingAllowed && Utils.Dates.isDateInFutureOrdersTimeRange(timeInfo.Date, min, max);
                    //             if (isInFutureOrdersTimeRange) {
                    //                 return [
                    //                     ...acc,
                    //                     timeInfo,
                    //                 ];
                    //             }
                    //             return acc;

                    //         }, [] as APIv1.LocationOrderingTimeInfoModel[]) : location.OrderingTimeInfo,
                    //     };
                    // });
                })
            );
    }

    public getLocation(locationNo: number): Observable<APIv1.TerminalLocationDataModel> {
        return this.httpClient.get<APIv1.TerminalLocationDataModel>(`${this.config.api.base}/terminals/locations/${locationNo}`);
    }

    public getImageForLocation(locationNo: number, width: number = null, height: number = null): Observable<string> {
        const params: HttpParams = new HttpParams({
            fromObject: ({ width, height } as any)
        });

        return this.httpClient.get<string>(`${this.config.api.base}/images/location/${locationNo}`, { params });
    }

    public getOpeningHoursForLocation(locationNo: number, p: { date?: string, duration?: OLO.Enums.OPENING_HOURS.WEEK; } = {}): Observable<APICommon.IOpeningHoursPaginatedList> {
        const params: HttpParams = new HttpParams({
            fromObject: ({
                duration: OLO.Enums.OPENING_HOURS.WEEK,
                ...p
            } as any)
        });
        return this.httpClient.get(`${this.config.api.base}/terminals/locations/${locationNo}/openingHours`, { params })
            .pipe(
                map((response: APICommon.IOpeningHoursPaginatedList) => {
                    const daysNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                    return {
                        ...response,
                        Items: response.Items.map(obj => {
                            obj._DayName = daysNames[obj.DayOfWeek];
                            return obj;
                        })
                    };
                })
            ) as Observable<APICommon.IOpeningHoursPaginatedList>;

    }

    public getIsLocationOpen(locationNo: number): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.config.api.base}/terminals/locations/${locationNo}/isOpen`);
    }

    public getLocationPickupTimes(p: APICommon.ITerminalsLocationPickupTimeGetParams): Observable<APIv1.TerminalsGetMinimumPickupTimes.Responses.$200> {
        const params: HttpParams = new HttpParams({
            fromObject: ({
                ...p
            } as any)
        });

        return this.httpClient.get<APIv1.TerminalsGetMinimumPickupTimes.Responses.$200>(`${this.config.api.base}/terminals/locations/${p.locationNo}/pickupTimes`, { params });
    }

    public getOrderingTimeInfoForLocations(locationNos: number[], dateToCheck: string): Observable<APIv1.LocationOrderingTimeInfoBusinessModel[]> {
        /* This endpoint require Z in dateToCheck */
        return this.httpClient
            // tslint:disable-next-line:max-line-length
            .get<APIv1.TerminalsCompleteOrderingTimeInfo.Responses.$200>(`${this.config.api.base}/terminals/locations/orderingTimeInfo?${Utils.HTTP.idsToStringParams('locationNos', locationNos)}${dateToCheck ? `&dateToCheck=${dateToCheck.includes('Z') ? dateToCheck : dateToCheck + 'Z'}` : ''}`)
            .pipe(
                map(response => {


                    return response.map((orderingTime) => {
                        return {
                            ...orderingTime,

                            OrderingTimeInfo: orderingTime.OrderingTimeInfo.map((obj, index) => {
                                const dateObj: Date = Utils.Dates.createDate(dateToCheck);
                                dateObj.setDate(dateObj.getDate() + index);

                                const currDay = dateObj.getDay();
                                const localISODate = Utils.Dates.getLocalISOFormatDate(dateObj).replace(/T.+/i, 'T' + obj.OpeningTime + '.000Z');

                                return {
                                    ...obj,
                                    _Id: index + 1,
                                    _Date: localISODate,
                                    _DayName: Utils.Dates.getDayName(currDay)
                                };
                            })
                        };

                    });


                    // return response;
                })
            );
    }

    public requestLocations(): void {
        this.store.dispatch(actions.LocationsRequest({
            venueNo: this.config.venue ? this.config.venue.id : null,
            dateToCheck: Utils.Dates.getLocalISOFormatDate(new Date(), false), /* Without Z - will not return TODAY if true */
            duration: APICommon.ORDERING_TIME_INFO_DURATION.MONTH,
        }));
    }
}
