export class CreditCards {
    public static detectFormat(date: string): number {
        if (typeof date !== 'string') return null;

        const f1 = date.match(/^\d{2}-\d{2}-\d{2}$/gm); /* 12-31-24 */
        const f2 = date.match(/^\d{2}\/\d{2}$/gm); /* 01/24 */
        const f3 = date.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.?(\d{3}Z)?$/gm); /* 2014-01-02T23:22:33.333Z */

        if (f1) return 0;
        if (f2) return 1;
        if (f3) return 2;

        return null;
    }

    public static dateToApiFormat(date: string): string {
        if (typeof date !== 'string') return null;
        const format = CreditCards.detectFormat(date);

        switch (true) {
            case format === 0:
                return date;
            case format === 1:
                return date.replace('/', '-01-');
            case format === 2:
                const arr = date.split('T')[0].split('-');
                return `${arr[1]}-01-${arr[0].slice(2)}`;
            default:
                console.warn('Date format not supported', date);
                return null;
        }
    }

    public static dateToShortFormat(date: string): string {
        if (typeof date !== 'string') return null;
        const d: string = CreditCards.dateToApiFormat(date);
        return d && d.replace(/-\d{2}-/, '/') || null;
    }

    public static dateToISOString(date: string): string {
        /* Will return edge date to last milisecond */
        if (typeof date !== 'string') return null;

        const isApiFormat = CreditCards.detectFormat(date) === 0;
        if (!isApiFormat) return null;

        const apiDate = CreditCards.dateToApiFormat(date);
        const d = apiDate.split('-');
        const lastDay = new Date(+`20${d[2]}`, +d[0], 0).getDate();

        return `20${d[2]}-${d[0]}-${lastDay < 10 ? `0${lastDay}` : `${lastDay}`}T23:59:59.999Z`;
    }

    /* https://www.freeformatter.com/credit-card-number-generator-validator.html */
    public static readonly cardPatterns: OLO.Common.ICreditCardPatterns = {
        Visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
        MasterCard: /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/,
        AmericanExpress: /^3[47][0-9]{13}$/,
        DinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
        Discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        JCB: /^(?:2131|1800|35\d{3})\d{11}$/,
    };

    public static detectCardType(cardNo: string | number): OLO.Enums.CREDIT_CARD_TYPES {
        cardNo = typeof cardNo === 'number' ? `${cardNo}` : cardNo;

        if (typeof cardNo !== 'string' || !cardNo || !cardNo.replace) return null;

        cardNo = cardNo.replace(/\D/gm, '');

        switch (true) {
            case CreditCards.cardPatterns.Visa.test(cardNo):
                return OLO.Enums.CREDIT_CARD_TYPES.VISA;

            case CreditCards.cardPatterns.MasterCard.test(cardNo):
                return OLO.Enums.CREDIT_CARD_TYPES.MASTER_CARD;

            case CreditCards.cardPatterns.AmericanExpress.test(cardNo):
                return OLO.Enums.CREDIT_CARD_TYPES.AMERICAN_EXPRESS;

            case CreditCards.cardPatterns.DinersClub.test(cardNo):
                return OLO.Enums.CREDIT_CARD_TYPES.DINERS_CLUB;

            case CreditCards.cardPatterns.Discover.test(cardNo):
                return OLO.Enums.CREDIT_CARD_TYPES.DISCOVER;

            case CreditCards.cardPatterns.JCB.test(cardNo):
                return OLO.Enums.CREDIT_CARD_TYPES.JCB;

            default:
                return null;
        }
    }

    public static mapEnumToTypeName(enumId: number | OLO.Enums.CREDIT_CARD_TYPES): string {
        switch (enumId) {
            case OLO.Enums.CREDIT_CARD_TYPES.VISA:
                return `Visa`;
            case OLO.Enums.CREDIT_CARD_TYPES.MASTER_CARD:
                return 'Master Card';
            case OLO.Enums.CREDIT_CARD_TYPES.AMERICAN_EXPRESS:
                return 'American Express';
            case OLO.Enums.CREDIT_CARD_TYPES.DINERS_CLUB:
                return 'Diners Club';
            case OLO.Enums.CREDIT_CARD_TYPES.DISCOVER:
                return 'Discover';
            case OLO.Enums.CREDIT_CARD_TYPES.JCB:
                return 'JCB';
            default:
                return 'N/A';
        }
    }

    public static processCardNumber(cardNo: number | string): string {
        return (`${cardNo}`).replace(/[^0-9]/gi, '');
    }

    public static validateCardNumber(cardNo: number | string, preprocessString: boolean = true): boolean {
        // /* https://stackoverflow.com/questions/9315647/regex-credit-card-number-tests */
        // /* https://www.regular-expressions.info/creditcard.html */
        const REGEX = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
        const REGEX_END_OF_STRING = /[^(0-9)]+$/;
        const cardNoToProcess: string = preprocessString ? CreditCards.processCardNumber(cardNo) : `${cardNo}`;
        return REGEX.test(cardNoToProcess) && REGEX_END_OF_STRING.test(cardNoToProcess) === false;
    }

    public static remapRedirectCardDetails(
        cardData: OLO.CreditCards.ICreditCardRedirectDetails
    ): OLO.CreditCards.ICreditCardDetails {
        return {
            cardHolderName: cardData.CardHolderName,
            cardNumber: cardData.CardNumber,
            cvv: cardData.Cvc2,
            expiryDate: `${cardData.ExpiryMonth}/${cardData.ExpiryYear}`,
            isDefaultPaymentMethod: cardData.isDefaultPaymentMethod || false,
            saveCard: cardData.saveCard || false,
        };
    }

    public static createCardModel(
        paymentProvider: OLO.Enums.PAYMENT_PROVIDER,
        number: string,
        type: OLO.Enums.CREDIT_CARD_TYPES,
        expiryDate: string,
        token: string,
        isDefault: boolean = true,
        saveCard: boolean = false,
        id: number = null
    ): OLO.Members.IMemberCreditCardDetails {
        const m: OLO.Members.IMemberCreditCardDetails = {
            ExpirationDate: CreditCards.dateToApiFormat(expiryDate),
            CardType: type,
            Token: token,
            DisplayName: number?.substring(number.length - 4) || null, /* Older API versions without Payment Express support */
            NiceName: number?.substring(number.length - 4) || null,
            Id: id,
            PaymentProvider: paymentProvider,
            IsDefault: isDefault || false,
        };

        if (saveCard) {
            m._SaveAwait = true;
        }

        return m;
    }

}
