import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store, select } from '@ngrx/store';

import * as actions from '@shared/state/actions';
import * as selectors from '@shared/state/selectors';

import * as Tokens from '@shared/core/tokens';
import * as State from '@shared/state/interface';
import * as Utils from '@shared/core/utils';

import { Observable } from 'rxjs';
import { map, filter, withLatestFrom, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class OnlineMenuService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
        public store: Store<State.IStateShared>
    ) { }

    public getMenuPages(p: APICommon.IOnlineMenuGetParams = { includePrices: true }): Observable<APIv1.OnlineMenuGetOnlineMenu.Responses.$200> {
        const params: HttpParams = new HttpParams({
            fromObject: {
                ...p as any
            },
        });

        return this.httpClient.get<APIv1.OnlineMenuGetOnlineMenu.Responses.$200>(`${this.config.api.base}/OnlineMenu`, { params })
            .pipe(
                map(menu => {

                    menu.Pages.forEach(Page => {
                        Page.Products.sort((a, b) => {
                            switch (true) {
                                case a.DisplayIndex > b.DisplayIndex:
                                    return 1;
                                case a.DisplayIndex < b.DisplayIndex:
                                    return -1;
                                default:
                                    return 0;
                            }
                        });
                    });

                    return menu;
                })
            );
    }

    public getFlatProductsFromMenuPages(pages: APIv1.OnlineMenuPageResponseModel[] = []): APIv1.OnlineMenuProductResponseModel[] {
        /*
            Gets all products in online menu (All pages) and creates flat collection
            - usefull for further processing, i.e. getting images for these products.
        */
        const products: APIv1.OnlineMenuProductResponseModel[] = [];

        pages.forEach(page => {
            page.Products.forEach(product => products.find(p => p.Id === product.Id) ? null : products.push(product));
        });

        return products;
    }


    public requestOnlineMenuForLocation(locationNo: number): void {
        this.store
            .pipe(
                select(selectors.isLocationLoading(locationNo)),
                filter(isCalculating => isCalculating === false),
                withLatestFrom(
                    this.store.pipe(select(selectors.getCart)),
                    this.store.pipe(
                        select(selectors.getAvailablePickupTimesWithFutureForLocation(this.config, locationNo, this.config.pickups?.futureOrders === true))
                    ),
                    this.store.pipe(
                        select(selectors.getLocationFilters),
                        map(filters => filters.pickupTime)
                    ),
                    this.store.pipe(
                        select(selectors.getLocationDetails(locationNo))
                    ),
                    this.store.pipe(
                        select(selectors.getCurrentPickupTime)
                    ),
                    this.store.pipe(
                        select(selectors.getAppLocationMode)
                    )
                ),
                take(1),
            ).subscribe(([isCalculating, cart, availablePickups, filterPickup, locationDetails, currentPickup, locationMode]) => {
                if (!availablePickups) return;
                let matchedPickupTime: OLO.Ordering.IPickupTime;

                /* Check filters */
                if (filterPickup) {
                    /* validate filter pickup if it matches current location pickups */
                    if (filterPickup.IsToday) {
                        const timeInfo = availablePickups.find(pickup => pickup.Id === filterPickup.Id);
                        if (timeInfo) {
                            matchedPickupTime = filterPickup;
                        }
                    } else {
                        const timeInfo = locationDetails?.OrderingTimeInfo?.find(Utils.Dates.datesMatchByDayCallback(filterPickup.DateLocalISO));
                        if (timeInfo) {
                            /* Is in range of found ordering time info */
                            const isInValidRange: boolean = Utils.Dates.isFuturePickupTimeValid(
                                filterPickup,
                                timeInfo,
                                locationDetails.FutureOrderingMinDaysAhead,
                                locationDetails.FutureOrderingMaxDaysAhead
                            );
                            if (isInValidRange) {
                                matchedPickupTime = filterPickup;
                            }
                        }
                    }
                }

                /* set from cart */
                if (!matchedPickupTime && cart.pickupTime) {
                    const matchedCartPickupTime = availablePickups.find(pickup => pickup.Id === cart.pickupTime.Id);
                    if (matchedCartPickupTime) {
                        matchedPickupTime = matchedCartPickupTime;
                    }
                }

                /* set from previously selected */
                if (locationMode === OLO.Enums.APP_MODE.VENUE && !matchedPickupTime && currentPickup) {
                    const matchedCurrentPickupTime = availablePickups.find(pickup => pickup.Id === currentPickup.Id);

                    if (matchedCurrentPickupTime) {
                        matchedPickupTime = matchedCurrentPickupTime;
                    }
                }

                /* set first available */
                if (!matchedPickupTime && availablePickups) {
                    matchedPickupTime = availablePickups[0];
                }

                if (matchedPickupTime) {
                    this.store.dispatch(actions.CurrentLocationPickupTimeSet({ ...matchedPickupTime }));
                    // this.store.dispatch(actions.LocationsFiltersSyncPickupTime(matchedPickupTime.IsAsap ? null : { ...matchedPickupTime }));
                }
            });

    }
}
