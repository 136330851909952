import { Injectable, Inject } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { Observable, throwError } from 'rxjs';
import { flatMap, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ConvergePaymentProviderService {
    private _injectedScript: HTMLScriptElement;

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
    ) { }

    private async _attachVendorScriptHTMLTag(url: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (this._injectedScript) return resolve(true);

            const scriptTag: HTMLScriptElement = document.createElement('script');
            scriptTag.type = 'text/javascript';
            scriptTag.src = url;

            document.body.appendChild(scriptTag);

            scriptTag.onload = () => {
                this._injectedScript = scriptTag;
                resolve(true);
            };

            scriptTag.onerror = () => {
                reject('unable load PP script');
            };

        });
    }

    private _request(params: PPConverge.ICommonParams): Observable<PPConverge.ICheckoutJSSuccessResponseFields> {
        const defaults: PPConverge.ICommonParams = {
            ssl_add_token: 'Y'
        };

        const allFields = { ...defaults, ...params as PPConverge.ICommonParams };
        const targetFields = {
            ssl_card_number: allFields.ssl_card_number,
            ssl_exp_date: allFields.ssl_exp_date,
            ssl_merchant_id: allFields.ssl_merchant_id,
            ssl_pin: allFields.ssl_pin,
            ssl_transaction_type: allFields.ssl_transaction_type,
            ssl_txn_auth_token: allFields.ssl_txn_auth_token,
            ssl_user_id: allFields.ssl_user_id,
            ssl_add_token: allFields.ssl_add_token,
        };

        return Observable.create(observer => {
            let status: string = null;

            if (('ConvergeEmbeddedPayment' in window) === false) {
                observer.error(new Error('ConvergeEmbeddedPayment payment provider library not included in html'));
            }

            const callbacks = {
                onError: function (error: undefined) {
                    status = 'ERROR';
                    console.error('GOT AN ERROR', error);
                    observer.error(new Error('Payment error'));
                },
                onDeclined: function (response) {
                    status = 'DECLINED';
                    console.error('DECLINED', response);
                    observer.error(new Error(JSON.stringify(response)));
                },
                onApproval: function (response: PPConverge.ICheckoutJSSuccessResponseFields) {
                    status = 'SUCCESS';
                    observer.next(response);
                    observer.complete();
                },

            };
            /* https://developer.elavon.com/#/api/eb6e9106-0172-4305-bc5a-b3ebe832f823.rcosoomi/versions/5180a9f2-741b-439c-bced-5c84a822f39b.rcosoomi/documents/?converge-integration-guide/book/integration_methods/checkoutjs.html */
            (window as any).ConvergeEmbeddedPayment.pay(targetFields, callbacks);

            return () => {
                console.log('payment process has ended with status ' + status);
            };
        });
    }

    public convertAPIConvergeSettingsToAuthModelAsync(model: APIv2.ConvergeSettingsResponse): Observable<PPConverge.IAuthRequestParams> {
        return Observable.create(observer => {

            this._attachVendorScriptHTMLTag(`${model.ApiUrl}Checkout.js`)
                .then(isLoaded => {
                    if (isLoaded) {
                        observer.next({
                            ssl_merchant_id: model.MechantId,
                            ssl_pin: model.Pin,
                            ssl_user_id: model.UserId,
                            ssl_vendor_id: model.VendorId,
                            ssl_txn_auth_token: model.SessionToken.SessionToken,
                        });
                        observer.complete();
                    }
                });

        });
    }

    public requestCardToken(params: PPConverge.ITokenForCardRequestParams, locationNo: number = null, defaultSettings: APIv1.ConvergeSettingsResponse = null):
        Observable<PPConverge.ICheckoutJSSuccessResponseFields> {
        const defaults: PPConverge.ICommonParams = {
            ssl_add_token: 'Y',
            ssl_transaction_type: 'CCGETTOKEN',
        };
        params.ssl_exp_date = params.ssl_exp_date.replace('-01-', ''); /* Converting API format to Converge format - so so so so .. pro */

        if (!locationNo) {
            if (!defaultSettings) return throwError('No default settings provided for converge payment provider');

            /* When adding card with defaults and without locationNo */
            return this.convertAPIConvergeSettingsToAuthModelAsync(defaultSettings)
                .pipe(
                    switchMap(defaultAuthSettings => this._request({ ...defaults, ...params, ...defaultAuthSettings }))
                );
        }

        return this.getConvergeSettingsForLocation(locationNo)
            .pipe(
                flatMap(authSettings => {
                    return this._request({ ...defaults, ...params, ...authSettings });
                })
            );
    }

    public getConvergeSettingsForLocation(locationNo: number, clientAppKey: string = this.config.api.key): Observable<PPConverge.IAuthRequestParams> {
        return this.httpClient.get(`${Utils.HTTP.switchApi(this.config.api.base)}/Payments/converge/settings/${locationNo}`)
            .pipe(
                switchMap((response: APIv2.ConvergeSettingsResponse) => {
                    if (response.SessionToken.SessionToken === null) {
                        console.warn('Setup issue for Converge Payment Provider. Session token for location:', locationNo, ' is null. Full config:', response);
                    }
                    return this.convertAPIConvergeSettingsToAuthModelAsync(response);
                })
            );
    }
}
