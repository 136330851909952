import { Injectable, Inject } from '@angular/core';
import { Store, Action, select } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Tokens from '@shared/core/tokens';
import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';

import * as StateModels from '../interface';

import { Observable, never, of, forkJoin } from 'rxjs';
import { switchMap, tap, mergeMap, withLatestFrom, take, map, filter } from 'rxjs/operators';

@Injectable()
export class ReorderEffects {
    private _activeModalsWithReordersRegistry: Map<number, number> = new Map();

    @Effect() public setupModalAndRequestDataForReorder$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.ReorderSetup
            ),
            tap(action => this._onlineOrdersService.reorderInitCalculations(action.orderId)),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getCurrentPickupTime)
                    )
            ),
            switchMap(([action, pickupTime]) => {
                const modalId = action.modalId || new Date().getTime();
                this._modalsService.show({
                    type: 'loading',
                    id: modalId,
                    orderId: action.orderId,
                    locationNo: action.locationNo,
                });

                this._activeModalsWithReordersRegistry.set(modalId, action.orderId);

                return this._store
                    .pipe(
                        select(selectors.getReorder(action.orderId, action.locationNo, pickupTime)),
                        filter(reorder => reorder !== null && reorder !== undefined && reorder.isDownloading === false),
                        take(1),
                        switchMap(reorder => {
                            if (reorder.hasFailed || !reorder.data) {
                                console.error(`There was an error during reorder calculations`, reorder);
                                return never();
                            }

                            this._modalsService.swap(modalId, {
                                type: 'reorder'
                            });

                            return never();
                        })
                    );
            }),
        );

    @Effect() requestDataForReorder$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.ReorderCalculateRequest),
            switchMap(action => {
                return this._store.select(selectors.getReorder(action.orderId, action.locationNo, action.pickupTime))
                    .pipe(
                        take(1),
                        withLatestFrom(
                            this._store.select(selectors.getCurrentMember),
                            this._store.select(selectors.getCurrentPickupTime),
                            this._store.select(selectors.getHistoryOrder(action.orderId)),
                        ),
                        switchMap(([reorder, member, currentPickupTime, order]) => {
                            // if (reorder.data.cart || reorder.data.onlineMenu) return of(new actions.ReorderCalculateSuccessRequest(action.orderId, action.locationNo, action.pickupTime, reorder.data));

                            if (!member || !currentPickupTime) {
                                console.error(`Error in reorder recalc request. Member: ${member}, CurrentPickupTime ${currentPickupTime} - one of these params is not set`);
                                return of(actions.ReorderCalculateErrorRequest({
                                    orderId: action.orderId,
                                    locationNo: action.locationNo,
                                    pickupTime: action.pickupTime,
                                    ex: new Error(`Error in reorder recalc request. Member: ${member}, CurrentPickupTime ${currentPickupTime} - one of these params is not set`)
                                }));
                            }

                            const onlineMenuRequestParams: APICommon.IOnlineMenuGetParams = {
                                locationNo: action.locationNo,
                                includePrices: true,
                                memberId: member.MemberId,
                                menuDate: currentPickupTime.DateLocalISO + 'Z',
                            };

                            const summary: StateModels.IReorderDetails = {
                                onlineMenu: null,
                                menuFlowsDetails: [],
                                ingredients: [],

                                errors: null,
                                cart: null,
                            };

                            return this._onlineMenuService.getMenuPages(onlineMenuRequestParams)
                                .pipe(
                                    mergeMap(onlineMenu => {
                                        summary.onlineMenu = { ...onlineMenu };
                                        /* Get details about each menuFlow */
                                        const menuFlowsRequests: Observable<APIv1.MenuFlowDetailsModel>[] = [];
                                        const relevantMenuFlowsIds: number[] = order.data.MenuFlowActivations.map(menuFlowActivation => menuFlowActivation.MenuFlowId);

                                        onlineMenu.Pages.forEach(Page => {
                                            Page.Products.forEach(Product => {
                                                if (Product.MenuFlowId && relevantMenuFlowsIds.includes(Product.MenuFlowId)) {
                                                    menuFlowsRequests.push(
                                                        this._menuFlowsService.getMenuFlowDetailsForLocation(Product.MenuFlowId, action.locationNo)
                                                    );
                                                }
                                            });
                                        });

                                        if (!menuFlowsRequests.length) {
                                            if (this._config.onlineOrders && this._config.onlineOrders.allowModifiers === false) {
                                                summary.ingredients = [];
                                            }

                                            const finalPayload = Utils.OnlineOrders.covertOrderToCart(order.data, summary.onlineMenu, summary.menuFlowsDetails, summary.ingredients, currentPickupTime);

                                            return of(actions.ReorderCalculateSuccessRequest({
                                                orderId: action.orderId,
                                                locationNo: action.locationNo,
                                                pickupTime: action.pickupTime,
                                                payload: {
                                                    ...summary,
                                                    cart: finalPayload.cart,
                                                    errors: finalPayload.errors,
                                                }
                                            }));
                                        }

                                        return forkJoin(menuFlowsRequests)
                                            .pipe(
                                                mergeMap((menuFlowsDetails) => {
                                                    summary.menuFlowsDetails = [...menuFlowsDetails];
                                                    /* Get details about upsells */
                                                    const upsellMenuflowsRequsts = [];

                                                    menuFlowsDetails.forEach((MenuFlow: APIv1.MenuFlowDetailsModel) => {
                                                        if (MenuFlow.UpsellMenuFlowId) {
                                                            upsellMenuflowsRequsts.push(
                                                                this._menuFlowsService.getMenuFlowDetailsForLocation(MenuFlow.UpsellMenuFlowId, action.locationNo)
                                                            );
                                                        }
                                                    });

                                                    return (upsellMenuflowsRequsts.length ? forkJoin(upsellMenuflowsRequsts) : of([]))
                                                        .pipe(
                                                            mergeMap(upsellsMenuFlowDetails => {
                                                                summary.menuFlowsDetails = [
                                                                    ...summary.menuFlowsDetails,
                                                                    ...upsellsMenuFlowDetails as APIv1.MenuFlowDetailsModel[],
                                                                ];

                                                                /* Get ingredients for all products in menuflows */
                                                                let productIds = [];

                                                                summary.menuFlowsDetails.forEach(MenuFlow => {
                                                                    const currentMenuFlowProductIds = this._menuFlowsService.getFlatProductsFromMenuFlow(MenuFlow).map(Product => Product.ProductId);
                                                                    productIds = [...productIds, ...currentMenuFlowProductIds];
                                                                });
                                                                const unduplicatedProductIds = Array.from(new Set(productIds));
                                                                /*
                                                                    Warning - when there are too many IDS, API endpoint will return 404 ERROR. This might need some refactor
                                                                */
                                                                return this._productsService.getIngredientsForLocationExtended(action.locationNo, ...unduplicatedProductIds)
                                                                    .pipe(
                                                                        map(ingredients => {
                                                                            summary.ingredients = [...ingredients];

                                                                            if (this._config.onlineOrders && this._config.onlineOrders.allowModifiers === false) {
                                                                                summary.ingredients = [];
                                                                            }

                                                                            const finalPayload: OLO.Ordering.IOnlineOrder2CartConverter = Utils.OnlineOrders.covertOrderToCart(order.data, summary.onlineMenu, summary.menuFlowsDetails, summary.ingredients, currentPickupTime);

                                                                            return actions.ReorderCalculateSuccessRequest({
                                                                                orderId: action.orderId,
                                                                                locationNo: action.locationNo,
                                                                                pickupTime: action.pickupTime,
                                                                                payload: {
                                                                                    ...summary,
                                                                                    cart: finalPayload.cart,
                                                                                    errors: finalPayload.errors,
                                                                                }
                                                                            });
                                                                        })
                                                                    );

                                                            })
                                                        );
                                                })
                                            );

                                    })
                                );
                        })
                    );
            })
        );

    @Effect() public cleanupAfterReorderModalsIsClosed$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.ModalClose,
                actions.ModalCloseAll,
            ),
            switchMap(action => {
                if (action.type === actions.ModalClose.type) {
                    const orderId = this._activeModalsWithReordersRegistry.get(action.id);

                    if (orderId) {
                        return of(actions.ReorderUnmount({ orderId }));
                    }

                    return never();
                }

                this._activeModalsWithReordersRegistry.clear();
                return of(actions.ReorderUnmount({}));
            })
        );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _actions$: Actions,
        private _store: Store<StateModels.IStateShared>,
        private _onlineMenuService: Services.OnlineMenuService,
        private _menuFlowsService: Services.MenuFlowsService,
        private _productsService: Services.ProductsService,
        private _modalsService: Services.ModalsService,
        private _onlineOrdersService: Services.OnlineOrdersService,
    ) { }
}
