import { Strings } from './strings.utils';
// import { RequestOptions, Headers, Response } from '@angular/common/http'; /* For none authorized users */

import { pipe, Observable, throwError, empty, OperatorFunction, fromEvent, merge } from 'rxjs';
import { map, first, catchError } from 'rxjs/operators';

export class HTTP {
    public static isOnline$(): Observable<boolean> {
        return merge(
            fromEvent(window, 'online'),
            fromEvent(window, 'offline'),
        ).pipe(
            map((event: Event) => event.type === 'online')
        );
    }

    // public static createUnauthorizedRequestOptions(headers?: string): RequestOptions {
    //     const options: RequestOptions = new RequestOptions({
    //         headers: new Headers()
    //     });

    //     if (headers && typeof headers === 'string') {
    //         options.headers.set('Authorization', `Key ${Strings.removeQuotesChars(headers)}`);
    //     }

    //     return options;
    // }

    public static switchApi(urlString: string): string {
        return urlString.replace('api/v1', 'api/v2');
    }

    public static object2string(rawParams: { [param: string]: any; }): string {
        const tempArr: string[] = [];

        Object.keys(rawParams).forEach((key: string) => {
            if (rawParams[key] instanceof Array) {

                for (const value of rawParams[key]) {
                    tempArr.push(`${key}=${encodeURIComponent(value)}`);
                }

            } else {
                tempArr.push(`${key}=${encodeURIComponent(rawParams[key])}`);
            }
        });

        return tempArr.length ? `?${tempArr.join('&')}` : '';
    }

    public static idsToStringParams(paramName: string, ids: number[]) {
        return ids.map(id => `${paramName}=${id}`).join('&');
    }

    public static cleanHeaders(value: string): string {
        return Strings.removeQuotesChars(value);
    }
}
