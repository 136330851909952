import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';

import * as Tokens from '@shared/core/tokens';
import * as State from '@shared/state/interface';

import * as actions from '@shared/state/actions';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class LoyaltyAppService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
        public store: Store<State.IStateShared>,
    ) { }

    public apiGetLoyaltyAppInfo(): Observable<APIv1.LoyaltyAppModel> {
        return this.httpClient.get<APIv1.LoyaltyAppModel>(`${this.config.api.base}/loyaltyapp`);
    }

    public apiGetLoyaltyIntroductionPages(): Observable<APIv1.LoyaltyAppGetLoyaltyAppIntroductionPages.Responses.$200> {
        return this.httpClient.get<APIv1.LoyaltyAppGetLoyaltyAppIntroductionPages.Responses.$200>(`${this.config.api.base}/loyaltyapp/introductionPages?status=1`);
    }

    public apiGetLoyaltyProductPrograms(programName: string): Observable<APIv1.GetLoyaltyProductProgramBusinessModel[]> {
        return this.httpClient.get<APIv1.LoyaltyProductProgramGetLoyaltyProductPrograms.Responses.$200>(`${this.config.api.base}/LoyaltyProductProgram?programName=${programName}`)
            .pipe(
                map(response => response.Items)
            );
    }

    public apiGetOrderTypes(locationNo: number): Observable<APIv1.LoyaltyAppGetLoyaltyAppOrderTypes.Responses.$200> {
        return this.httpClient.get<APIv1.LoyaltyAppGetLoyaltyAppOrderTypes.Responses.$200>(`${this.config.api.base}/loyaltyapp/orderTypes?locationNo=${locationNo}`)
            .pipe(
                map(payload => {
                    return payload.map(orderType => {
                        return {
                            ...orderType,
                            Details: orderType.Details ? orderType.Details
                                .sort((a, b) => (isNaN(a.DisplayIndex) ? a.Id : a.DisplayIndex) - (isNaN(b.DisplayIndex) ? b.Id : b.DisplayIndex))
                                : orderType.Details,
                        };
                    });
                })
            );
    }

    public apiGetMessages(): Observable<APIv1.LoyaltyAppGetLoyaltyAppMessages.Responses.$200> {
        return this.httpClient.get<APIv1.LoyaltyAppGetLoyaltyAppMessages.Responses.$200>(`${this.config.api.base}/loyaltyapp/messages`)
            .pipe(
                map(payload => payload.sort((a, b) => a.DisplayOrder - b.DisplayOrder))
            );
    }

    public requestLoyaltyAppInfo(): void {
        this.store.dispatch(actions.AppSettingsRequest());
    }

    public requestLoyaltyIntroductionPages(): void {
        this.store.dispatch(actions.LoyaltyIntroductionPagesRequest());
    }


}
