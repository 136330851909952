/*
https://fluin.io/blog/google-analytics-with-tag-manager-and-angular
https://www.npmjs.com/package/angular-gtag
*/
import { Inject, Injectable, Optional } from '@angular/core';

import * as Tokens from '@shared/core/tokens';

@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
    ) { }

    private get _ga(): IGAnalyticsAccount[] {
        return (this.config?.googleAnalytics || []).filter(obj => !!obj.id === true);
    }

    public get isConfigured(): boolean {
        return window.hasOwnProperty('gtag') === true && this._ga.length > 0;
    }

    public trackRouteNavigation(url: string, extraParams: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams = {}): void {
        if (!this.isConfigured || typeof url !== 'string') return;

        this._ga.forEach(obj => {
            window.gtag('config', obj.id, {
                page_path: url,
                ...extraParams,
            });
        });
    }

    public trackEvent(params: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams = {}, ...eventNames: string[]): void {
        /* https://developers.google.com/gtagjs/reference/api#event */
        if (!this.isConfigured || !eventNames || eventNames.length === 0 || !params || typeof params !== 'object') return;

        this._ga.forEach(account => {
            eventNames.forEach(eventName => {
                if (typeof eventName !== 'string') return;
                window.gtag('event', eventName, {
                    ...params,
                    send_to: account.id
                });
            });
        });

    }
}
