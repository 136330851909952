import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as Utils from '@shared/core/utils';
import * as Tokens from '@shared/core/tokens';
import * as State from '@shared/state/interface';

import { JWTService } from './jwt.shared.service';
import { SessionService } from './session.shared.service';

import { Observable, of } from 'rxjs';
import { withLatestFrom, map, take, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private readonly _whiteList: string[] = [
        'email-confirm',
        'reset-password',
    ];

    public readonly apiBaseUrl: string = this.config.api.base;
    public readonly apiKey: string = this.config.api.key;

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
        public store: Store<State.IStateShared>,
        public router: Router,
        public jwtService: JWTService,
        public sessionService: SessionService,
    ) { }

    public isAuthorized$(): Observable<boolean> {
        return this.jwtService.getCurrentTokens()
            .pipe(
                withLatestFrom(
                    this.store
                        .pipe(
                            select(selectors.isMemberAuthorizedJWT)
                        )
                ),
                map(([jwt, stateAuthorized]) => {
                    const JWTFailed: boolean = !!jwt === false || (jwt && !!jwt.AccessToken === false || !!jwt.RefreshToken === false);

                    return JWTFailed === false && stateAuthorized === true;
                })
            );
    }

    public validateSession(): Observable<boolean> {
        return this.jwtService.getCurrentTokens()
            .pipe(
                take(1),
                catchError(ex => {

                    return of(false);
                }),
                map(isValid => {
                    return !!isValid;
                })
            );
    }

    public signIn(credentials: APIv1.MemberLoginModel): Observable<string> {
        const postModel: APIv1.MemberLoginModel = { ...credentials };

        return this.httpClient.post<boolean>(`${this.apiBaseUrl}/members/login`, postModel)
            .pipe(
                map(response => {
                    if (response) {
                        return 'temp-session-key';
                    }
                    return null;
                })
            );
    }

    public signUp(model: APIv1.MemberModel, existingMember: APIv1.MemberModel, overwriteProps: APIv1.MemberModel = { IsMobileValidated: true }): Observable<APIv1.MemberModel> {
        model = model ? model : {};
        const postModel: APIv1.MemberModel = {
            SexId: OLO.Enums.SEX.RATHER_NOT_ANSWER,
            ...model,
        };
        /* Create new user */
        if (!existingMember || (typeof existingMember === 'object' && Object.keys(existingMember).length === 0)) return this.httpClient.post<APIv1.MemberModel>(`${this.apiBaseUrl}/members`, {
            ...postModel,
            IsOnlineRegistered: true,
            ...overwriteProps,
        });

        /*
            Safe merge new model with old model.
            Make sure new member model without values
            won't overwrite existing defined values
        */
        existingMember = Object.keys(model).reduce((acc, key) => {
            const newVal = model[key];
            const existingVal = existingMember[key];

            if ((newVal === null || newVal === undefined) && existingVal !== null && existingVal !== undefined) {
                return {
                    ...acc,
                    [key]: existingVal
                };
            }

            return acc;
        }, {
            ...existingMember,
            ...postModel,
        } as APIv1.MemberModel);

        // existingMember = {
        //     ...existingMember,
        //     ...postModel,
        // };

        if (existingMember.IsOnlineRegistered === false) {
            existingMember.IsEmailValidated = false;
        }

        existingMember.IsOnlineRegistered = true;

        existingMember = {
            ...existingMember,
            ...overwriteProps,
        };

        return this.httpClient.put<boolean>(`${this.apiBaseUrl}/members`, existingMember)
            .pipe(
                map(() => ({ ...existingMember }))
            );
    }

    public verifyPhoneNumber(PhoneNumber: string, LoyaltyAppId: number = null): Observable<boolean> {
        const postModel: APIv1.PhoneTemporaryCodeRequest = {
            LoyaltyAppId,
            PhoneNumber,
        };
        return this.httpClient.post<boolean>(`${this.apiBaseUrl}/Auth/temporaryCode`, postModel);
    }

    public verifyPhoneNumberToken(PhoneNumber: string, Token: string): Observable<boolean> {
        const postModel: APIv1.PhoneTemporaryCodeValidationRequest = {
            Token,
            PhoneNumber,
        };
        return this.httpClient.put<boolean>(`${this.apiBaseUrl}/Auth/temporaryCode`, postModel);
    }

    public async softSignOut(): Promise<boolean> {
        /*
            Prevents member data in state, but sets proper flags to false, and removes session data
        */
        this.sessionService.removeSession();
        this.jwtService.clearTokens();

        this.store.dispatch(actions.HistoryOrdersReset());
        this.store.dispatch(actions.CreditCardTokenDataReset());
        this.store.dispatch(actions.MemberAuthorizationSetFlag({ flag: false }));
        return true;
    }

    public async signOut(redirect: boolean | string = '/', resetCart: boolean = false): Promise<boolean> {
        await this.softSignOut();

        if (resetCart) this.store.dispatch(actions.CartReset());

        this.store.dispatch(actions.MemberStateReset());
        this.store.dispatch(actions.MemberGuestModeSet({ flag: true }));

        Utils.Storage.remove(OLO.Enums.USER_STORAGE.BIRTHDAY_REWARDS);

        if (redirect) {
            if (redirect === true) return this.router.navigate(['/']);

            return this.router.navigate([redirect]);
        }

        return true;
    }
}
