import { createAction } from '@ngrx/store';

export const OnlineMenuPagesRequest = createAction(
    '[Online menu] Request menu pages',
    (locationNo: number, memberId: number = null, pickupDate: string = new Date().toISOString()) => ({ locationNo, memberId, pickupDate }),
);

export const OnlineMenuPagesSuccessRequest = createAction(
    '[Online menu] Success request menu pages',
    (locationNo: number, memberId: number = null, pickupDate: string, payload: APIv1.OnlineMenuResponseModel) => ({ locationNo, memberId, pickupDate, payload }),
);

export const OnlineMenuPageErrorRequest = createAction(
    '[Online menu] Error request menu pages',
    (locationNo: number, memberId: number = null, pickupDate: string, ex: any = null) => ({ locationNo, memberId, pickupDate, ex })
);
