import { createAction, props } from '@ngrx/store';

export const LocationsRequest = createAction(
    '[Locations] Request locations',
    (params: APICommon.ITerminalsOnlineOrderingLocationsGetParams = {}) => ({ params })
);

export const LocationsSuccessRequest = createAction(
    '[Locations] Success request locations',
    props<{ params: APICommon.ITerminalsOnlineOrderingLocationsGetParams, payload: APIv1.LocationBusinessModel[]; }>()
);

export const LocationsErrorRequest = createAction(
    '[Locations] Error request locations',
    (params: APICommon.ITerminalsOnlineOrderingLocationsGetParams, ex: any = null) => ({ ex, params })
);
