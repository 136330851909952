import { Injectable, Inject } from '@angular/core';
import { Store, select, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as State from '@shared/state/interface';
import * as Utils from '@shared/core/utils';
import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';

import * as StateModels from '../interface';

import { Observable, of, forkJoin, never } from 'rxjs';
import { switchMap, catchError, map, withLatestFrom, mergeMap } from 'rxjs/operators';


@Injectable()
export class LatestTransactionsEffects {
    @Effect() public resetLoyaltyProgramOnSignOut$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.MemberSignOut,
            ),
            switchMap(() => {
                return of(actions.LatestTransactionsReset());
            })
        );

    @Effect() public requestLatestTransactions$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.LatestTransactionsRequest
            ),
            mergeMap(({ memberId }) => {
                return this._transactionsService.getLatestTransactionsForMember(memberId, 10)
                    .pipe(
                        map(payload => actions.LatestTransactionsSuccessRequest({ memberId, payload })),
                        catchError(ex => {
                            console.error('getLatestTransactionsForMember', ex);
                            return of(actions.LatestTransactionsErrorRequest({ memberId, ex }));
                        })
                    );
            })
        );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _actions$: Actions,
        private _transactionsService: Services.TransactionsService,
    ) { }
}
