import { Injectable, Inject } from '@angular/core';
import { Store, select, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as State from '@shared/state/interface';
import * as Utils from '@shared/core/utils';
import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';

import * as StateModels from '../interface';

import { Observable, of, forkJoin } from 'rxjs';
import { switchMap, catchError, map, withLatestFrom, mergeMap } from 'rxjs/operators';


@Injectable()
export class LoyaltyMessagesEffects {
    @Effect() public requestMessagesOnSettingsDownloaded$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.AppSettingsSuccessRequest),
            switchMap(() => of(actions.LoyaltyMessagesRequest()))
        );

    @Effect() public requestMessages$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.LoyaltyMessagesRequest),
            switchMap(() => this._loyaltyAppService.apiGetMessages()
                .pipe(
                    map(payload => actions.LoyaltyMessagesSuccessRequest({ payload })),
                    catchError(ex => {
                        console.error('Request Messages Error', ex);
                        return of(actions.LoyaltyMessagesErrorRequest({ ex }));
                    })
                )
            )
        );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _actions$: Actions,
        private _store: Store<StateModels.IStateShared>,
        private _loyaltyAppService: Services.LoyaltyAppService,
    ) { }
}
