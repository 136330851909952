import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as actions from '@shared/state/actions';
import * as selectors from '@shared/state/selectors';

import * as State from '@shared/state/interface';
import * as Tokens from '@shared/core/tokens';

import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GeolocationService {
    public currentPosition: Coordinates | boolean;

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public store: Store<State.IStateShared>
    ) { }

    public static deg2rad(deg): number {
        return deg * (Math.PI / 180);
    }

    public static getDistanceFromTo(coordsFrom: OLO.Maps.IMapSimpleCoords, coordsTo: OLO.Maps.IMapSimpleCoords): number {
        /*
            https://stackoverflow.com/questions/21279559/geolocation-closest-locationlat-long-from-my-position
            In kilometers
        */
        const earthRadiusKm: number = 6371;
        const dLat: number = GeolocationService.deg2rad(coordsTo.latitude - coordsFrom.latitude);
        const dLng: number = GeolocationService.deg2rad(coordsTo.longitude - coordsFrom.longitude);

        /* No idea... I feel like an idiot althoug I had taken math and physics tests on my mature exam in highschool */
        const a: number = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(GeolocationService.deg2rad(coordsFrom.latitude)) * Math.cos(GeolocationService.deg2rad(coordsTo.latitude)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return earthRadiusKm * c;
    }

    public static findNearestLocation(coords: OLO.Maps.IMapSimpleCoords, locations: APIv1.LocationBusinessModel[]): { distance: number, location: APIv1.LocationBusinessModel; } {
        if (!coords) {
            return {
                distance: null,
                location: locations[0]
            };
        }

        let closestLocation: { index: number, distance: number, location: APIv1.LocationBusinessModel; } = null;

        locations.forEach((location, index) => {
            const distance = GeolocationService.getDistanceFromTo({ latitude: coords.latitude, longitude: coords.longitude }, { latitude: location.Latitude, longitude: location.Longitude });
            const firstOrCloserThanPrevious: boolean = !closestLocation || closestLocation.distance && distance < closestLocation.distance;

            if (firstOrCloserThanPrevious) {
                closestLocation = { index, distance, location };
            }
        });

        return closestLocation ? closestLocation : { distance: null, location: locations[0] };
    }

    public getUserCoords(): Promise<Coordinates> {
        return new Promise((resolve, reject) => {

            if (this.currentPosition) {
                resolve(this.currentPosition as Coordinates);
            }

            if (!navigator.geolocation || this.currentPosition === false) {
                this.currentPosition = false;
                reject(`GeolocationError`);
            }

            navigator.geolocation.getCurrentPosition(
                ({ coords }) => {
                    this.currentPosition = {
                        latitude: coords.latitude,
                        longitude: coords.longitude,
                        altitude: coords.altitude,
                        accuracy: coords.accuracy,
                        altitudeAccuracy: coords.altitudeAccuracy,
                        heading: coords.heading,
                        speed: coords.speed
                    };

                    resolve(this.currentPosition);

                },
                (error) => {
                    this.currentPosition = false;
                    reject(error);
                });
        });
    }

    public requestUserCoordinates(): void {
        this.store.pipe(
            select(
                selectors.getGeolocationState
            ),
            take(1)
        ).subscribe(state => {
            if (state.isRequesting || state.hasFailed || state.hasSucceeded) return;

            this.store.dispatch(actions.geolocationPositionRequest());
        });
    }
}
