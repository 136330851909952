import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import * as State from '@shared/state/interface';
import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';
import * as APIV2Services from '@api/v2/services';

import * as actions from '@shared/state/actions';
import * as selectors from '@shared/state/selectors';

import { ConvergePaymentProviderService } from './paymentProviders/converge.payment-provider.shared.service';
import { CardConnectPaymentProviderService } from './paymentProviders/card-connect.payment-provider.shared.service';
import { PaymentExpressPaymentProviderService } from './paymentProviders/payment-express.payment-provider.shared.service';

import { Observable, throwError, of } from 'rxjs';
import { catchError, flatMap, map, filter, take, delay, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CreditCardsService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public store: Store<State.IStateShared>,
        public apiV2Services: APIV2Services.MembersService,
        public httpClient: HttpClient,
        @Optional() public convergePaymentProviderService: ConvergePaymentProviderService,
        @Optional() public cardConnectPaymentProviderService: CardConnectPaymentProviderService,
        @Optional() public paymentExpressPaymentProviderService: PaymentExpressPaymentProviderService,
        @Optional() public actions$: Actions,
    ) { }

    public requestCardTokenForDefaultPaymentProvider(cardData: OLO.CreditCards.ICreditCardDetails, locationNo: number = null): Observable<{ token: string; redirectUrl?: string; }> {
        /* When we don't have info about payment provider or locationNo i.e. on account page */
        return this.store
            .pipe(
                select(selectors.getLoyaltyAppSettings),
                filter(appSettings => appSettings.data !== null),
                take(1),
                flatMap(appSettings => {
                    switch (true) {
                        case this.config.paymentProvider === OLO.Enums.PAYMENT_PROVIDER.CONVERGE && appSettings.data.AppSettings.DefaultConvergeSettings !== null && this.convergePaymentProviderService !== null:

                            return this.convergePaymentProviderService.requestCardToken({
                                ssl_card_number: cardData.cardNumber,
                                ssl_exp_date: cardData.expiryDate,
                                ssl_add_token: 'Y',
                            }, locationNo, appSettings.data.AppSettings.DefaultConvergeSettings)
                                .pipe(
                                    map(convergeResponse => ({
                                        token: convergeResponse.ssl_token
                                    }))
                                );

                        case this.config.paymentProvider === OLO.Enums.PAYMENT_PROVIDER.CARD_CONNECT && appSettings.data.AppSettings.DefaultCardConnectSettings !== null && this.cardConnectPaymentProviderService !== null:
                            return this.cardConnectPaymentProviderService.requestCardToken(cardData.cardNumber, cardData.expiryDate, locationNo, appSettings.data.AppSettings.DefaultCardConnectSettings)
                                .pipe(
                                    map(cardConnectResponse => ({
                                        token: cardConnectResponse.cardsecure.data,
                                    }))
                                );

                        case this.config.paymentProvider === OLO.Enums.PAYMENT_PROVIDER.PAYMENT_EXPRESS && appSettings.data.AppSettings.DefaultPaymentExpressSettings !== null && this.paymentExpressPaymentProviderService !== null:

                            return this.paymentExpressPaymentProviderService.requestCardToken(cardData, locationNo, appSettings.data.AppSettings.DefaultPaymentExpressSettings)
                                .pipe(
                                    map(paymentExpressResponse => {
                                        return ({
                                            token: paymentExpressResponse.SessionToken,
                                            redirectUrl: paymentExpressResponse.Url
                                        });
                                    })
                                );

                        default:
                            return throwError('Default payment provider not configured');

                    }
                }),
                catchError(ex => {
                    console.error('Unable to get card token', ex);
                    return throwError(ex);
                })
            );
    }

    public getCardItems(clientAppKey: string = this.config.api.key): Observable<APIv2.MembersGetMemberCards.Responses.$200> {
        return this.httpClient.get<APIv2.MembersGetMemberCards.Responses.$200>(`${Utils.HTTP.switchApi(this.config.api.base)}/members/creditCards`);
    }

    public addMemberCard(model: APIv2.CreatePaymentAccountRequest, clientAppKey: string = this.config.api.key): Observable<APIv2.CreatePaymentAccountResponse> {
        const fixedDateMode: APIv2.CreatePaymentAccountRequest = {
            ...model,
            ExpirationDate: Utils.CreditCards.dateToApiFormat(model.ExpirationDate),
        };
        return this.httpClient.post<APIv2.CreatePaymentAccountResponse>(`${Utils.HTTP.switchApi(this.config.api.base)}/members/creditCards`, fixedDateMode);
    }

    public removeMemberCardRequest(cardId: number, clientAppKey: string = this.config.api.key): Observable<boolean> {
        return this.httpClient.delete<boolean>(`${Utils.HTTP.switchApi(this.config.api.base)}/members/creditCards/${cardId}`);
    }

    public async addCreditCardWithRedirect(cardData: OLO.CreditCards.ICreditCardDetails): Promise<string> {
        this.store.dispatch(actions.GetCreditCardTokenWithRedirect(cardData));

        /* Refactor required - add proper redirect substate for better control and move this to effects */
        return new Promise((resolve, reject) => {
            Utils.Redirect.setRedirect()
                .then(() => {
                    this.store
                        .pipe(
                            select(selectors.getCardState),
                            filter(state => {
                                return state.token.isGettingToken === false && (state.token.hasSucceeded === true || state.token.hasFailed === true);
                            }),
                            take(1),
                            switchMap<State.ICreditCards, Observable<string>>(state => {
                                if (state.token.hasSucceeded && state.activeCardRedirectUrl) {
                                    if (Utils.Redirect.isRedirecting()) {
                                        /* Unselect current card to handle errors for sepcific card when got error response */
                                        this.store.dispatch(actions.SelectActiveCreditCardId({ cardId: null }));
                                        this.store.dispatch(actions.SelectActiveCreditCardToken({ token: null }));
                                        this.store.dispatch(actions.StateSave());

                                        return this.actions$
                                            .pipe(
                                                ofType(
                                                    actions.StateSaveSuccess,
                                                    actions.StateSaveError
                                                ),
                                                take(1),
                                                switchMap(action => {
                                                    if (action.type === actions.StateSaveSuccess.type) {
                                                        return of(state.activeCardRedirectUrl);
                                                    }

                                                    return of(null);
                                                })
                                            );
                                    }

                                    return of(state.activeCardRedirectUrl);
                                }
                                return of(null);
                            }),
                            delay(10),
                        ).subscribe(activeCardRedirectUrl => {
                            if (activeCardRedirectUrl) {
                                return resolve(activeCardRedirectUrl);
                            }

                            Utils.Redirect.unsetRedirect()
                                .then(() => {
                                    if (this.config.demoMode === true) {
                                        return resolve('DEMO_URL');
                                    }
                                    reject(`There was an error getting redirectUrl for credit card`);
                                });
                        });
                });
        });
    }

    public async handleCardReturnRedirect(status: boolean): Promise<boolean> {
        this.store.dispatch(actions.StateRestore({
            setProps: {
                modals: []
            }
        }));

        return new Promise(resolve => {
            Utils.Redirect.unsetRedirect()
                .then(() => {
                    if (!status) {
                        /*
                            We don't need to check anything as we already know status from payment provider
                            from query params. Just make sure to trigger validation error! TOLO-80
                        */
                        console.warn('Provider responded with "FAILED" status. Please contact payment provider for more details.');
                        this.store.dispatch(actions.CreditCardsValidateErrorRequest());
                        return resolve(false);
                    }

                    this.store.dispatch(actions.CreditCardsValidateRequest());

                    this.store
                        .pipe(
                            select(selectors.getCardState),
                            filter(state => {
                                return (state.validation.hasSucceeded === true || state.validation.hasFailed === true);
                            }),
                            take(1)
                        ).subscribe(state => {
                            if (state.validation.hasFailed) {
                                console.warn('Payment method validation failed failed');
                                return resolve(false);
                            }
                            resolve(true);
                        });

                });
        });
    }
}
