import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as Tokens from '@shared/core/tokens';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ProductsService {

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
    ) { }

    public getIngredients(...productIds: number[]): Observable<APIv1.ProductsGetProductRecipeIngredients.Responses.$200> {
        const generatedProductIds: string = productIds.map(productId => `products=${productId}`).join('&');

        return this.httpClient.get(`${this.config.api.base}/products/Ingredients?${generatedProductIds}`);
    }

    public getIngredientsForLocation(locationNo: number, ...productIds: number[]): Observable<APICommon.IProductLocationIngredient[]> {
        const generatedProductIds: string = productIds.map(productId => `productIDs=${productId}`).join('&');

        return this.httpClient.get<APIv1.ProductWithIngredientsModel[]>(`${this.config.api.base}/products/Ingredients/Location/${locationNo}?${generatedProductIds}`)
            .pipe(
                map(response => {
                    return response.map(ingredient => {
                        return {
                            ...ingredient,
                            _LocationNo: locationNo
                        };
                    });
                })
            );
    }

    public getIngredientsForLocationExtended(locationNo: number, ...productIds: number[]): Observable<APICommon.IProductLocationIngredientExtended[]> {
        return this.getIngredientsForLocation(locationNo, ...productIds)
            .pipe(
                map(response => {
                    return response.map(productIngredient => {
                        if (productIngredient.Ingredients === null) return productIngredient as APICommon.IProductLocationIngredientExtended;

                        const ingredientsExtended: APICommon.IProductIngredientExtended[] = productIngredient.Ingredients.map(ingredient => {
                            const Modifiers: APICommon.IIngredientModifierExtended[] = ingredient.Modifiers === null ? null : ingredient.Modifiers.map(modifier => {
                                return {
                                    ...modifier,
                                    IngredientPLU: ingredient.IngredientPLU,
                                    _IsOptional: false, // ingredient.IsOptional, model has changed. There is no IsOptional flag
                                    _ProductId: productIngredient.ProductID,
                                    _LocationNo: locationNo,
                                    _ProductPLU: productIngredient.PLU,
                                };
                            });
                            const Swaps = ingredient.Swaps === null ? null : ingredient.Swaps.map(swap => {
                                return {
                                    ...swap,
                                    Modifiers: swap.Modifiers.map(modifier => {
                                        return {
                                            ...modifier,
                                            IngredientPLU: ingredient.IngredientPLU,
                                            _IsOptional: false, // ingredient.IsOptional, model has changed. There is no IsOptional flag
                                            _ProductId: productIngredient.ProductID,
                                            _LocationNo: locationNo,
                                            _ProductPLU: productIngredient.PLU,
                                        };
                                    })
                                };
                            });

                            return {
                                ...ingredient,
                                Modifiers,
                                Swaps: Swaps,
                            };
                        });

                        return {
                            ...productIngredient,
                            Ingredients: ingredientsExtended,
                            _LocationNo: locationNo
                        };
                    });
                }),
            );
    }

    public getIngredientsModifiers(...productIds: number[]): Observable<APIv1.ProductRecipeIngredientModel[]> {
        return this.getIngredients(...productIds)
            .pipe(
                map(ingredients => ingredients.Items),
            );
    }
}
