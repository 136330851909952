export class Arrays {
    public static sortByDisplayIndex(items: any[], order: string = 'asc'): any[] {
        if (!items || items instanceof Array && items.length === 0) { return items; }

        let newArr: any[];

        if (order === 'desc') {
            newArr = items.sort((item1: any, item2: any) => {
                if (!item1.hasOwnProperty('DisplayIndex') || !item2.hasOwnProperty('DisplayIndex')) {
                    return 0;
                }
                return item2['DisplayIndex'] - item1['DisplayIndex'];
            });
        } else {
            newArr = items.sort((item1: any, item2: any) => {
                if (!item1.hasOwnProperty('DisplayIndex') || !item2.hasOwnProperty('DisplayIndex')) {
                    return 0;
                }
                return item1['DisplayIndex'] - item2['DisplayIndex'];
            });
        }

        return newArr;
    }

    public static sortByProp(propName: string, direction: string = 'desc'): any {
        return (a, b) => {
            switch (true) {
                case (direction === 'desc' ? a[propName] < b[propName] : a[propName] > b[propName]):
                    return 1;
                case (direction === 'desc' ? a[propName] > b[propName] : a[propName] < b[propName]):
                    return -1;
                default:
                    return 0;
            }
        };
    }

    public static remove(arr: any[], element: any): any[] {
        return arr.filter(item => item !== element);
    }
}
