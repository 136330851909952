import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, IMenuFlowDefaultActivation } from '../interface';

const state = createFeatureSelector<IStateShared, IMenuFlowDefaultActivation[]>('menuFlowDefaultActivations');

export const getMenuFlowDefaultActivation = (menuFlowId: number) => createSelector(
    state,
    menuFlowDefaultActivations => menuFlowDefaultActivations.find(obj => obj.MenuFlowId === menuFlowId)
);

export const isDownloadingAnyMenuFlowDefaultActivation = createSelector(
    state,
    menuFlowDefaultActivations => menuFlowDefaultActivations.some(obj => obj.isDownloading)
);
