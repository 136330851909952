import { createAction, props } from '@ngrx/store';

export const OrderTypesImagesRequest = createAction(
    '[Order Types images] Request',
    props<{ params: APICommon.IImagesGetParams; orderTypesIds: number[]; }>(),
);

export const OrderTypesImagesSuccessRequest = createAction(
    '[Order Types images] Success request',
    props<{ params: APICommon.IImagesGetParams; payload: APIv1.ImageUrlModel[]; orderTypesIds: number[]; }>(),
);

export const OrderTypesImagesErrorRequest = createAction(
    '[Order Types images] Error request',
    props<{ params: APICommon.IImagesGetParams; ex?: any; orderTypesIds: number[]; }>(),
);
