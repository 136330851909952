import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './locations-filters.actions';
import { ILocationsFilters } from './locations-filters.interface';

const initialState: ILocationsFilters = {
    pickupMode: {
        Id: 1,
        Name: 'ASAP',
    },
    pickupTime: null,
    search: null,
};

export const locationsFiltersReducerFn = createReducer(
    initialState,
    on(
        actions.LocationsFiltersReset,
        (state, action) => JSON.parse(JSON.stringify(initialState))
    ),
    on(
        actions.LocationsFiltersRestorePickupTime,
        (state, action) => ({
            ...state,
            pickupMode: {
                ...initialState.pickupMode
            },
            pickupTime: null,
        })
    ),
    on(
        actions.LocationsFiltersSetPickupMode,
        (state, action) => ({
            ...state,
            pickupMode: action.mode,
        })
    ),
    on(
        actions.LocationsFiltersSyncPickupTime,
        actions.LocationsFiltersSetPickupTime,
        (state, action) => {
            return ({
                ...state,
                pickupTime: action.pickupTime,
            });
        }
    ),
    on(
        actions.LocationsFiltersSetSearch,
        (state, action) => ({
            ...state,
            search: action.search,
        })
    ),
);

export function locationsFiltersReducer(state: ILocationsFilters | undefined, action: Action) {
    return locationsFiltersReducerFn(state, action);
}
