import { Strings } from './strings.utils';
import { Numbers } from './numbers.utils';

import * as moment from 'moment';



export class Dates {
    public static readonly days: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    public static readonly months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    public static isToday(date: Date | string): boolean {
        return Dates.datesDiffInDays(new Date(), date) === 0;
    }
    public static datesDiffInDays(a: string | Date, b: string | Date): number {
        /* https://stackoverflow.com/questions/3224834/get-difference-between-2-dates-in-javascript */
        const _MS_PER_DAY: number = 1000 * 60 * 60 * 24;
        const d1 = Dates.createDate(a);
        const d2 = Dates.createDate(b);

        const utc1 = Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate());
        const utc2 = Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate());

        return Math.abs(Math.floor((utc2 - utc1) / _MS_PER_DAY));
    }

    public static transformHours(hours: string, meridemiSeparator: string = ''): string {
        /*
            Will convert 09:00:00 to 9:00am, or 21:30:00 to 9:30pm
        */
        if (!hours || typeof hours !== 'string') return hours;

        const split: string[] = hours.includes('T') ? hours.replace(/^\d{4}-\d{2}-\d{2}T/, '').replace(/\.\d{3}Z?$/, '').split(':') : hours.split(':');
        const hour: number = +split[0];
        const meridiem: string = hour < 12 ? 'am' : 'pm';
        const newHour: string = hour > 12 ? `${hour - 12}` : `${hour}`;
        return `${newHour === '0' || newHour === '00' ? '12' : newHour}:${split[1]}${meridemiSeparator}${meridiem}`;
    }

    public static descriptionDate(date: string | Date, includeYear: boolean = false): string {
        /*
            Will output 'Wednesday, September 12'
        */
        const d: Date = typeof date === 'string' ? new Date(date) : date;
        const month: string = Strings.capitalizeFirstChar(Dates.months[d.getMonth()]);
        const day: string = Strings.capitalizeFirstChar(Dates.days[d.getDay()]);
        const year: number = d.getFullYear();

        return `${day}, ${month} ${d.getDate()}` + (includeYear ? year : '');
    }

    public static getMinAgeDate(minAge: number): Date {
        return new Date(new Date().setFullYear(new Date().getFullYear() - minAge)); /* minimumAgeToSignUp */
    }

    public static checkAge(date: Date, minAge: number, maxAge?: number): boolean {
        if (!minAge) { return true; }

        /* Logic transpiled from our backend! https://pastebin.com/ifv8kigC  */
        const today: Date = new Date();
        let age: number = today.getFullYear() - date.getFullYear();

        if (today.getMonth() < date.getMonth() || (today.getMonth() === date.getMonth() && today.getDate() < date.getDate())) {
            age--;
        }

        if (age < minAge) {
            return false;
        }

        if (typeof maxAge === 'number' && age > maxAge) {
            return false;
        }

        return true;
    }

    public static getDaysInMonth(month, year): number {
        return new Date(year, month, 0).getDate();
    }

    public static minsToMiliseconds(minutes: number): number {
        return (minutes * 60 * 1000);
    }

    public static millisecsToMins(millis): number {
        return Math.floor(millis / 60000);
    }

    public static toMilliseconds(date: string | number | Date): number {
        const newDate: Date = date instanceof Date ? date as Date : new Date(date as any);

        if (newDate.toString() === 'Invalid Date') {
            return null;
        }

        return newDate.getTime();
    }

    public static inTimeRange(closingTime: string | number | Date, checkDate: string | number | Date, threshold: number = 0): boolean {
        const isClosing: number = Dates.toMilliseconds(closingTime);
        const date: number = Dates.toMilliseconds(checkDate);

        return isClosing - Dates.minsToMiliseconds(threshold) > date;
    }

    public static hoursFromDate(date: Date, meridemi: boolean = true): string {
        let hours: number = date.getHours();
        let minutes: number = date.getMinutes();
        let timeString: string = ``;

        if (meridemi) {
            let hoursConverted = hours > 12 ? hours - 12 : hours;
            timeString += hoursConverted < 10 ? '0' + hoursConverted : `${hoursConverted}`;
        } else {
            timeString += hours < 10 ? '0' + hours : `${hours}`;
        }

        return timeString += `:${minutes < 10 ? '0' + minutes : minutes}${meridemi ? (hours < 12 ? 'am' : 'pm') : ''}`;
    }

    public static getDayName(dayNo: OLO.Dates.DAY_OF_WEEK): string {
        return Dates.days[dayNo];
    }

    public static getDayNameFromDate(date: Date): string {
        return Dates.days[date.getDay()];
    }

    public static decomposeDate(date: Date): OLO.Dates.IDateDecomposed {
        return {
            hours: date.getHours(),
            minutes: date.getMinutes(),
            seconds: date.getSeconds(),
            milliseconds: date.getMilliseconds(),
            day: date.getDate(),
            month: date.getMonth() + 1,
            year: date.getFullYear(),
            weekDay: date.getDay(),
        };
    }

    public static createDateString(obj: OLO.Dates.IDateParamsObj, zulu: boolean = false): string {
        const currDate: OLO.Dates.IDateDecomposed = this.decomposeDate(new Date());

        const d: OLO.Dates.IDateParamsObj = {
            year: obj.year ? obj.year : currDate.year,
            day: obj.day ? obj.day : currDate.day,
            month: obj.month ? obj.month : currDate.month,
            hours: obj.hours ? obj.hours : currDate.hours,
            minutes: obj.minutes ? obj.minutes : currDate.minutes,
            seconds: obj.seconds ? obj.seconds : currDate.seconds,
            milliseconds: obj.milliseconds ? obj.milliseconds : currDate.milliseconds,
        };

        for (const prop in d) {
            d[prop] = Numbers.leadingZero(d[prop]);
        }

        return `${d.year}-${d.month}-${d.day}T${d.hours}:${d.minutes}:${d.seconds}:${d.milliseconds}${zulu ? 'Z' : ''}`;
    }

    public static normalizeYear(year: number): number {
        // Century fix
        let YEARS_AHEAD = 20;
        if (year < 100) {
            let nowYear = new Date().getFullYear();
            year += Math.floor(nowYear / 100) * 100;
            if (year > nowYear + YEARS_AHEAD) {
                year -= 100;
            } else if (year <= nowYear - 100 + YEARS_AHEAD) {
                year += 100;
            }
        }
        return year;
    }

    // public static createDate(date: Date | string | null = null): Date {
    //     let d: Date;
    //     if (!date) {
    //         d = new Date();
    //     }

    //     if (typeof date === 'string') {
    //         d = new Date(date);
    //     }

    //     if ((date instanceof Date) === true) {
    //         d = date as Date;
    //     }

    //     return d;
    // }

    public static createDate(date: Date | string | null | number = null): Date {
        let d: Date;
        if (!date) {
            d = new Date();
        }

        if (typeof date === 'number') {
            d = new Date(date);
        };

        if (typeof date === 'string') {
            const a: number[] = (date as string).split(/[\T\-\:\.]/gi).map((obj: string, index) => {
                obj = obj.replace('Z', '');
                if (index === 1) {
                    return +obj - 1;
                } else {
                    return +obj;
                }
            });

            if (a.length > 7) {
                a.length = 7;
            } else if (a.length < 7) {
                while (a.length < 7) {
                    a.push(0);
                }
            }

            d = new Date(a[0], a[1], a[2], a[3], a[4], a[5], a[6]);
        }

        if ((date instanceof Date) === true) {
            d = date as Date;
        }

        return d;
    }

    public static getLocalISOFormatDate(date: Date = new Date(), includeZ: boolean = false): string {
        if ((date instanceof Date) === false) return '';

        const year: number = date.getFullYear();
        const month: string = Numbers.leadingZero(date.getMonth() + 1);
        const day: string = Numbers.leadingZero(date.getDate());
        const hours: string = Numbers.leadingZero(date.getHours());
        const minutes: string = Numbers.leadingZero(date.getMinutes());
        const seconds: string = Numbers.leadingZero(date.getSeconds());
        let miliseconds: number | string = date.getMilliseconds();

        switch (true) {
            case miliseconds < 100 && miliseconds > 10:
                miliseconds = `0${miliseconds}`;
                break;
            case miliseconds < 10:
                miliseconds = `00${miliseconds}`;
                break;
            default:
                miliseconds = `${miliseconds}`;
        }

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${miliseconds}${includeZ ? 'Z' : ''}`;

    }

    public static createHoursIntFromDate(date: Date | string | number): number {
        let stringDate: string = date as string;

        if (date instanceof Date) {
            stringDate = Dates.getLocalISOFormatDate(date);
        }

        if (typeof date === 'number') {
            stringDate = Dates.getLocalISOFormatDate(new Date(date));
        }

        return Number(stringDate.match(/(\d{2}:){2}\d{2}/g)[0].replace(/:/g, ''));
    }

    public static isHourInHoursRange(toCheck: Date | string | number, from: Date | string | number, to: Date | string | number, equal: null | 'from' | 'to' | 'both' = 'both'): boolean {
        const n: number = Dates.createHoursIntFromDate(toCheck);
        const f: number = Dates.createHoursIntFromDate(from);
        const t: number = Dates.createHoursIntFromDate(to);
        // console.warn('n', n);
        // console.warn('f', f);
        // console.warn('t', t);
        if (!equal) return n > f && n < t;

        if (equal === 'from') return n >= f && n < t;

        if (equal === 'to') return n > f && n <= t;

        return n >= f && n <= t;
    }

    public static roundToNearestQuarter(date: Date, direction: 'up' | 'down'): Date {
        let dateCopy = new Date(date.getTime());
        let mins = dateCopy.getMinutes();
        let newMins;

        switch (true) {
            case mins < 15:
                newMins = direction === 'up' ? 15 : 0;
                break;

            case mins >= 15 && mins < 30:
                newMins = direction === 'up' ? 30 : 15;
                break;

            case mins >= 30 && mins < 45:
                newMins = direction === 'up' ? 45 : 30;
                break;

            case mins >= 45 && mins < 59:
                newMins = direction === 'up' ? 60 : 45;
                break;
        }

        return new Date(dateCopy.setMinutes(newMins));
    }

    public static roundToNearestHour(date: Date, direction: 'up' | 'down'): Date {
        let dateCopy = new Date(date.getTime());
        let mins = dateCopy.getMinutes();
        let newMins;

        switch (true) {
            case mins === 0:
                newMins = 0;
                break;
            case mins < 30:
                newMins = direction === 'up' ? 60 : 0;
                break;

            case mins >= 30:
                newMins = direction === 'up' ? 60 : 0;
                break;
        }

        return new Date(dateCopy.setMinutes(newMins));
    }

    public static getClosedTimeFromHourString(hours: string): string {
        /* will convert 22:59:00 -> 23:00:00, or 23:59:00 -> 00:00:00 */
        const strArr: string[] = hours.split(/:/g);
        if (strArr.length !== 3 || hours.length !== 8) return null;

        if (strArr[1] === '59') {
            strArr[1] = '00';

            strArr[0] = `${+strArr[0] + 1}`;
        }

        if (strArr[0] === '24') {
            strArr[0] = '00';
        }

        return strArr.join(':');

    }

    public static stringHourToNumber(hour: string): number {
        if (typeof hour !== 'string') return hour;
        return Number(hour.replace(/:/g, ''));
    }

    public static isHourInTimeRange(pickupHour: string, startTime: string, endTime: string) {
        if (!pickupHour) return false;

        const startTimeNo: number = Dates.stringHourToNumber(startTime || pickupHour);
        const endTimeNo: number = Dates.stringHourToNumber(endTime || pickupHour);
        const pickupHourNo: number = Dates.stringHourToNumber(pickupHour);

        return pickupHourNo <= endTimeNo && pickupHourNo >= startTimeNo;
    }

    public static calcInitialTimesObj(
        locationNo: number,
        asapPickupMins: number,
        openingHours: APIv1.LocationOrderingTimeInfoModel[],
        orderTimeoutBufferMins: number,
        startBufferMins: number,
        date: Date = new Date(),
    ): OLO.Ordering.IPickupForLocation {
        if (orderTimeoutBufferMins === undefined || orderTimeoutBufferMins === null ||
            startBufferMins === undefined || startBufferMins === null
        ) {
            throw new Error('OrderTimeoutBufferMins and startBufferMins must be provided');
        }

        const localDate: string = date instanceof Date ? Dates.getLocalISOFormatDate(date) : date;
        const openingHourObj: APIv1.LocationOrderingTimeInfoModel = openingHours?.find(day => {
            const byDate = day.Date ? day.Date.split('T')[0] === localDate.split('T')[0] : false;
            const byDayOfWeek = day.DayOfWeek === date.getDay();
            return byDate || byDayOfWeek;
        });
        if (!openingHourObj) {
            /* No today ordering - location might be available in further days */
            return null;
        }

        const openingDateRaw: string = localDate.replace(/T.+/, ''); // openingHourObj.Date.replace(/T.+/, '');
        const totalMinimumPickupMins: number = asapPickupMins < startBufferMins ? startBufferMins : asapPickupMins; /* Approved by Karolina on 2nd April 2019 on RingCentral */

        let startTime: Date;
        let closeTime: Date;
        let minimumPickupTimeForAsap: Date = new Date(new Date(date.getTime()).setSeconds(0, 0)); /* CHECK FOR NEAREST OPEN TIME IF IS CLOSED NOW */
        let minimumPickupTime: Date;
        let maximumPickupTime: Date;

        /* Comparing type */
        /* IOS FIX? AOLO-462 */
        const arrDate: number[] = openingDateRaw.split('-').map(d => +d);
        const arrHoursOpen: number[] = openingHourObj.OpeningTime.split(':').map(d => +d);
        const arrHoursClose: number[] = openingHourObj.ClosingTime.split(':').map(d => +d);

        startTime = new Date(arrDate[0], arrDate[1] - 1, arrDate[2], arrHoursOpen[0], arrHoursOpen[1], arrHoursOpen[2]);
        closeTime = new Date(arrDate[0], arrDate[1] - 1, arrDate[2], arrHoursClose[0], arrHoursClose[1], arrHoursClose[2]);

        minimumPickupTime = new Date(new Date(date.getTime() + (totalMinimumPickupMins * 60 * 1000)).setSeconds(0, 0));
        maximumPickupTime = new Date(new Date(new Date(closeTime.getTime() - ((orderTimeoutBufferMins + asapPickupMins) * 60 * 1000))).setSeconds(0, 0));

        if (minimumPickupTime >= maximumPickupTime) {
            minimumPickupTime = new Date(maximumPickupTime.getTime());
        }


        /* AOLO-215 */
        const diff: number = startTime.getTime() - date.getTime();
        if (diff >= totalMinimumPickupMins) {
            /* add asapiPickupTimeMins to openHours */
            minimumPickupTime = new Date(new Date(startTime.getTime() + (asapPickupMins * 60 * 1000)).setSeconds(0, 0));
        }

        /* AOLO-215 */
        const diff2: number = closeTime.getTime() - date.getTime();
        if (diff2 >= totalMinimumPickupMins) {
            maximumPickupTime = new Date(new Date(new Date(closeTime.getTime()/*  - (asapPickupMins * 60 * 1000) */)).setSeconds(0, 0));
        }

        /* ASAP Check if location is open to adjust asap time */
        const locationOpenHour: number = Dates.createHoursIntFromDate(openingHourObj.OpeningTime);
        const locationCloseHour: number = Dates.createHoursIntFromDate(openingHourObj.ClosingTime);
        // const asapPickupHour: number = Dates.createHoursIntFromDate(minimumPickupTimeForAsap.getTime() + asapPickupMins * 60 * 1000);
        const nowHour: number = Dates.createHoursIntFromDate(date);
        const isOpen: boolean = nowHour >= locationOpenHour && nowHour <= locationCloseHour;
        const locationWillBeOpenToday: boolean = locationOpenHour > nowHour;
        const locationIsClosing = (minimumPickupTimeForAsap.getTime() + (totalMinimumPickupMins) * 60 * 1000) > maximumPickupTime.getTime();
        if (locationIsClosing === true && locationWillBeOpenToday === false) {
            return null;
        }

        if (!isOpen) {
            if (!locationWillBeOpenToday) {
                return null;
            }
            minimumPickupTimeForAsap = new Date(startTime.getTime() + asapPickupMins * 60 * 1000);
        }
        /*
            For calculations only!
        */
        return {
            DayOfWeek: openingHourObj.DayOfWeek,
            DayName: Dates.getDayName(openingHourObj.DayOfWeek),
            LocationNo: locationNo,
            Date: localDate, /* SHOULD INCLUDE Z? */

            IsOpen: isOpen, /* Helper to determine other props in other functions */
            OpeningTime: startTime,
            ClosingTime: closeTime,
            MinimumPickupTimeForAsap: minimumPickupTimeForAsap,
            MinimumPickupTime: minimumPickupTime,
            MaximumPickupTime: maximumPickupTime,
        };
    }

    public static generatePickupTimesList(params: OLO.Ordering.IGeneratePickupsParams): OLO.Ordering.IPickupTime[] {
        const defaults: OLO.Ordering.IGeneratePickupsParams = {
            location: null,
            asapPickupMins: 0,
            openingHours: null,
            orderTimeoutBufferMins: 0,
            startBufferMins: 0,
            nextTick: 15,
            schedule: false,
        };

        const opts = {
            ...defaults,
            ...params,
        };
        if (opts.asapPickupMins === undefined || opts.asapPickupMins === null || !opts.openingHours) return [];
        const isFutureOrderingOnly = opts.schedule === true && opts.location !== null
            && opts.location.FutureOrderingMaxDaysAhead !== null
            && opts.location.FutureOrderingMinDaysAhead !== null
            && opts.location.FutureOrderingMinDaysAhead !== 0;
        if (isFutureOrderingOnly) return [];

        /* ASAP - AOLO-248 */
        const now = new Date();
        const initialAsapPickupValue = Dates.calcInitialTimesObj(opts.location?.LocationNo || 0, opts.asapPickupMins, opts.openingHours, opts.orderTimeoutBufferMins, 0, now);
        if (!initialAsapPickupValue) return [];

        let asapPickup: Date = initialAsapPickupValue.MinimumPickupTimeForAsap;

        let arr: OLO.Ordering.IPickupTime[] = [];
        arr.push({
            Id: asapPickup.getTime(),
            Index: 1,
            Name: opts.schedule ? Dates.createPickupLabelName(opts.schedule, 'common', asapPickup, true, opts.displayFormat) : `${Dates.hoursFromDate(asapPickup, true)}`,
            ShortName: `${Dates.hoursFromDate(asapPickup, true)}`,
            Date: asapPickup,
            MinutesFromNow: opts.asapPickupMins,
            IsAsap: initialAsapPickupValue.IsOpen, /* THIS WAS CHANGED! */
            Hour: `${Dates.hoursFromDate(asapPickup, false)}:00`,
            DateLocalISO: `${Dates.getLocalISOFormatDate(new Date(asapPickup.getTime()))}`,
            PlaceOrderTimeout: new Date(asapPickup.getTime() + opts.orderTimeoutBufferMins * 60 * 1000),
            IsToday: Dates.datesDiffInDays(initialAsapPickupValue.Date, now) === 0,
            DayNo: Dates.datesDiffInDays(initialAsapPickupValue.Date, now),
        });

        /* Later today */
        const initialLaterPickupValue = Dates.calcInitialTimesObj(opts.location?.LocationNo || 0, opts.asapPickupMins, opts.openingHours, opts.orderTimeoutBufferMins, opts.startBufferMins, now);
        let nextLaterPickup: Date = Dates.roundToNearestQuarter(initialLaterPickupValue.MinimumPickupTime, 'up');
        let nextLaterPlaceOrderTimeout: Date = new Date(nextLaterPickup.getTime() - ((opts.startBufferMins - opts.orderTimeoutBufferMins) * 60 * 1000)); /* Timeout to place an order for selected pickup time */

        if (nextLaterPickup > initialLaterPickupValue.MaximumPickupTime) return arr;

        while (nextLaterPickup < initialLaterPickupValue.MaximumPickupTime) {

            if (nextLaterPickup > initialLaterPickupValue.MinimumPickupTimeForAsap) {
                arr.push(Dates.createTimeObj(opts.schedule, nextLaterPickup, nextLaterPlaceOrderTimeout, arr.length + 1));
            }


            nextLaterPickup = new Date(nextLaterPickup.setMinutes(nextLaterPickup.getMinutes() + opts.nextTick));
            nextLaterPlaceOrderTimeout = new Date(nextLaterPlaceOrderTimeout.setMinutes(nextLaterPlaceOrderTimeout.getMinutes() + opts.nextTick));
        }

        return arr;
    }

    public static createPeriodObject(obj: APIv1.LocationOrderingTimeInfoModel, format: string = 'ddd, D MMM', prefixes: boolean = true): OLO.Ordering.IPeriod {
        const normalizeDate = (date: string) => date.split('T').map((key, index) => index === 0 ? key : /* `12:00:00.000` */obj?.ClosingTime ? obj.ClosingTime + '.000' : `12:00:00.000`).join('T');
        const now: Date = new Date();
        const newDate: string = normalizeDate(obj.Date);
        const Id = Dates.datesDiffInDays(now, newDate);

        let Name: string = ``;
        if (prefixes && Id < 2) {
            if (Id === 0) {
                Name = 'Today, ';
            } else {
                Name = 'Tomorrow, ';
            }
        }

        Name += moment(Dates.createDate(newDate)).format(format);

        return {
            Id: Id + 1,
            DayName: Dates.getDayName(obj.DayOfWeek),
            DayOfWeek: obj.DayOfWeek,
            Date: newDate,
            IsToday: Id === 0,
            Name,
        };
    }

    public static generatePickupTimesFutureList(period: OLO.Ordering.IPeriod, params: OLO.Ordering.IGeneratePickupsParams): OLO.Ordering.IPickupTime[] {
        const now = new Date();
        const defaults: OLO.Ordering.IGeneratePickupsParams = {
            location: null,
            openingHours: null,
            orderTimeoutBufferMins: 0,
            startBufferMins: 0,
            nextTick: 15,
            displayFormat: 'ddd, D MMM',
        };

        const opts: OLO.Ordering.IGeneratePickupsParams = {
            ...defaults,
            ...params,
        };

        const futureDefaults = {
            orderTimeoutBufferMins: 60,
            startBufferMins: 60,
            nextTick: 60,
        };

        if (!opts.openingHours) return [];
        const openingHours: APIv1.LocationOrderingTimeInfoModel = opts.openingHours.find(obj => obj.Date.split('T')[0] === period.Date.split('T')[0]);
        const startDate: Date = new Date(Dates.createDate(period.Date.split('T').map((obj, index) => !index ? obj : openingHours.OpeningTime).join('T')).getTime() + opts.startBufferMins * 60 * 1000);

        let endDate = Dates.roundToNearestHour(Dates.createDate(period.Date.split('T').map((obj, index) => !index ? obj : openingHours.ClosingTime).join('T')), 'down');
        const isToday: boolean = Dates.isToday(endDate);

        if (openingHours.ClosingTime && openingHours.ClosingTime.split(':')[1] === '59') {
            endDate = new Date(endDate.getTime() + 1 * 60 * 60 * 1000);
        }

        let nextDate: Date = isToday ? Dates.roundToNearestQuarter(startDate, 'up') : Dates.roundToNearestHour(startDate, 'down');

        const arr: OLO.Ordering.IPickupTime[] = [];
        if (nextDate > endDate) return arr;

        while (nextDate < endDate) {
            arr.push(Dates.createTimeObj(
                opts.schedule,
                nextDate,
                null,
                arr.length + 1,
                false,
                opts.displayFormat,
            ));

            const nextTick = Dates.isToday(nextDate) ? opts.nextTick : futureDefaults.nextTick;
            nextDate = new Date(nextDate.setMinutes(nextDate.getMinutes() + nextTick));
        }
        return arr.filter(obj => obj.Date > now);
    }

    public static createPickupLabelName(
        isSchedule: boolean,
        type: OLO.Types.PICKUP_LABEL_TYPES,
        date: Date | string,
        isAsap: boolean = false,
        format: string = 'ddd, D MMM',
        asapMinutesFromNow: number = null,
    ): string {
        if (!format) {
            format = 'ddd, D MMM';
        }
        if (typeof date === 'string') {
            date = Dates.createDate(date);
        }
        if (!(date instanceof Date)) {
            date = new Date();
            isAsap = true;
        }
        const currentTime: Date = new Date(new Date().setSeconds(0, 0));
        const daysDiff: number = Dates.datesDiffInDays(date, currentTime);
        let minutesFromNow: number = Math.floor(((date.getTime() || 0) - (currentTime.getTime() || 0)) / (60 * 1000));
        if (minutesFromNow < 0) {
            minutesFromNow = 0;
        }

        if (asapMinutesFromNow !== null) {
            minutesFromNow = asapMinutesFromNow;
        }

        let label: string = `${Dates.hoursFromDate(date, true)}`;
        if (!isSchedule) {
            if (isAsap) {
                switch (true) {
                    case type === 'common':
                        return `ASAP`;
                    case type === 'checkoutBox':
                        return `in ${minutesFromNow}mins`;
                    case type === 'location':
                        return minutesFromNow ? `ASAP (${minutesFromNow}mins)` : 'ASAP';
                    case type === 'filter':
                        return `Now`;
                }
            } else {
                switch (true) {
                    case type === 'common':
                        return `at ${label}`;
                    case type === 'checkoutBox':
                        return `at ${label}`;
                    case type === 'location':
                        return `${label}`;
                    case type === 'filter':
                        return `At ${label}`;
                }
            }
            return label;
        }

        if (type === 'orderConfirmation') {
            switch (true) {
                case daysDiff === 0:
                    return `Today at ${label}`;
                case daysDiff === 1:
                    return `Tomorrow at ${label}`;
                case daysDiff < 7:
                    return `${Dates.getDayName(date.getDay())} at ${label}`;
                default:
                    return `${moment(date).format(format || 'Do of D MMM')} at ${label}`;
            }
        }

        if (type === 'common') {
            switch (true) {
                case daysDiff === 0:
                    return `Today ${isAsap ? 'ASAP' : 'at ' + label}`;
                case daysDiff === 1:
                    return `Tomorrow at ${label}`;
                case daysDiff < 7:
                    return `This ${Dates.getDayName(date.getDay())} at ${label}`;
                default:
                    return `${moment(date).format(format || 'ddd, D MMM')} at ${label}`;
            }
        }

        if (type === 'checkoutBox') {
            switch (true) {
                case daysDiff === 0:
                    return `today ${isAsap ? `in ${minutesFromNow} min` : 'at ' + label}`;
                case daysDiff === 1:
                    return `tomorrow at ${label}`;
                case daysDiff < 7:
                    return `${Dates.getDayName(date.getDay())} at ${label}`;
                default:
                    return `${moment(date).format(format || 'ddd, D MMM')} at ${label}`;
            }
        }

        if (type === 'location') {
            switch (true) {
                case daysDiff === 0:
                    return `Today ${isAsap ? minutesFromNow ? `ASAP (${minutesFromNow}min)` : `ASAP` : 'at ' + label}`;
                case daysDiff === 1:
                    return `Tomorrow at ${label}`;
                case daysDiff < 7:
                    return `${Dates.getDayName(date.getDay())} at ${label}`;
                default:
                    return `${moment(date).format(format || 'ddd, D MMM')} at ${label}`;
            }
        }

        if (type === 'filter') {
            switch (true) {
                case daysDiff === 0:
                    return isAsap ? 'Now' : `${label} today`;
                case daysDiff === 1:
                    return `${label} tomorrow`;
                case daysDiff < 7:
                    return `${label} ${Dates.getDayName(date.getDay())}`;
                default:
                    return `${label} ${moment(date).format(format || 'ddd, D MMM')}`;
            }
        }
        return 'undefined case';
        // switch (true) {
        //     case daysDiff === 0:
        //         return `Today ${isAsap ? '' : ' at '}${label}`;
        //     case daysDiff === 1:
        //         return `Tomorrow ${isAsap ? '' : ' at '}${label}`;
        //     case daysDiff < 7:
        //         return `This ${Dates.getDayName(date.getDay())} at ${label}`;
        //     default:
        //         return `${moment(date).format(format || 'ddd, D MMM')} at ${label}`;
        // }
    }

    public static createTimeObj(
        isSchedule: boolean,
        forDate: Date,
        edgeDate: Date,
        index: number = null,
        isAsap: boolean = false,
        displayFormat: string = 'ddd, D MMM',
    ): OLO.Ordering.IPickupTime {
        const currentTime: Date = new Date(new Date().setSeconds(0, 0));

        const IsToday: boolean = Dates.datesDiffInDays(forDate, currentTime) === 0;
        const DayNo: number = Dates.datesDiffInDays(forDate, currentTime);

        return {
            Id: forDate.getTime(),
            Index: index,
            Name: Dates.createPickupLabelName(isSchedule, 'common', forDate, isAsap, displayFormat),
            ShortName: `${Dates.hoursFromDate(forDate, true)}`,
            Date: new Date(forDate.getTime()),
            MinutesFromNow: Math.floor((forDate.getTime() - currentTime.getTime()) / (60 * 1000)),
            IsAsap: isAsap,

            Hour: `${Dates.hoursFromDate(forDate, false)}:00`, /* For comapring dates with online order */
            DateLocalISO: `${Dates.getLocalISOFormatDate(new Date(forDate.getTime()))}`, /* Local time iso string - similar stuff gets returned from our API so it will be usefull to compare this date with api dates */

            PlaceOrderTimeout: edgeDate ? new Date(edgeDate.getTime()) : null, /* For checking if user hasn't spent too much time placing an order */
            IsToday,
            DayNo,
        };
    }

    public static createAsapTimeObj(isSchedule: boolean, asapPickupTime: number, placeOrderTimeout: Date, index: number = -1): OLO.Ordering.IPickupTime {
        const asapDate: Date = new Date(new Date().setSeconds(0, 0) + asapPickupTime * 60 * 1000);
        return {
            Id: asapDate.getTime(),
            Index: index,
            Name: Dates.createPickupLabelName(isSchedule, 'common', asapDate, true),
            ShortName: `${Dates.hoursFromDate(asapDate, true)}`,
            Date: new Date(asapDate.getTime()),
            MinutesFromNow: asapPickupTime,
            IsAsap: true,

            Hour: `${Dates.hoursFromDate(asapDate, false)}:00`, /* For comapring dates with online order */
            DateLocalISO: `${Dates.getLocalISOFormatDate(new Date(asapDate.getTime()))}`, /* Local time iso string - similar stuff gets returned from our API so it will be usefull to compare this date with api dates */

            PlaceOrderTimeout: new Date(placeOrderTimeout.getTime()), /* For checking if user hasn't spent too much time placing an order */
            IsToday: Dates.datesDiffInDays(new Date(), asapDate) === 0,
            DayNo: Dates.datesDiffInDays(new Date(), asapDate),
        };
    }

    public static overrdrivePickupTimeObjToAsap(pickupTime: OLO.Ordering.IPickupTime): OLO.Ordering.IPickupTime {
        let pickupTimeObj = pickupTime;
        if (pickupTimeObj.IsAsap) {
            pickupTimeObj = Dates.createAsapTimeObj(false, pickupTime.MinutesFromNow, pickupTime.PlaceOrderTimeout, -1);
        }

        return pickupTimeObj;
    }

    public static isDateInFutureOrdersTimeRange(
        date: Date | string,
        futureOrdersMinDay: number = null,
        futureOrdersMaxDay: number = null,
    ): boolean {
        if (futureOrdersMinDay === null || futureOrdersMaxDay === null) return false;
        if (!date) return false;
        date = typeof date === 'string' ? Dates.createDate(date) : date as Date;

        const diff: number = Dates.datesDiffInDays(new Date(), date);
        return diff >= futureOrdersMinDay && diff <= futureOrdersMaxDay;

    }

    public static isFuturePickupTimeValid(
        pickupTime: OLO.Ordering.IPickupTime,
        timeInfo: APIv1.LocationOrderingTimeInfoModel,
        futureOrdersMinDay: number = null,
        futureOrdersMaxDay: number = null,
    ) {
        /* Check if selected pickup time is in range of open +1 - close-1 hours */
        const startDate: Date = new Date(Dates.createDate(timeInfo.Date.split('T').map((obj, index) => !index ? obj : timeInfo.OpeningTime).join('T')).getTime() + 1 * 60 * 1000);
        const endDate: Date = Dates.roundToNearestHour(Dates.createDate(timeInfo.Date.split('T').map((obj, index) => !index ? obj : timeInfo.ClosingTime).join('T')), 'down');

        const targetDate = Dates.createDate(pickupTime?.DateLocalISO || Dates.getLocalISOFormatDate(Dates.createDate(null)));

        const isInLocationTimeRange: boolean = Dates.isDateInFutureOrdersTimeRange(targetDate, futureOrdersMinDay, futureOrdersMaxDay);
        return targetDate >= startDate && targetDate <= endDate && isInLocationTimeRange === true;
    }

    public static datesMatchByDayCallback(dateToCompare: string): (obj: any) => boolean {
        return (obj) => obj?.Date?.split('T')[0] === dateToCompare.split('T')[0];
    }

    public static getFilteredOrderingTimeInfo(location: APIv1.OnlineOrderingLocationBusinessModel): APIv1.LocationOrderingTimeInfoModel[] {
        const now = new Date();

        const min = location.FutureOrderingMinDaysAhead;
        const max = location.FutureOrderingMaxDaysAhead;

        /* 
            Min = null or/and Max = null  -> No future ordering
            Min = 0 and Max = 0  -> Only today
            Min = 0 and Max >0  -> today and other days
            Min >= 1 and Max >=1  -> today not available and can order for future days only
        */
        const isFutureOrderingAllowed = min !== null && max !== null;
        const isTodayAllowed = min === 0 || min === null;

        const arr = location.OrderingTimeInfo?.reduce((acc, timeInfo, index) => {

            const isToday: boolean = Dates.isToday(timeInfo.Date);

            switch (true) {
                case isToday && isTodayAllowed:
                    /* Min = 0 and Max = 0  -> Only today */
                    return [
                        ...acc,
                        timeInfo,
                    ];
                case isToday && !isTodayAllowed:
                    /* Min = 0 and Max = 0  -> Only today */
                    return acc;
                case !isToday && isFutureOrderingAllowed && Dates.isDateInFutureOrdersTimeRange(timeInfo.Date, min, max):
                    /* Min = 0 and Max >0  -> today and other days */
                    /* Min >= 1 and Max >=1  -> today not available and can order for future days only */
                    return [
                        ...acc,
                        timeInfo,
                    ];
                default:
                    /* Min = null or/and Max = null  -> No future ordering */
                    return acc;
            }

            // // const isToday: boolean = Dates.datesDiffInDays(now, timeInfo.Date) === 0;
            // // if (isToday) {
            // //     return [
            // //         ...acc,
            // //         timeInfo,
            // //     ];
            // // }

            // // const isInFutureOrdersTimeRange = isFutureOrderingAllowed && Dates.isDateInFutureOrdersTimeRange(timeInfo.Date, min, max);
            // // if (isInFutureOrdersTimeRange) {
            // //     return [
            // //         ...acc,
            // //         timeInfo,
            // //     ];
            // // }
            // return acc;

        }, [] as APIv1.LocationOrderingTimeInfoModel[]) || [];


        return arr;
    }

    public static createScheduleModel(): OLO.Ordering.IPickupTime {
        return {
            Date: null,
            DateLocalISO: null,
            Hour: null,
            Id: -1,
            Index: null,
            IsAsap: false,
            MinutesFromNow: null,
            Name: `Schedule for later`,
            ShortName: `Schedule for later`,
            PlaceOrderTimeout: null,
            IsToday: null,
            DayNo: null,
        };
    }
    public static createAsapModel(): OLO.Ordering.IPickupTime {
        return {
            Date: null,
            DateLocalISO: null,
            Hour: null,
            Id: 1,
            Index: null,
            IsAsap: true,
            MinutesFromNow: null,
            Name: `Today ASAP`,
            ShortName: `ASAP`,
            PlaceOrderTimeout: null,
            IsToday: true,
            DayNo: 0,
        };
    }
}
