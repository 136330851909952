export enum ERROR_CODES {
    GEOLOCATION_1 = 'GL/USER_DENIED/1', /* When user blocked golocation in on browser's permission request. */
    GEOLOCATION_2 = 'GL/NO_RESPONSE/2', /* When connection problem */

    HTTP_0 = 'HTTP/0',
    HTTP_400 = 'HTTP/BAD_REQUEST/400',
    HTTP_401 = 'HTTP/UNAUTHORIZED/401',
    HTTP_402 = 'HTTP/PAYMENT_REQUIRED/402',
    HTTP_403 = 'HTTP/FORBIDDEN/403',
    HTTP_404 = 'HTTP/NOT_FOUND/404',
    HTTP_405 = 'HTTP/METHOD_NOT_ALLOWED/405',
    HTTP_406 = 'HTTP/NOT_ACCEPTABLE/406',
    HTTP_407 = 'HTTP/PROXY_AUTH_REQ/407',
    HTTP_408 = 'HTTP/TIMEOUT/408',
    HTTP_440 = 'HTTP/SESSION_EXPIRED/440',
    HTTP_500 = 'HTTP/SERVER_INTERNAL/500',
    HTTP_501 = 'HTTP/SERVER_IMPLEMENT/501',
    HTTP_502 = 'HTTP/SERVER_BAD_GATEWAY/502',
    HTTP_503 = 'HTTP/SERVER_UNAVAILABLE/503',
    HTTP_504 = 'HTTP/SERVER_GATE_TIMEOUT/504',
    HTTP_505 = 'HTTP/SERVER_HTTP_UNSUPPORTED/505',
}
