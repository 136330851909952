import { Injectable, Inject, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';

import * as Utils from '@shared/core/utils';
import * as Tokens from '@shared/core/tokens';
import * as Services from '@shared/core/services';
import * as actions from '@shared/state/actions';
import { IStateShared } from '@shared/state';

import { Observable, throwError, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    // private _authService: Services.AuthService;
    private _jwtService: Services.JWTService;
    private _inflightAuthRequest: Observable<OLO.Authorization.IJWTokenObject> = null;

    /*
        Authorization: 'Bearer JWTOKEN' - won't be added to these urls
    */
    private _blacklistJWTUrls: RegExp[] = [
        /\/api\/v2\/OnlineOrders\/\d*\/pay$/i,
        /\/api\/v2\/Payments\/[a-z\-0-9]{1,}$/i,
        /\/api\/v2\/Payments\/(converge|cardConnect|paymentExpress)\/settings\/[0-9]{1,}$/i,
        /\/api\/v2\/Payments\/(paymentExpress)\/session\/.{1,}$/i,

        /\/api\/v2\/auth\/login\/member$/i,
    ];

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _injector: Injector,
        private _store: Store<IStateShared>,
    ) { }

    private _handleNext(next: HttpHandler, request: HttpRequest<any>): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((ex: HttpErrorResponse) => {
                    if (ex.status === 0) {
                        console.warn('Connection status: Offline!');
                        this._store.dispatch(actions.AppSettingsSetOnlineStatus({ online: false }));
                    } else {
                        this._store.dispatch(actions.AppSettingsSetOnlineStatus({ online: true }));
                    }

                    return throwError(ex);
                })
            );
    }

    private _isUnauthorized(ex: any): boolean {
        return (typeof ex === 'string' && ex.includes('session expired'))
            || (ex.status === 401)
            || (ex.url !== undefined && ex.url.includes('auth/login/refresh/') && ex.status === 403);
    }

    private _handleUnauthorizedExeption(): Observable<null> {
        // this._authService = this._injector.get(Services.AuthService);
        this._store.dispatch(actions.MemberSignOut({ redirect: '/sign-in/login', resetCart: false }));
        return of(null);
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        /* https://codinglatte.com/posts/angular/refreshing-authorization-tokens-angular-6/ */
        /* https://github.com/mainawycliffe/refreshing-authorization-token-angular-6/blob/master/src/app/http-auth-interceptor.ts */
        /* https://blog.angularindepth.com/top-10-ways-to-use-interceptors-in-angular-db450f8a62d6 */
        switch (true) {
            /* Not API Requests or excluded */
            case request.url.match(/\/api\/v\d\//i) === null || request.headers.get('authExempt') === 'true':
                return this._handleNext(next, request)
                    .pipe(
                        catchError(ex => {
                            if (ex.status === 403) {
                                return this._handleUnauthorizedExeption();
                            }
                            return throwError(ex);
                        })
                    );

            case request.url.match(/\/api\/v1\//i) !== null:
                const appKeyRequest = request.clone({
                    headers: request.headers.set('Authorization', `Key ${this._config.api.key}`)
                });
                return this._handleNext(next, appKeyRequest);



            case request.url.match(/\/api\/v2\//i) !== null:
                let JWTokenRequired: boolean = true;

                for (let i = 0, j = this._blacklistJWTUrls.length; i < j; i++) {
                    const regExp = this._blacklistJWTUrls[i];
                    if (request.url.match(regExp)) {
                        JWTokenRequired = false;
                    }
                }
                if (JWTokenRequired) {
                    this._jwtService = this._injector.get(Services.JWTService);

                    if (!this._inflightAuthRequest) {
                        this._inflightAuthRequest = this._jwtService.getCurrentTokens();
                    }

                    return this._inflightAuthRequest
                        .pipe(
                            switchMap((tokens: OLO.Authorization.IJWTokenObject) => {
                                this._inflightAuthRequest = null;

                                const withJWTRequest = request.clone({
                                    headers: request.headers
                                        .set('Authorization', `Bearer ${Utils.HTTP.cleanHeaders(`${tokens.AccessToken}`)}`)
                                        .set('ClientAppKey', `${Utils.HTTP.cleanHeaders(`${this._config.api.key}`)}`)
                                });

                                return this._handleNext(next, withJWTRequest);
                            }),
                            catchError(ex => {
                                if (ex.hasOwnProperty('status') && ex.status !== 401 && ex.status !== 403) return throwError(ex);

                                if (this._isUnauthorized(ex)) {
                                    return this._handleUnauthorizedExeption();
                                }

                                if (!this._inflightAuthRequest) {
                                    this._inflightAuthRequest = this._jwtService.getCurrentTokens();

                                    return this._inflightAuthRequest
                                        .pipe(
                                            switchMap((tokens: OLO.Authorization.IJWTokenObject) => {
                                                this._inflightAuthRequest = null;

                                                const withJWTRequest = request.clone({
                                                    headers: request.headers
                                                        .set('Authorization', `Bearer ${Utils.HTTP.cleanHeaders(`${tokens.AccessToken}`)}`)
                                                        .set('ClientAppKey', `${Utils.HTTP.cleanHeaders(`${this._config.api.key}`)}`)
                                                });

                                                return this._handleNext(next, withJWTRequest);
                                            }),
                                        );
                                }

                            })
                        );
                }

                const noJWTRequest = request.clone({
                    headers: request.headers
                        .set('ClientAppKey', `${Utils.HTTP.cleanHeaders(`${this._config.api.key}`)}`)
                });

                return this._handleNext(next, noJWTRequest);

            default:
                console.log('api interceptor - unhandeled case', request);
                return this._handleNext(next, request);
        }

    }
}
