import { Inject, Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';

import * as State from '@shared/state';
import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { Observable, of } from 'rxjs';
import { map, filter, take, distinctUntilChanged, auditTime } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AppSettingsController {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _store: Store<State.IStateShared>,
    ) { }

    public get appModeLoyaltyOnly(): boolean {
        return this._config.appMode === IAppMode.LOYALTY_ONLY;
    }

    public get appSettingsLinksFromConfig(): IConfigLink[] {
        return this._config.links || [];
    }

    public onTermsAndConditionsClick(): void {
        this._store
            .pipe(
                select(selectors.getLoyaltyAppSettings),
                take(1),
            ).subscribe(settings => {
                let url: string = settings?.data?.AppSettings?.TermsAndConditionsUrl || this._config.termsAndConditionsUrl || null;

                if (!url) {
                    return console.warn('Link is not defined in app settings nor in config.js file');
                }

                window.open(url, '_blank').focus();
            });
    }

    public onPrivacyPolicyClick(): void {
        this._store
            .pipe(
                select(selectors.getLoyaltyAppSettings),
                take(1),
            ).subscribe(settings => {
                let url: string = settings?.data?.AppSettings?.PrivacyPolicyUrl || this._config.links?.find(obj => obj.name.toLowerCase().includes('privacy'))?.url || null;

                if (!url) {
                    return console.warn('Link is not defined in app settings nor in config.js file');
                }

                window.open(url, '_blank').focus();
            });
    }

    public appSettingsLinks$(): Observable<IConfigLink[]> {
        const extractLink = (name: string, dynamicLinks: IConfigLink[], staticLinks: IConfigLink[]) => {
            return dynamicLinks.find(obj => obj.name.toLowerCase() === name.toLowerCase()) || staticLinks.find(obj => obj.name.toLowerCase() === name.toLowerCase()) || null;
        };

        return this._store
            .pipe(
                select(selectors.getAppSettingsLinks),
                filter(links => links !== null),
                distinctUntilChanged((prev, next) => prev.length === next.length),
                map(dynamiLinks => {
                    const staticLinks = this.appSettingsLinksFromConfig;
                    return [
                        extractLink('Contact Us', dynamiLinks, staticLinks),
                        extractLink('User Agreement', dynamiLinks, staticLinks),
                        extractLink('Privacy Policy', dynamiLinks, staticLinks),
                    ].filter(obj => obj !== null);
                }),
            );
    }

    public appLocationMode$(): Observable<OLO.Enums.APP_MODE> {
        return this._store
            .pipe(
                select(
                    selectors.getAppLocationMode
                ),
            );
    }

    public isVenueMode$(): Observable<boolean> {
        return this.appLocationMode$()
            .pipe(
                map(mode => mode === OLO.Enums.APP_MODE.VENUE)
            );
    }

    public showDistance$(): Observable<boolean> {
        return this.appLocationMode$()
            .pipe(
                map(mode => mode === OLO.Enums.APP_MODE.LOCATION),
            );
    }

    public deviceType$(): Observable<OLO.Enums.DEVICE_TYPE> {
        return this._store
            .pipe(
                select(
                    selectors.getDeviceType
                ),
            );
    }

    public hasInitialized$(): Observable<boolean> {
        return this._store
            .pipe(
                select(
                    selectors.hasAppInitialized
                ),
            );
    }

    public isOnline$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isOnline)
            );
    }

    public getMappedOfflineError(): Observable<OLO.Components.IMappedMessage> {
        return this._store
            .pipe(
                select(selectors.isOnlineMapped)
            );
    }
}
