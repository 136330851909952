import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { Observable, throwError } from 'rxjs';

import { map, catchError, flatMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CardConnectPaymentProviderService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
    ) { }

    public getCardConnectSettings(locationNo: number, clientAppKey: string = this.config.api.key): Observable<APIv2.CardConnectSettingsResponse> {
        return this.httpClient.get<APIv2.CardConnectSettingsResponse>(`${Utils.HTTP.switchApi(this.config.api.base)}/Payments/cardConnect/settings/${locationNo}`);
    }

    public cardConnectSecureCard(apiUrl: string, cardNo: number | string): Observable<PPCardConnect.ISecureResponse> {
        return this.httpClient.get(`${apiUrl}/cardsecure/cs?action=CE&data=${Utils.CreditCards.processCardNumber(cardNo)}&type=xml`, {
            responseType: 'text',
            headers: new HttpHeaders({
                'Content-Type': `text/xml`,
                'Accept': `text/xml`
            }),

        }).pipe(
            map((response: string) => {
                /* '<?xml version="1.0" encoding="UTF-8"?><cardsecure><action>CE</action><data>9607172566116666</data></cardsecure>' */
                /* '<?xml version="1.0" encoding="UTF-8"?><cardsecure><action>ER</action><data>0008::Data not decimal digits</data></cardsecure>' */

                const obj: PPCardConnect.ISecureResponse = Utils.XML.xmlString2Json(response);
                if (obj && obj.hasOwnProperty('cardsecure')) {
                    return obj;
                }

                return null;
            }),
        );
    }

    public requestCardToken(cardNumber: string, expDate: string, locationNo: number = null, defaultSettings: APIv1.CardConnectSettingsResponse = null, clientAppKey: string = this.config.api.key): Observable<PPCardConnect.ISecureResponse> {
        return this.getCardConnectSettings(locationNo, /* tokens.AccessToken,  */clientAppKey)
            .pipe(
                flatMap((settings: APIv2.CardConnectSettingsResponse) => this.cardConnectSecureCard(settings.ApiUrl, cardNumber)),
                map(response => {
                    if (response.cardsecure.action === 'ER') throw response;

                    return response;
                }),
                catchError(ex => throwError(ex))
            );
    }
}
