import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, IOnlineMenu } from '../interface';

const state = createFeatureSelector<IStateShared, IOnlineMenu>('onlineMenu');

export const isDownloadingAnyOnlineMenu = createSelector(
    state,
    onlineMenu => onlineMenu.isDownloading
);

export const getOnlineMenu = createSelector(
    state,
    onlineMenu => onlineMenu
);

export const getOnlineMenuData = createSelector(
    state,
    onlineMenu => onlineMenu.data
);

export const getOnlineMenuPages = createSelector(
    state,
    onlineMenu => onlineMenu.data ? onlineMenu.data.Pages : null
);

export const getOnlineMenuItemById = (id: number) => createSelector(
    state,
    onlineMenu => {
        if (!onlineMenu.data) return null;

        let product: APIv1.OnlineMenuProductResponseModel = null;

        for (let i = 0, j = onlineMenu.data.Pages.length; i < j; i++) {
            if (product) break;

            const page = onlineMenu.data.Pages[i];

            for (let k = 0, m = page.Products.length; k < m; k++) {
                if (page.Products[k].ProductId === id || page.Products[k].MenuFlowId === id) {
                    product = page.Products[k];
                    break;
                }
            }
        }

        return product;
    }
);

export const getOnlineMenuPrice = (id: number) => createSelector(
    getOnlineMenuItemById(id),
    onlineMenu => {
        if (!onlineMenu) return null;

        return onlineMenu.Price;
    }
);
