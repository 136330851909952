import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as State from '@shared/state/interface';
import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { Observable, throwError, forkJoin } from 'rxjs';
import { catchError, delay, map, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class MembersService/*  implements Resolve<Models.IMember> */ {
    /* https://stackoverflow.com/questions/39777220/cannot-read-property-type-of-undefined-ngrx */

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
        public store: Store<State.IStateShared>,
    ) { }

    public getUserData(memberId: number | string): Observable<APIv1.MembersGetMemberById.Responses.$200> {
        return this.httpClient.get<APIv1.MemberModel>(`${this.config.api.base}/members/${memberId}`)
            .pipe(
                delay(1000),
                catchError((ex) => throwError(ex))
            );
    }

    public checkMemberUniqueCode(memberCode: string): Observable<APIv1.MemberModel> {
        return this.httpClient.get<APIv1.MemberModel>(`${this.config.api.base}/members/uniqueCode/${window.encodeURIComponent(memberCode)}`);
    }

    public getMembers(props: APICommon.IMembersGetPaginatedList = {}): Observable<APIv1.MembersGetMembersPaginated.Responses.$200> {
        return this.httpClient.get<APIv1.MembersGetMembersPaginated.Responses.$200>(`${this.config.api.base}/members${Utils.HTTP.object2string(props)}`);
    }

    public validateUserNewProfileData(newMemberModel: APIv1.MemberModel): Observable<boolean> {
        /* When updating user profile details, verify its email and phone no */
        return forkJoin(
            this.getMembers({ mobilePhoneNumber: newMemberModel.MobilePhone }),
            this.getMembers({ memberEmail: newMemberModel.EmailAddress }),
        )
            .pipe(
                map(([byPhone, byEmail]) => {
                    const duplicatedPhoneNo = byPhone.Items.find(member => member.MobilePhone === newMemberModel.MobilePhone && member.MemberId !== newMemberModel.MemberId);
                    const duplicatedEmail = byEmail.Items.find(member => member.EmailAddress === newMemberModel.EmailAddress && member.MemberId !== newMemberModel.MemberId);

                    if (duplicatedEmail || duplicatedPhoneNo) return false;

                    return true;
                })
            );
    }

    public updateUser(memberModel: APIv1.MembersUpdateMember.Parameters.MemberToUpdate): Observable<APIv1.MembersUpdateMember.Responses.$200> {
        return this.httpClient.put<boolean>(`${this.config.api.base}/members`, memberModel)
            .pipe(
                catchError((ex) => throwError(ex))
            );
    }

    public updatePasswordRequest(passwordModel: APIv1.MembersChangeMemberPassword.Parameters.Model, MemberId: number): Observable<APIv1.MembersChangeMemberPassword.Responses.$200> {
        return this.httpClient.put<boolean>(`${this.config.api.base}/members/${MemberId}/changePassword`, passwordModel)
            .pipe(
                catchError((ex) => throwError(ex))
            );
    }

    public resetForgottenPassword(model: APIv1.MembersForgotPasswordReset.Parameters.Model): Observable<APIv1.MembersForgotPasswordReset.Responses.$200> {
        return this.httpClient.put<boolean>(`${this.config.api.base}/members/resetForgottenPassword`, model)
            .pipe(
                catchError((ex) => throwError(ex))
            );
    }

    public confirmEmailAddress(Token: string): Observable<boolean> {
        return this.httpClient.put<boolean>(`${this.config.api.base}/members/confirmMemberEmail`, {
            Token
        })
            .pipe(
                catchError((ex) => throwError(ex))
            );
    }

    public validateMemberPasswordResetToken(Token: string): Observable<boolean> {
        return this.httpClient.post<boolean>(`${this.config.api.base}/members/validateResetForgottenPasswordToken`, {
            Token
        })
            .pipe(
                catchError((ex) => throwError(ex))
            );
    }

    public validateMemberConfirmEmailToken(Token: string): Observable<boolean> {
        return this.httpClient.post<boolean>(`${this.config.api.base}/members/validateEmailConfirmationToken`, {
            Token
        })
            .pipe(
                catchError((ex) => throwError(ex))
            );
    }

    public validateMemberByMemberCard(value: string): Observable<boolean> {
        const queryProperty: string = 'memberCardNumber';
        const modelProperty: string = 'MemberCardNumber';

        return this.httpClient.get<APIv1.MembersGetMembersPaginated.Responses.$200>(`${this.config.api.base}/members?${queryProperty}=${value}`)
            .pipe(
                map(response => {
                    if (!response.hasOwnProperty('Items')) {
                        throw new Error('Response has no "Items" property');
                    }

                    const existingMember: APIv1.MemberModel | undefined = response.Items.find((member: APIv1.MemberModel) => {
                        const propsMatch: boolean = member[modelProperty].toLowerCase() === value.toLowerCase();

                        return propsMatch;
                    });

                    if (existingMember) {
                        return existingMember.IsOnlineRegistered === false; // if false, show error Card is already registered
                    }
                    return null; // Card is not registered in system - show error
                }),
            );
    }

    public validateMemberByProperty(value: string, type: OLO.Enums.LOGIN_TYPE = OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN, memberId: number = null): Observable<boolean> {
        /* Can user sign up/in */

        let queryProperty: string = 'mobilePhoneNumber';
        let modelProperty: string = 'MobilePhone';
        let validatedProperty: string = 'IsMobileValidated';

        switch (type) {
            case OLO.Enums.LOGIN_TYPE.EMAIL_BASED_LOGIN:
                queryProperty = 'memberEmail';
                modelProperty = 'EmailAddress';
                validatedProperty = 'IsEmailValidated';
                break;
        }

        return this.httpClient.get<APIv1.MembersGetMembersPaginated.Responses.$200>(`${this.config.api.base}/members?${queryProperty}=${window.encodeURIComponent(value)}`)
            .pipe(
                map(response => {
                    if (!response.hasOwnProperty('Items')) {
                        throw new Error('Response has no "Items" property');
                    }

                    const existingMember: APIv1.MemberModel | undefined = response.Items.find((member: APIv1.MemberModel) => {
                        const propsMatch: boolean = member[modelProperty].toLowerCase() === value.toLowerCase();
                        return propsMatch && member.IsOnlineRegistered === true;
                    });

                    if (!existingMember) {
                        return true;
                    }
                    if (memberId) {
                        if (existingMember.MemberId === memberId) {
                            return true;
                        }
                    }

                    return existingMember[validatedProperty] ? false : true;
                })
            );
    }

    public isMobileNumberRegistered(phoneNo: string): Observable<boolean> {
        return this.httpClient.get<APIv1.MembersGetMembersPaginated.Responses.$200>(`${this.config.api.base}/members?mobilePhoneNumber=${encodeURIComponent(phoneNo)}`)
            .pipe(
                map(response => {
                    if (!response.hasOwnProperty('Items')) {
                        throw new Error('Response has no "Items" property');
                    }

                    return response.Items.length !== 0;
                })
            );
    }

    public changeMemberPassword(memberId: number, password: string): Observable<boolean> {
        /*
            resetPassword ? so clear!!
            ... and the best one - password string needs to be double quoted https://media.giphy.com/media/LObjDkMUNFU2oRnXve/giphy.gif
        */
        return this.httpClient.put<boolean>(`${this.config.api.base}/members/${memberId}/resetPassword`, `"${password}"`);
    }

    public resetPassword(email: string): Observable<boolean> {
        return this.httpClient.post<boolean>(`${this.config.api.base}/members/forgotPassword`, email);
    }

    public resendEmailConfirmation(model: APIv1.MemberEmailConfirmationRequestModel, LoyaltyAppId: number = null): Observable<boolean> {
        const postModel: APIv1.MemberEmailConfirmationRequestModel = {
            LoyaltyAppId,
            ...model
        };

        return this.httpClient.post<boolean>(`${this.config.api.base}/members/resendEmailConfirmation`, postModel);
    }

    public resendForgotPasswordConfirmation(MemberEmail: string, LoyaltyAppId: number = null): Observable<boolean> {
        const postModel: APIv1.MemberForgotPasswordRequestModel = {
            LoyaltyAppId,
            MemberEmail,
        };
        return this.httpClient.post<boolean>(`${this.config.api.base}/members/resendForgotPassword`, postModel);
    }

    public validateLogin(login: string, loginType: OLO.Enums.LOGIN_TYPE): Observable<APIv1.MemberModel> {
        let paramFieldKey: 'memberCardNumber' | 'mobilePhoneNumber' | 'memberEmail';

        switch (loginType) {
            case OLO.Enums.LOGIN_TYPE.MEMBER_CARD_NUMBER_BASED_LOGIN:
                paramFieldKey = 'memberCardNumber';
                break;
            case OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN:
                paramFieldKey = 'mobilePhoneNumber';
                break;
            case OLO.Enums.LOGIN_TYPE.MEMBER_ID:
                throw new Error('you are trying to validate unsupported login type!');
            default:
                paramFieldKey = 'memberEmail';
                break;
        }

        const processRequest = (userLoginType, loginValue) => response => {
            if (!response.hasOwnProperty('Items')) {
                throw new Error('Response has no "Items" property');
            }

            /* Search for user that matches criteria - email and IsOnlineRegistered flag */

            if (response.Items.length === 0) {
                return null;
            }

            let existingMember: APIv1.MemberModel;

            switch (userLoginType) {
                case OLO.Enums.LOGIN_TYPE.MEMBER_CARD_NUMBER_BASED_LOGIN:
                    existingMember = response.Items.find((member: APIv1.MemberModel) => {
                        return member.MemberCardNumber === loginValue && member.IsOnlineRegistered;
                    });

                    if (!existingMember) {
                        existingMember = response.Items.find((member: APIv1.MemberModel) => {
                            return member.MemberCardNumber === loginValue;
                        });
                    }

                    break;
                case OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN:
                    existingMember = response.Items.find((member: APIv1.MemberModel) => {
                        return member.MobilePhone.toLowerCase() === loginValue.toLowerCase();
                    });

                    if (!existingMember) {
                        existingMember = response.Items.find((member: APIv1.MemberModel) => {
                            return member.MobilePhone === loginValue;
                        });
                    }
                    break;
                default:
                    existingMember = response.Items.find((member: APIv1.MemberModel) => {
                        return member.EmailAddress.toLowerCase() === loginValue.toLowerCase() && member.IsOnlineRegistered;
                    });

                    if (!existingMember) {
                        existingMember = response.Items.find((member: APIv1.MemberModel) => {
                            return member.EmailAddress.toLowerCase() === loginValue.toLowerCase();
                        });
                    }
                    break;
            }

            return existingMember || null;
        };


        const request = (key: 'memberCardNumber' | 'mobilePhoneNumber' | 'memberEmail', value: string): Observable<APIv1.MemberModel> => {
            return this.httpClient.get<APIv1.MembersGetMembersPaginated.Responses.$200>(`${this.config.api.base}/members?${key}=${encodeURIComponent(value)}`)
                .pipe(
                    map(processRequest(loginType, value))
                );
        };

        if (paramFieldKey !== 'mobilePhoneNumber') return request(paramFieldKey, login);

        let [countryId, phoneNo] = login.split(':');
        if (!phoneNo && countryId) {
            /* Case when country Id wasn't provided before : or there was no : in provided format */
            phoneNo = countryId;
            return request(paramFieldKey, phoneNo);
        }
        if (!countryId && phoneNo) {
            /*  or no countries were configured in config.js */
            return request(paramFieldKey, phoneNo);
        }

        const country: ICountry = this.config.countries.find(obj => obj.Id === +countryId);
        if (!country) {
            console.warn(`That's strange - country prefix wasn't found in config.js`, this.config.countries, `by Id: ${countryId}`);
            return request(paramFieldKey, phoneNo);
        }
        return forkJoin([
            request(paramFieldKey, `${country.PhonePrefix}${phoneNo}`),
            request(paramFieldKey, `${phoneNo}`),
        ])
            .pipe(
                map(([prefixResponse, response]) => {
                    return prefixResponse || response;
                }),
                catchError(ex => {
                    console.error(`Error validating member login`, ex);
                    return throwError(ex);
                })
            );
    }

    public apiGetFreeProductsForMemberRequest(memberId: number, redeemed: boolean = false): Observable<APIv1.MemberFreeProductModel[]> {
        return this.httpClient.get<APIv1.MembersGetMemberFreeProducts.Responses.$200>(`${this.config.api.base}/members/${memberId}/FreeProducts?returnRedeemedProducts=${redeemed}`)
            .pipe(
                map(response => response.Items)
            );
    }


    public apiGetLoyaltyProductsForMemberRequest(memberId: number): Observable<APIv1.GetLoyaltyProductProgramTrackingBusinessModel[]> {
        return this.httpClient.get<APIv1.MembersGetLoyaltyProductProgramTrackings.Responses.$200>(`${this.config.api.base}/members/${memberId}/LoyaltyProducts`)
            .pipe(
                map(response => response.Items)
            );
    }

    public apiGetMemberAccountBalance(): Observable<APIv2.MembersGetMemberAccountBalance.Responses.$200> {
        return this.httpClient.get<APIv2.MembersGetMemberAccountBalance.Responses.$200>(`${Utils.HTTP.switchApi(this.config.api.base)}/members/accountBalance`);
    }


    public async resendTemporaryVerificationCode(): Promise<boolean> {
        return new Promise(resolve => {
            this.store
                .pipe(
                    select(selectors.getMemberPhoneNo),
                    take(1),
                ).subscribe(phoneNo => {
                    if (!phoneNo) return resolve(false);

                    this.store.dispatch(actions.MemberVerifyPhoneRestoreFlags());

                    this.store.dispatch(actions.MemberSendPhoneVerificationCodeDataRequest({ phoneNo }));

                    resolve(true);
                });
        });
    }

}
