import { Injectable, Inject, Renderer2, Injector, RendererFactory2 } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';

import * as Decorators from '@shared/core/decorators';
import * as Tokens from '@shared/core/tokens';
import * as State from '@shared/state/interface';
import * as Utils from '@shared/core/utils';

import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ImagesService {

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
        public store: Store<State.IStateShared>,
        public rendererFactory2: RendererFactory2,
    ) { }

    public preloadImageInMemory(imgUrl: string): Observable<boolean> {
        return Observable.create(observer => {
            if (!imgUrl || imgUrl === 'null') {
                observer.next(false);
                observer.complete();
                return;
            }
            let renderer: Renderer2 = this.rendererFactory2.createRenderer(null, null);

            const holder = renderer.createElement('div');
            const imgElem = renderer.createElement('img');
            renderer.setAttribute(imgElem, 'src', imgUrl);
            renderer.appendChild(holder, imgElem);

            imgElem.onload = () => {
                observer.next(true);
                observer.complete();
            };

            imgElem.onerror = () => {
                observer.next(false);
                observer.complete();
            };

            return () => {
                setTimeout(() => {
                    renderer.destroy();
                    renderer = null;
                }, 1000);
            };

        });
    }

    public getImageForOrderType(params: APICommon.IImagesGetParams, ...orderTypes: number[]): Observable<APIv1.ImageUrlModel[]> {
        const stringParams: string = Utils.HTTP.idsToStringParams('orderTypes', orderTypes);
        const sizeParams: string = `${params.width ? `&width=${params.width}` : ''}${params.height ? `&height=${params.height}` : ''}`;
        return this.httpClient
            .get<APIv1.ImageGetOrderTypeImages.Responses.$200>(`${this.config.api.base}/images/orderType?${stringParams}${sizeParams}`);
    }

    public getImageForLocation(locationNo: number, width: number = null, height: number = null): Observable<APIv1.ImageGetLocationImage.Responses.$200> {
        const params: HttpParams = new HttpParams({
            fromObject: ({ width, height } as any)
        });

        return this.httpClient.get<APIv1.ImageGetLocationImage.Responses.$200>(`${this.config.api.base}/images/location/${locationNo}`, { params });
    }

    @Decorators.deprecate()
    public getImagesForLocations(locationNos: number[], width: number = null, height: number = null): Observable<{ locationNo: number, imageUrl: string; }[]> {
        return forkJoin(
            locationNos.map(locationNo =>
                this.getImageForLocation(locationNo, width, height)
                    .pipe(
                        map(imageUrl => ({ locationNo, imageUrl })
                        )
                    )),
        );
    }

    public getImagesForLocationsByType(params: APICommon.IVenueImagesGetParams, ...locationIds: number[]): Observable<APIv1.ImageUrlModel[]> {
        const stringParams: string = Utils.HTTP.idsToStringParams('locationIds', locationIds);
        const sizeParams: string = `${params.width ? `&width=${params.width}` : ''}${params.height ? `&height=${params.height}` : ''}`;
        return this.httpClient
            .get<APIv1.ImageGetLocationsImages.Responses.$200>(`${this.config.api.base}/images/location?${stringParams}&imageType=${params.imageType || 4}${sizeParams}`);


    }

    public getProductImages(params: APICommon.IImagesGetParams, ...productIds: number[]): Observable<APIv1.ImageUrlModel[]> {
        const stringParams: string = Utils.HTTP.idsToStringParams('productIds', productIds);
        return this.httpClient
            .get<APIv1.ImageGetProductImages.Responses.$200>(`${this.config.api.base}/images/Products?${stringParams}&width=${params.width || 150}&heigth=${params.height || 150}`)
            .pipe(
                map(paginatedResult => paginatedResult.Items) /* #FIX: Endpoint is not cool */
            );
    }

    public getImagesForOnlineMenuPages(params: APICommon.IImagesGetParams, imageType: OLO.Enums.IMAGE_TYPE, pageIds: number[]): Observable<APIv1.ImageUrlModel[]> {
        const stringParams: string = Utils.HTTP.idsToStringParams('pageId', pageIds);
        return this.httpClient
            .get<APIv1.ImageGetOnlineMenuPageImages.Responses.$200>(`${this.config.api.base}/images/onlineMenuPage${Utils.HTTP.object2string(params)}&${stringParams}&imageType=${imageType}`);
    }

    public getImagesForOnlineMenuPageProducts(params: APICommon.IImagesGetParams, pageId: number): Observable<APIv1.ImageUrlModel[]> {
        return this.httpClient
            .get<APIv1.ImageGetOnlineMenuPageProductsImages.Responses.$200>(`${this.config.api.base}/images/onlineMenuPageProducts/${pageId}`)
            .pipe(
                map(paginatedResults => paginatedResults.Items),
            );
    }

    public getDietaryTagsImages(params: APICommon.IImagesGetParams, ...dietaryTagIds: number[]): Observable<APIv1.ImageGetDietaryTagsImages.Responses.$200> {
        const stringParams: string = Utils.HTTP.idsToStringParams('dietaryTagId', dietaryTagIds);
        return this.httpClient
            .get<APIv1.ImageGetDietaryTagsImages.Responses.$200>(`${this.config.api.base}/images/dietaryTags?${stringParams}&width=${params.width || 150}&heigth=${params.height || 150}`);

    }

    public getInteracts(params: APICommon.IInteractImagesGetParams, ...productIds: number[]): Observable<APIv1.ImageGetInteractImages.Responses.$200> {
        const stringParams: string = Utils.HTTP.idsToStringParams('productId', productIds);
        return this.httpClient
            .get<APIv1.ImageGetInteractImages.Responses.$200>(`${this.config.api.base}/images/interact${Utils.HTTP.object2string(params)}&${stringParams}`);

    }

    public getMenuFlowImages(params: APICommon.IImagesGetParams, ...menuFlowIds: number[]): Observable<APIv1.ImageUrlModel[]> {
        const stringParams: string = Utils.HTTP.idsToStringParams('menuFlowId', menuFlowIds);
        return this.httpClient
            .get<APIv1.ImageGetMenuFlowsImages.Responses.$200>(`${this.config.api.base}/images/menuFlows?${stringParams}&width=${params.width || 150}&heigth=${params.height || 150}`)
            .pipe(
                map(paginatedResult => paginatedResult.Items) /* #FIX: Endpoint is not cool */
            );
    }

    public getVenueImagesByType(params: APICommon.IVenueImagesGetParams, ...venueIds: number[]): Observable<APIv1.ImageGetVenuesImages.Responses.$200> {
        const stringParams: string = Utils.HTTP.idsToStringParams('venueIds', venueIds);
        return this.httpClient
            .get<APIv1.ImageUrlModel[]>(`${this.config.api.base}/images/venue?${stringParams}&imageType=${params.imageType || 4}&width=${params.width || 150}&height=${params.height || 150}`);
    }

    public getImageForLoyaltyIntroductionPage(pageId: number, params: { width?: number; height?: number; } = {}): Observable<string> {
        return this.httpClient
            .get<APIv1.ImageGetIntroductionPageImages.Responses.$200>(`${this.config.api.base}/images/introductionPage/${pageId}?width=${params.width || 300}&height=${params.height || 300}`)
            .pipe(
                map(response => response.Items[0])
            );
    }

    public getImageForMemberFreeProduct(productId: number, params: { width?: number; height?: number; } = {}): Observable<string> {
        return this.httpClient
            .get<APIv1.ImageGetMemberFreeProductImage.Responses.$200>(`${this.config.api.base}/images/memberFreeProducts/${productId}?width=${params.width || 300}&height=${params.height || 300}`);
    }

    public getMemberCardBarcode(memberId: number, params: { width?: number; height?: number; } = {}, type: OLO.Enums.BARCODE_SYMBOLOGY = OLO.Enums.BARCODE_SYMBOLOGY.CODE_QR): Observable<string> {
        return this.httpClient
            .get<string>(`${this.config.api.base}/images/memberCardBarcodes/${memberId}?barcodeSymbology=${type}&width=${params.width || window.innerWidth}&height=${params.height || window.innerWidth}`);
    }
}
