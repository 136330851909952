import { Injectable, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';
import * as StateModels from '../interface';

import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';
import * as Tokens from '@shared/core/tokens';

import { Observable, of, never, } from 'rxjs';
import { switchMap, tap, distinct, withLatestFrom, filter } from 'rxjs/operators';

@Injectable()
export class CurrentLocationEffects {
    @Effect() public onCartPickupTimeUpdateForceUpdateCurrentLocationPickupTime$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CartPickupTimeUpdate
            ),
            switchMap(action => {
                return of(actions.CurrentLocationPickupTimeSet(action.pickupTime));
            })
        );

    @Effect({ dispatch: false }) public removeLocalStorageDataOnReset$: Observable<never> = this._actions$
        .pipe(
            ofType(
                actions.CurrentLocationReset
            ),
            switchMap(action => {
                Utils.Storage.remove(OLO.Enums.USER_STORAGE.CURRENT_PICKUP_TIME as unknown as string);
                Utils.Storage.remove(OLO.Enums.USER_STORAGE.CURRENT_LOCATION as unknown as string);
                Utils.Storage.remove(OLO.Enums.USER_STORAGE.CURRENT_LOCATION_UPDATE_DATA as unknown as string);
                return never();
            })
        );

    @Effect({ dispatch: false }) public savePickupTimeInLocalStorage$: Observable<never> = this._actions$
        .pipe(
            ofType(
                actions.CurrentLocationPickupTimeSet,
                actions.CurrentLocationFiltersPickupTimeSync,
            ),
            switchMap(({ pickupTime }) => {
                if (!pickupTime) {
                    Utils.Storage.remove(OLO.Enums.USER_STORAGE.CURRENT_PICKUP_TIME as unknown as string);
                    return never();
                }

                const obj = {
                    ...pickupTime,
                    Date: pickupTime.Date.getTime(),
                    PlaceOrderTimeout: pickupTime.Date.getTime(),
                };

                Utils.Storage.set(OLO.Enums.USER_STORAGE.CURRENT_PICKUP_TIME as unknown as string, obj);
                return never();
            })
        );

    @Effect({ dispatch: false }) public onLocationSetSaveItToLocalStorage$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CurrentLocationSet
            ),
            distinct(action => {
                return action.locationNo;
            }),
            switchMap(action => {
                Utils.Storage.set(OLO.Enums.USER_STORAGE.CURRENT_LOCATION as unknown as string, action.locationNo);
                return never();
            })
        );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _actions$: Actions,
        private _store: Store<StateModels.IStateShared>,
    ) { }

}
