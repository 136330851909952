import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CryptoService } from './crypto.shared.service';

@Injectable({
    providedIn: 'root',
})
export class QueryParamsService {
    constructor(
        public cryptoService: CryptoService,
        public router: Router,
    ) { }

    public encryptOrderData(locationNo: number, orderId: number): string {
        return this.cryptoService.encrypt(`${orderId}:${locationNo}`, null, null);
    }

    public decryptOrderData(queryParams: { [key: string]: string; }): { orderId: number, locationNo: number; } {
        const obj = {
            orderId: null,
            locationNo: null,
        };
        try {
            if (!queryParams.order) throw new Error(`no order param provided`);

            const decrypted = this.cryptoService.decrypt(queryParams.order, null, null);
            if (!decrypted) throw new Error('unable to read query params');
            const extracted: string[] = decrypted.split(':');
            obj.orderId = +extracted[0];
            obj.locationNo = +extracted[1];

            if (Number.isNaN(obj.orderId) || Number.isNaN(obj.locationNo)) throw new Error('unable to read query params details');

        } catch (ex) {
            console.error('Error', ex);
            return null;
        }

        return obj;
    }

    public async clearQueryParams(...params): Promise<boolean> {
        const urlArr = window.location.href.split('?');
        if (!urlArr[1]) return false;
        let obj: { [key: string]: null; } = null;

        if (!params || params.length === 0) {
            obj = urlArr[1].split('&').reduce((acc, param) => {
                acc[param.split('=')[0]] = null;
                return acc;
            }, {});
        } else {
            obj = params.reduce((acc, key) => {
                acc[key] = null;
                return acc;
            }, {});
        }

        if (Object.keys(obj).length) {
            return this.router.navigate([], {
                queryParams: obj,
                queryParamsHandling: 'merge',
            });
        }

        return false;
    }
}
