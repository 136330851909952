import { MemoizedSelector, createSelector } from '@ngrx/store';
import * as Utils from '@shared/core/utils';
import * as State from './interface';

import * as fromAlertMessages from './alertMessages/alert-messages.selectors';
import * as fromAppSettings from './appSettings/app-settings.selectors';
import * as fromAvailablePickups from './availablePickups/available-pickups.selectors';
import * as fromCart from './cart/cart.selectors';
import * as fromCartPopup from './cartPopup/cart-popup.selectors';
import * as fromCreditCards from './creditCards/credit-cards.selectors';
import * as fromCurrentLocation from './currentLocation/current-location.selectors';
import * as fromDietaryTagsImages from './dietaryTagsImages/dietary-tags-images.selectors';
import * as fromFreeProductsImages from './freeProductsImages/free-products-images.selectors';
import * as fromGeolocation from './geolocation/geolocation.selectors';
import * as fromHistoryOrders from './historyOrders/history-orders.selectors';
import * as fromIngredients from './ingredients/ingredients.selectors';
import * as fromLatestTransactions from './latestTransactions/latest-transactions.selectors';
import * as fromLoader from './loader/loader.selectors';
import * as fromLocations from './locations/locations.selectors';
import * as fromLocationsFilters from './locationsFilters/locations-filters.selectors';
import * as fromLocationsImages from './locationsImages/locations-images.selectors';
import * as fromLoyaltyIntroductionPages from './loyaltyIntroductionPages/loyalty-introduction-pages.selectors';
import * as fromLoyaltyIntroductionPagesImages from './loyaltyIntroductionPagesImages/loyalty-introduction-pages-images.selectors';
import * as fromLoyaltyMessages from './loyaltyMessages/loyalty-messages.selectors';
import * as fromLoyaltyProductPrograms from './loyaltyProductPrograms/loyalty-product-programs.selectors';
import * as fromMemberCardBarcodesImages from './memberCardBarcodesImages/member-card-barcodes-images.selectors';
import * as fromMembers from './members/members.selectors';
import * as fromMenuFlowDefaultActivations from './menuFlowDefaultActivations/menu-flow-default-activations.selectors';
import * as fromMenuFlowImages from './menuFlowImages/menu-flow-images.selectors';
import * as fromMenuFlows from './menuFlows/menu-flows.selectors';
import * as fromModals from './modals/modal.selectors';
import * as fromOnlineMenu from './onlineMenu/online-menu.selectors';
import * as fromOnlineMenuFilters from './onlineMenuFilters/online-menu-filters.selectors';
import * as fromOnlineMenuPagesImages from './onlineMenuPagesImages/online-menu-pages-images.selectors';
import * as fromOnlineMenuProductsImages from './onlineMenuProductsImages/online-menu-products-images.selectors';
import * as fromOnlineOrder from './onlineOrder/online-order.selectors';
// import * as fromOrderingTimeInfo from './orderingTimeInfo/ordering-time-info.selectors';
import * as fromOrderTypes from './orderTypes/order-types.selectors';
import * as fromOrderTypesImages from './orderTypesImages/order-types-images.selectors';
import * as fromPayment from './payment/payment.selectors';
import * as fromProductImages from './productImages/product-images.selectors';
import * as fromReorder from './reorder/reorder.selectors';
import * as fromRouter from './router/router.selectors';
import * as fromTopBar from './topBar/top-bar.selectors';
import * as fromVenuesImages from './venuesImages/venues-images.selectors';
import * as fromWizzard from './wizzard/wizzard.selectors';

export * from './alertMessages/alert-messages.selectors';
export * from './appSettings/app-settings.selectors';
export * from './availablePickups/available-pickups.selectors';
export * from './cart/cart.selectors';
export * from './cartPopup/cart-popup.selectors';
export * from './creditCards/credit-cards.selectors';
export * from './currentLocation/current-location.selectors';
export * from './dietaryTagsImages/dietary-tags-images.selectors';
export * from './freeProductsImages/free-products-images.selectors';
export * from './geolocation/geolocation.selectors';
export * from './historyOrders/history-orders.selectors';
export * from './ingredients/ingredients.selectors';
export * from './latestTransactions/latest-transactions.selectors';
export * from './loader/loader.selectors';
export * from './locations/locations.selectors';
export * from './locationsFilters/locations-filters.selectors';
export * from './locationsImages/locations-images.selectors';
export * from './loyaltyIntroductionPages/loyalty-introduction-pages.selectors';
export * from './loyaltyIntroductionPagesImages/loyalty-introduction-pages-images.selectors';
export * from './loyaltyMessages/loyalty-messages.selectors';
export * from './loyaltyProductPrograms/loyalty-product-programs.selectors';
export * from './memberCardBarcodesImages/member-card-barcodes-images.selectors';
export * from './members/members.selectors';
export * from './menuFlowDefaultActivations/menu-flow-default-activations.selectors';
export * from './menuFlowImages/menu-flow-images.selectors';
export * from './menuFlows/menu-flows.selectors';
export * from './modals/modal.selectors';
export * from './onlineMenu/online-menu.selectors';
export * from './onlineMenuFilters/online-menu-filters.selectors';
export * from './onlineMenuPagesImages/online-menu-pages-images.selectors';
export * from './onlineMenuProductsImages/online-menu-products-images.selectors';
export * from './onlineOrder/online-order.selectors';
// export * from './orderingTimeInfo/ordering-time-info.selectors';
export * from './orderTypes/order-types.selectors';
export * from './orderTypesImages/order-types-images.selectors';
export * from './payment/payment.selectors';
export * from './productImages/product-images.selectors';
export * from './reorder/reorder.selectors';
export * from './router/router.selectors';
export * from './topBar/top-bar.selectors';
export * from './venuesImages/venues-images.selectors';
export * from './wizzard/wizzard.selectors';

export const getOnlineMenuPageDetails = (pageId: number, imageType: OLO.Enums.IMAGE_TYPE = OLO.Enums.IMAGE_TYPE.ForWeb):
    MemoizedSelector<State.IStateShared, OLO.Components.IOnlineMenuPageDetails<State.IOnlineMenuPageImage>> => createSelector(
        fromOnlineMenu.getOnlineMenu,
        fromOnlineMenuPagesImages.getOnlineMenuPageImage(pageId, imageType),
        (onlineMenu, image) => {
            if (!onlineMenu || !onlineMenu.data) return null;

            const foundPage = onlineMenu.data.Pages.find(obj => obj.Id === pageId);
            if (!foundPage) return null;

            return {
                title: foundPage.Name,
                description: foundPage.Description,
                image: image
            } as OLO.Components.IOnlineMenuPageDetails<State.IOnlineMenuPageImage>;
        }
    );
export const getOnlineMenuPagesFiltered = createSelector(
    fromOnlineMenu.getOnlineMenu,
    fromOnlineMenuFilters.getOnlineMenuFiltersSearchInput,
    (onlineMenu, searchInput) => {
        const pages = onlineMenu.data?.Pages || null;
        if (!searchInput || !pages) {
            return pages;
        }

        return pages.reduce((pagesAcc, page) => {
            if (page.Products.length === 0) return pagesAcc;

            /* Page check */
            const pageMatches = Utils.Strings.searchValueStringInObject(searchInput, page, 'Name', 'Description');
            if (pageMatches) {
                if (page.Products.length) {
                    pagesAcc.push(page);
                }
                return pagesAcc;
            }

            /* Check products */
            const productsFiltered = page.Products.filter(product => {
                const productMatches = Utils.Strings.searchValueStringInObject(searchInput, product, 'PosDisplay', 'PosDescription');
                if (productMatches) return true;
                /* Check tags if no match in prop values */
                const tagMatches = !product.Tags ? false : product.Tags.some(t => Utils.Strings.searchValueStringInObject(searchInput, t, 'Name'));

                return tagMatches;
            });

            if (productsFiltered.length) {
                pagesAcc.push({
                    ...page,
                    Products: productsFiltered
                });
            }

            return pagesAcc;

        }, [] as APIv1.OnlineMenuPageResponseModel[]);
    }
);

export const getOrderTypesForCartsLocation = createSelector(
    fromCart.getCartLocationNo,
    fromOrderTypes.getOrderTypesForAllLocations,
    (locationNo, orderTypes) => {
        if (!locationNo || orderTypes.length === 0) return null;

        const foundTypes = orderTypes.find(obj => obj.locationNo === locationNo && obj.data && obj.data.length > 0 && obj.isDownloading === false);
        if (!foundTypes) return null;

        return foundTypes.data;
    }
);

export const isLoadingLoyaltyInfoForCurrentMember = createSelector(
    fromMembers.isMemberLoading,
    fromMembers.isDownloadingLoyaltyProducts,
    fromMembers.isDownloadingFreeProducts,
    fromLoyaltyProductPrograms.isDownloadingAnyProgram,
    (memberIsLoading, isLoadingLoyaltyProducts, isLoadingFreeProducts, isLoadingPrograms) => memberIsLoading
        || isLoadingLoyaltyProducts
        || isLoadingFreeProducts
        || isLoadingPrograms
);

export const getLoyaltyFreeProductsForCurrentMember = createSelector(
    fromMembers.isMemberAuthorizedJWT,
    fromMembers.getMemberState,
    fromLoyaltyProductPrograms.getAllValidLoyaltyPrograms,
    fromLoyaltyProductPrograms.isDownloadingAnyProgram,
    fromMembers.freeValidProducts,
    fromMembers.loyaltyProducts,
    fromLoyaltyProductPrograms.getLoyaltyProductPrograms,
    (isAuthorized, member, programs, isDownloadingAnyProgram, freeProducts, loyaltyProducts, programsState) => {
        if (!isAuthorized
            || !member.data
            || !freeProducts
            || !loyaltyProducts
            || !loyaltyProducts.data) return null;

        return freeProducts.reduce((acc, product) => {
            if (product.MemberId !== member.data.MemberId) return acc;

            const pr: APIv1.GetLoyaltyProductProgramBusinessModel = programs.find(obj => obj.ProductId === product.ProductId);
            if (!pr) return acc;
            const loyaltyProduct = loyaltyProducts.data.find(obj => obj.PLU === pr.PLU); /* ROTFL ROTFL ROTFL... */
            if (!loyaltyProduct) return acc;

            const codeName: string = programsState.find(obj => obj.data.some(innerObj => innerObj.Id === pr.Id)).ProgramName;

            return [
                ...acc,
                {
                    isDownloading: isDownloadingAnyProgram || member.freeProducts.isDownloading || member.loyaltyProducts.isDownloading,
                    freeProduct: [
                        product
                    ],
                    loyaltyProduct,
                    program: pr,
                    codeName,
                }
            ];
        }, []) as OLO.Ordering.ILoyaltyFreeProductItemModel[];
    }
);

export const getLoyaltyFreeProductsForCurrentMemberGrouped = createSelector(
    getLoyaltyFreeProductsForCurrentMember,
    items => {
        return items !== null ? items.reduce((acc, item) => {
            const codeName = item.codeName;
            const qty = item.program.ProductQauantityToIssue;

            const existingItem = acc.find(obj => obj.codeName === codeName);
            if (!existingItem) {
                acc.push({
                    ...item,
                    program: { ...item.program },
                    freeProduct: [...item.freeProduct],
                    loyaltyProduct: { ...item.loyaltyProduct }
                });
            } else {
                existingItem.freeProduct.push({ ...item });
                existingItem.program.ProductQauantityToIssue = existingItem.program.ProductQauantityToIssue + qty;
            }

            return acc;

        }, []) as OLO.Ordering.ILoyaltyFreeProductItemModel[] : [];
    }
);

export const getLoyaltyProgramProductItemsForCurrentMember = createSelector(
    fromMembers.isMemberAuthorizedJWT,
    fromMembers.getCurrentMember,
    fromLoyaltyProductPrograms.getAllValidLoyaltyPrograms,
    fromLoyaltyProductPrograms.isDownloadingAnyProgram,
    fromMembers.loyaltyProducts,
    fromLoyaltyProductPrograms.getLoyaltyProductPrograms,
    (isAuthorized, member, programs, isDownloadingAnyProgram, stateProducts, programsState) => {
        if (!isAuthorized || !member || programs.length === 0) return null;

        const emptyPrograms = programs.reduce((acc, program) => {
            const codeName: string = programsState.find(obj => obj.data?.some(innerObj => innerObj.Id === program.Id) || false)?.ProgramName;
            return [
                ...acc,
                {
                    isDownloading: isDownloadingAnyProgram || stateProducts.isDownloading,
                    product: null,
                    program,
                    codeName,
                }
            ];
        }, []) as OLO.Ordering.ILoyaltyProgramProductItemModel[];

        const withProducts = stateProducts?.data?.reduce((acc, product) => {
            if (product.MemberId !== member.MemberId) return acc;

            const pr: APIv1.GetLoyaltyProductProgramBusinessModel = programs.find(obj => obj.Id === product.LoyaltyProductProgramId);
            if (!pr) return acc;

            const codeName: string = programsState.find(obj => obj.data.some(innerObj => innerObj.Id === pr.Id)).ProgramName;
            return [
                ...acc,
                {
                    isDownloading: isDownloadingAnyProgram || stateProducts.isDownloading,
                    product,
                    program: pr,
                    codeName,
                }
            ];
        }, []) as OLO.Ordering.ILoyaltyProgramProductItemModel[];

        return emptyPrograms.map(empty => withProducts?.find(obj => obj.codeName === empty.codeName) || empty).sort((a, b) => b.codeName.length - a.codeName.length);
    }
);

export const getOrderingTimeInfoByCartLocation = createSelector(
    fromCart.getCartLocationNo,
    // fromOrderingTimeInfo.getOrderingTimeInfoState,
    fromLocations.getLocationsState,
    (locationNo, locationState) => {
        if (!locationNo || !locationState.data) return null;

        return locationState.data?.find(obj => obj.LocationNo === locationNo)?.OrderingTimeInfo;
    }
);

export const getOrderingTimeInfoForTodayByCartLocation = createSelector(
    getOrderingTimeInfoByCartLocation,
    (orderingTimeInfo) => {
        if (!orderingTimeInfo) return null;
        const date = new Date();
        const currDay = date.getDay();

        return orderingTimeInfo.find(obj => obj.DayOfWeek === currDay);
    }
);

export const currentLocationNoByRoute = createSelector(
    fromAppSettings.isInVenueMode,
    fromRouter.getCurrentRoute,
    fromLocations.getLocationsState,
    (isVenueMode, route, locations) => {
        if (!route || !locations || locations.isDownloading || locations.data === null) return undefined;

        if (!isVenueMode) return route.params.id ? +route.params.id : null;

        const foundLocation: APIv1.LocationBusinessModel = locations.data.find(location => {
            if (location.OnlineOrderingStatus !== 0 || location.LocationOLOIsActive !== true || !location.LocationFriendlyName || !route.params.LocationFriendlyName) return false;

            return location.LocationFriendlyName.toLowerCase().replace(/\s/gi, '') === route.params.LocationFriendlyName.toLowerCase();
        });

        return foundLocation ? foundLocation.LocationNo : null;
    }
);

/*
    Check if user can ENTER location and can ORDER from it
    even if it's closed but will be open later.
*/

export const getOrderSummary: MemoizedSelector<State.IStateShared, OLO.Ordering.IOrderSummary> = createSelector(
    fromOnlineOrder.getOnlineOrderState,
    fromOnlineOrder.getOnlineOrderTotalTax,
    ({ recalculateRequest, orderType }, Tax) => {
        if (!recalculateRequest.data || Tax === null) return null;
        return {
            Subtotal: recalculateRequest.data?.TotalNettValue,
            Tax,
            Total: recalculateRequest.data?.TotalLeftToPay,
            Surcharges: (orderType?.Surcharges || []).sort((a, b) => (a.DisplayIndex || 0) - (b.DisplayIndex || 0))
        };
    }
);

export const memberHasAvailableBalanceToPayForCartOrder = createSelector(
    fromMembers.getMemberState,
    getOrderSummary,
    (member, summary) => {
        if (!summary || !member.data || member.accountBalance.isDownloading === true || !member.accountBalance.data) return null;
        return (summary.Total) <= member.accountBalance.data?.AvailableBalance;

    }
);

export const getPickupTimeLabelForCart = createSelector(
    fromLocationsFilters.getLocationFilters,
    fromCart.getCartPickupTimeName,
    (filters, cartLabel) => {
        if (!cartLabel && !filters) return null;

        if (cartLabel) return cartLabel;

        if (!filters.pickupTime) return filters.pickupMode.Name;

        return filters.pickupTime.Name;
    }
);

export const locationWithValidation = (locationNo: number): MemoizedSelector<State.IStateShared, APIv1.LocationBusinessModel> => createSelector(
    fromLocations.getLocationsState,
    fromCurrentLocation.getCurrentLocationValidationState,
    (locations, validation) => {
        if (!locations || !locations.data || !locationNo || !validation || !validation.hasSucceeded) return null;

        const foundLocation: APIv1.LocationBusinessModel = locations.data.find(obj => obj.LocationNo === locationNo);
        if (!foundLocation) return null;

        return foundLocation;
    }
);


// export const locationNameWithValidation = (locationNo: number): MemoizedSelector<State.IStateShared, string> => createSelector(
//     locationWithValidation(locationNo),
//     location => location ? location.LocationFriendlyName : null
// );

export const currentLocationWithValidation: MemoizedSelector<State.IStateShared, APIv1.LocationBusinessModel> = createSelector(
    fromLocations.getLocationsState,
    fromCurrentLocation.getCurrentLocationNo,
    fromCurrentLocation.getCurrentLocationValidationState,
    (locations, locationNo, validation) => {
        if (!locations || !locations.data || !locationNo || !validation || !validation.hasSucceeded) return null;

        const foundLocation: APIv1.LocationBusinessModel = locations.data.find(obj => obj.LocationNo === locationNo);
        if (!foundLocation) return null;

        return foundLocation;
    }
);

export const currentLocationNameWithValidation: MemoizedSelector<State.IStateShared, string> = createSelector(
    currentLocationWithValidation,
    location => location ? location.LocationFriendlyName : null
);

export const canShowOnlineMenuForCurrentLocation = createSelector(
    fromCurrentLocation.getCurrentLocationNo,
    fromCurrentLocation.getCurrentLocationValidationState,
    fromOnlineMenu.getOnlineMenu,
    (locationNo, validation, onlineMenu) => {
        if (!onlineMenu
            || !onlineMenu.data
            || !onlineMenu.data
            || !onlineMenu.locationNo
            || !locationNo
            || (onlineMenu.locationNo !== locationNo)
            || onlineMenu.isDownloading
            || !validation
            || validation.isValidating
            || validation.hasFailed
            || !validation.hasSucceeded) return false;

        return true;
    }
);

export const wizzardMenuFlowPagesReports: MemoizedSelector<State.IStateShared, State.IWizzardPageReport[]> = createSelector(
    fromWizzard.getWizzard,
    fromMenuFlows.getMenuFlows,
    (wizzard, menuFlows) => {
        if (!wizzard || !wizzard.itemsMenuFlow) return null;

        const menuFlowId: number = wizzard.itemsMenuFlow.MenuFlowId;
        const locationNo: number = wizzard.itemsMenuFlow.LocationNo;
        const menuFlow: State.IMenuFlows = menuFlows.find(obj => obj.LocationNo === locationNo && obj.MenuFlowId === menuFlowId);

        if (!menuFlow || !menuFlow.data) return null;

        const reports = [];

        for (let i = 0, j = wizzard.itemsMenuFlow.Pages.length; i < j; i++) {
            const wizzardPage: State.IWizzardMenuFlowPage = wizzard.itemsMenuFlow.Pages[i];
            const menuFlowPage: APIv1.MenuFlowPage = menuFlow.data.Pages.find(obj => obj.PageIdentifier === wizzardPage.PageIdentifier);
            if (!menuFlowPage) return;

            const totalProductsQuantity = wizzardPage.Products.reduce((acc, product) => acc + product.Quantity, 0);

            reports.push({
                pageIdentifier: wizzardPage.PageIdentifier,
                isComplete: totalProductsQuantity >= menuFlowPage.PageMinQuantity && totalProductsQuantity <= menuFlowPage.PageMaxQuantity,
                errors: wizzard.errors && wizzard.errors.length > 0
            });
        }

        return reports;
    }
);

export const getStatsForWizzardItem = (isMenuFlow: boolean, label: string = 'From: '): MemoizedSelector<State.IStateShared, OLO.Components.IStatsComponentInput> => createSelector(
    fromOnlineMenu.getOnlineMenu,
    fromWizzard.getWizzardSimpleItem,
    fromWizzard.getWizzardMenuFlow,
    fromDietaryTagsImages.getDietaryTagImages,
    (onlineMenu, singleProduct, menuFlow, images) => {
        if (!onlineMenu || !onlineMenu.data || isMenuFlow && !menuFlow || !isMenuFlow && !singleProduct || !images) return null;

        const itemId: number = isMenuFlow ? menuFlow.MenuFlowId : singleProduct.ProductId;

        let stats: OLO.Components.IStatsComponentInput = null;

        onlineMenu.data.Pages.forEach(page => {
            page.Products.forEach(product => {
                if (stats) return;

                if (isMenuFlow) {
                    if (product.MenuFlowId === itemId) {

                        stats = {
                            label: product.Price ? label : null,
                            price: product.Price,
                            kilojoules: product.Kilojoules,
                            cals: Math.ceil(product.Kilojoules / 4.184),
                        };
                    }
                    return;
                }

                if (product.ProductId === itemId) {
                    console.log(product);
                    stats = {
                        label: null,
                        price: product.Price,
                        kilojoules: product.Kilojoules,
                        cals: Math.ceil(product.Kilojoules / 4.184),
                    };
                }
            });
        });

        return stats;
    }
);

export const getDietaryTagImagesForWizzardItem = (isMenuFlow: boolean): MemoizedSelector<State.IStateShared, State.IDietaryTagImage[]> => createSelector(
    fromOnlineMenu.getOnlineMenu,
    fromWizzard.getWizzardSimpleItem,
    fromWizzard.getWizzardMenuFlow,
    fromDietaryTagsImages.getDietaryTagImages,
    (onlineMenu, singleProduct, menuFlow, images) => {
        if (!onlineMenu || !onlineMenu.data || isMenuFlow && !menuFlow || !isMenuFlow && !singleProduct || !images) return null;

        const itemId: number = isMenuFlow ? menuFlow.MenuFlowId : singleProduct.ProductId;

        let dietaryTags: APIv1.TagModel[];

        onlineMenu.data.Pages.forEach(page => {
            page.Products.forEach(product => {
                if (dietaryTags) return;

                if (isMenuFlow) {
                    if (product.MenuFlowId === itemId && product.DietaryTags) {
                        dietaryTags = [...product.DietaryTags];
                    }
                    return;
                }

                if (product.ProductId === itemId && product.DietaryTags) {
                    dietaryTags = [...product.DietaryTags];
                }
            });
        });

        if (!dietaryTags) return null;
        return images.reduce((acc, tagImg) => {
            const tag = dietaryTags.find(obj => obj.Id === tagImg.Id);
            if (tag) {
                return [...acc, { ...tagImg, Name: tag.Name }];
            }
            return acc;
        }, []);

    }
);

export const getImageForWizzardItem = (isMenuFlow: boolean) => createSelector(
    fromOnlineMenu.getOnlineMenu,
    fromWizzard.getWizzardSimpleItem,
    fromWizzard.getWizzardMenuFlow,
    fromOnlineMenuProductsImages.getImagesForOnlineMenuPages,
    fromOnlineMenuProductsImages.isDownloadingAnyOnlineMenuPageImages,
    fromCart.getCart,
    (onlineMenu, singleProduct, menuFlow, images, isDownloadingAnything, cart) => {
        if (isDownloadingAnything
            || !images
            || isMenuFlow && !menuFlow
            || !isMenuFlow && !singleProduct) {
            return null;
        }

        const itemId: number = isMenuFlow ? menuFlow.MenuFlowId : singleProduct.ProductId;
        const isEditing: boolean = isMenuFlow ? menuFlow._Id ? true : false : singleProduct._Id ? true : false;
        const onlineMenuSelection: APIv1.OnlineMenuResponseModel = isEditing && cart.onlineMenu ? cart.onlineMenu : onlineMenu.data;

        if (!onlineMenuSelection) return null;

        let foundInOnlineMenuItemId: number;
        onlineMenuSelection.Pages.forEach(page => {
            page.Products.forEach(product => {
                if (foundInOnlineMenuItemId) return;

                if (isMenuFlow) {
                    if (product.MenuFlowId === itemId) {
                        foundInOnlineMenuItemId = product.Id;
                    }
                    return;
                }

                if (product.ProductId === itemId) {
                    foundInOnlineMenuItemId = product.Id;
                }
            });
        });


        let foundImage: string = null;

        for (let i = 0, j = images.length; i < j; i++) {
            if (foundImage) break;

            let page = images[i];

            if (page.data) {
                for (let k = 0, l = page.data.length; k < l; k++) {
                    if (foundImage) break;

                    let img = page.data[k];

                    if (img.ParentId === foundInOnlineMenuItemId && img.ImageUrl) {
                        foundImage = img.ImageUrl;
                    }
                }
            }
        }

        return foundImage;
    }
);

// export const __TEMP_SOLUTION_canEditItemInCart = createSelector(
//     fromCurrentLocation.getCurrentLocationNo,
//     fromCart.getCartLocationNo,
//     fromOnlineMenu.getOnlineMenu,
//     (currentLocationNo, cartLocationNo, { locationNo }) => currentLocationNo !== null && currentLocationNo === cartLocationNo && cartLocationNo !== null && locationNo !== null && locationNo === cartLocationNo
// );

// export const canShowAddNewCardForm = createSelector(
//     fromCreditCards.isCardListEmpty,
//     fromCreditCards.showAddCardForm,
//     fromMembers.isGuestModeEnabled,
//     fromCreditCards.getCreditCardIsRequestInError,
//     (isCardListEmpty, showAddCardForm, isGuestModeEnabled, hasErrors) => {
//         return !isCardListEmpty && showAddCardForm && !isGuestModeEnabled || hasErrors;
//     }
// );

// export const canShowPaymentMethods = createSelector(
//     fromCreditCards.showAddCardForm,
//     fromMembers.isGuestModeEnabled,
//     (showAddCardForm, isGuestModeEnabled) => !isGuestModeEnabled && !showAddCardForm
// );

// export const canPayWithCreditCard = (state: State.IStateShared): boolean => { /* FIX THIS SELECTOR */
//     /* Guest case */
//     if (state.creditCards.token.isGettingToken) return null;
//     if (state.creditCards.token.hasFailed) return false;
//     if (state.creditCards.token.hasSucceeded) return true;

//     /* Member case */
//     if (state.members.data) {
//         if (!state.creditCards.activeCardId || state.creditCards.activeCardToken) return false;
//     }

//     return false;
// };

export const canPostOnlineOrder = (state: State.IStateShared): boolean => {
    const isCreditCardValid: boolean = (state.creditCards.activeCardId !== null || state.creditCards.activeCardToken !== null);
    const isMemberValid: boolean = (state.members.data !== null || state.members.guestData !== null);
    const isCartValid: boolean = (state.cart !== null && (state.cart.itemsSimple.length !== 0 || state.cart.itemsMenuFlow.length !== 0));
    const isOrderDataValid: boolean = (state.onlineOrder.createRequest.hasSucceeded === false && state.onlineOrder.recalculateRequest.hasSucceeded === true && state.onlineOrder.recalculateRequest.data !== null);
    const isPaymentPossible: boolean = (state.payment.hasSucceeded === false && state.payment.data === null && state.payment.PaymentStepStatus !== 'complete' && state.payment.PaymentStepStatus !== 'payment_status_check');

    return isCreditCardValid && isMemberValid && isCartValid && isOrderDataValid && isPaymentPossible;
};

export const __DEMO__canPostOnlineOrder = (state): boolean => { /* FIX THIS SELECTOR */
    const isMemberValid: boolean = (state.members.data !== null || state.members.guestData !== null);
    const isCartValid: boolean = (state.cart !== null && (state.cart.itemsSimple.length !== 0 || state.cart.itemsMenuFlow.length !== 0));

    return isMemberValid && isCartValid;
};

// export const getCurrentLocationFriendlyName = createSelector(
//     fromCurrentLocation.getCurrentLocationNo,
//     fromLocations.getLocationsState,
//     (currentLocation, locations) => {
//         if (!currentLocation || !locations.data) return null;

//         const foundLocation = locations.data.find(obj => obj.LocationNo === currentLocation);
//         return foundLocation ? foundLocation.LocationFriendlyName : null;
//     }
// );

export const getCartMenuFlowTotalQuantityForCurrentLocation = (menuFlowId: number) => createSelector(
    fromCart.getCart,
    fromCart.getCartMenuFlowQuantityByMenuFlowId(menuFlowId),
    fromCurrentLocation.getCurrentLocationNo,
    (cart, total, currentLocation) => {
        if (!cart || !cart.locationNo || !currentLocation || cart.locationNo !== currentLocation) return null;
        return total;
    }
);

export const getCartSimpleProductTotalQuantityForCurrentLocation = (productId: number) => createSelector(
    fromCart.getCart,
    fromCart.getCartSimpleItemQuantity(productId),
    fromCurrentLocation.getCurrentLocationNo,
    (cart, total, currentLocation) => {
        if (!cart || !cart.locationNo || !currentLocation || cart.locationNo !== currentLocation) return null;
        return total;
    }
);

// export const isLoadingDataForMenuFlow = (menuFlowId: number, locationNo: number) => createSelector(
//     fromMenuFlows.getMenuFlow(menuFlowId, locationNo),
//     fromMenuFlowDefaultActivations.isDownloadingAnyMenuFlowDefaultActivation,
//     fromIngredients.isDownloadingAnyIngredientModifiers,
//     fromOnlineMenu.isDownloadingAnyOnlineMenu,
//     (menuFlowState, isDownloadingActivation, isDownloadingIngredients, isDownloadingOnlineMenu) => {

//         if (!menuFlowState) return true;

//         return menuFlowState.isDownloading || isDownloadingActivation || isDownloadingIngredients || isDownloadingOnlineMenu;

//     }
// );

export const isPaymentDisabledForMember = createSelector(
    fromMembers.getMemberState,
    fromMembers.isMemberLoading,
    fromCreditCards.getCardState,
    (membersState, memberLoading, cardsState) => {
        return memberLoading ||
            membersState.isDownloading ||
            membersState.isGuestModeEnabled && !membersState.guestData ||
            membersState.isGuestModeEnabled && !cardsState.activeCardToken ||
            !membersState.isGuestModeEnabled && !cardsState.activeCardId && !cardsState.activeCardToken ||
            !membersState.isGuestModeEnabled && !membersState.data;

    }
);

export const isPaymentDisabledForAccountCharge = (config: IConfig) => createSelector(
    fromMembers.isAccountSet(config),
    fromCreditCards.getCardState,
    memberHasAvailableBalanceToPayForCartOrder,
    (isAccountSet, cardsState, accountHasSufficientCredits) => {
        if (cardsState.activeCardId !== -1) return false;
        if (!isAccountSet) return true;
        return !accountHasSufficientCredits;
    }
);

// export const __DEMO__isPaymentDisabled = createSelector(
//     fromCart.getCartTotalQuantity,
//     fromOnlineOrder.isOnlineOrderLoading,
//     fromOnlineOrder.isOrderTypeValidForPosting,
//     fromOnlineOrder.isOnlineOrderRecalculating,
//     fromMembers.isMemberLoading,
//     fromPayment.getPaymentStatus,
//     fromPayment.isPaying,
//     fromMembers.isGuestModeEnabled,
//     (cartTotalQuantity, isOrderLoading, isOrderTypeValid, isRecalculating, isMemberLoading, paymentStatus, isPaying, isGuestModeEnabled) => {
//         return !cartTotalQuantity ||
//             isOrderLoading ||
//             !isOrderTypeValid ||
//             isRecalculating ||
//             isMemberLoading ||
//             (paymentStatus === 'pending' || paymentStatus === 'success') ||
//             isPaying;
//     }
// );

export const isCartLocationsPickupsCalculating = createSelector(
    fromCart.getCartLocationNo,
    // fromOrderingTimeInfo.getOrderingTimeInfoState,
    fromAvailablePickups.getAvailablePickupTimesForAllLocations,
    (locationNo, availablePickups) => {
        if (!locationNo) return null;
        // const foundLocationPickupTimes = pickupTimes.find(item => item.locationNo === locationNo);
        const foundAvailablePickups = availablePickups.find(item => item.locationNo === locationNo);

        if (!foundAvailablePickups) return null;

        return foundAvailablePickups.isCalculating;
    }
);

export const getCurrentLocationBusinessModel = createSelector(
    fromCurrentLocation.getCurrentLocationNo,
    fromLocations.getLocationsState,
    (locationNo, locations) => {
        if (!locationNo || !locations || !locations.data) return null;

        const currentLocation = locations.data.find(obj => obj.LocationNo === locationNo);
        return currentLocation || null;
    }
);

export const getLocationImageForCurrentLocation = createSelector(
    fromCurrentLocation.getCurrentLocationNo,
    fromLocationsImages.getAllLocationImages,
    (locationNo, images) => {
        if (!locationNo || !images || !images.forWeb) return null;
        const foundImage = images.forWeb.find(obj => obj.Id === locationNo);

        return foundImage && foundImage.data ? foundImage.data.ImageUrl : null;
    }
);

export const getOpenStatusForCurrentLocation = createSelector(
    fromCurrentLocation.getCurrentLocationNo,
    fromLocations.getOrderInfoCompleteForLocationsByDate(),
    (locationNo, orderInfo) => {
        if (!locationNo || !orderInfo) return false;

        const foundStatusObj = orderInfo.find(obj => obj.locationNo === locationNo && obj.hasSucceeded === true);
        if (!foundStatusObj) return false;
        return foundStatusObj.IsOpen;
    }
);

// export const getCurrentPickupTimeLabel = createSelector(
//     fromCurrentLocation.getCurrentPickupTime,
//     getOpenStatusForCurrentLocation,
//     (pickupTime, isOpen) => {
//         if (pickupTime === null) return null;

//         if (!pickupTime) return 'select';

//         if (pickupTime.IsAsap && isOpen) {
//             return pickupTime.MinutesFromNow ? `ASAP - ${pickupTime.MinutesFromNow} mins` : `ASAP`;
//         }

//         return pickupTime.Name;
//     }
// );

// export const getCurrentPickupTimeLabelCustomized = (defaultLabel: string = 'select', asapPrefix: string = 'ASAP ( ', asapSuffix: string = 'min )', asapDefault: string = 'ASAP') => createSelector(
//     fromCurrentLocation.getCurrentPickupTime,
//     getOpenStatusForCurrentLocation,
//     (pickupTime, isOpen) => {
//         if (pickupTime === null) return null;

//         if (!pickupTime) return defaultLabel;

//         if (pickupTime.IsAsap && isOpen) {
//             return pickupTime.MinutesFromNow ? `${asapPrefix}${pickupTime.MinutesFromNow}${asapSuffix}` : `${asapDefault}`;
//         }

//         return pickupTime.Name;
//     }
// );

export const isLocationLoading = (locationNo: number): MemoizedSelector<State.IStateShared, boolean> =>
    createSelector(
        fromAvailablePickups.getAvailablePickupTimesForLocation(locationNo),
        fromLocations.getOrderInfoCompleteForLocationByDate(locationNo),
        (availablePickups, orderInfo) => {
            return !availablePickups || availablePickups.isCalculating || orderInfo.isDownloading;
        }
    );

export const isLoadingDataForAnyLocation: MemoizedSelector<State.IStateShared, boolean> = createSelector(
    fromAvailablePickups.isCalculatingAvailablePickups,
    // fromOrderingTimeInfo.isDownloadingAnyOrderingTimeInfo,
    fromLocations.getLocationsState,
    (isCalculating, /* isDownloadingOrderInfo,  */{ isDownloading }) => {
        return isCalculating === true || isDownloading === true /* || isDownloadingOrderInfo === true */;
    }
);

// export const isLoadingOrderSummary: MemoizedSelector<State.IStateShared, boolean> = createSelector(
//     isLoadingDataForAnyLocation,
//     fromHistoryOrders.isLoadingAnyHistoryOrder,
//     (loadingLocations, loadingOrders) => loadingLocations === true || loadingOrders === true
// );

export const getCurrentLocationDetails = createSelector(
    fromCurrentLocation.getCurrentLocationNo,
    fromLocations.getLocations,
    (locationNo, locations) => {
        if (!locationNo || !locations) return null;
        return locations.find(obj => obj.LocationNo === locationNo);
    }
);


export const getPeriodsForCurrentLocation = (format: string = 'ddd, D MMM', prefixes: boolean = true) => createSelector(
    getCurrentLocationDetails,
    (location) => {
        if (!location) return null;
        const periods = Utils.Dates.getFilteredOrderingTimeInfo(location)
            .reduce((acc, obj) => {
                acc.push(Utils.Dates.createPeriodObject(obj, format, prefixes));
                return acc;
            }, []);

        return periods;
    }
);

export const getCurrentLocationFuturePickupList = (config: IConfig, format: string = 'ddd, D MMM', prefixes: boolean = true) => createSelector(
    getPeriodsForCurrentLocation(format, prefixes),
    getCurrentLocationDetails,
    (periods, location) => {
        if (!periods || !location) return null;

        return periods.reduce((acc, period) => {
            return [
                ...acc,
                ...Utils.Dates.generatePickupTimesFutureList(period, {
                    location,
                    openingHours: location.OrderingTimeInfo,
                    orderTimeoutBufferMins: config.pickups.orderTimeoutBufferMins,
                    startBufferMins: config.pickups.startBufferMins,
                    nextTick: config.pickups.nextTick,
                })
            ];
        }, [] as OLO.Ordering.IPickupTime[]);
    }
);


// export const getAvailablePickupTimesForCurrentLocation = (futureOrders: boolean = false): MemoizedSelector<State.IStateShared, OLO.Ordering.IPickupTime[]> => createSelector(
//     fromCurrentLocation.getCurrentLocationNo,
//     fromCurrentLocation.getCurrentPickupTime,
//     fromAvailablePickups.getAvailablePickupTimesForAllLocations,
//     getCurrentLocationFuturePickupList(),
//     (locationNo, pickupTime, pickupsArr, futurePickupList) => {
//         if (locationNo === 1) {
//             console.log(pickupTime, pickupsArr, futurePickupList);
//         }
//         if (pickupTime?.IsToday) {
//             if (!locationNo || pickupsArr === null || pickupsArr.length === 0) return null;

//             const foundObj = pickupsArr.find(obj => obj.hasSucceeded === true && obj.locationNo === locationNo && obj.data !== null && obj.data.length > 0);
//             return foundObj ? foundObj.data : (futureOrders ? futurePickupList : null);
//         } else {
//             return futureOrders ? futurePickupList : null;
//         }
//     }
// );



export const getLocationPeriods = (locationNo: number, format: string = 'ddd, D MMM', prefixes: boolean = true) => createSelector(
    fromLocations.getLocationDetails(locationNo),
    fromAvailablePickups.getAvailablePickupTimesForLocation(locationNo),
    (location, availablePickups) => {
        if (!location || !location.OrderingTimeInfo) return null;

        return Utils.Dates.getFilteredOrderingTimeInfo(location)
            .reduce((acc, obj) => {
                const period = Utils.Dates.createPeriodObject(obj, format, prefixes);
                const isToday = Utils.Dates.isToday(obj.Date);

                if (isToday) {
                    const todaysList = availablePickups?.data?.filter(a => a.IsToday === true);
                    if (!todaysList || !todaysList?.length) return acc;
                }


                acc.push(period);
                return acc;
            }, []);
    }
);


export const getLocationFuturePickupList = (
    config: IConfig,
    locationNo: number,
    format: string = 'ddd, D MMM',
    prefixes: boolean = true
) => createSelector(
    getLocationPeriods(locationNo, format, prefixes),
    fromLocations.getLocationDetails(locationNo),
    (periods, location) => {
        if (!periods || !location) return null;

        return periods.reduce((acc, period) => {
            return [
                ...acc,
                ...Utils.Dates.generatePickupTimesFutureList(period, {
                    location,
                    openingHours: location.OrderingTimeInfo,
                    orderTimeoutBufferMins: config.pickups.orderTimeoutBufferMins,
                    startBufferMins: config.pickups.startBufferMins,
                    nextTick: config.pickups.nextTick,
                })
            ];
        }, [] as OLO.Ordering.IPickupTime[]);
    }
);


export const getAvailablePickupTimesWithFutureForLocation = (config: IConfig, locationNo: number, futureOrders: boolean = false): MemoizedSelector<State.IStateShared, OLO.Ordering.IPickupTime[]> => createSelector(
    fromCurrentLocation.getCurrentPickupTime,
    fromAvailablePickups.getAvailablePickupTimesForLocation(locationNo),
    getLocationFuturePickupList(config, locationNo),
    (pickupTime, availablePickups, futurePickupList) => {
        let arr: OLO.Ordering.IPickupTime[] = [];
        // if (locationNo === 5) {
        //     console.log(availablePickups, futurePickupList, new Date());
        // }
        if (!locationNo) return arr;

        if (availablePickups.data) {
            arr = [
                ...availablePickups.data
            ];
        }

        if (futureOrders && futurePickupList) {
            futurePickupList.forEach(pickup => {
                if (arr.some(obj => obj.Id === pickup.Id)) return;

                arr.push(pickup);
            });
        }

        return arr;
        // if (pickupTime?.IsToday || !pickupTime) {
        //     if (locationNo === 2) {
        //         console.log(availablePickups.data);
        //     }
        //     if (!locationNo || availablePickups === null || !availablePickups || (!futureOrders && !availablePickups.data)) return arr;

        //     if (availablePickups && availablePickups.data) {
        //         arr = [
        //             ...arr,
        //             ...availablePickups.data
        //         ];
        //     }

        //     if (futureOrders && futurePickupList) {
        //         arr = [
        //             ...arr,
        //             ...futurePickupList
        //         ];
        //     }

        //     return arr;
        // }

        // if (futureOrders && futurePickupList) {
        //     arr = [
        //         ...arr,
        //         ...futurePickupList
        //     ];
        // }

        return arr;
    }
);

export const getFilteredLocations = (sortTag: string = null, filterParams: OLO.Common.ILocationFilterParams = {}): MemoizedSelector<State.IStateShared, APIv1.LocationBusinessModel[]> =>
    createSelector(
        fromLocations.getLocationsState,
        fromLocationsFilters.getLocationFilters,
        fromAvailablePickups.getAvailablePickupTimesForAllLocations,
        (locations, filters, availablePickups) => {
            if (!locations.data.length || locations.isDownloading || locations.hasFailed) return [];

            const filterBy: OLO.Common.ILocationFilterParams = {
                bySearch: true,
                byOpenStatus: true,
                byPickupTime: true,
                ...filterParams,
            };

            let clientTime: Date | string = new Date();
            const weekDay: number = clientTime.getDay();
            const isFutureSearch = filters.pickupTime ? filters.pickupTime.IsToday === false : false;
            const filtered = locations.data.filter(location => {
                const l = locations.data.find(obj => obj.LocationNo === location.LocationNo);

                const orderInfoForLocation = Utils.Dates.getFilteredOrderingTimeInfo(l);
                if (!orderInfoForLocation) return false;

                // let timeInfo: APIv1.LocationOrderingTimeInfoModel = isFutureSearch ?
                //     orderInfoForLocation.find(obj => obj.Date.split('T')[0] === filters.pickupTime.DateLocalISO.split('T')[0])
                //     : orderInfoForLocation.find(obj => obj.DayOfWeek === weekDay);
                const dateToSearch = filters.pickupTime && filters.pickupTime.DateLocalISO || Utils.Dates.getLocalISOFormatDate(new Date());
                let timeInfo: APIv1.LocationOrderingTimeInfoModel = orderInfoForLocation.find(Utils.Dates.datesMatchByDayCallback(dateToSearch));

                const isLocationConfiguredForFutureOrder = location.FutureOrderingMinDaysAhead !== null
                    && location.FutureOrderingMaxDaysAhead !== null;


                if (!timeInfo && filterBy.byPickupTime) return false;

                // if (location.LocationNo === 1) {
                //     console.log('timeinfo', timeInfo, filterBy);
                // }
                /* Check hours - if no filters - compare to current time, else, get time from filters */
                if (filterBy.byPickupTime && filters.pickupTime) {
                    clientTime = filters.pickupTime.DateLocalISO;
                }

                const locationPickupTimesObj: State.IAvailablePickup = availablePickups.find(obj => obj.locationNo === location.LocationNo && obj.hasSucceeded === true && obj.data.length > 0);
                const locationPickupTimesList: OLO.Ordering.IPickupTime[] = locationPickupTimesObj ? locationPickupTimesObj.data : [];
                const locationAsapObj: OLO.Ordering.IPickupTime = locationPickupTimesList[0] || null;
                const openTime: number = timeInfo ? Utils.Dates.createHoursIntFromDate(timeInfo.OpeningTime) : null;
                const closeTime: number = timeInfo ? Utils.Dates.createHoursIntFromDate(timeInfo.ClosingTime) : null;

                /* AOLO-273 - ASAP - if location is still open and preperation doesn't exceed location open time */
                if (filterBy.byPickupTime) {
                    if (!filters.pickupTime || filters.pickupTime.IsAsap) {
                        if (locationAsapObj) {
                            const preperationTime: number = Utils.Dates.createHoursIntFromDate(locationAsapObj.Id + locationAsapObj.MinutesFromNow * 60 * 1000);

                            if (preperationTime >= closeTime) return false;
                        }
                    }



                    /* AOLO-276 - validate open status when filters is set to ASAP */
                    if (!filters.pickupTime || filters.pickupTime.IsAsap) {
                        const isLocationOpen: boolean = Utils.Dates.isHourInHoursRange(Utils.Dates.getLocalISOFormatDate(new Date()), timeInfo.OpeningTime, timeInfo.ClosingTime, 'from');

                        if (!isLocationOpen) {
                            return false;
                        }
                    }
                }

                if (filterBy.byOpenStatus) {
                    const timeToCheck: number = Utils.Dates.createHoursIntFromDate(clientTime);

                    if (timeToCheck < openTime || timeToCheck > closeTime) {
                        return false;
                    }
                }

                if (filterBy.bySearch) {

                    let searchMatch: boolean = true;
                    if (filters.search) {
                        const searchString: string = filters.search.toLowerCase();
                        const _s = (fieldName: string) => (location[fieldName] !== null ? location[fieldName].toLowerCase().includes(searchString) : false);

                        searchMatch = _s('LocationFriendlyName')
                            || _s('LocationDescription')
                            || _s('StreetAddress')
                            || _s('Suburb')
                            || _s('PostCode')
                            || _s('LocationNotes');
                    }
                    if (!searchMatch) return false;
                }

                if (filterBy.byPickupTime) {
                    /* Validate filter pickup time */
                    const availablePickup = availablePickups.find(pickup => pickup.locationNo === location.LocationNo && pickup.hasSucceeded === true && pickup.data !== null);
                    if (!isFutureSearch && (!availablePickup || !availablePickup.data || availablePickup.data.length === 0)) {
                        return false;
                    }

                    if (!isFutureSearch && !isLocationConfiguredForFutureOrder) {
                        if (!filters.pickupTime) return true; /* Pure ASAP */

                        return availablePickup.data.some(pickup => pickup.Id === filters.pickupTime.Id);
                    }

                    const isOk = Utils.Dates.isFuturePickupTimeValid(
                        filters.pickupTime || null,
                        timeInfo,
                        location.FutureOrderingMinDaysAhead,
                        location.FutureOrderingMaxDaysAhead
                    );

                    return isOk;

                    // if (filters.pickupTime) {
                    //     if (!isFutureSearch && !isLocationConfiguredForFutureOrder) {
                    //         const pickupTimeId = filters.pickupTime.Id;
                    //         const foundMatchingPickupTime = availablePickup.data.find(pickup => pickup.Id === pickupTimeId);
                    //         if (!foundMatchingPickupTime) {
                    //             return false;
                    //         }
                    //         return true;
                    //     } else {
                    //         /* Check if selected pickup time is in range of open +1 - close-1 hours */
                    //         return Utils.Dates.isFuturePickupTimeValid(
                    //             filters.pickupTime,
                    //             timeInfo,
                    //             location.FutureOrderingMinDaysAhead,
                    //             location.FutureOrderingMaxDaysAhead
                    //         );
                    //     }
                    // }

                    // if (!filters.pickupTime && isLocationConfiguredForFutureOrder) {
                    //     /* Default case when pickup Time is not selected */
                    //     return Utils.Dates.isFuturePickupTimeValid(
                    //         null,
                    //         timeInfo,
                    //         location.FutureOrderingMinDaysAhead,
                    //         location.FutureOrderingMaxDaysAhead
                    //     );
                    // }
                }
                return true;
            });

            return !sortTag ? filtered : filtered.sort((a, b) => {
                const aTag = !!a.LocationTags.find(tag => tag.TagName === sortTag);
                const bTag = !!b.LocationTags.find(tag => tag.TagName === sortTag);

                switch (true) {
                    case aTag && bTag:
                        return 0;
                    case aTag && !bTag:
                        return -1;
                    case !aTag && bTag:
                        return 1;
                }
            });
        }
    );

// export const isOnlineMenuMenuFlowItemLoading = (menuFlowId: number, locationNo: number): MemoizedSelector<State.IStateShared, boolean> =>
//     createSelector(
//         fromMenuFlows.getMenuFlow(menuFlowId, locationNo),
//         fromMenuFlowDefaultActivations.getMenuFlowDefaultActivation(menuFlowId),
//         (menuFlow, activation) => {
//             if (!menuFlow || !menuFlow.data) return true; /* data not ready, waiting for action dispatch */

//             if (!activation) return false; /* no need to show loader for enhanced menuflow */

//             return menuFlow.isDownloading || activation.isDownloading;
//         }
//     );


export const getState = (state: State.IStateShared): State.IStateShared => state;

export const getMenuFlowDetailsByWizzard = (state: State.IStateShared): APIv1.MenuFlowDetailsModel => {
    if (!state.wizzard.itemsMenuFlow) return null;
    const { MenuFlowId, LocationNo } = state.wizzard.itemsMenuFlow;
    const menuFlow = state.menuFlows.find(menuFlowObj => menuFlowObj.MenuFlowId === MenuFlowId && menuFlowObj.LocationNo === menuFlowObj.LocationNo);
    return menuFlow ? menuFlow.data : null;
};

// export const getMenuFlowByWizzard = (state: State.IStateShared): State.IMenuFlows => {
//     if (!state.wizzard.itemsMenuFlow) return null;
//     const { MenuFlowId, LocationNo } = state.wizzard.itemsMenuFlow;
//     const menuFlow = state.menuFlows.find(menuFlowObj => menuFlowObj.MenuFlowId === MenuFlowId && menuFlowObj.LocationNo === menuFlowObj.LocationNo);
//     return menuFlow ? menuFlow : null;
// };

/* Get price for product in menuflow including special price conditions */
export const getNextPriceForMenuFlowProduct = (productId: number, pageIdentifier: number): MemoizedSelector<State.IStateShared, number> =>
    createSelector(
        getMenuFlowDetailsByWizzard,
        fromWizzard.getWizzardMenuFlowPageProducts(pageIdentifier),
        (menuFlow, wizzardPageProducts) => { /* TODO: move this logic to utils Pricing */
            if (!menuFlow) return null;

            const pageDetails: APIv1.MenuFlowPage = menuFlow.Pages.find(Page => Page.PageIdentifier === pageIdentifier);
            if (!pageDetails) return null;

            const product: APIv1.MenuFlowProduct = pageDetails.Products.find(productItem => productItem.ProductId === productId);

            if (!product) return null;

            if (product.ExcludeFromSpecialPricing) {
                return product['OverridedPrice'] !== null ? product['OverridedPrice'] : product['OriginalPrice'];
            }

            const sortDetails = Utils.Pricing.sortDetails(pageDetails);
            if (sortDetails.property === 'default') {
                if (menuFlow.OverridePrice !== null) {
                    return product.OverridedPrice || 0;
                } else {
                    return product.OverridedPrice !== null ? product.OverridedPrice : product.OriginalPrice;
                }
            }

            const isPageSpecialPricingLimitReached: boolean = Utils.Pricing.isPageSpecialPricingLimitReached(pageDetails, wizzardPageProducts as APIv1.MenuFlowProduct[]);

            if (isPageSpecialPricingLimitReached) {

                // if (sortDetails.property === 'default') {/* AOLO-169 fix */
                //     return product['OverridedPrice'] !== null ? product['OverridedPrice'] : product['OriginalPrice'];
                // }

                return product[sortDetails.property] !== null ? product[sortDetails.property] : product['OriginalPrice'];
            }

            return pageDetails.SpecialPrice;

        }
    );


/* Get modal data for menu flow */
export const getModalDataForOnlineMenuItem = (itemId: number, isMenuFlow: boolean): MemoizedSelector<State.IStateShared, OLO.Components.Modals.IModalDynamicStateForOnlineMenuItem<State.IMenuFlowImage>> =>
    createSelector(
        fromOnlineMenu.getOnlineMenuItemById(itemId),
        fromCart.getCartOnlineMenuItemById(itemId),
        fromMenuFlowImages.getImageForMenuFlow(itemId),
        fromWizzard.getWizzard,
        (onlineMenu, cartOnlineMenu, image, wizzard) => {
            if (!wizzard || isMenuFlow && !wizzard.itemsMenuFlow || !isMenuFlow && !wizzard.itemsSimple) return null;

            const isEditing = isMenuFlow ? !!wizzard.itemsMenuFlow['_Id'] : !!wizzard.itemsSimple['_Id'];

            if (!isEditing && !onlineMenu || isEditing && !cartOnlineMenu) return null;

            const selectedOnlineMenu: APIv1.OnlineMenuProductResponseModel = isEditing ? cartOnlineMenu : onlineMenu;

            return {
                PosDisplay: selectedOnlineMenu.PosDisplay,
                PosDescription: selectedOnlineMenu.PosDescription,
                Image: isMenuFlow ? image : null,
                Stats: {
                    price: selectedOnlineMenu.Price,
                    cals: selectedOnlineMenu.Kilojoules,
                }
            };
        }
    );

export const getStatsForSingleProduct = (productId: number): MemoizedSelector<State.IStateShared, OLO.Components.IStatsComponentInput> =>
    createSelector(
        fromOnlineMenu.getOnlineMenuItemById(productId),
        (product) => {
            if (!product) return null;
            return {
                price: product.Price,
                kilojoules: product.Kilojoules,
                cals: Math.ceil(product.Kilojoules / 4.184),
            };
        }
    );

// export const getModalDataForMenuFlowUpsell = (menuFlowId: number): MemoizedSelector<State.IStateShared, OLO.Components.Modals.IModalDynamicStateForOnlineMenuItem<State.IMenuFlowImage>> =>
//     createSelector(
//         getMenuFlowDetailsByWizzard,
//         fromMenuFlowImages.getImageForMenuFlow(menuFlowId),
//         (menuFlow, image) => {
//             if (!menuFlow) return null;
//             return {
//                 PosDisplay: menuFlow.MenuFlowDescription,
//                 PosDescription: menuFlow.MenuFlowNotes || null,
//                 Image: image,
//                 Stats: null
//             };
//         }
//     );

export const getMenuFlowDetailsSepcificPageByWizzard = (pageIdentifier: number): MemoizedSelector<State.IStateShared, APIv1.MenuFlowPage> => createSelector(
    getMenuFlowDetailsByWizzard,
    menuFlow => {
        if (!menuFlow) return null;

        return menuFlow.Pages.find(page => page.PageIdentifier === pageIdentifier);
    }
);

export const getMenuFlowDetailsPagesByWizzard = createSelector(
    getMenuFlowDetailsByWizzard,
    menuFlow => {
        if (!menuFlow) return null;

        return menuFlow.Pages;
    }
);

// export const getWizzardMenuFlowPageLimits = (pageIdentifier: number) =>
//     createSelector(
//         getMenuFlowDetailsSepcificPageByWizzard(pageIdentifier),
//         page => {
//             if (!page) return null;

//             return {
//                 MaxQuantity: page.PageMaxQuantity,
//                 MinQuantity: page.PageMinQuantity,
//             };
//         }
//     );

export const isWizzardPageLimitReached = (pageIdentifier: number): MemoizedSelector<State.IStateShared, boolean> =>
    createSelector(
        getMenuFlowDetailsSepcificPageByWizzard(pageIdentifier),
        fromWizzard.getWizzardMenuFlowPageItemsTotalQuantity(pageIdentifier),
        (page, currentTotalQuantity) => {
            if (!page || !Number.isInteger(currentTotalQuantity)) return null;

            return currentTotalQuantity >= page.PageMaxQuantity;
        }
    );

// export const canIncrementProduct = (pageIdentifier: number, product: APIv1.MenuFlowProduct): MemoizedSelector<State.IStateShared, boolean> =>
//     createSelector(
//         getMenuFlowDetailsSepcificPageByWizzard(pageIdentifier),
//         fromWizzard.getWizzardMenuFlowPageItemsTotalQuantity(pageIdentifier),
//         fromWizzard.getWizzardMenuFlowProductTotalQuantity(pageIdentifier, product.ProductId),
//         (page, currentTotalQuantity, productTotalQuantity) => {
//             if (!page || !Number.isInteger(currentTotalQuantity)) return null;
//             const canIncrementProductCheck: boolean = productTotalQuantity < product.MaximumQuantity;
//             const canAddToPage: boolean = currentTotalQuantity < page.PageMaxQuantity;
//             return canIncrementProductCheck && canAddToPage;
//         }
//     );

export const isWizzardPageOneAllowed = (pageIdentifier: number): MemoizedSelector<State.IStateShared, boolean> =>
    createSelector(
        getMenuFlowDetailsSepcificPageByWizzard(pageIdentifier),
        pageDetails => pageDetails ? pageDetails.PageMaxQuantity === 1 : null
    );

export const getCurrentLocationNoWithMemberId: MemoizedSelector<State.IStateShared, {
    locationNo: number;
    memberId: number;
}> = createSelector(
    fromCurrentLocation.getCurrentLocationNo,
    fromMembers.getCurrentMember,
    (locationNo, member) => ({
        locationNo,
        memberId: member ? member.MemberId : null
    })
);

// export const getImageForItem = (productItem: APIv1.OnlineMenuProductResponseModel): MemoizedSelector<State.IStateShared, State.IProductImage | State.IMenuFlowImage> => {
//     return createSelector(
//         fromProductImages.getImageForProduct(productItem.ProductId),
//         fromMenuFlowImages.getImageForMenuFlow(productItem.MenuFlowId),
//         (productImage, menuFlowImage) => {
//             if (productItem.MenuFlowId) {
//                 return menuFlowImage;
//             }
//             return productImage;
//         }
//     );
// };

/*
    These will help to select item and setup wizzard/stage.
    If item is already in cart, it will be taken from cart, else
    form either from onlineMenu state if it's a single product or from menuFlows state if it's a menu flow
*/
// export const getSimpleItemFromCartOrOnlineMenu = (productId: number): MemoizedSelector<State.IStateShared, APIv1.OnlineMenuProductResponseModel | State.ICartSimpleItem> => createSelector(
//     fromCart.getCartSimpleItemByProductId(productId),
//     fromOnlineMenu.getOnlineMenuItemById(productId),
//     (cartItem, onlineMenuItem) => cartItem ? cartItem : onlineMenuItem
// );

export const getCartMenuFlowDescription = (tempId: number): MemoizedSelector<State.IStateShared, string> =>
    createSelector(
        fromCart.getCartMenuFlowById(tempId),
        menuFlow => {
            if (!menuFlow) return null;
            return Utils.Items.generateCartMenuFlowDescription(menuFlow);
        }
    );

// export const getOnlineOrderMenuFlowDescription = (onlineOrderId: number, orderMenuFlowId: number): MemoizedSelector<State.IStateShared, string> =>
//     createSelector(
//         fromHistoryOrders.getHistoryOrder(onlineOrderId),
//         order => {
//             if (!order || !order.data) return null;

//             const foundMenuFlow: APIv1.OnlineOrderMenuFlowActivation = order.data.MenuFlowActivations.find(menuFlow => menuFlow.Id === orderMenuFlowId);
//             if (!foundMenuFlow) return null;

//             return Utils.Items.generateOnlineOrderMenuFlowDescription(foundMenuFlow);
//         }
//     );

export const getCartLocationFriendlyName: MemoizedSelector<State.IStateShared, string> = createSelector(
    fromCart.getCart,
    fromLocations.getLocations,
    (cart, locations) => {
        if (!cart || !cart.locationNo || !locations) return null;
        const foundLocation = locations.find(location => location.LocationNo === cart.locationNo);

        return foundLocation ? foundLocation.LocationFriendlyName : null;
    }
);

export const canNavigateToCheckoutAuthorizedOnly: MemoizedSelector<State.IStateShared, boolean> = createSelector(
    fromCart.isCartEmpty,
    fromCurrentLocation.getCurrentLocationNo,
    fromCurrentLocation.getCurrentPickupTime,
    fromMembers.isGuestModeEnabled,
    fromMembers.isMemberAuthorizedJWT,
    (isCartEmpty, currentLocationNo, pickupTime, isGuestModeEnabled, isMemberAuthorizedJWT) => {
        if (!isGuestModeEnabled && !isMemberAuthorizedJWT) { /* AOLO-316 fix - prevent from entering order summary directly because isGuestModeEnabled flag is not set  when page reloads */
            return false;
        }

        if (isCartEmpty === true) {
            return false;
        }

        if (currentLocationNo === null || currentLocationNo === 0) {
            return false;
        }

        if (pickupTime === null) {
            return false;
        }

        return true;
    }
);

export const canNavigateToCheckoutUnauthorized: MemoizedSelector<State.IStateShared, boolean> = createSelector(
    fromCart.isCartEmpty,
    fromCurrentLocation.getCurrentLocationNo,
    fromCurrentLocation.getCurrentPickupTime,
    (isCartEmpty, currentLocationNo, pickupTime) => {
        if (isCartEmpty === true) {
            return false;
        }

        if (currentLocationNo === null || currentLocationNo === 0) {
            return false;
        }

        if (pickupTime === null) {
            return false;
        }

        return true;
    }
);

export const isMenuFlowProductSelectable = (pageIdentifier: number, productId: number, productState: number = null) => createSelector(
    isWizzardPageOneAllowed(pageIdentifier),
    isWizzardPageLimitReached(pageIdentifier),
    fromWizzard.getWizzardMenuFlowProductTotalQuantity(pageIdentifier, productId),
    (isPageOneAllowed, pageLimitReached, productQty) => {
        if (isPageOneAllowed) return true;
        const isClickable: boolean = productQty === 0 && pageLimitReached === false;

        return productState === null ? isClickable : isClickable && productState === 0;
    }
);

export const productActionToTake = (pageIdentifier: number, productId: number) => createSelector(
    isMenuFlowProductSelectable(pageIdentifier, productId),
    isWizzardPageLimitReached(pageIdentifier),
    fromWizzard.getWizzardMenuFlowProductTotalQuantity(pageIdentifier, productId),
    isWizzardPageOneAllowed(pageIdentifier),
    (canSelect, pageLimitReached, productQty, isPageOneAllowed) => {
        if (!canSelect) return null;
        if (isPageOneAllowed) {
            if (pageLimitReached && productQty !== 0) {
                return 'remove';
            }

            if (pageLimitReached && productQty === 0) {
                return 'replace';
            }

            if (!pageLimitReached) {
                return 'add';
            }
        } else {
            if (!pageLimitReached) {
                return 'add';
            }

            return null;
        }
    }
);

export const isPaymentProcessValid = createSelector(
    fromPayment.getPaymentState,
    fromOnlineOrder.getOnlineOrderState,
    (payment, onlineOrder) => {
        if (payment.Errors.length > 0) return false;
        if (!onlineOrder.recalculateRequest.data) return false;

        return true;
    }
);

export const routeIsLocationsSearchPage = (config: IConfig) => createSelector(
    fromRouter.getCurrentRoute,
    fromAppSettings.getAppLocationMode,
    (route, locationMode) => {
        if (route === null || locationMode === null) return null;
        if (locationMode === OLO.Enums.APP_MODE.LOCATION) {
            return route.url === '/locations';
        }

        return route.url === `/${config.venue.name}`;
    }
);

export const routeIsLocationDetailsPage = (config: IConfig, LOCATION_DETAILS_REGEXP: RegExp = /\/locations\/\d+\/details\??/gim) => createSelector(
    fromRouter.getCurrentRoute,
    fromAppSettings.getAppLocationMode,
    (route, locationMode) => {
        if (route === null || locationMode === null) return null;

        if (locationMode === OLO.Enums.APP_MODE.LOCATION) {
            const matchFound: RegExpMatchArray = route.url.match(LOCATION_DETAILS_REGEXP);
            return !!matchFound;
        }
        return route.params.LocationFriendlyName !== undefined && route.url ? route.url.includes(config.venue.name) : false;
    }
);

export const routeIsAccountPage = (ACCOUNT_REGEXP: RegExp = /\/account(\/[a-zA-Z\/]{1,})?$/i) => createSelector(
    fromRouter.getCurrentRoute,
    route => route === null ? null : route.url.match(ACCOUNT_REGEXP) !== null ? true : false
);

export const routeIsErrorPage = (ERROR_REGEXP: RegExp = /\/404$/i) => createSelector(
    fromRouter.getCurrentRoute,
    route => route === null ? null : route.url.match(ERROR_REGEXP) !== null ? true : false
);

export const getOrderErrorsMapped = createSelector(
    fromAppSettings.isOnlineMapped,
    fromPayment.getPaymentErrorsMapped,
    fromOnlineOrder.onlineOrderErrorsMapped,
    (offlineErrors, paymentErrors, onlineOrders) => {
        return offlineErrors || paymentErrors || onlineOrders;
    }
);
export const isPaymentDisabled = (config: IConfig) => createSelector(
    getOrderErrorsMapped,
    fromCart.getCartTotalQuantity,
    fromOnlineOrder.isPaymentDisabledForOnlineOrderValidation,
    isPaymentDisabledForMember,
    fromPayment.isPaymentDisabledForPayments,
    fromCreditCards.isPaymentDisabledForCards,
    isPaymentDisabledForAccountCharge(config),
    (errors, cartTotalQuantity, orderPaymentDisabled, memberPaymentDisabled, paymentDisabledForPayments, cardsPaymentDisabled, accountChargePaymentDisabled) => {
        return !cartTotalQuantity ||
            paymentDisabledForPayments ||
            orderPaymentDisabled ||
            memberPaymentDisabled ||
            cardsPaymentDisabled ||
            errors;
    }
);

export const getOrderingTimeInfoForCartPickupLocationNo = (locationNo: number) => createSelector(
    fromCart.getCart,
    fromLocations.getLocationDetails(locationNo),
    (cart, locations) => {
        const timeinfo = locations?.OrderingTimeInfo;
        if (!timeinfo) return null;

        const isValid = timeinfo.find(Utils.Dates.datesMatchByDayCallback(cart.pickupTime.DateLocalISO));
        return isValid;
    }
);


export const generatedPickupTimesForLocationForPeriod = (
    period: OLO.Ordering.IPeriod,
    locationNo: number,
    orderTimeoutBufferMins: number = 0, /* Static value */
    startBufferMins: number = 60, /* Static value */
    nextTick: number = 60, /* Static value */
) => createSelector(
    fromLocations.getPickupTimesForSelectedDateForLocation(period.Date, locationNo),
    fromAvailablePickups.getAvailablePickupTimesForLocation(locationNo),
    (timeInfo, todayPickups) => {
        if (!period || period.IsToday) return todayPickups.data || null;


        return Utils.Dates.generatePickupTimesFutureList(
            period,
            {
                location: {
                    LocationNo: locationNo
                },
                asapPickupMins: nextTick,
                openingHours: [{
                    Date: period.Date,
                    ...timeInfo
                }],
                orderTimeoutBufferMins,
                startBufferMins,
                nextTick,
            }
        );
    }
);


export const canOrderFromLocation2 = (locationNo: number, config: IConfig) => createSelector(
    fromLocations.getLocationDetails(locationNo),
    fromLocations.getMinimumPickupTimeForLocation(locationNo),
    fromLocations.getOrderingTimeInfoByLocationNo(locationNo),
    fromLocations.isLocationAvailableForOnlineOrdering(locationNo),
    fromLocations.getLocationOpenStatus(locationNo),
    fromCurrentLocation.getCurrentPickupTime,
    (location, minimumPickupTime, openingHours, isAvailable, isOpen, currentPickupTime) => {
        if (
            !location
            || !locationNo
            || !minimumPickupTime
            || minimumPickupTime.hasSucceeded === false
            || !openingHours
            || isAvailable === null
        ) return null;

        if (!isAvailable || !config.onlineOrders.allowClosedLocationOrders && !isOpen && config.pickups?.futureOrders !== true) {

            return false;
        }
        // if (config.pickups?.futureOrders === true && currentPickupTime) {
        //     const isToday = Utils.Dates.isToday(currentPickupTime.DateLocalISO);

        //     if (!isToday) {
        //         if (location.FutureOrderingMaxDaysAhead === null || location.FutureOrderingMinDaysAhead === null) {
        //             return false;
        //         }

        //         return Utils.Dates.isDateInFutureOrdersTimeRange(currentPickupTime.DateLocalISO, location.FutureOrderingMinDaysAhead, location.FutureOrderingMaxDaysAhead);
        //     }
        // }

        return Utils.OnlineOrders.canOrder(
            location,
            config.pickups?.futureOrders === true,
            minimumPickupTime.MinimumPickupTime,
            openingHours,
            config.pickups.orderTimeoutBufferMins,
            config.pickups.startBufferMins,
        );
    }
);

export const canOrderFromLocation = (locationNo: number, config: IConfig) => createSelector(
    getLocationFuturePickupList(config, locationNo),
    fromLocations.getLocationDetails(locationNo),
    fromLocations.getMinimumPickupTimeForLocation(locationNo),
    fromLocations.getLocationOpenStatus(locationNo),
    fromLocationsFilters.getLocationFilters,
    fromAppSettings.getAppLocationMode,
    (availablePickups, location, minPickupTime, isOpen, filters, locationMode) => {
        if (!availablePickups || availablePickups.length === 0 || !location || location.OnlineOrderingStatus !== 0) return false;
        const canOrderFromClosedLocation = config.pickups?.futureOrders === true || config.onlineOrders.allowClosedLocationOrders === true;
        const isClosed = !canOrderFromClosedLocation && !isOpen;

        if (isClosed) return false;

        const locationIsConfiguredForFutureOrdering = location.FutureOrderingMaxDaysAhead !== null && location.FutureOrderingMinDaysAhead !== null
            && location.FutureOrderingMaxDaysAhead !== 0;

        if (!locationIsConfiguredForFutureOrdering) {
            return Utils.OnlineOrders.canOrder(
                location,
                false,
                minPickupTime.MinimumPickupTime,
                location.OrderingTimeInfo,
                config.pickups.orderTimeoutBufferMins,
                config.pickups.startBufferMins,
            );
        }

        if (locationMode === OLO.Enums.APP_MODE.LOCATION) return true;

        if (config.pickups?.futureOrders === true) {
            const isToday = Utils.Dates.isToday(filters.pickupTime?.DateLocalISO || new Date());

            if (!isToday) {
                return Utils.Dates.isDateInFutureOrdersTimeRange(filters.pickupTime?.DateLocalISO || new Date(), location.FutureOrderingMinDaysAhead, location.FutureOrderingMaxDaysAhead);
            }
        }


        return Utils.OnlineOrders.canOrder(
            location,
            config.pickups?.futureOrders === true,
            minPickupTime.MinimumPickupTime,
            location.OrderingTimeInfo,
            config.pickups.orderTimeoutBufferMins,
            config.pickups.startBufferMins,
        );
    }
);

