import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as State from '@shared/state';
import * as Tokens from '@shared/core/tokens';
import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';

import { Observable } from 'rxjs';
import { map, distinctUntilChanged, auditTime, filter, combineLatest, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LocationsController {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _store: Store<State.IStateShared>,
        private _modalsService: Services.ModalsService,
    ) { }

    public getPeriodsForAllLocations$(format: string = 'ddd, D MMM', prefixes: boolean = true): Observable<OLO.Ordering.IPeriod[]> {
        return this._store
            .pipe(
                select(selectors.getAllLocationsPeriods()),
            );
    }


    public getPeriodsForLocation$(locationNo: number, format: string = 'ddd, D MMM', prefixes: boolean = true): Observable<OLO.Ordering.IPeriod[]> {
        return this._store
            .pipe(
                select(selectors.getLocationPeriods(locationNo, format, prefixes)),
            );
    }

    public currentLocationNoByRoute$(): Observable<number> {
        const isVenueSet = this._config.venue !== null && this._config.venue !== undefined && !!this._config.venue.name;

        return this._store
            .pipe(
                select(selectors.getCurrentRoute),
                combineLatest(
                    this._store.pipe(
                        select(selectors.getLocationsState),
                    )
                ),
                filter(([route, locations]) => locations.isDownloading === false && locations.data !== null),
                map(([route, locations]) => {
                    if (!route || !locations) return null;
                    if (!isVenueSet) return route.params.id ? +route.params.id : null;

                    const foundLocation: APIv1.LocationBusinessModel = locations.data.find(location => {
                        if (location.OnlineOrderingStatus !== 0 || location.LocationOLOIsActive !== true || !location.LocationFriendlyName || !route.params.LocationFriendlyName) return false;

                        return location.LocationFriendlyName.toLowerCase().replace(/\s/gi, '') === route.params.LocationFriendlyName.toLowerCase();
                    });

                    return foundLocation ? foundLocation.LocationNo : null;
                })
            );
    }

    public isLocationViewStable$(): Observable<boolean> {
        /* Check if locationNo by route equals currentLocation state - used to check if can make action after location details view is checked, activated and stable */
        return this.currentLocationNoByRoute$()
            .pipe(

                combineLatest(
                    this._store
                        .pipe(
                            select(selectors.getCurrentLocationNo)
                        )
                ),
                filter(([urlLocationNo, currentLocationNo]) => urlLocationNo !== null && currentLocationNo !== null && currentLocationNo !== undefined),
                map(([urlLocationNo, currentLocationNo]) => {
                    return urlLocationNo === currentLocationNo;
                })
            );
    }

    public locationsListBy(sortTag: 'Popular' | 'Distance' = null, filterParams: OLO.Common.ILocationFilterParams = {}, limit: number = 3): Observable<APIv1.LocationBusinessModel[]> {
        return this._store
            .pipe(
                select(selectors.getFilteredLocations(sortTag, filterParams)),
                map(locations => {
                    if (limit) {
                        if (locations.length > 3) {
                            locations.length = 3;
                        }
                    }
                    return locations;
                }),
                distinctUntilChanged(),
                auditTime(0),
            );
    }

    public locationsListByAppMode$(limit: number = 3): Observable<APIv1.LocationBusinessModel[]> {
        return this._store
            .pipe(
                select(selectors.getAppLocationMode),
                switchMap(mode => {
                    if (mode === OLO.Enums.APP_MODE.VENUE) {
                        return this.locationsListBy('Popular', { byOpenStatus: false, byPickupTime: true, bySearch: false, }, limit);
                    }

                    return this.locationsListBy('Distance', { byOpenStatus: false, byPickupTime: false, bySearch: true, }, limit);
                })
            );
    }

    public isLoadingLocations$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isLoadingDataForAnyLocation),
            );
    }

    public hasRequestedLocations$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.hasRequestedLocations)
            );
    }

    public currentLocation$(): Observable<APIv1.LocationBusinessModel> {
        return this._store
            .pipe(
                select(selectors.getCurrentLocationBusinessModel),
                distinctUntilChanged(),
            );
    }

    public locationDetails$(locationNo: number): Observable<APIv1.LocationBusinessModel> {
        return this._store
            .pipe(
                select(selectors.getLocationDetails(locationNo)),
                distinctUntilChanged()
            );
    }

    public locationFriendlyName$(locationNo: number): Observable<string> {
        return this.locationDetails$(locationNo)
            .pipe(
                map(location => {
                    if (!location) return null;

                    return location.LocationFriendlyName || null;
                })
            );
    }

    public locationAddress$(locationNo: number): Observable<string> {
        return this.locationDetails$(locationNo)
            .pipe(
                map(location => {
                    if (!location) return null;
                    const arr: string[] = [];
                    if (location.StreetAddress) arr.push(location.StreetAddress);
                    if (location.Suburb) arr.push(location.Suburb);
                    if (location.PostCode) arr.push(location.PostCode);

                    return arr.join(', ');
                }),
            );
    }

    public googleMapsLocationAddressLink$(locationNo: number): Observable<string> {
        return this.locationAddress$(locationNo)
            .pipe(
                map(address => `https://maps.google.com/maps?q=${window.encodeURIComponent(address)}`)
            );
    }

    public openStatus(locationNo: number, shortHour: boolean = false): Observable<OLO.Common.ILocationOpenStatus> {
        return this._store
            .pipe(
                select(selectors.getLocationOpenStatus(locationNo)),
                distinctUntilChanged(),
                filter(obj => obj !== null),
                map(obj => {

                    if (shortHour) {
                        const split: string[] = obj.hour.replace(/[a-zA-Z]/gi, '').split(':');
                        const isFullHour: boolean = split[1] === '00';
                        return {
                            ...obj,
                            hour: isFullHour ? obj.hour.replace(/\:\d\d\s?/, '') : obj.hour,
                        };
                    }
                    return obj;
                }),
            );
    }

    public locationDescription$(locationNo: number): Observable<string> {
        return this._store
            .pipe(
                select(selectors.getAppLocationMode),
                combineLatest(
                    this.locationDetails$(locationNo),
                ),
                map(([appMode, location]) => {
                    const isVenue: boolean = appMode === OLO.Enums.APP_MODE.VENUE;

                    let address: string = '';
                    let coma: string = `, `;
                    if (location.StreetAddress) {
                        address = location.StreetAddress;
                    }
                    if (location.Suburb) {
                        address = address ? `${address}${coma}${location.Suburb}` : location.Suburb;
                    }
                    if (location.PostCode) {
                        address = address ? `${address}${coma}${location.PostCode}` : location.PostCode;
                    }

                    if (!isVenue) return address;

                    return location.LocationNotes || address || '';
                }),
            );
    }

    public openingHours$(locationNo: number): Observable<APIv1.LocationOrderingTimeInfoModel[]> {
        return this._store
            .pipe(
                select(selectors.getOrderingTimeInfoByLocationNo(locationNo, false)),
                distinctUntilChanged(),
                filter(obj => obj !== undefined && obj !== null),
                map(obj => {
                    const sunday: APIv1.LocationOrderingTimeInfoModel[] = [];
                    const reduced = obj.reduce((acc, day, index) => {
                        if (index < 7) {
                            if (day.DayOfWeek === 0) {
                                sunday.push(day);
                                return acc;
                            }
                            return [
                                ...acc,
                                day
                            ];
                        }
                        return acc;
                    }, [] as APIv1.LocationOrderingTimeInfoModel[]);

                    return [
                        ...reduced.sort((a, b) => a.DayOfWeek - b.DayOfWeek),
                        ...sunday
                    ];
                }),
            );
    }

    public locationImage$(locationNo: number, type: OLO.Enums.IMAGE_TYPE): Observable<OLO.Common.IImageStateObj> {
        return this._store.pipe(select(selectors.getLocationImage(type, locationNo)));
    }

    public locationImageSrc$(locationNo: number, type: OLO.Enums.IMAGE_TYPE): Observable<string> {
        return this.locationImage$(locationNo, type)
            .pipe(
                filter(img => img !== null && img !== undefined && img.data !== null),
                map(img => img.data.ImageUrl || null)
            );
    }

    public isLocationOpenNow$(locationNo: number): Observable<boolean> {
        return this._store.pipe(select(selectors.isLocationOpenNowByOrderingTimeInfoObj(locationNo)));
    }

    public isValidatingLocation$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.currentLocationIsValidating)
            );
    }

    public isLocationValid$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.currentLocationIsValid)
            );
    }

    public canChangeLocation$(locationNo: number): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.getCartLocationNo),
                map(cartLocationNo => {
                    if (!cartLocationNo) return false;

                    return locationNo !== cartLocationNo;
                }),
            );
    }

    public canOrderFromLocationIncludingFutureOpening(locationNo: number): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.canOrderFromLocation(locationNo, this._config))
            );
    }

    public hasLocations$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.hasLocations)
            );
    }

    public restoreValidationFlags(): void {
        this._store.dispatch(actions.CurrentLocationValidationReset());
    }

    public openCartLocationOpenHoursModal(): void {
        this._store
            .pipe(
                select(selectors.getCartLocationNo),
                take(1)
            ).subscribe(locationNo => {
                this._modalsService.show({ type: 'location-open-hours', locationNo, params: { canNavigate: true } });
            });
    }
}
