import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';
import * as State from '@shared/state/interface';
import { PickupsService } from './pickups.shared.service';

import { Observable, BehaviorSubject, pipe, UnaryFunction, of, throwError } from 'rxjs';
import { map, take, switchMap, tap, filter, withLatestFrom, catchError, auditTime, combineLatest } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class OnlineOrdersService {
    public isDownloading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
        public store: Store<State.IStateShared>,
        @Optional() public pickupsService: PickupsService,
    ) {
        /*
            Prevent from triggering twice same action when there are two components on the same view;
        */
        this.store
            .pipe(
                select(selectors.getHistoryOrdersStatus)
            ).subscribe(status => {
                this.isDownloading$.next(status.isDownloading);
            });
    }

    public filterClonedItemsFromMenuFlowProductsPipe(order: APIv1.OnlineOrderDetailedBusinessModel): UnaryFunction<Observable<APIv1.OnlineOrderDetailedBusinessModel>, Observable<APIv1.OnlineOrderDetailedBusinessModel>> {
        /*
            USE FOR VIEW ONLY. DON'T USE THIS MODEL FOR UPDATE
            Removing cloned products from MenuFlowsActivations into Items,
            This is a bad misunderstanding on the API side...
            Someone should get a Noble prize for that behavior... NOT!
        */
        return pipe(
            map((order: APIv1.OnlineOrderDetailedBusinessModel) => {
                const items: APIv1.OnlineOrderMenuFlowItem[] = [];

                order.MenuFlowActivations.forEach(menuFlow => {
                    menuFlow.MenuFlowItems.forEach(item => items.push(item));
                });

                if (!items.length) return order;

                /* Start filtering stuff */
                order.Items = order.Items.filter(product => !items.find(item => item.Quantity === product.Quantity && item.PLU === product.PLU && item.OnlineOrderItemId === product.Id));

                return order;
            })
        );
    }

    public createNewOnlineOrder(model: APIv1.OnlineOrderDetailedBusinessModel, ...receiptEmails: string[]): Observable<APIv1.OnlineOrderDetailedBusinessModel> {
        const { discounts } = this.config.onlineOrders;
        if (discounts.enable) {
            model = {
                ...model,
                OnlineDiscounts: [{
                    Id: null,
                    OnlineOrderId: model.Id || null,
                    Value: discounts.value,
                }]
            };
        }

        return this.httpClient.post<APIv1.OnlineOrderDetailedBusinessModel>(`${this.config.api.base}/OnlineOrders`, model)
            .pipe(
                switchMap(response => {
                    if (receiptEmails?.filter(e => e).length) {
                        return this.addEmailReceiptNotificationDetailsWithOrderReturned(response.Id, ...receiptEmails);
                    }

                    return of(response);
                })
            );
    }


    public sendEmailWithOrderConfirmation(orderId: number): Observable<boolean> {
        const model: APIv1.OnlineOrderEmailConfirmationRequestModel = {
            OnlineOrderId: orderId
        };
        return this.httpClient.post<boolean>(`${this.config.api.base}/OnlineOrders/sendOrderConfirmationEmail`, model);
    }


    public recalculateOnlineOrder(model: APIv1.OnlineOrderDetailedBusinessModel): Observable<APIv1.OnlineOrderDetailedBusinessModel> {
        /* AOLO-218 - add discount if configured */
        const { discounts } = this.config.onlineOrders;
        if (discounts.enable) {
            model = {
                ...model,
                OnlineDiscounts: [{
                    Id: null,
                    OnlineOrderId: model.Id || null,
                    Value: discounts.value,
                }]
            };
        }

        return this.httpClient.post<APIv1.OnlineOrderDetailedBusinessModel>(`${this.config.api.base}/OnlineOrders/Recalculate`, model);
    }

    public getMinimumPickupTime(locationNo: number, p: APICommon.IOnlineOrdersPickupTimeParams = {}): Observable<APIv1.OnlineOrdersGetOnlineOrderMinimumPickupTime.Responses.$200> {
        const params: HttpParams = new HttpParams({
            fromObject: ({
                ...p,
                dateToCheck: p.dateToCheck ? p.dateToCheck.replace('Z', '') : new Date().toISOString().replace('Z', ''),  /* Psikutas bez 's' */
            } as any)
        });

        return this.httpClient.get(`${this.config.api.base}/OnlineOrders/pickupTime/${locationNo}`, { params }) as Observable<number>;
    }

    public getOnlineOrders(p: APICommon.IOnlineOrdersGetOrdersParams = {}): Observable<APIv1.OnlineOrdersGetOnlineOrders.Responses.$200> {
        return this.httpClient.get<APIv1.OnlineOrdersGetOnlineOrders.Responses.$200>(`${this.config.api.base}/OnlineOrders${Utils.HTTP.object2string(p)}`)
            .pipe(
                map(response => {
                    return {
                        ...response,
                        Items: response.Items.map(order => Utils.OnlineOrders.onlineOrderModelFix(order))
                    };
                })
            );
    }

    public getOnlineOrder(orderId: number): Observable<APIv1.OnlineOrderDetailedBusinessModel> {
        return this.httpClient.get<APIv1.OnlineOrderDetailedBusinessModel>(`${this.config.api.base}/OnlineOrders/${orderId}`)
            .pipe(
                map(order => Utils.OnlineOrders.onlineOrderModelFix(order)),
            );
    }

    public requestOrders(params: APICommon.IOnlineOrdersGetOrdersParams = {}): void {
        this.store.dispatch(actions.HistoryOrdersRequest({
            'pagingArgs.pageNo': 1,
            statuses: [
                OLO.Enums.ONLINE_ORDER_STATUS.VALIDATED,
                OLO.Enums.ONLINE_ORDER_STATUS.RECIVED_AT_SITE,
                OLO.Enums.ONLINE_ORDER_STATUS.SENT_TO_KITCHEN,
                OLO.Enums.ONLINE_ORDER_STATUS.SENT_TO_SITE,
                OLO.Enums.ONLINE_ORDER_STATUS.FINALIZED,
            ],
            ...params
        }));
    }

    public cleanHistoryOrderForGuest(): void {
        /*
            This should be used in checkout view when guest orders and then signs in.
            http://support.taskretail.com.au:8080/browse/AOLO-484
        */
        this.store
            .pipe(
                select(selectors.isGuestModeEnabled),
                take(1),
            ).subscribe(guestMode => {
                if (!guestMode) return;

                this.store.dispatch(actions.HistoryOrdersReset());
            });
    }

    public recalculateOrderAction(): void {
        /*
            Use this when entering checkout page
        */
        this.store.dispatch(actions.OnlineOrderRecalculateRequest());
    }


    public async placeOrder(creditCard: State.IPaymentCreditCardData = null): Promise<OLO.Ordering.IPaymentSummary> {

        return new Promise((resolve, reject) => {
            if (!this.pickupsService) return reject('pickupsService not provided');
            this.pickupsService.validateCartWithPopup()
                .pipe(
                    take(1),
                    withLatestFrom(
                        this.store.pipe(select(selectors.isGuestModeEnabled)),
                        this.store.pipe(select(selectors.getMemberState)),
                        this.store.pipe(select(selectors.getOnlineOrderRecalcData))
                    ),
                    tap(([isCartValid, isGuest, membersState]) => {
                        if (isCartValid) {
                            let member: APICommon.IOnlineOrderPartialMember = null;

                            if (isGuest && membersState.guestData) {
                                member = membersState.guestData;
                            }

                            return this.store.dispatch(actions.PaymentInit());

                        }
                    }),
                    switchMap(([isCartValid, isGuest, memberState, recalcOrder]) => {
                        if (!isCartValid) return of(null);

                        return this.store
                            .pipe(
                                select(selectors.getPaymentStepsStatus),
                                auditTime(200),
                                filter(status => status === 'complete' || status === 'failed'),
                                withLatestFrom(
                                    this.store.pipe(select(selectors.getPaymentState)),
                                ),
                                take(1),
                                map(([status, paymentState]) => {
                                    /* [paymentStatus, recalcOrder.data.PickupLocation] */
                                    return {
                                        status: status === 'failed' ? OLO.Enums.PAYMENT_STATUS.FAILED : OLO.Enums.PAYMENT_STATUS.SUCCESS,
                                        orderId: paymentState.OrderId,
                                        locationNo: recalcOrder.data.PickupLocation
                                    };
                                })
                            );

                    }),
                    catchError(ex => {
                        console.error('Unable to make payment', ex);
                        return throwError(ex);
                    })
                )
                .subscribe((summary: OLO.Ordering.IPaymentSummary) => {
                    if (!summary || summary.status !== OLO.Enums.PAYMENT_STATUS.SUCCESS || !summary.locationNo || !summary.orderId) {
                        console.error('Invalid payment summary', summary);
                        return reject(summary);
                    }
                    resolve(summary);
                });
        });
    }

    public apiPutEmailReceiptNotificationDetails(orderId: number, ...emails: string[]): Observable<boolean> {
        return this.httpClient.put<boolean>(`${this.config.api.base}/OnlineOrders/${orderId}/InsertReceiptNotificationDetails`, emails.map(email => ({ NotificationEmailAddress: email })));
    }

    public addEmailReceiptNotificationDetailsWithOrderReturned(orderId: number, ...emails: string[]): Observable<APIv1.OnlineOrderDetailedBusinessModel> {
        /* Wrapper for  apiPutEmailReceiptNotificationDetails to get updated order model */
        return this.apiPutEmailReceiptNotificationDetails(orderId, ...emails)
            .pipe(
                switchMap(response => {
                    if (!response) return throwError(`Unable to add receipt to order ${orderId}`);

                    return this.getOnlineOrder(orderId);
                })
            );
    }

    public sendOnlineOrderReceipt(orderId: number): Observable<boolean> {
        return this.httpClient.post<boolean>(`${this.config.api.base}/OnlineOrders/sendOnlineOrderReceiptEmail`, {
            LoyaltyMobileAppId: null,
            OnlineOrderId: orderId
        });
    }

    public requestHistoryOrder(orderId: number): void {
        this.store.dispatch(actions.HistoryOrderRequest({ orderId }));
    }

    public reorderSetup(orderId: number, locationNo: number, modalId: number = null): void {
        this.store.dispatch(actions.ReorderSetup({ orderId, locationNo, modalId }));
    }

    public reorderInitCalculations(orderId: number): void {
        this.store
            .pipe(
                select(selectors.getHistoryOrder(orderId)),
                filter(order => order !== null && order !== undefined && order.isDownloading !== true),
                combineLatest(
                    this.store
                        .pipe(
                            select(selectors.getCurrentPickupTime),
                            filter(pickupTime => pickupTime !== null),
                            take(1),
                        )
                ),
                take(1)
            ).subscribe(([order, pickupTime]) => {
                if (!order.data) {
                    console.warn(`Invalid order data for order ${orderId}:`, order);
                    return;
                }

                this.store.dispatch(actions.ReorderCalculateRequest({
                    orderId,
                    locationNo: order.data.PickupLocation,
                    pickupTime: { ...pickupTime }
                }));
            });
    }

    public reorderToggleItemSelected(orderId: number, item: State.ICartMenuFlowExtended | State.ICartSimpleItemExtended): void {
        if (item._IsSelected) {
            return this.store.dispatch(actions.ReorderDeselectItem({ orderId, item }));
        }
        this.store.dispatch(actions.ReorderSelectItem({ orderId, item }));
    }

    public reorderAccept(orderId: number, locationNo: number, modalId: number = null): void {
        this.store
            .pipe(
                select(selectors.getCurrentPickupTime),
                switchMap(pickupTime => this.store
                    .pipe(
                        select(selectors.getReorder(orderId, locationNo, pickupTime))
                    )
                ),
                take(1)
            ).subscribe(reorder => {
                const menuFlows = reorder.data.cart.itemsMenuFlow.filter(menuFlow => {
                    if (menuFlow && menuFlow._IsSelected && !menuFlow._IsDisabled) {
                        return true;
                    }
                    return false;
                });

                const simpleItems = reorder.data.cart.itemsSimple.filter(simpleItem => {
                    if (simpleItem && simpleItem._IsSelected && !simpleItem._IsDisabled) {
                        return true;
                    }
                    return false;
                });

                this.store.dispatch(actions.CartSetupWithMultipleItems(
                    modalId,
                    locationNo,
                    menuFlows,
                    simpleItems
                ));
            });
    }
}
