import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as State from '@shared/state';
import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';

import { Observable, of, pipe } from 'rxjs';
import { map, filter, distinctUntilChanged, combineLatest, switchMap, take, pairwise, withLatestFrom } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class WizzardController {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _store: Store<State.IStateShared>,
        private _routeService: Services.RouteService,
    ) { }

    public wizzardSimpleItem$(): Observable<State.IWizzardSimpleItem> {
        return this._store
            .pipe(
                select(selectors.getWizzardSimpleItem)
            );
    }

    public wizzardMenuFlow$(): Observable<State.IWizzardMenuFlow> {
        return this._store
            .pipe(
                select(selectors.getWizzardMenuFlow)
            );
    }

    public isEditing$(isMenuFlow: boolean): Observable<boolean> {
        if (isMenuFlow) {
            return this.wizzardMenuFlow$()
                .pipe(
                    filter(menuFlow => menuFlow !== null),
                    map(menuFlow => !!menuFlow['_Id'])
                );
        }

        return this.wizzardSimpleItem$()
            .pipe(
                filter(item => item !== null),
                map(item => !!item['_Id'])
            );
    }

    public getWizzardMenuFlowPages$(includeHidden: boolean = false): Observable<State.IWizzardMenuFlowPage[]> {
        return this.wizzardMenuFlow$()
            .pipe(
                filter(menuFlow => menuFlow !== null),
                map(menuFlow => {
                    if (includeHidden) {
                        return menuFlow.Pages;
                    }

                    return menuFlow.Pages.filter(page => page.HideFromKiosk !== true);
                })
            );
    }

    public wizzardMenuFlowUnitPrice$(): Observable<number> {
        return this._store
            .pipe(
                select(selectors.getWizzardSimpleItemUnitPrice)
            );
    }

    public wizzardSimpleItemUnitPrice$(): Observable<number> {
        return this._store
            .pipe(
                select(selectors.getWizzardSimpleItemUnitPrice)
            );
    }

    public wizzardMenuFlowQuantity$(): Observable<number> {
        return this._store
            .pipe(
                select(selectors.getWizzardMenuFlowQuantity)
            );
    }

    public wizzardSimpleItemQuantity$(): Observable<number> {
        return this._store
            .pipe(
                select(selectors.getWizzardSimpleItemQuantity)
            );
    }

    public wizzardMenuFlowTotalValue$(): Observable<number> {
        return this._store
            .pipe(
                select(selectors.getWizzardMenuFlowTotalValue)
            );
    }

    public wizzardSimpleItemTotalValue$(): Observable<number> {
        return this._store
            .pipe(
                select(selectors.getWizzardSimpleItemTotalValue)
            );
    }

    public wizzardMenuFlowImage$(): Observable<string> {
        return this._store
            .pipe(
                select(selectors.getImageForWizzardItem(true)),
            );
    }

    public wizzardSimpleItemImage$(): Observable<string> {
        return this._store
            .pipe(
                select(selectors.getImageForWizzardItem(false)),
            );
    }

    public wizzardItemImageLoading$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isDownloadingAnyOnlineMenuPageImages),
            );
    }

    public wizzardMenuFlowTagsImages$(): Observable<State.IDietaryTagImage[]> {
        return this._store
            .pipe(
                select(selectors.getDietaryTagImagesForWizzardItem(true))
            );
    }

    public wizzardSimpleItemTagsImages$(): Observable<State.IDietaryTagImage[]> {
        return this._store
            .pipe(
                select(selectors.getDietaryTagImagesForWizzardItem(false))
            );
    }


    public wizzardMenuFlowStats$(label: string = null): Observable<OLO.Components.IStatsComponentInput> {
        return this._store
            .pipe(
                select(selectors.getStatsForWizzardItem(true, label))
            );
    }

    public wizzardSimpleItemStats$(label: string = null): Observable<OLO.Components.IStatsComponentInput> {
        return this._store
            .pipe(
                select(selectors.getStatsForWizzardItem(false, label))
            );
    }

    public getMenuFlowPageIdentifierOneNewError$(): Observable<number> {
        return this._store
            .pipe(
                select(selectors.getWizzardErrors),
                pairwise(),
                distinctUntilChanged(),
                map(([prev, curr]) => {
                    if (!curr[0]) return null;
                    const pageIdentifier: number = curr[0].pageIdentifier;
                    const isNewError: boolean = !prev.find(err => err.pageIdentifier === pageIdentifier);
                    return isNewError ? pageIdentifier : null;
                })
            );
    }

    public wizzardMenuFlowTotalSelectedProducts$(): Observable<number> {
        return this._store
            .pipe(
                select(selectors.getWizzardTotalSelectedProducts)
            );
    }

    public wizzardAcceptBtnLabel$(customLabels: { add?: string; edit?: string; no?: string; } = {}): Observable<string> {
        const defaults = {
            add: 'Add to bag',
            edit: 'Edit',
            no: 'No, thanks'
        };

        const labels = {
            ...defaults,
            ...customLabels
        };

        return this.wizzardMenuFlow$()
            .pipe(
                filter(menuFlow => menuFlow !== undefined && menuFlow !== null),
                combineLatest(
                    this.wizzardMenuFlowTotalSelectedProducts$()
                ),
                map(([menuFlow, totalSelected]) => {
                    if (menuFlow.IsUpsell && totalSelected === 0) {
                        return labels.no;
                    }

                    if (menuFlow._Id) return labels.edit;

                    return labels.add;
                })
            );

    }

    public wizzardAcceptBtnLabelPrice$(): Observable<number> {
        return this.wizzardMenuFlow$()
            .pipe(
                filter(menuFlow => menuFlow !== undefined && menuFlow !== null),
                combineLatest(
                    this.wizzardMenuFlowTotalSelectedProducts$(),
                    this.wizzardMenuFlowTotalValue$()
                ),
                map(([menuFlow, totalSelected, totalValue]) => {
                    if (menuFlow.IsUpsell && totalSelected === 0) {
                        return null;
                    }

                    return totalValue;
                }),
            );
    }

    public wizzardMenuFlowTitle$(): Observable<string> {
        return this.wizzardMenuFlow$()
            .pipe(
                filter(menuFlow => menuFlow !== undefined && menuFlow !== null),
                map(menuFlow => {
                    if (!menuFlow.IsUpsell) return menuFlow.PosDisplay;

                    return menuFlow.MenuFlowNotes;
                }),
            );
    }

    public wizzardMenuFlowDescription$(): Observable<string> {
        return this.wizzardMenuFlow$()
            .pipe(
                filter(menuFlow => menuFlow !== undefined && menuFlow !== null),
                map(menuFlow => {
                    if (!menuFlow.IsUpsell) return menuFlow.PosDescription;

                    return menuFlow.PosDescription;
                }),
            );
    }

    public menuFlowPageHasErrors$(pageIdentifier: number): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.getWizzardErrors),
                map(errors => {
                    return errors.find(error => error.pageIdentifier === pageIdentifier) ? true : false;
                })
            );
    }

    public isMenuFlowPageQuantityValid$(page: APIv1.MenuFlowPage): Observable<boolean> {
        /* Will be more advanced */
        return this._store.pipe(
            select(selectors.isWizzardPageQuantityValid(page))
        );
    }

    public isMenuFlowPageQuantityMaxedOut$(page: APIv1.MenuFlowPage): Observable<boolean> {
        return this._store.pipe(
            select(selectors.isWizzardPageQuantityMaxedOut(page))
        );
    }

    public pageHasSomeProductsSelected$(page: APIv1.MenuFlowPage): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.wizzardPageHasSelectedAnyProduct(page))
            );
    }

    public isMenuFlowProductSelectable$(pageIdentifier: number, productId: number, productState: number = null): Observable<boolean> {
        /* Decide if product is selectable when one is allowed or when multiple allowed and page limit is reached */
        const isPageOneAllowed$ = this._store.pipe(select(selectors.isWizzardPageOneAllowed(pageIdentifier)));
        const isPageLimitReached$ = this._store.pipe(select(selectors.isWizzardPageLimitReached(pageIdentifier)));
        const productWizzardQuantity$ = this._store.pipe(select(selectors.getWizzardMenuFlowProductTotalQuantity(pageIdentifier, productId)));

        return productWizzardQuantity$
            .pipe(
                combineLatest(
                    isPageLimitReached$,
                    isPageOneAllowed$,
                ),
                map(([productQty, pageLimitReached, isPageOneAllowed]) => {
                    if (isPageOneAllowed) return true;
                    const isClickable: boolean = productQty === 0 && pageLimitReached === false;

                    return productState === null ? isClickable : isClickable && productState === 0;
                })
            );
    }

    public isPageLimitReached$(pageIdentifier: number): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isWizzardPageLimitReached(pageIdentifier)
                )
            );
    }

    public canShowNextPrice$(pageIdentifier: number, product: APIv1.MenuFlowProduct): Observable<boolean> {
        return this.menuFlowProductQuantity$(pageIdentifier, product.ProductId)
            .pipe(
                combineLatest(
                    this.nextProductPrice$(pageIdentifier, product.ProductId)
                ),
                map(([quantity, nextPrice]) => {
                    return !!nextPrice === true && quantity > 0; // && product.MaximumQuantity > 1; TOLO-47 fix
                })
            );
    }

    public nextProductPrice$(pageIdentifier: number, productId: number): Observable<number> {
        return this._store
            .pipe(
                select(selectors.getNextPriceForMenuFlowProduct(productId, pageIdentifier))
            );
    }

    public productAddReplaceRemove$(pageIdentifier: number, productId: number): Observable<string> {
        /* Decide what to do with the product - add, remove, replace */
        const isPageOneAllowed$ = this._store.pipe(select(selectors.isWizzardPageOneAllowed(pageIdentifier)));
        const isPageLimitReached$ = this._store.pipe(select(selectors.isWizzardPageLimitReached(pageIdentifier)));
        const productWizzardQuantity$ = this._store.pipe(select(selectors.getWizzardMenuFlowProductTotalQuantity(pageIdentifier, productId)));
        const pageWizzardQuantity$ = this._store.pipe(select(selectors.getWizzardMenuFlowPageItemsTotalQuantity(pageIdentifier)));

        return this.isMenuFlowProductSelectable$(pageIdentifier, productId)
            .pipe(
                switchMap(canSelect => {
                    if (!canSelect) return of(null);

                    return productWizzardQuantity$
                        .pipe(
                            combineLatest(
                                isPageLimitReached$,
                                isPageOneAllowed$,
                                pageWizzardQuantity$,
                            ),
                            map(([productQty, pageLimitReached, isPageOneAllowed]) => {
                                if (isPageOneAllowed) {
                                    if (pageLimitReached && productQty !== 0) {
                                        return 'remove';
                                    }

                                    if (pageLimitReached && productQty === 0) {
                                        return 'replace';
                                    }

                                    if (!pageLimitReached) {
                                        return 'add';
                                    }
                                } else {
                                    if (!pageLimitReached) {
                                        return 'add';
                                    }

                                    return null;
                                }
                            })
                        );

                })
            );

    }

    public getIngredientsForWizzardProductForDropdown$(product: APIv1.MenuFlowProduct): Observable<OLO.Components.DropDown.IDropDownOption[]> {
        return this._store
            .pipe(
                select(selectors.getWizzardMenuFlow),
                filter(wizzardMenuFlow => wizzardMenuFlow !== null),
                switchMap(wizzardMenuFlow =>
                    this._store
                        .pipe(
                            select(selectors.getIngredientsForProduct(product.ProductId, wizzardMenuFlow.LocationNo)))
                ),
                filter(ingredient => ingredient !== undefined
                    && !ingredient.isDownloading
                    && !ingredient.hasFailed
                    && ingredient.data !== null
                    && ingredient.data.Ingredients !== null
                    && ingredient.data.Ingredients[0] !== undefined
                    && ingredient.data.Ingredients[0].Modifiers !== null
                ),
                map(ingredient => {
                    return ingredient.data.Ingredients[0].Modifiers.map(modifier => ({
                        ...modifier,
                        Id: modifier.ModifierID,
                        Name: modifier.ModifierName
                    }));
                }),
            );
    }

    public getSelectedIngredientForWizzardProduct$(pageIdentifier: number, productId: number): Observable<APICommon.IIngredientModifierExtended> {
        return this._store
            .pipe(
                select(selectors.getWizzardSelectedModifierForMenuFlowProduct(pageIdentifier, productId)),
                filter(selected => selected !== null),
                map(selected => ({
                    ...selected,
                    Id: selected.ModifierID,
                    Name: selected.ModifierName
                })),
            );
    }


    public isProductDisabledByPageLimit$(pageIdentifier: number, productId: number, productState: number = null): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.getWizzardMenuFlowProductTotalQuantity(pageIdentifier, productId)),
                combineLatest(this._store.select(selectors.isWizzardPageLimitReached(pageIdentifier))),
                map(([qty, limitReached]) => {
                    const isDisabled = qty === 0 && limitReached === true;
                    if (productState == null) {
                        return isDisabled;
                    }

                    return isDisabled && productState !== 0;
                })
            );
    }

    public menuFlowProductQuantity$(pageIdentifier: number, productId: number): Observable<number> {
        return this._store
            .pipe(
                select(selectors.getWizzardMenuFlowProductTotalQuantity(pageIdentifier, productId))
            );
    }

    public isProductSelected$(pageIdentifier: number, productId: number): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.getWizzardMenuFlowProductTotalQuantity(pageIdentifier, productId)),
                map(qty => qty > 0),
            );
    }

    public isProductDisabled$(pageIdentifier: number, productId: number, productState: number = null): Observable<boolean> {
        return this.isProductDisabledByPageLimit$(pageIdentifier, productId, productState)
            .pipe(
                combineLatest(
                    this.isPageLimitReached$(pageIdentifier),
                    this.isProductSelected$(pageIdentifier, productId),
                    this.isMenuFlowProductSelectable$(pageIdentifier, productId, productState),
                    this._store
                        .pipe(
                            select(
                                selectors.getWizzardMenuFlowProduct(pageIdentifier, productId)
                            )
                        )
                ),
                map(([isDisabled, isPageLimitReached, isSelected, isClickable, product]) => {
                    return (product && product.State !== 0) ||
                        productState !== null && productState !== 0 ||
                        isDisabled ||
                        isPageLimitReached && !isSelected && !isClickable;
                }),
            );
    }

    public showRemoveButton$(pageIdentifier: number, productId: number): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.getWizzardMenuFlowProductTotalQuantity(pageIdentifier, productId)),
                combineLatest(this._store.select(selectors.isWizzardPageOneAllowed(pageIdentifier))),
                map(([qty, isOneAllowed]) => qty > 0 && isOneAllowed === true),
            );
    }

    public showCounterButtons$(pageIdentifier: number, productId: number): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.getWizzardMenuFlowProductTotalQuantity(pageIdentifier, productId)),
                combineLatest(this._store.select(selectors.isWizzardPageOneAllowed(pageIdentifier))),
                map(([qty, isOneAllowed]) => qty > 0 && isOneAllowed === false)
            );
    }

    public wizzardPageTotalQuantity$(pageIdentifier: number): Observable<number> {
        return this._store.pipe(select(selectors.getWizzardMenuFlowPageItemsTotalQuantity(pageIdentifier)));
    }

    public wizzardPageHasErrors$(pageIdentifier: number): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.getWizzardErrors),
                map(errors => !!errors.find(error => error.pageIdentifier === pageIdentifier))
            );
    }

    /* Wizzard hints */

    public wizzardHintShowAddUpTo$(page: APIv1.MenuFlowPage): Observable<boolean> {
        /*
            If the minimum page quantity is 0, and no items have been selected in that page:
            Add up to {{MaximumPageQty}}
        */
        const minPageQty: number = page.PageMinQuantity;
        const maxPageQty: number = page.PageMaxQuantity;

        return this.wizzardPageTotalQuantity$(page.PageIdentifier)
            .pipe(
                map(wizzardQty => {
                    return minPageQty !== maxPageQty && wizzardQty === 0;
                })
            );
    }

    public wizzardHintShowCounter$(page: APIv1.MenuFlowPage): Observable<boolean> {
        /*
            If the minimum page quantity has been met (e.g. Min Qty = 0, user has selected 2):
            {{CurrentSelectedPageCount}} / {{MaximumPageQty}}
            OR If the minimum page quantity matches the maximum page quantity (e.g. Min 1 / Max 1), and the current selected count HAS reached the maximum
            {{CurrentSelectedPageCount}} / {{MaximumPageQty}}
        */
        const minPageQty: number = page.PageMinQuantity;
        const maxPageQty: number = page.PageMaxQuantity;

        return this.wizzardPageTotalQuantity$(page.PageIdentifier)
            .pipe(
                map(wizzardQty => {
                    return (minPageQty <= wizzardQty && wizzardQty !== 0)
                        || (minPageQty === maxPageQty && wizzardQty === maxPageQty);
                })
            );
    }

    public wizzardHintShowChooseMinimumQuantity$(page: APIv1.MenuFlowPage): Observable<boolean> {
        /*
            If the minimum page quantity matches the maximum page quantity (e.g. Min 1 / Max 1), and the current selected count HAS NOT reached the maximum
            Choose {{MinimumPageQty}}
        */
        const minPageQty: number = page.PageMinQuantity;
        const maxPageQty: number = page.PageMaxQuantity;

        return this.wizzardPageTotalQuantity$(page.PageIdentifier)
            .pipe(
                map(wizzardQty => {
                    return minPageQty === maxPageQty && wizzardQty === 0;
                })
            );
    }

    /* Collapse pages */
    public wizzardAllProductsForMenuFlowPage$(pageIdentifier: number): Observable<State.IWizzardMenuFlowItem[]> {
        return this._store
            .pipe(
                select(selectors.getWizzardMenuFlowAllProductsForPage(pageIdentifier))
            );
    }

    public wizzardFirstProductForMenuFlowPage$(pageIdentifier: number): Observable<State.IWizzardMenuFlowItem> {
        return this._store
            .pipe(
                select(selectors.getWizzardMenuFlowFirstProductForPage(pageIdentifier))
            );
    }

    public wizzardCollapsePageTitlePrefix$(page: APIv1.MenuFlowPage): Observable<string> {
        return this.isMenuFlowPageQuantityValid$(page)
            .pipe(
                map(isComplete => isComplete ? page.PageName : null)
            );
    }

    public wizzardCollapsePageTitle$(page: APIv1.MenuFlowPage, defaultPrefix: string = 'Choose your '): Observable<string> {
        return this.wizzardCollapsePageTitlePrefix$(page)
            .pipe(
                combineLatest(
                    this.wizzardAllProductsForMenuFlowPage$(page.PageIdentifier)
                ),
                map(([isComplete, selectedProducts]) => {
                    if (!isComplete || !selectedProducts) return `${defaultPrefix}${page.PageName}`;
                    return selectedProducts.reduce((acc, product, index) => {
                        /* ProductName */
                        if (!index) return product.ProductName;
                        return acc += `, ${product.ProductName}`;
                    }, '');
                }),
            );
    }

    public wizzardMenuFlowPagesReports$(): Observable<State.IWizzardPageReport[]> {
        return this._store
            .pipe(
                select(selectors.wizzardMenuFlowPagesReports)
            );
    }

    public getWizzardErrors$(limit: number = 1): Observable<State.IWizzardError[]> {
        return this._store
            .pipe(
                select(selectors.getWizzardErrors),
                map(errors => {
                    if (errors.length === 0) return [];

                    if (limit) {
                        errors.length = limit;
                    }

                    return errors;
                }),
            );
    }

    public getWizzardErrorsMessages$(limit: number = 1): Observable<string[]> {
        return this.getWizzardErrors$(limit)
            .pipe(
                combineLatest(
                    this._store.pipe(
                        select(selectors.getMenuFlowDetailsPagesByWizzard)
                    )
                ),
                filter(([errors, pages]) => errors !== null && pages !== null),
                map(([errors, pages]) => {
                    return errors.map(error => {
                        const page = pages.find(obj => obj.PageIdentifier === error.pageIdentifier);
                        if (!page) return null;

                        switch (true) {
                            case error.error === State.WIZZARD_ERROR.REQUIRED:
                                if (page.PageMaxQuantity === page.PageMinQuantity) {
                                    return `Please select ${page.PageMinQuantity} ${page.PageName.toLowerCase()}`;
                                }

                                if (page.PageMinQuantity !== page.PageMaxQuantity) {
                                    return `Please select minimum ${page.PageMinQuantity} ${page.PageName.toLowerCase()}`;
                                }

                                break;
                            default:
                                /* TODO - State.WIZZARD_ERROR.OVERFLOW */
                                return 'OVERFLOW';
                        }

                        return 'unknown error';
                    });
                })
            );
    }

    public getSpecialInstructions(type: State.MODAL_TYPE): Observable<string> {
        if (type === 'product') {
            return this.wizzardProductSpecialInstructions$();
        }

        return this.wizzardMenuFlowSpecialInstructions$();
    }


    public wizzardMenuFlowSpecialInstructions$(): Observable<string> {
        return this.wizzardMenuFlow$()
            .pipe(
                filter(wizzard => wizzard !== null),
                map(wizzard => wizzard.SpecialInstructions)
            );
    }

    public wizzardProductSpecialInstructions$(): Observable<string> {
        return this.wizzardSimpleItem$()
            .pipe(
                filter(product => product !== null),
                map(product => product.SpecialInstructions),
            );
    }


    public hasSpecialInstructions$(type: State.MODAL_TYPE): Observable<boolean> {
        const specialInstructionsPipe = pipe(
            filter(product => product !== undefined && product !== null),
            map((product: State.IWizzardSimpleItem | State.IWizzardMenuFlow) => {
                return product.SpecialInstructions ? true : false;
            })
        );

        if (type === 'product') {
            return this.wizzardSimpleItem$()
                .pipe(
                    specialInstructionsPipe
                );
        }
        return this.wizzardMenuFlow$()
            .pipe(
                specialInstructionsPipe
            );
    }

    public showSpecialInstructions$(type: State.MODAL_TYPE): Observable<boolean> {
        const specialInstructionsPipe = pipe(
            filter(product => product !== undefined && product !== null),
            map((product: State.IWizzardSimpleItem | State.IWizzardMenuFlow) => {
                if (product.SpecialInstructions) return true;
                if (product.Tags && product.Tags instanceof Array && product.Tags.find(tag => tag.Name.toLowerCase().replace(/\s/, '') === 'specialinstruction')) return true;
                if (this._config.showSpecialInstructionsForProducts === true && (type === 'product')) return true;
                if (this._config.showSpecialInstructionsForMenuFlows === true && (type !== 'product')) return true;

                return false;
            })
        );

        if (type === 'product') {
            return this.wizzardSimpleItem$()
                .pipe(
                    specialInstructionsPipe
                );
        }
        return this.wizzardMenuFlow$()
            .pipe(
                specialInstructionsPipe
            );
    }

    public wizzardMenuFlowIncrement(): void {
        this._store.dispatch(actions.WizzardMenuFlowIncrement());
    }

    public wizzardSimpleItemIncrement(): void {
        this._store
            .pipe(
                select(selectors.getWizzardSimpleItem),
                take(1)
            ).subscribe(product => this._store.dispatch(actions.WizzardSimpleItemIncrement(product)));
    }

    public wizzardMenuFlowDecrement(): void {
        this._store.dispatch(actions.WizzardMenuFlowDecrement());
    }

    public wizzardSimpleItemDecrement(): void {
        this._store
            .pipe(
                select(selectors.getWizzardSimpleItem),
                take(1)
            ).subscribe(product => this._store.dispatch(actions.WizzardSimpleItemDecrement(product)));
    }

    public wizzardMenuFlowCTAButtonClick(modal: State.IModal): void {
        if (modal.type === 'upsell') {
            this._store
                .pipe(
                    select(selectors.getWizzardTotalSelectedProducts),
                    take(1)
                ).subscribe(total => {
                    if (!total) {
                        return this._store.dispatch(actions.WizzardCancel({ modalId: modal.id }));
                    }

                    this.wizzardMenuFlowAddToCart(modal.id, modal.locationNo);
                });

            return;
        }

        this.wizzardMenuFlowAddToCart(modal.id, modal.locationNo);
    }

    public wizzardMenuFlowAddToCart(modalId: number, locationNo: number, clearQueryParams: boolean = true): void {
        this._store
            .pipe(
                select(selectors.getWizzardMenuFlow),
                withLatestFrom(
                    this._store
                        .pipe(
                            select(selectors.wizzardRecalculatePricesObj)
                        )
                ),
                filter(([menuFlow, recalculatePrices]) => recalculatePrices.hasSucceeded === true),
                take(1)
            ).subscribe(([menuFlow]) => {
                if (clearQueryParams) {
                    this._routeService.removeURLQueryParams();
                }

                this._store.dispatch(actions.CartSetup({ modalId, locationNo, item: menuFlow }));
            });
    }

    public canAddWizzardMenuFlowToCart$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.wizzardRecalculatePricesObj),
                map(obj => obj.hasSucceeded === true),
            );
    }

    public wizzardSimpleItemAddToCart(modalId: number, locationNo: number, clearQueryParams: boolean = true): void {
        this._store
            .pipe(
                select(selectors.getWizzardSimpleItem),
                take(1)
            ).subscribe(product => {
                if (clearQueryParams) {
                    this._routeService.removeURLQueryParams();
                }

                this._store.dispatch(actions.CartSetup({ modalId, locationNo, item: product }));
            });
    }

    public selectOnlineMenuProduct(product: APIv1.OnlineMenuProductResponseModel): void {
        this._store
            .pipe(
                select(selectors.getCurrentLocationNo),
                take(1)
            ).subscribe(locationNo => this._store.dispatch(actions.WizzardSetupItem(locationNo, product)));
    }

    public selectIngredientForWizzardMenuFlowProduct(pageIdentifier: number, productId: number, option: APICommon.IIngredientModifierExtended & OLO.Components.DropDown.IDropDownOption): void {
        this._store.dispatch(
            actions.WizzardMenuFlowSelectModifier({ modifier: option, pageIdentifier: pageIdentifier, productId: productId })
        );
    }

    public handleWizzardMenuFlowProductClick(pageIdentifier: number, product: APIv1.MenuFlowProduct): void {
        this._store
            .pipe(
                select(selectors.productActionToTake(pageIdentifier, product.ProductId)),
                take(1)
            ).subscribe(decision => {
                if (!decision) return;

                switch (decision) {
                    case 'add':
                        return this._store.dispatch(
                            actions.WizzardMenuFlowAddProduct({
                                pageIdentifier: pageIdentifier,
                                product: {
                                    ...product,
                                    IngredientsChanges: {
                                        IngredientsModified: [], /* TODO */
                                        IngredientsAdded: [], /* TODO */
                                        IngredientsRemoved: [], /* NOT IN USE */
                                        IngredientsSwapped: [], /* NOT IN USE */
                                    }
                                }
                            }
                            )
                        );

                    case 'remove':
                        return this._store.dispatch(
                            actions.WizzardMenuFlowRemoveProduct({
                                pageIdentifier: pageIdentifier,
                                productId: product.ProductId
                            }
                            )
                        );

                    case 'replace':
                        return this._store.dispatch(
                            actions.WizzardMenuFlowReplaceAllWithNew({
                                pageIdentifier: pageIdentifier,
                                product: {
                                    ...product,
                                    IngredientsChanges: {
                                        IngredientsModified: [], /* TODO */
                                        IngredientsAdded: [], /* TODO */
                                        IngredientsRemoved: [], /* NOT IN USE */
                                        IngredientsSwapped: [], /* NOT IN USE */
                                    }
                                }
                            }
                            )
                        );
                }
            });
    }

    public incrementMenuFlowProduct(pageIdentifier: number, productId: number): void {
        this._store
            .pipe(
                select(selectors.getWizzardMenuFlowProduct(pageIdentifier, productId)),
                take(1)
            ).subscribe(product => {
                if (product.Quantity < product.MaximumQuantity) {
                    return this._store.dispatch(actions.WizzardMenuFlowProductIncrement(pageIdentifier, productId));
                }
                console.warn(`You cannot add more product than maximum quantity`);
            });


    }

    public decrementMenuFlowProduct(pageIdentifier: number, productId: number): void {
        this._store
            .pipe(
                select(selectors.getWizzardMenuFlowProductTotalQuantity(pageIdentifier, productId)),
                take(1)
            ).subscribe(total => {
                if (total <= 1) {
                    return this._store.dispatch(actions.WizzardMenuFlowRemoveProduct({ pageIdentifier, productId }));
                }
                this._store.dispatch(actions.WizzardMenuFlowProductDecrement(pageIdentifier, productId));
            });
    }



    public setWizzardSpecialInstructions(type: State.MODAL_TYPE, instruction: string): void {
        if (type === 'product') {
            return this.setProductWizzardInstructions(instruction);
        }

        this.setMenuFlowWizzardInstructions(instruction);
    }

    public setMenuFlowWizzardInstructions(instruction: string): void {
        this._store.dispatch(actions.WizzardMenuFlowSpecialInstructions(instruction));
    }

    public setProductWizzardInstructions(instruction: string): void {
        this._store.dispatch(actions.WizzardSimpleItemSpecialInstructions(null, instruction));
    }
}
