/* https://github.com/pmiatkowski/angularNgrx/blob/master/src/app/state/effects/currency.effects.ts */
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as actions from './locations.actions';
import * as Services from '@shared/core/services';

import { Observable, of } from 'rxjs';
import { mergeMap, catchError, map } from 'rxjs/operators';

@Injectable()
export class LocationsEffects {

    // @Effect() requestLocations$: Observable<Action> = this._actions$
    //     .pipe(
    //         ofType(actions.LocationsRequest),
    //         mergeMap(action => {
    //             return this._locationsService.getLocations(action.params)
    //                 .pipe(
    //                     map(response => actions.LocationsSuccessRequest({ params: action.params, payload: response.Items })),
    //                     catchError(ex => {
    //                         return of(actions.LocationsErrorRequest(action.params, ex));
    //                     })
    //                 );
    //         }),
    //     );

    @Effect() requestLocations$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.LocationsRequest),
            mergeMap(action => {
                return this._locationsService.getLocationsWithOnlineOrdering(action.params)
                    .pipe(
                        map(payload => actions.LocationsSuccessRequest({ params: action.params, payload })),
                        catchError(ex => {
                            return of(actions.LocationsErrorRequest(action.params, ex));
                        })
                    );
            }),
        );

    constructor(
        private _actions$: Actions,
        private _locationsService: Services.LocationsService,
    ) { }

}
