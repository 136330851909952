import * as State from '@shared/state';

import { Items } from './items.utils';
import { Dates } from './dates.utils';


export class OnlineOrders {
    public static extendWithOrderTypeSurcharges(onlineOrder: APIv1.OnlineOrderDetailedBusinessModel, orderType: APICommon.OrderTypeExtended, extendItemsModel: boolean): APIv1.OnlineOrderDetailedBusinessModel {
        const baseModel: APIv1.OnlineOrderDetailedBusinessModel = {
            OrderTypeId: orderType?.Id || null,
            OrderTypeDetails: orderType?.Details?.reduce((acc, obj) => {
                if (!obj._Value) return acc;
                return [
                    ...acc,
                    {
                        DetailsDefinitionId: obj.Id,
                        ValueProvided: obj._Value || null,
                    }
                ];
            }, []) || null,
            Items: [
                ...onlineOrder.Items,
            ],
            Medias: [
                ...onlineOrder.Medias,
            ],
        };


        if (extendItemsModel && orderType?.Surcharges?.length > 0) {
            baseModel.Surcharges = [
                ...orderType.Surcharges.map(obj => {
                    baseModel.Items.push({
                        PLU: obj.PLU,
                        Value: obj.Value,
                        Quantity: 1,
                    });

                    return {
                        ...obj,
                        Id: null,
                        OrderSurchargeDefinitionId: obj.Id,
                    };
                })
            ];
        }

        return baseModel;
    }

    public static onlineOrderModelFix(order: APIv1.OnlineOrderDetailedBusinessModel): APIv1.OnlineOrderDetailedBusinessModel {
        order.Items = order.Items.filter(item => {
            const foundMenuFlowActivation = order.MenuFlowActivations.some(menuFlow => {
                return menuFlow.MenuFlowItems.some(menuFlowItem => menuFlowItem.OnlineOrderItemId === item.Id);
            });

            const foundSurcharge = order.Surcharges.some(surcharge => {
                return surcharge.PLU === item.PLU;
            });

            return !foundMenuFlowActivation && !foundSurcharge;
        });

        return order;
    }

    public static isOrderActive(status: OLO.Enums.ONLINE_ORDER_STATUS): boolean {
        return status >= OLO.Enums.ONLINE_ORDER_STATUS.VALIDATED && status < OLO.Enums.ONLINE_ORDER_STATUS.FINALIZED;
    }

    public static isOrderFinalized(status: OLO.Enums.ONLINE_ORDER_STATUS): boolean {
        return status === OLO.Enums.ONLINE_ORDER_STATUS.FINALIZED;
    }

    public static canOrder(
        location: APIv1.OnlineOrderingLocationBusinessModel,
        futureOrders: boolean,
        asapPickupMins: number,
        openingHours: APIv1.OpeningHoursModel[],
        orderTimeoutBufferMins: number,
        startBufferMins: number,
    ): boolean {
        const p: OLO.Ordering.IPickupForLocation = Dates.calcInitialTimesObj(location.LocationNo, asapPickupMins, openingHours, orderTimeoutBufferMins, startBufferMins);

        if (p === null) { /* Location is closed and wont be open today */
            if (futureOrders && openingHours && openingHours.length > 0) return true;
            return false;
        }
        return p.MinimumPickupTime >= p.OpeningTime && p.MaximumPickupTime <= p.ClosingTime;
    }

    public static unduplicateOrderItems(order: APIv1.OnlineOrderDetailedBusinessModel): APIv1.OnlineOrderDetailedBusinessModel {
        if (!order) return null;
        const newOrder: APIv1.OnlineOrderDetailedBusinessModel = JSON.parse(JSON.stringify(order));
        const menuFlowProducts: number[] = [];

        newOrder.MenuFlowActivations.forEach(MenuFlow => MenuFlow.MenuFlowItems.forEach(item => menuFlowProducts.push(item.OnlineOrderItemId)));
        newOrder.Items = newOrder.Items.filter(item => !menuFlowProducts.includes(item.Id));

        return newOrder;
    }

    public static generateDescriptionForMenuFlowActivationItem(menuFlowActivation: APIv1.OnlineOrderMenuFlowActivation): string {
        return menuFlowActivation.MenuFlowItems.reduce((acc, item) => {
            if (item.IsHiddenFromUser) return acc;

            let qty = '';
            let prefix = '';
            if (acc) {
                prefix = ', ';
            }
            if (item.Quantity > 1) {
                qty = `${item.Quantity}x `;
            }

            let modifiersDescription: string = item.IngredientsChanges.IngredientsModified.reduce((accM, itemM, indexM) => {
                return accM += `${indexM && accM ? ', ' : ''}${itemM.ModifierName}`;
            }, ``);

            return acc += `${prefix}${qty}${item.DisplayName}${modifiersDescription ? `(${modifiersDescription})` : ''}`;

        }, '').replace(/\s{1,},/g, ',');
    }

    public static generateDescriptionForTransactionMenuFlowActivationItem(menuFlowActivation: APIv1.LoyaltyAppTransactionMenuFlow): string {
        return menuFlowActivation.MenuFlowProducts.reduce((acc, item) => {

            let qty = '';
            let prefix = '';
            if (acc) {
                prefix = ', ';
            }
            if (item.Quantity > 1) {
                qty = `${item.Quantity}x `;
            }

            let modifiersDescription: string = '';
            // let modifiersDescription: string = item.IngredientsChanges.IngredientsModified.reduce((accM, itemM, indexM) => {
            //     return accM += `${indexM && accM ? ', ' : ''}${itemM.ModifierName}`;
            // }, ``);

            return acc += `${prefix}${qty}${item.POSDisplay}${modifiersDescription ? `(${modifiersDescription})` : ''}`;

        }, '').replace(/\s{1,},/g, ',');
    }

    public static generateDescriptionFromTransaction(transaction: APIv1.LoyaltyAppTransactionModel): string {
        if (!transaction) return null;

        let description = ``;

        const descBuilder = (obj: APIv1.LoyaltyAppTransactionMenuFlow & APIv1.LoyaltyAppTransactionProduct) => {
            if (obj.ProductID === -99999) return;
            let prefix = '';
            let qty = '';
            if (!description) {
                prefix = '';
            } else {
                prefix = ', ';
            }
            if (obj.Quantity > 1) {
                qty = `${(obj as any).Quantity}x `;
            }

            description += `${prefix}${qty}${obj.MenuFlowDescription || obj.POSDisplay}`;
        };

        transaction.TransactionMenuFlows.forEach(descBuilder);
        transaction.TransactionProducts.forEach(descBuilder);

        return description.replace(/\s{1,},/g, ',');
    }

    public static generateDescriptionFromOrder(order: APIv1.OnlineOrderDetailedBusinessModel): string {
        if (!order) return null;

        const fixedOrder = OnlineOrders.unduplicateOrderItems(order);
        let description = ``;

        const descBuilder = (obj) => {
            let prefix = '';
            let qty = '';
            if (!description) {
                prefix = '';
            } else {
                prefix = ', ';
            }
            if (obj.Quantity > 1) {
                qty = `${obj.Quantity}x `;
            }

            description += `${prefix}${qty}${obj.DisplayName}`;
        };

        fixedOrder.MenuFlowActivations.forEach(descBuilder);
        fixedOrder.Items.forEach(descBuilder);

        return description.replace(/\s{1,},/g, ',');
    }

    public static covertOrderToCart(
        onlineOrder: APIv1.OnlineOrderDetailedBusinessModel,
        onlineMenu: APIv1.OnlineMenuResponseModel,
        menuFlowsDetails: APIv1.MenuFlowDetailsModel[],
        ingredients: APICommon.IProductLocationIngredientExtended[],
        pickupTime: OLO.Ordering.IPickupTime,
        fixMenuFlowActivationsDescriptions: boolean = true,
    ): OLO.Ordering.IOnlineOrder2CartConverter {
        const cart: State.ICart = {
            locationNo: onlineOrder.PickupLocation,
            onlineMenu,
            pickupTime: { ...pickupTime },
            itemsMenuFlow: null,
            itemsSimple: null,
            // pendingCartClear: false,
        };

        const errors: OLO.Errors.IOrder2CartConvertErrors = {
            general: [],
            menuFlows: [],
            simpleItems: [],
        };
        const order: APIv1.OnlineOrderDetailedBusinessModel = OnlineOrders.unduplicateOrderItems(onlineOrder);

        /* #1 deal with menuflows */
        const itemsMenuFlow = order.MenuFlowActivations.map(menuFlowActivation => {
            /* Get relevant online menu page and menu flow details for current menu flow activation */
            const baseOnlineMenuPage: APIv1.OnlineMenuProductResponseModel = onlineMenu.Pages.reduce<APIv1.OnlineMenuProductResponseModel>((acc, page) => {
                if (acc) return acc;
                const foundCorespondingMenuFlow = page.Products.find(obj => obj.MenuFlowId === menuFlowActivation.MenuFlowId);
                if (foundCorespondingMenuFlow) {
                    return foundCorespondingMenuFlow;
                }
                return acc;
            }, null);

            let hasErrors: boolean = false;

            const baseMenuFlowDetails: APIv1.MenuFlowDetailsModel = menuFlowsDetails.find(obj => obj.MenuFlowId === menuFlowActivation.MenuFlowId && obj.IsActive === true);

            const isMenuFlowUpsell: boolean = menuFlowActivation.IsUpsell === true && baseOnlineMenuPage === null;
            if (!isMenuFlowUpsell) {
                if (!baseOnlineMenuPage) {
                    errors.menuFlows.push({
                        errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                        menuFlowId: menuFlowActivation.Id,
                        error: 'unable to find coresponding online menu page for menu flow activation item',
                    });
                    hasErrors = true;
                    return null;
                }

                if (baseOnlineMenuPage.State !== 0) {
                    errors.menuFlows.push({
                        errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                        menuFlowId: menuFlowActivation.Id,
                        error: `menuFlow (${baseOnlineMenuPage.MenuFlowId}) is currently unavailable - state property is not 0 (${baseOnlineMenuPage.State})`,
                    });
                    hasErrors = true;
                    return null;
                }
            }

            if (!baseMenuFlowDetails) {
                errors.menuFlows.push({
                    errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                    menuFlowId: menuFlowActivation.Id,
                    error: 'unable to find coresponding menu flow in online menu',
                });
                hasErrors = true;
                return null;
            }

            /* Restore pages container */
            const generatedPages: State.ICartMenuFlowPage[] = Items.createMenuFlowItemPagesFromMenuFlowDetailsModel<State.ICartMenuFlowPageProduct>(baseMenuFlowDetails);

            menuFlowActivation.MenuFlowItems.forEach(menuFlowProduct => {
                const basePageForProduct: APIv1.MenuFlowPage = baseMenuFlowDetails.Pages.find(obj => obj.PageNo === menuFlowProduct.MenuFlowPageId);
                /* Validate page params */
                if (!basePageForProduct) {
                    errors.menuFlows.push({
                        errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                        menuFlowId: menuFlowActivation.Id,
                        error: `unable to find coresponding menu flow page (${menuFlowProduct.MenuFlowPageId}) in menu flow details`,
                    });
                    hasErrors = true;
                    return;
                }

                const baseMenuFlowProduct: APIv1.MenuFlowProduct = basePageForProduct.Products.find(product => product.Plu === menuFlowProduct.PLU);
                /* Validate product params */
                if (!baseMenuFlowProduct) {
                    errors.menuFlows.push({
                        errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                        menuFlowId: menuFlowActivation.Id,
                        error: `unable to find coresponding menu flow product (${menuFlowProduct.PLU}) in menu flow details`,
                    });
                    hasErrors = true;
                    return;
                }

                // if (baseMenuFlowProduct.ProductId === 113608) {
                //     console.warn('baseMenuFlowProduct', baseMenuFlowProduct, baseOnlineMenuPage)
                // }

                if (baseMenuFlowProduct.State !== 0) {
                    errors.menuFlows.push({
                        errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                        menuFlowId: menuFlowActivation.Id,
                        error: `product (${menuFlowProduct.PLU}) is currently unavailable - state property is not 0 (${baseMenuFlowProduct.State})`,
                    });
                    hasErrors = true;
                    return;
                }


                /* Fetch page */
                const pageToFetch = generatedPages.find(obj => obj.PageIdentifier === basePageForProduct.PageIdentifier);
                if (!pageToFetch) {
                    errors.menuFlows.push({
                        errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                        menuFlowId: menuFlowActivation.Id,
                        error: `Unable to find page in generated pages - looking for ${basePageForProduct.PageIdentifier} in menuFlow ${menuFlowProduct.OnlineOrderMenuFlowActivationId} for product ${menuFlowProduct.DisplayName} with PLU ${menuFlowProduct.PLU}`,
                    });
                    hasErrors = true;
                    return;
                }

                function extendIngredients(ingredient: APIv1.OnlineOrderItemIngredientModification): APICommon.IIngredientModifierExtended {
                    // const freashIngredient: Models.IProductLocationIngredientExtended = ingredients.find(obj => obj.ProductID === menuFlowProduct.Id);
                    const freashIngredient: APICommon.IProductLocationIngredientExtended = ingredients.find(obj => obj.PLU === menuFlowProduct.PLU);

                    if (!freashIngredient) {
                        errors.menuFlows.push({
                            errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                            menuFlowId: menuFlowActivation.Id,
                            error: `Unable to find coresponding ingredient ${ingredient.IngredientPLU} for product ${menuFlowProduct.Id}`,
                        });

                        hasErrors = true;
                    }

                    let modifier: APICommon.IIngredientModifierExtended;
                    if (freashIngredient) {
                        modifier = freashIngredient.Ingredients[0].Modifiers.find(obj => obj.ModifierID === ingredient.ModifierID);

                        if (!modifier) {
                            errors.menuFlows.push({
                                errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                                menuFlowId: menuFlowActivation.Id,
                                error: `Unable to find coresponding ingredient modifier ${ingredient.ID} ${ingredient.ModifierName} for product ${menuFlowProduct.Id}`,
                            });
                            hasErrors = true;
                        }
                    }

                    return {
                        ...ingredient,
                        ...modifier,
                        _IsOptional: modifier ? modifier._IsOptional : null,
                        _ProductId: baseMenuFlowProduct.ProductId,
                        _LocationNo: order.PickupLocation,
                        _ProductPLU: baseMenuFlowProduct.Plu,
                        ModifierName: modifier ? modifier.ModifierName : null,
                    };
                }

                const productForPage = {
                    ...baseMenuFlowProduct,
                    ...menuFlowProduct as any,
                    IngredientsChanges: {
                        ...(menuFlowProduct.IngredientsChanges as OLO.Ordering.IMenuFlowItemPageProductIngredientChanges),
                        IngredientsModified: menuFlowProduct.IngredientsChanges.IngredientsModified.map(extendIngredients),
                        IngredientsAdded: menuFlowProduct.IngredientsChanges.IngredientsAdded.map(extendIngredients),
                    }
                };


                pageToFetch.Products.push(productForPage);

            });

            const newMenuFlowActivation: State.ICartMenuFlowExtended = Items.createMenuFlowItemFromOnlineOrder(menuFlowActivation, baseMenuFlowDetails, {
                LocationNo: order.PickupLocation,
                _IsDisabled: hasErrors,
                _Id: menuFlowActivation.Id,
                IsUpsell: menuFlowActivation.IsUpsell || false,
                Pages: generatedPages,
                DisplayDescription: fixMenuFlowActivationsDescriptions ? OnlineOrders.menuFlowActivationItemsDescription(menuFlowActivation).DisplayDescription : null,
            });
            return Items.updateMenuFlowItemPrices(baseMenuFlowDetails, newMenuFlowActivation);
        });

        /* #2 deal with products */
        const itemsSimple: State.ICartSimpleItem[] = order.Items.reduce((acc, item: APIv1.OnlineOrderItemModel) => {
            let baseProduct: APIv1.OnlineMenuProductResponseModel;
            let hasErrors: boolean = false;

            const baseOnlineMenuPage: APIv1.OnlineMenuPageResponseModel = onlineMenu.Pages.reduce<APIv1.OnlineMenuPageResponseModel>((innerAcc, page) => {
                if (innerAcc) return innerAcc;
                baseProduct = page.Products.find(obj => obj.Plu === item.PLU);
                if (baseProduct) {
                    innerAcc = page;
                }
                return innerAcc;
            }, null);

            if (!baseOnlineMenuPage) {
                errors.simpleItems.push({
                    errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                    productId: item.Id,
                    error: `Unable to find BASE PAGE for simple product ${item.DisplayName} with PLU ${item.PLU}`,
                });
                hasErrors = true;
            }

            if (!baseProduct) {
                errors.simpleItems.push({
                    errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                    productId: item.Id,
                    error: `Unable to find BASE PRODUCT for simple product  ${item.DisplayName} with PLU ${item.PLU}`,
                });
                hasErrors = true;
            }

            if (baseProduct && baseProduct.State !== 0) {
                errors.simpleItems.push({
                    errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                    productId: item.Id,
                    error: `Product  ${item.DisplayName} with PLU ${item.PLU} is currently unavailable - state property is not 0 (${baseProduct.State})`,
                });
                hasErrors = true;
            }

            return [
                ...acc,
                Items.createSimpleItemFromOnlineOrderItemModel(item, {
                    _IsDisabled: hasErrors,
                    LocationNo: order.PickupLocation,
                    DietaryTags: baseProduct ? baseProduct.DietaryTags : null,
                }),
            ];

        }, []);

        /* #3 check if order pickupTime is in current online menu range */
        const startTime = Dates.createHoursIntFromDate(onlineMenu.StartTime);
        const endTime = Dates.createHoursIntFromDate(onlineMenu.EndTime);
        const orderPickupTime = Dates.createHoursIntFromDate(order.PickUpDate);

        if (orderPickupTime < startTime || orderPickupTime > endTime) {
            errors.general.push({
                errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                error: `Order pickupTime not in range of online menu StartTime and EndTime`,
            });
        }

        cart.itemsMenuFlow = itemsMenuFlow.filter(item => item !== null && item !== undefined);
        cart.itemsSimple = itemsSimple;

        return {
            cart,
            errors,
        };
    }

    public static convertCart(saleName: string, cart: State.ICart, PickupObj: OLO.Ordering.IPickupTime, extraParams: APICommon.ICartToOrderConvertExtraParams = {}): APIv1.OnlineOrderDetailedBusinessModel {
        if (!PickupObj) return null;
        /*
            Doing https://lh3.googleusercontent.com/V8ehXTGa-NIpxHJjZKxOtajAUlhIZE483BpEPH49nJRCxJfX4ifC8d8WU9bXr0n9uQqwLtTdivfuog=w270-h130-rw-no stuff...
        */
        const locationNo: number = cart.locationNo;
        /* Brejnfak, normalize hours */
        const createdDate = Dates.getLocalISOFormatDate(new Date(), true);
        const pickupTime = PickupObj.DateLocalISO + 'Z'; /* Z is required in our sick api */

        return {
            SaleName: `${saleName} ${createdDate.replace(/:\d{2}\.\d{3}Z?/i, '')}`,
            MemberId: extraParams.MemberId || null,
            PartialMember: extraParams.PartialMember || null,
            OnlineOrderType: extraParams.OnlineOrderType || 0,
            OrderTypeId: extraParams.OrderTypeId || null,
            PickupLocation: locationNo,
            PickUpDate: pickupTime,
            OrderedDate: createdDate,
            IsDelivery: false,
            Status: OLO.Enums.ONLINE_ORDER_STATUS.CREATED,
            SendToKMS: true,
            OnlineDiscounts: [],
            Medias: [
                /* Boldman says 'NO MEDIA UGHhhh' */

            ],
            Items: cart.itemsSimple.map(product => {
                return {
                    ...product,
                    PLU: product.Plu,
                    Value: product.UnitPrice * product.Quantity,
                    Modifiers: [],
                    IngredientsChanges: {
                        IngredientsModified: [],
                        IngredientsRemoved: [],
                        IngredientsAdded: [],
                        IngredientsSwapped: [],
                    }

                };
            }),

            MenuFlowActivations: [
                /* MenuFlowActivations start */
                ...cart.itemsMenuFlow.map(menuFlow => {
                    return {
                        Id: null,
                        IsUpsell: menuFlow.IsUpsell || false,
                        MenuFlowId: menuFlow.MenuFlowId,
                        Quantity: menuFlow.Quantity,
                        UnitPrice: menuFlow.UnitPrice,
                        SpecialInstructions: menuFlow.SpecialInstructions || null,
                        Value: menuFlow.UnitPrice * menuFlow.Quantity, /* ( Value including UnitPrice and total menu flows products value ) * Quantity */
                        MenuFlowItems: [

                            ...menuFlow.Pages.reduce((acc, page) => {
                                return acc.concat(
                                    page.Products.map(product => ({
                                        Id: null,
                                        OnlineOrderItemId: null,
                                        Type: null,
                                        MenuFlowPageId: product.PageNo,
                                        PLU: product.Plu,
                                        Quantity: product.Quantity,

                                        /* These values should be CALCULATED using condition for Page */
                                        UnitPrice: product.UnitPrice,
                                        Value: product.UnitPrice * product.Quantity,

                                        IngredientsChanges: {
                                            IngredientsModified: extraParams.RemoveModifiers ? [] : OnlineOrders.modifierPropsTrimmer<APIv1.OnlineOrderItemIngredientModification>(product, `IngredientsModified`), // product.IngredientsChanges && product.IngredientsChanges.IngredientsModified ? product.IngredientsChanges.IngredientsModified : [],
                                            IngredientsRemoved: extraParams.RemoveModifiers ? [] : OnlineOrders.modifierPropsTrimmer<APIv1.OnlineOrderItemIngredientRemoval>(product, `IngredientsRemoved`), // product.IngredientsChanges && product.IngredientsChanges.IngredientsRemoved ? product.IngredientsChanges.IngredientsRemoved : [],
                                            IngredientsAdded: extraParams.RemoveModifiers ? [] : OnlineOrders.modifierPropsTrimmer<APIv1.OnlineOrderItemIngredientAddition>(product, `IngredientsAdded`), // product.IngredientsChanges && product.IngredientsChanges.IngredientsAdded ? product.IngredientsChanges.IngredientsAdded : [],
                                            IngredientsSwapped: extraParams.RemoveModifiers ? [] : OnlineOrders.modifierPropsTrimmer<APIv1.OnlineOrderItemIngredientSwap>(product, `IngredientsSwapped`), // product.IngredientsChanges && product.IngredientsChanges.IngredientsSwapped ? product.IngredientsChanges.IngredientsSwapped : [],
                                        }
                                    }))
                                );
                            }, []),

                        ]
                    };
                }),
                /* MenuFlowActivations end */
            ],

        };
    }

    public static modifierPropsTrimmer<T>(product: State.IWizzardMenuFlowItem, propName: string): T[] {
        if (!product.IngredientsChanges || !product.IngredientsChanges[propName]) return [];

        const trimmedArray: T[] = [];

        product.IngredientsChanges[propName].forEach(modifier => {
            const newModifierObj: T = {} as T;

            Object.keys(modifier).forEach(key => {
                if (key.charAt(0) !== '_') {
                    newModifierObj[key] = modifier[key];
                }
            });

            trimmedArray.push(newModifierObj);
        });

        return trimmedArray;
    }

    public static mapOnlineOrderProducts(order: APIv1.OnlineOrderDetailedBusinessModel, fixMenuFlowDescriptions: boolean = false): OLO.Ordering.IOnlineOrderMappedProducts {
        const distinctMenuFlowProducts: number[] = [];

        const obj: OLO.Ordering.IOnlineOrderMappedProducts = {
            itemsSimple: [],
            itemsMenuFlow: [],
        };

        order.MenuFlowActivations.forEach(menuFlowActivation => {
            menuFlowActivation.MenuFlowItems.forEach(item => distinctMenuFlowProducts.push(item.OnlineOrderItemId));
        });

        obj.itemsMenuFlow = [
            ...order.MenuFlowActivations,
        ];

        obj.itemsSimple = [
            ...order.Items,
        ].filter(item => !distinctMenuFlowProducts.includes(item.Id));

        if (fixMenuFlowDescriptions) {
            obj.itemsMenuFlow = obj.itemsMenuFlow.map(OnlineOrders.menuFlowActivationItemsDescription);
        }

        return obj;
    }

    public static menuFlowActivationItemsDescription(menuFlowActivation: APIv1.OnlineOrderMenuFlowActivation): APIv1.OnlineOrderMenuFlowActivation {
        return {
            ...menuFlowActivation,
            DisplayDescription: OnlineOrders.generateDescriptionForMenuFlowActivationItem(menuFlowActivation),
            // DisplayDescription: menuFlowActivation.MenuFlowItems.reduce((acc, item, index) => {
            //     if (item.IsHiddenFromUser) {
            //         return acc;
            //     };

            //     let prefix: string = `, `;

            //     let modifiersDescription: string = item.IngredientsChanges.IngredientsModified.reduce((accM, itemM, indexM) => {
            //         return accM += `${indexM && accM ? prefix : ''}${itemM.ModifierName}`;
            //     }, ``);

            //     return acc += `${index && acc ? prefix : ''}${item.Quantity}x ${item.DisplayName}${modifiersDescription ? ` (${modifiersDescription})` : ''}`

            // }, ``)
        };
    }
}

