import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as State from '@shared/state';
import * as Tokens from '@shared/core/tokens';
import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';

import { Observable, of } from 'rxjs';
import { map, delay, distinctUntilChanged, auditTime, filter, combineLatest, withLatestFrom, take, tap, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class OnlineOrdersController {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _store: Store<State.IStateShared>,
        private _modalsService: Services.ModalsService,
    ) { }

    public sendEmailReceipt(orderId: number): void {
        this._store.dispatch(actions.OnlineOrderSendEmailReceiptRequest({ orderId }));
    }

    public isSendingEmailReceipt$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isSendingEmailReceipt)
            );
    }

    public resetSendingEmailReceiptState(): void {
        this._store
            .pipe(
                select(selectors.isSendingEmailReceipt),
                filter(isSending => isSending === false),
                take(1)
            ).subscribe(() => {
                this._store.dispatch(actions.OnlineOrderSendEmailReceiptReset());
            });
    }

    public isOrderTypeValid$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isOrderTypeValid),
            );
    }

    public getMappedErrors$(): Observable<OLO.Components.IMappedMessage> {
        return this._store
            .pipe(
                select(selectors.getOrderErrorsMapped)
            );
    }

    public async fillOutOrderType(formValues: { [key: string]: string; }, orderType: APIv1.OrderType): Promise<boolean> {
        const values: APICommon.OrderTypeDetailDefinitionExtended[] = Object.keys(formValues).reduce((acc, key) => {
            const found: APIv1.OrderTypeDetailDefinition = orderType.Details.find(obj => Utils.Forms.labelToName(obj.CustomerFriendlyName) === key);
            if (!found) return acc;
            acc.push({
                ...found,
                _Value: formValues[key]
            });
            return acc;
        }, []);

        if (values) {
            this._store.dispatch(actions.OnlineOrderTypeUpdateValues({ values }));
            return true;
        }

        return false;
    }

    public showOrderTypesInfoForCurrentCart(): void {
        this._store
            .pipe(
                select(selectors.getCartLocationNo),
                take(1)
            ).subscribe(locationNo => {
                this._modalsService.show({
                    type: 'order-types',
                    locationNo,
                });
            });
    }

    public getOrderTypesForCartsLocation$(): Observable<APIv1.OrderType[]> {
        return this._store
            .pipe(
                select(selectors.getOrderTypesForCartsLocation)
            );
    }

    public getOrderTypesForCartsLocationMapped$(): Observable<OLO.Components.OptionTabs.IOption[]> {
        return this.getOrderTypesForCartsLocation$()
            .pipe(
                map(types => {
                    if (!types) return null;

                    return types.map(obj => ({
                        Id: obj.Id,
                        Name: obj.Description,
                        ...obj,
                    }));
                })
            );
    }

    public selectOrderType(orderType: APIv1.OrderType, forceRecalculate: boolean = false): void {
        this._store.dispatch(actions.OnlineOrderTypeSelect({ orderType }));
        if (forceRecalculate) {
            this._store.dispatch(actions.OnlineOrderRecalculateRequest());
        }
    }

    public resetOrderTypeValues(): void {
        this._store.dispatch(actions.OnlineOrderResetOrderTypeValues());
    }

    public selectDefaultOrderType(): void {
        this._store
            .pipe(
                select(selectors.isDownloadingAnyOrderTypes),
                delay(100),
                filter(isDownloading => isDownloading === false),
                take(1),
                switchMap(() => {
                    return this._store
                        .pipe(
                            select(selectors.getOrderTypesForCartsLocation),
                            take(1),
                        );
                }),
                withLatestFrom(
                    this._store
                        .pipe(
                            select(selectors.getOnlineOrderState)
                        )
                )
            ).subscribe(([orderTypes, state]) => {
                if (!orderTypes || state.orderType !== null) return;

                this._store.dispatch(actions.OnlineOrderTypeSelect({ orderType: orderTypes[0] }));
            });
    }

    public getSelectedOrderType$(): Observable<APICommon.OrderTypeExtended> {
        return this._store
            .pipe(
                select(selectors.getSelectedOrderType)
            );
    }

    public getOrderTypesForLocation$(locationNo: number): Observable<State.IOrderType> {
        return this._store
            .pipe(
                select(selectors.getOrderTypesForLocation(locationNo))
            );
    }

    public orderSummary$(): Observable<OLO.Ordering.IOrderSummary> {
        return this._store
            .pipe(
                select(selectors.getOrderSummary)
            );
    }

    public isRecalculating$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isOnlineOrderRecalculating)
            );
    }

    public canPlaceOrder$(): Observable<boolean> {
        return this._store
            .pipe(
                // select(this._config.demoMode ? selectors.__DEMO__isPaymentDisabled : selectors.isPaymentDisabled),
                select(selectors.isPaymentDisabled(this._config)),
                map(isDisabled => !isDisabled)
            );
    }

    public totalGrossValue$(): Observable<number> {
        return this.orderSummary$()
            .pipe(
                map(summary => summary ? summary.Total : 0)
            );
    }

    public getReorder$(orderId: number, locationNo: number = null, pickupTime: OLO.Ordering.IPickupTime = null): Observable<State.IReorder> {
        return this._store
            .pipe(
                select(selectors.getCurrentPickupTime),
                combineLatest(
                    this._store
                        .pipe(
                            select(selectors.getHistoryOrder(orderId))
                        ),
                ),
                switchMap(([statePickupTime, order]) => {
                    if (!statePickupTime && !pickupTime || !order || !order.data) return of(null);

                    return this._store
                        .pipe(
                            select(selectors.getReorder(orderId, locationNo || order.data.PickupLocation, pickupTime || statePickupTime))
                        );
                })
            );
    }

    public reorderTotalSelectedItems$(orderId: number, locationNo: number = null, pickupTime: OLO.Ordering.IPickupTime = null): Observable<number> {
        return this.getReorder$(orderId, locationNo, pickupTime)
            .pipe(
                switchMap(reorder => {
                    if (!reorder) return of(0);

                    return this._store
                        .pipe(
                            select(selectors.reorderSelectedItemsTotalQuantity(reorder.OrderId, reorder.LocationNo, reorder.PickupTime))
                        );
                })
            );
    }

    public reorderTotalValue$(orderId: number, locationNo: number = null, pickupTime: OLO.Ordering.IPickupTime = null): Observable<number> {
        return this.getReorder$(orderId, locationNo, pickupTime)
            .pipe(
                switchMap(reorder => {
                    if (!reorder) return of(0);

                    return this._store
                        .pipe(
                            select(selectors.reorderSelectedItemsTotalValue(reorder.OrderId, reorder.LocationNo, reorder.PickupTime))
                        );
                })
            );
    }

    public reorderIsValid$(orderId: number, locationNo: number = null, pickupTime: OLO.Ordering.IPickupTime = null): Observable<boolean> {
        return this.getReorder$(orderId, locationNo, pickupTime)
            .pipe(
                switchMap(reorder => {
                    if (!reorder || reorder.isDownloading === true || reorder.hasSucceeded !== true || reorder.data === null) return of(false);

                    return this._store
                        .pipe(
                            select(selectors.canAddToCartReorderItems(reorder.OrderId, reorder.LocationNo, reorder.PickupTime))
                        );
                })
            );
    }

    public reorderItemsMappedFromOrder$(orderId: number, locationNo: number = null, pickupTime: OLO.Ordering.IPickupTime = null): Observable<OLO.Ordering.IOnlineOrderMappedProducts> {
        return this.getReorder$(orderId, locationNo, pickupTime)
            .pipe(
                combineLatest(
                    this._store
                        .pipe(
                            select(selectors.getHistoryOrder(orderId))
                        ),
                ),
                map(([reorder, order]) => {
                    if (!reorder || !reorder.data || !order || !order.data) return null;
                    return Utils.OnlineOrders.mapOnlineOrderProducts(order.data, true);
                }),
            );
    }

    public reorderItems$(orderId: number, locationNo: number = null, pickupTime: OLO.Ordering.IPickupTime = null): Observable<Array<State.ICartMenuFlowExtended | State.ICartSimpleItemExtended>> {
        return this.reorderItemsMappedFromOrder$(orderId, locationNo, pickupTime)
            .pipe(
                combineLatest(
                    this.getReorder$(orderId, locationNo, pickupTime),
                ),
                map(([orderItems, reorder]) => {
                    if (!reorder || !reorder.data || !reorder.data.cart || !orderItems) return null;
                    if (orderItems.itemsMenuFlow.length === 0 && orderItems.itemsSimple.length === 0) {
                        console.error('Corrupted data in online order', orderItems);
                        return null;
                    }
                    return [
                        ...orderItems.itemsMenuFlow.reduce((acc, orderItem) => {
                            const found = reorder.data.cart.itemsMenuFlow.find(obj => obj._Id === orderItem.Id);
                            if (found) {
                                acc.push(found);
                            }
                            return acc;
                        }, []),

                        ...orderItems.itemsSimple.reduce((acc, orderItem) => {
                            const found = reorder.data.cart.itemsSimple.find(obj => obj._Id === orderItem.Id);
                            if (found) {
                                acc.push(found);
                            }
                            return acc;
                        }, []),
                    ];
                }),
            );
    }
}
