import { Injectable, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Tokens from '@shared/core/tokens';
import * as State from '@shared/state/interface';
import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';

import * as StateModels from '../interface';

import { Observable, of, throwError, never } from 'rxjs';
import { switchMap, catchError, map, withLatestFrom, delay, take, tap, filter, combineLatest, auditTime } from 'rxjs/operators';

@Injectable()
export class CreditCardEffects {
    @Effect() public requestCardToken$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.GetCreditCardToken,
                actions.GetCreditCardTokenWithRedirect
            ),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getCartLocationNo)
                    )
            ),
            switchMap(([action, locationNo]) => {
                const cardType = Utils.CreditCards.detectCardType(action.cardNumber);
                const expiryDate: string = Utils.CreditCards.dateToApiFormat(action.expiryDate);
                // console.warn('DEMO MODE IS TEMP DISABLED HERE - REMEMBER TO UNCOMMENT BELOW LINE AFTER DEVELOPMENT FINISH!!!')
                if (this._config.demoMode === true) return of(actions.__DEMO__getCardToken(action));

                /*
                    Create card, get token, allow redirect if needed.
                    Redirects are controlled in specific form component on form submit. At least for payment express.
                */

                return this._creditCardsService.requestCardTokenForDefaultPaymentProvider({
                    cardNumber: action.cardNumber,
                    expiryDate
                }, locationNo)
                    .pipe(
                        switchMap(({ token, redirectUrl }) => {
                            const isDefaultPaymentMethod = typeof action.isDefaultPaymentMethod !== 'boolean' ? true : action.isDefaultPaymentMethod;
                            const saveCard = typeof action.saveCard === 'boolean' ? action.saveCard : false;

                            const successData: State.ICreditCardTokenResponse = {
                                token,
                                cardNumber: action.cardNumber,
                                expiryDate,
                                cardType,
                                saveCard: action.saveCard,
                                isDefaultPaymentMethod,
                                redirectUrl: redirectUrl || null,
                            };
                            if (action.type === actions.GetCreditCardTokenWithRedirect.type) {
                                const model: OLO.Members.IMemberCreditCardDetails = {
                                    ...Utils.CreditCards.createCardModel(this._config.paymentProvider, action.cardNumber, cardType, action.expiryDate, null, isDefaultPaymentMethod, saveCard),
                                    _ValidationStatus: 'validating',
                                };

                                return [
                                    actions.AddCardToState({ card: model }),
                                    actions.CreditCardsSuccessRequestTokenWithRedirect(successData)
                                ]; /* Handled in credit cards service and then passed to formPaymentMethodWithRedirect */
                            }

                            return of(actions.CreditCardsSuccessRequestToken(successData));
                        }),
                        catchError(ex => {
                            console.warn('cc errror', ex);
                            return of(actions.CreditCardsErrorRequestToken({ ex }));
                        })
                    );
            })
        );

    /* After redirect validation */
    @Effect() public validateCardOnAfterRedirect$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.CreditCardsValidateRequest),
            switchMap(action => {
                return this._store
                    .pipe(
                        select(selectors.getLoyaltyAppSettings),
                        filter(appSettings => appSettings.data !== null),
                        take(1),
                        switchMap(appSettings => {
                            return this._store
                                .pipe(
                                    select(selectors.getSessionToken),
                                    take(1),
                                    withLatestFrom(
                                        this._store
                                            .pipe(
                                                select(
                                                    selectors.getCartLocationNo
                                                )
                                            )
                                    ),
                                    switchMap(([sessionToken, cartLocationNo]) => {

                                        return this._paymentExpressPaymentProviderService.getCardDetails({ sessionToken, appId: appSettings.data.AppSettings.Id, locationNo: cartLocationNo })
                                            .pipe(
                                                map((response: APIv2.PaymentExpressCardIdResponse) => {
                                                    return actions.CreditCardsValidateSuccessRequest({ card: response });
                                                }),
                                                catchError(ex => {
                                                    console.error('Unable to get payment express card details', ex);
                                                    return of(actions.CreditCardsValidateErrorRequest());
                                                })
                                            );

                                    })
                                );
                        }),
                        catchError(ex => {
                            console.error('Unable to get payment express card details', ex);
                            return of(actions.CreditCardsValidateErrorRequest());
                        })
                    );
            })
        );

    @Effect() public checkCardsToSaveCardAfterSuccessfulRedirectReturn$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CreditCardsValidateSuccessRequest
            ),
            switchMap(action => {

                return this._store
                    .pipe(
                        select(selectors.getCards),
                        take(1),
                        switchMap(cards => {
                            const payloadToken: string = action.card.CardId;
                            const card: OLO.Members.IMemberCreditCardDetails = cards.find(obj => obj.Token === payloadToken && obj.hasOwnProperty('_SaveAwait') === true && obj._SaveAwait === true && obj.Id === null);

                            if (!card) return never();

                            return of(actions.CreditCardsAddAfterRedirectRequest({ card }));
                        })
                    );
            })
        );

    @Effect() public saveCardAfterSuccessfulRedirectReturnCheck$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CreditCardsAddAfterRedirectRequest
            ),
            switchMap(action => {
                return this._creditCardsService.addMemberCard(
                    Utils.CreditCards.createCardModel(
                        this._config.paymentProvider,
                        action.card.NiceName || action.card.DisplayName,
                        action.card.CardType,
                        action.card.ExpirationDate,
                        action.card.Token,
                        !!action.card.IsDefault,
                    )
                )
                    .pipe(
                        switchMap(response => {
                            return [
                                actions.SelectActiveCreditCardId({ cardId: response.Id }),
                                actions.CreditCardsAddAfterRedirectSuccessRequest({ card: action.card, newCard: response }),
                            ];
                        }),
                        catchError(ex => {
                            return of(actions.CreditCardsAddAfterRedirectErrorRequest({ card: action.card, ex }));
                        })
                    );
            })
        );

    @Effect() public onGetTokenSuccessSaveOrSetupCardStateForPayment$: Observable<any> = this._actions$
        .pipe(
            ofType(actions.CreditCardsSuccessRequestToken),
            switchMap(action => {
                const card: OLO.Members.IMemberCreditCardDetails = {
                    ...Utils.CreditCards.createCardModel(this._config.paymentProvider, action.cardNumber, action.cardType, action.expiryDate, action.token, action.isDefaultPaymentMethod),
                    _ValidationStatus: action.saveCard ? 'validating' : 'success',
                };

                if (action.saveCard) {
                    return of(actions.CreditCardsAddRequest({ card }));
                } else {
                    return [
                        actions.AddCardToState({ card }),
                        actions.SelectActiveCreditCardToken({ token: action.token })
                    ];
                }

            })
        );

    @Effect() public onCreditCardAddRequest$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.CreditCardsAddRequest),
            switchMap(action => {
                return this._creditCardsService.addMemberCard(action.card)
                    .pipe(
                        map(response =>
                            response ?
                                actions.CreditCardsAddSuccessRequest({ newCard: response }) :
                                actions.CreditCardsAddErrorRequest({})),
                        catchError(ex => {
                            return of(actions.CreditCardsAddErrorRequest({ ex }));
                        })
                    );
            })
        );

    @Effect() public onCreditCardAddRequestSuccess$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.CreditCardsAddSuccessRequest),
            switchMap(action => {
                return [
                    actions.CreditCardsRequest(),
                    actions.SelectActiveCreditCardId({ cardId: action.newCard.Id }),
                    actions.CreditCardShowForm({ isAdding: false }),
                ];
            })
        );

    @Effect() public onCreditCardsRequest$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.CreditCardsRequest),
            switchMap(action => {
                return this._creditCardsService.getCardItems()
                    .pipe(
                        map((freshCardsList) =>
                            freshCardsList ?
                                actions.CreditCardsSuccessRequest({ payload: freshCardsList.Items }) :
                                actions.CreditCardsErrorRequest({})),
                        catchError(ex => {
                            return of(actions.CreditCardsErrorRequest({ ex }));
                        })
                    );
            })
        );

    @Effect() public selectDefaultPaymentMethod$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CreditCardsSuccessRequest,
                actions.MemberAccountBalanceSuccessRequest,
            ),
            switchMap(() => {
                return this._store
                    .pipe(
                        select(selectors.memberHasAvailableBalanceToPayForCartOrder),
                        filter(hasAccountAvailable => this._config.accountCharge?.enabled && hasAccountAvailable !== null || this._config.accountCharge?.enabled !== true),
                        take(1),
                        withLatestFrom(
                            this._store
                                .pipe(
                                    select(selectors.getCardState),
                                    take(1)
                                )
                        ),
                        tap(([hasAccountAvailable, state]) => {
                            if (!hasAccountAvailable && state.data?.length === 0) {
                                this._store.dispatch(actions.CreditCardShowForm({ isAdding: true }));
                            } else {
                                this._store.dispatch(actions.CreditCardShowForm({ isAdding: false }));
                            }
                        }),
                        switchMap(([hasAccountAvailable, state]) => {
                            if (state.activeCardId || state.activeCardToken) return never();

                            const hasUnsavedCreditCard = state.data.find(obj => obj.Id === null && obj.Token === null && (obj._SaveAwait === true || obj._ValidationStatus === 'validating' || obj._ValidationStatus === 'error'));
                            if (hasUnsavedCreditCard) return never();

                            if (hasAccountAvailable) {
                                return of(actions.SelectActiveCreditCardId({ cardId: -1 }));
                            }
                            const defaultCard = state.data.find(obj => obj.IsDefault === true && obj.Id !== null);

                            if (defaultCard) {
                                return of(actions.SelectActiveCreditCardId({ cardId: defaultCard.Id }));
                            } else if (state.data.length > 0) {
                                const firstAvailableCard = state.data.find(obj => obj.Id !== null && obj.Id !== undefined);
                                if (firstAvailableCard) {
                                    return of(actions.SelectActiveCreditCardId({ cardId: firstAvailableCard.Id }));
                                }
                            }

                            return never();
                        })
                    );
            })
        );

    @Effect() public onCreditCardRemove$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.CreditCardsRemoveRequest),
            switchMap(({ cardId }) => {
                if (cardId !== 0) {
                    return this._creditCardsService.removeMemberCardRequest(cardId)
                        .pipe(
                            map(response =>
                                response ?
                                    actions.CreditCardsRemoveSuccessRequest({ cardId }) :
                                    actions.CreditCardsRemoveErrorRequest({})),
                            catchError(ex => {
                                return of(actions.CreditCardsRemoveErrorRequest({ ex }));
                            })
                        );
                } else {
                    return [actions.CreditCardsRemoveSuccessRequest({ cardId })];
                }
            })
        );

    /* DEMO MODE */
    @Effect() public __DEMO__requestConvergeCardToken$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.__DEMO__getCardToken),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getCartLocationNo)
                    )
            ),
            delay(2000),
            switchMap(([action, locationNo]) => {
                const cardType = Utils.CreditCards.detectCardType(action.cardNumber);

                return of(actions.__DEMO__CreditCardsSuccessRequestToken({ token: `demo-mode-${new Date().getTime()}`, cardNumber: action.cardNumber, expiryDate: action.expiryDate, cardType, saveCard: action.saveCard }));
            })
        );

    @Effect() public __DEMO__onGetConvergeTokenSuccessSaveOrSetupCardStateForPayment$: Observable<any> = this._actions$
        .pipe(
            ofType(actions.__DEMO__CreditCardsSuccessRequestToken),
            switchMap(action => {

                const model: OLO.Members.IMemberCreditCardDetails = {
                    ExpirationDate: action.expiryDate,
                    CardType: action.cardType,
                    Token: action.token,
                    DisplayName: action.cardNumber.substring(action.cardNumber.length - 4),
                    Id: null,
                    _ValidationStatus: 'success',
                };

                return [
                    actions.AddCardToState({ card: model }),
                    actions.SelectActiveCreditCardToken({ token: action.token })
                ];

            })
        );

    @Effect() public setErrorValidationFlagToCardsAwaitingValidationStatusSuccess$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CreditCardsAddAfterRedirectErrorRequest,
                actions.CreditCardsAddErrorRequest,
                actions.CreditCardsValidateErrorRequest
            ),
            switchMap(() => of(actions.CreditCardsSetErrorValidationStatusToValidatingCards()))
        );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _store: Store<StateModels.IStateShared>,
        private _actions$: Actions,
        private _creditCardsService: Services.CreditCardsService,
        private _paymentExpressPaymentProviderService: Services.PaymentExpressPaymentProviderService
    ) { }

}
