import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { CryptoService } from './crypto.shared.service';

import { Observable, throwError, of } from 'rxjs';
import { map, share, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class JWTService {
    public readonly apiBaseUrl: string = this.config.api.base;
    public readonly apiKey: string = this.config.api.key;

    private readonly __enableLogging: boolean = false;

    // private readonly _jwtLifeTime: number = 15 * 60 * 1000; // 15 minute
    // private readonly _jwtRefreshTokenLifeTime: number = 60 * 60 * 1000; // 60 min
    private readonly _jwtLifeTime: number = 60 * 60 * 1000; // 60 minutes - value from API 2.0
    private readonly _jwtRefreshTokenLifeTime: number = 2 * 60 * 60 * 1000; // 2 h - just to be sure - but on API 2.0 this value is 48h

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
        public cryptoService: CryptoService,
    ) { }

    /* only 2 public methods here: requestJWToken - for first call to init stuff, getCurrentTokens to use in all future calls that will require it */

    public reqestJWToken(memberCredentials: OLO.Authorization.IJWTokenRequest): Observable<boolean> {
        return this.httpClient.post<OLO.Authorization.IJWTokenObject>(`${Utils.HTTP.switchApi(this.apiBaseUrl)}/auth/login/member`, memberCredentials)
            .pipe(
                map(this._setupTokens.bind(this))
            );
    }

    public getCurrentTokens(): Observable<OLO.Authorization.IJWTokenObject> {
        if (!this._checkIfAccessTokenIsValid()) {

            let checkIfCanRefresh = this._checkIfRefreshIsPossible();
            if (this.__enableLogging) console.log('checkIfRefreshIsPossible:', checkIfCanRefresh);

            if (checkIfCanRefresh) {

                const currentTokens = this._getTokens();

                if (this.__enableLogging) console.log('get current tokens and try refresh:', currentTokens);
                return this._refreshJWToken(currentTokens.AccessToken, currentTokens.RefreshToken, this.apiKey)
                    .pipe(
                        map((response) => {
                            if (response) {
                                const refreshedTokens = this._getTokens();
                                if (this.__enableLogging) console.log('return refreshed:', currentTokens);
                                return refreshedTokens;
                            }
                        }),
                        take(1)
                    );
            } else {
                return throwError('JWT session expired');
            }
        }

        const tokens = this._getTokens();
        return of(tokens).pipe(take(1));
    }

    /* all helper methods required to support above scenarios */

    private _refreshJWToken(currentJWToken: string, refreshToken: string, clientAppKey: string = this.apiKey): Observable<boolean> {
        return this.httpClient.post<OLO.Authorization.IJWTokenObject>(`${Utils.HTTP.switchApi(this.apiBaseUrl)}/auth/login/refresh/${Utils.Strings.removeQuotesChars(refreshToken)}`, null, {
            headers: new HttpHeaders()
                .set('ClientAppKey', `${Utils.Strings.removeQuotesChars(clientAppKey)}`)
                .set('Authorization', `Bearer ${Utils.Strings.removeQuotesChars(currentJWToken)}`)
                .set('authExempt', 'true'),
        }).pipe(
            share(),
            map(this._setupTokens.bind(this)),
        );
    }

    private _setupTokens(tokens: OLO.Authorization.IJWTokenObject): boolean {
        try {

            this._storeTokens(this._encrypt(tokens));

            if (this.__enableLogging) console.log('JWT tokens setup');
            return true;

        } catch (ex) {

            if (this.__enableLogging) console.warn('Tokens setup error: ', ex);
            return false;
        }
    }


    private _checkIfRefreshIsPossible(): boolean {
        const tokens: OLO.Authorization.IJWTokenObject = this._getTokens();

        if (!tokens) {
            /* No tokens found */
            this.clearTokens();
            return false;
        }

        const validation: OLO.Authorization.IJWTTokensExpValid = this._validateTokens(tokens as OLO.Authorization.IJWTokenObject);

        if (!validation.RefreshToken) {
            /* No hope, refresh token is not valid */
            this.clearTokens();
            return false;
        }

        /* all ok - can refres */
        return true;
    }

    private _getTokens(): OLO.Authorization.IJWTokenObject {
        try {

            const encrypted: OLO.Authorization.IJWTokenObject = this._getEncryptedTokens();

            if (!encrypted.AccessToken || !encrypted.RefreshToken) {
                throwError('Tokens not found in storage.');
            }

            return this._decrypt(encrypted);

        } catch (ex) {
            if (this.__enableLogging) console.error('Error getting tokens: ', ex);

            return null;
        }
    }


    private _checkIfAccessTokenIsValid(): boolean {
        const tokens: OLO.Authorization.IJWTokenObject | boolean = this._getTokens();

        if (!tokens) { return false; }

        let validation: boolean = true;

        for (const key in (tokens as OLO.Authorization.IJWTokenObject)) {
            if (tokens[key] === false) {
                validation = false;
            }
        }

        const expiryDateValidation: OLO.Authorization.IJWTTokensExpValid = this._validateTokens(tokens as OLO.Authorization.IJWTokenObject);

        if (!expiryDateValidation.AccessToken) {
            validation = false;
        }

        return validation;
    }

    /* store/encryption helpers */

    private _encrypt(tokens: OLO.Authorization.IJWTokenObject): OLO.Authorization.IJWTokenObject {
        return {
            AccessToken: this.cryptoService.encrypt(tokens.AccessToken),
            RefreshToken: this.cryptoService.encrypt(tokens.RefreshToken),
        };
    }

    private _storeTokens(tokens: OLO.Authorization.IJWTokenObject): void {
        Utils.Storage.set(OLO.Enums.JWT.ACCESS_TOKEN as unknown as string, tokens.AccessToken);
        Utils.Storage.set(OLO.Enums.JWT.ACCESS_TOKEN_EXPIRES as unknown as string, new Date().getTime() + this._jwtLifeTime);

        Utils.Storage.set(OLO.Enums.JWT.REFRESH_TOKEN as unknown as string, tokens.RefreshToken);
        Utils.Storage.set(OLO.Enums.JWT.REFRESH_TOKEN_EXPIERS as unknown as string, new Date().getTime() + this._jwtRefreshTokenLifeTime);
    }

    private _getEncryptedTokens(): OLO.Authorization.IJWTokenObject {
        return {
            AccessToken: Utils.Storage.getItem(OLO.Enums.JWT.ACCESS_TOKEN as unknown as string),
            RefreshToken: Utils.Storage.getItem(OLO.Enums.JWT.REFRESH_TOKEN as unknown as string),
        };
    }

    private _decrypt(tokens: OLO.Authorization.IJWTokenObject): OLO.Authorization.IJWTokenObject {
        return {
            AccessToken: this.cryptoService.decrypt(tokens.AccessToken),
            RefreshToken: this.cryptoService.decrypt(tokens.RefreshToken),
        };
    }

    private _validateTokens(tokens: OLO.Authorization.IJWTokenObject): OLO.Authorization.IJWTTokensExpValid {
        const currentTime: number = new Date().getTime();
        const accessTokenExpiry: number = +Utils.Storage.getItem(OLO.Enums.JWT.ACCESS_TOKEN_EXPIRES as unknown as string);
        const refreshTokenExpiry: number = +Utils.Storage.getItem(OLO.Enums.JWT.REFRESH_TOKEN_EXPIERS as unknown as string);

        const result: OLO.Authorization.IJWTTokensExpValid = {
            AccessToken: accessTokenExpiry > currentTime,
            RefreshToken: refreshTokenExpiry > currentTime,
        };

        if (this.__enableLogging) console.log('jwt validation result:', result);

        return result;
    }

    public clearTokens(): void {
        if (this.__enableLogging) console.log('clear jwt tokens');
        Utils.Storage.remove(OLO.Enums.JWT.ACCESS_TOKEN as unknown as string);
        Utils.Storage.remove(OLO.Enums.JWT.ACCESS_TOKEN_EXPIRES as unknown as string);
        Utils.Storage.remove(OLO.Enums.JWT.REFRESH_TOKEN as unknown as string);
        Utils.Storage.remove(OLO.Enums.JWT.REFRESH_TOKEN_EXPIERS as unknown as string);
    }

}
