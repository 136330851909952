import { Injectable, Inject } from '@angular/core';
import { Store, select, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as State from '@shared/state/interface';
import * as Utils from '@shared/core/utils';
import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';

import * as StateModels from '../interface';

import { Observable, of, forkJoin, never } from 'rxjs';
import { switchMap, catchError, map, withLatestFrom, mergeMap } from 'rxjs/operators';


@Injectable()
export class LoyaltyProductProgramsEffects {
    @Effect() public resetLoyaltyProgramOnSignOut$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.MemberSignOut,
            ),
            switchMap(() => {
                return of(actions.LoyaltyProductProgramsReset());
            })
        );

    @Effect() public requestLoyaltyInfoForCurrentMemberOnAppInit$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.AppSettingsSuccessRequest,
                actions.MemberJwtDataResponseSuccess,
            ),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.isMemberAuthorizedJWT)
                    ),
                this._store
                    .pipe(
                        select(selectors.hasRequestedLoyaltyProductPrograms)
                    )
            ),
            switchMap(([action, isAuthorized, hasRequestedPrograms]) => {
                if (this._config.appMode !== IAppMode.ORDERING_ONLY && isAuthorized && !hasRequestedPrograms) {
                    return [
                        actions.LoyaltyProductProgramsRequest({ requestParams: { programName: 'hot' } }),
                        actions.LoyaltyProductProgramsRequest({ requestParams: { programName: 'cold' } })
                    ];
                }

                return never();
            })
        );

    @Effect() public requestLoyaltyProductPrograms$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.LoyaltyProductProgramsRequest
            ),
            mergeMap(({ requestParams }) => {
                return this._loyaltyAppService.apiGetLoyaltyProductPrograms(requestParams.programName)
                    .pipe(
                        map(payload => actions.LoyaltyProductProgramsSuccessRequest({ requestParams, payload })),
                        catchError(ex => {
                            console.error('apiGetLoyaltyProductPrograms', ex);
                            return of(actions.LoyaltyProductProgramsErrorRequest({ requestParams, ex }));
                        })
                    );
            })
        );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _actions$: Actions,
        private _store: Store<StateModels.IStateShared>,
        private _loyaltyAppService: Services.LoyaltyAppService,
    ) { }
}
