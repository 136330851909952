import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as Utils from '@shared/core/utils';

import { DrawCollapseService } from '@shared/core/directives/drawCollapse/draw-collapse.shared.service';

import { BehaviorSubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SubNavService {
    public scrollingTime: number = 500;
    public activationThreshold: number = 70;
    public isScrolling: boolean = false;

    public targetRegistry: { [registryId: string]: Map<number, HTMLElement>; } = {};
    public currentRegistry: { [registryId: string]: number; } = {};

    public currentTargets$: BehaviorSubject<{ [currentId: string]: number; }> = new BehaviorSubject(this.currentRegistry);
    public heightUpdate$: Subject<boolean> = new Subject();

    constructor(
        public drawCollapseService: DrawCollapseService,
        public router: Router,
    ) { }

    public forceHeightUpdate(): void {
        this.heightUpdate$.next(true);
    }

    public registerTarget(registryId: string, targetId: number, elem: HTMLElement, setDefault: boolean = true): void {
        /*
            Register target to scroll
            and set default currentTarget if not set
        */
        if (!this.targetRegistry[registryId]) {
            this.targetRegistry[registryId] = new Map();
        }

        this.targetRegistry[registryId].set(targetId, elem);

        if (!this.currentRegistry[registryId] && setDefault) {
            this.setCurrent(registryId, targetId);
        }
    }

    public setCurrent(registryId: string, targetId: number): void {
        if (this.isScrolling) return;
        /*
            Updates current element
        */
        this.currentRegistry[registryId] = targetId;

        this.currentTargets$.next(this.currentRegistry);
    }

    public removeTarget(registryId: string, targetId: number): void {
        /*
            Remove target areas and cleanup in current registry
        */
        if (!this.targetRegistry[registryId]) return;

        this.targetRegistry[registryId].delete(targetId);

        if (this.targetRegistry[registryId].size === 0) {
            delete this.targetRegistry[registryId];
        }

        this.currentTargets$
            .pipe(
                take(1)
            ).subscribe(obj => {
                if (obj[registryId] === targetId) {
                    if (this.targetRegistry[registryId]) {
                        this.setCurrent(registryId, null);
                    } else {
                        delete this.currentRegistry[registryId];

                        this.currentTargets$.next(this.currentRegistry);
                    }
                }
            });
    }

    public unsetRegistry(registryId: string): void {
        delete this.currentRegistry[registryId];
        delete this.targetRegistry[registryId];
    }

    public clear(): void {
        this.currentRegistry = {};
        this.targetRegistry = {};
        this.currentTargets$.next(this.currentRegistry);
    }

    public navToUrl(url: string): Promise<boolean> {
        return this.router.navigate([url]);
    }

    public globalScrollTo(registryId: string, targetId: number, index: number = null): void {
        const registry = this.targetRegistry[registryId];
        if (!registry) return;

        const elem: HTMLElement = registry.get(targetId);

        if (!elem) return;

        this.setCurrent(registryId, targetId);
        this.isScrolling = true;

        const additionalDrawerOffset: number = this.drawCollapseService.drawCanvasExpandedHeight;

        /*
            Due to draw directive and f** scrolling page ideas, we need to
            'help' scroller to scroll back to first element... so CS with 'index'
        */
        const targetOffset: number = index === 0 ? 0 : elem.offsetTop + additionalDrawerOffset;

        Utils.Scroll.smoothScrollTo(targetOffset, this.scrollingTime, 'easeInOutCubic', () => {
            setTimeout(() => {
                this.isScrolling = false;
            }, 200);
        });
    }
}
