import { createReducer, on, Action } from '@ngrx/store';
import { IOrderTypeImage } from './order-types-images.interface';
import * as actions from './order-types-images.actions';

const initialState: IOrderTypeImage[] = [];

export const orderTypesImagesReducerFn = createReducer(
    initialState,
    on(
        actions.OrderTypesImagesRequest,
        (state, action) => {
            const newToConcat: IOrderTypeImage[] = [];

            action.orderTypesIds.forEach(id => {
                if (state.find(image => image.Id === id)) {
                    return;
                }

                newToConcat.push({
                    Id: id,
                    isDownloading: true,
                    hasSucceeded: false,
                    hasFailed: false,
                    data: null,
                });
            });

            return [
                ...state.map((image: IOrderTypeImage) => {
                    if (action.orderTypesIds.find(productId => productId === image.Id)) {
                        return {
                            ...image,
                            isDownloading: true,
                            hasSucceeded: false,
                            hasFailed: false,
                        };
                    }

                    return image;
                }),
                ...newToConcat,
            ];

        }
    ),
    on(
        actions.OrderTypesImagesSuccessRequest,
        (state, action) => {

            return state.map((image: IOrderTypeImage) => {

                const foundItem: APIv1.ImageUrlModel = action.payload.find(newImage => newImage.ParentId === image.Id);

                if (foundItem) {

                    return {
                        ...image,
                        data: { ...foundItem },
                        isDownloading: false,
                        hasSucceeded: true,
                        hasFailed: false,
                    };
                }

                return {
                    ...image,
                    isDownloading: false,
                    hasSucceeded: true,
                    hasFailed: false,
                };

            });
        }
    ),
    on(
        actions.OrderTypesImagesErrorRequest,
        (state, action) => {

            return state.map((image: IOrderTypeImage) => {
                if (action.orderTypesIds.find(productId => productId === image.Id)) {
                    return {
                        ...image,
                        isDownloading: false,
                        hasSucceeded: false,
                        hasFailed: true,
                    };
                }

                return image;
            });
        }
    ),
);

export function orderTypesImagesReducer(state: IOrderTypeImage[] | undefined, action: Action) {
    return orderTypesImagesReducerFn(state, action);
}
