import { createReducer, on, Action } from '@ngrx/store';
import { IOnlineOrder } from './online-order.interface';
import * as actions from './online-order.actions';

const initialState: IOnlineOrder = {
    createRequest: {
        isCreating: false,
        hasSucceeded: false,
        hasFailed: false,
    },
    updateRequest: {
        isUpdating: false,
        hasSucceeded: false,
        hasFailed: false,
    },
    downloadRequest: {
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: false,
    },
    recalculateRequest: {
        isRecalculating: false,
        hasSucceeded: false,
        hasFailed: false,
        data: null,
    },
    sendEmailReceipt: {
        isSending: false,
        hasSucceeded: false,
        hasFailed: false,
        orderId: null,
    },
    orderType: null,

    data: null,
};

export const onlineOrdersReducerFn = createReducer(
    initialState,
    on(
        actions.OnlineOrderStateReset,
        (state, action) => ({
            ...initialState
        })
    ),
    on(
        actions.OnlineOrderSendEmailReceiptRequest,
        (state, action) => ({
            ...state,
            sendEmailReceipt: {
                ...state.sendEmailReceipt,
                isSending: true,
                hasSucceeded: false,
                hasFailed: false,
                orderId: action.orderId,
            }
        })
    ),
    on(
        actions.OnlineOrderSendEmailReceiptSuccessRequest,
        (state, action) => ({
            ...state,
            sendEmailReceipt: {
                ...state.sendEmailReceipt,
                isSending: false,
                hasSucceeded: true,
                hasFailed: false,
                orderId: action.orderId,
            }
        })
    ),
    on(
        actions.OnlineOrderSendEmailReceiptErrorRequest,
        (state, action) => ({
            ...state,
            sendEmailReceipt: {
                ...state.sendEmailReceipt,
                isSending: false,
                hasSucceeded: false,
                hasFailed: true,
                orderId: action.orderId,
            }
        })
    ),
    on(
        actions.OnlineOrderTypeSelect,
        (state, action) => ({
            ...state,
            orderType: action.orderType
        })
    ),
    on(
        actions.OnlineOrderTypeUpdateValues,
        (state, action) => ({
            ...state,
            orderType: {
                ...state.orderType,
                Details: state.orderType.Details.map(obj => {
                    const found = action.values.find(val => val.Id === obj.Id);
                    if (found) {
                        return {
                            ...obj,
                            _Value: found._Value
                        };
                    }
                    return obj;
                })
            }
        })
    ),
    on(
        actions.OnlineOrderResetOrderTypeValues,
        (state, action) => ({
            ...state,
            orderType: {
                ...state.orderType,
                Details: state.orderType.Details.map(obj => {
                    const newObj = { ...obj };
                    delete newObj._Value;
                    return newObj;
                })
            }
        })
    ),
    on(
        actions.OnlineOrderClearPostOrderRequestFlags,
        (state, action) => ({
            ...state,
            createRequest:
            {
                isCreating: false,
                hasSucceeded: false,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.OnlineOrderCreateRequest,
        (state, action) => ({
            ...state,
            createRequest: {
                isCreating: true,
                hasSucceeded: false,
                hasFailed: false,
            }
        })
    ),
    on(
        actions.OnlineOrderCreateSuccessRequest,
        (state, action) => ({
            ...state,
            createRequest: {
                isCreating: false,
                hasSucceeded: true,
                hasFailed: false,
            },
            data: {
                ...state.data, // important. Some properties are filled only during recalculate call we don't want to override them
                ...{ ...action.payload },
            }

        })
    ),
    on(
        actions.OnlineOrderCreateErrorRequest,
        (state, action) => ({
            ...state,
            createRequest: {
                isCreating: false,
                hasSucceeded: false,
                hasFailed: true,
            },
        })
    ),
    on(
        actions.OnlineOrderUpdateRequest,
        (state, action) => ({
            ...state,
            updateRequest: {
                ...state.createRequest,
                isUpdating: true,
                hasSucceeded: false,
                hasFailed: false,
            }
        })
    ),
    on(
        actions.OnlineOrderUpdateSuccessRequest,
        (state, action) => ({
            ...state,
            updateRequest: {
                ...state.createRequest,
                isUpdating: false,
                hasSucceeded: true,
                hasFailed: false,
            },
            data: {
                ...state.data,
                ...action.payload,
            }
        })
    ),
    on(
        actions.OnlineOrderUpdateErrorRequest,
        (state, action) => ({
            ...state,
            updateRequest: {
                ...state.createRequest,
                isUpdating: false,
                hasSucceeded: false,
                hasFailed: true,
            },
        })
    ),
    on(
        actions.OnlineOrderRequest,
        (state, action) => ({
            ...state,
            downloadRequest: {
                isDownloading: true,
                hasSucceeded: false,
                hasFailed: false,
            }
        })
    ),
    on(
        actions.OnlineOrderSuccessRequest,
        (state, action) => ({
            ...state,
            downloadRequest: {
                isDownloading: false,
                hasSucceeded: true,
                hasFailed: false,
            },
            data: action.payload,
        })
    ),
    on(
        actions.OnlineOrderErrorRequest,
        (state, action) => ({
            ...state,
            downloadRequest: {
                isDownloading: false,
                hasSucceeded: false,
                hasFailed: true,
            }
        })
    ),
    on(
        actions.OnlineOrderRecalculateRequest,
        (state, action) => ({
            ...state,
            recalculateRequest: {
                ...state.recalculateRequest,
                isRecalculating: true,
                hasSucceeded: false,
                hasFailed: false,
            }
        })
    ),
    on(
        actions.OnlineOrderRecalculateSuccessRequest,
        (state, action) => ({
            ...state,
            recalculateRequest: {
                isRecalculating: false,
                hasSucceeded: true,
                hasFailed: false,
                data: action.payload
            },
            data: {
                ...state.data,
                ...action.payload,
            }
        })
    ),
    on(
        actions.OnlineOrderRecalculateErrorRequest,
        (state, action) => ({
            ...state,
            recalculateRequest: {
                ...state.recalculateRequest,
                isRecalculating: false,
                hasSucceeded: false,
                hasFailed: true,
            }
        })
    ),
);


export function onlineOrdersReducer(state: IOnlineOrder | undefined, action: Action) {
    return onlineOrdersReducerFn(state, action);
}
